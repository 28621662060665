const React = require('react');
const ReactDOM = require('react-dom');
const EventEmitter = require('events'); 
const {campaign,globalDataListener,areSameDeep,areSameDeepInst} = require('../lib/campaign.js');
const {displayMessage,snackMessage} = require('./notification.jsx');
import { Stage, Layer, Rect, Circle, Star, Group, Image, Line, Text, Path,Arrow } from 'react-konva';
import PopoutWindow from './react-popout.jsx';
const {MapPickList} = require('./rendermaps.jsx');
import Paper from '@material-ui/core/Paper';
const {HoverMenu} = require( "./hovermenu.jsx");
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {getAnchorPos,maxLen,isMobile} = require('./stdedit.jsx');
const {getTokenInfo} = require('./encountermonsterlist.jsx');
const {ShowHandout,PickHandout} = require('./handouts.jsx');
const {MapDialog} = require('./rendermaps.jsx');
const {PinMenu} = require('./pins.jsx');
const {imageCache} = require('./imagecache.jsx');
const MapSharedView2 = null && require('./map2.jsx').MapSharedView;

import sizeMe from 'react-sizeme';
const {sizeScaleMap,colorContrastMap} = require('../lib/stdvalues.js');

const bgColor = "#fdf1dc";
const fogColor = "#282828";
const toolHeight=34;
const skullUrl = "/scross.png";
const heartbeatUrl = "/heartbeat.png";

class Map extends React.Component {
    constructor(props) {
        super(props);

        if (props.mapRef) {
            props.mapRef(this);
        }

        popupState.setGrid(campaign.getPrefs().grid||false);
        this.state = this.getState();

        this.datachangeFn = this.datachange.bind(this);
    }

    componentDidMount() {
        this.unsubscribe = popupState.subscribe(this.datachangeFn);
        globalDataListener.onChangeCampaignContent(this.datachangeFn,"adventure");
    }

    componentWillUnmount() {
        popupState.unsubscribe(this.datachangeFn);
        globalDataListener.removeCampaignContentListener(this.datachangeFn, "adventure");
    }

    datachange(){
        const newState = this.getState();
        this.setState(newState);
    }

    getState() {
        const handouts = campaign.getHandouts();
        return({
            popupWidth:popupState.popupWidth||0, 
            popupHeight:popupState.popupHeight||0,
            showPopup:popupState.showPopup,
            grid:popupState.grid||false,
            handout:(handouts.showHandout && handouts.mru && handouts.mru.length)?handouts.mru[0]:null
        });
    }

	render() {
        const t=this;
        const size = Object.assign({}, this.props.size);
        return <div className="w-100 h-100 overflow-hidden">
            <div style={{position:"absolute", left:0, top:0, height:size.height, width:size.width, overflow:"hidden"}} >
                <MapPane 
                    size={size} 
                    popupWidth={this.state.popupWidth} 
                    popupHeight={this.state.popupHeight}
                    showPopup={this.state.showPopup}
                    showImagePick={!this.props.disableImagePicker}
                    mapRef={function(ref) {t.map=ref;}}
                    combatants={this.props.combatants}
                    onChangeCombatantPos={this.props.onChangeCombatantPos}
                    onTapToken={this.props.onTapToken}
                    onTapMap={this.props.onTapMap}
                    pinRules={this.props.pinRules}
                    onGoToEncounter={this.props.onGoToEncounter}
                    onAddEncounter={this.props.onAddEncounter}
                    onAddPreviousEncounter={this.props.onAddPreviousEncounter}
                    onGoToMap={this.props.onGoToMap}
                    onGoToBook={this.props.onGoToBook}
                    bookname={this.props.bookname}
                    chapter={this.props.chapter}
                    section={this.props.section}
                    subsection={this.props.subsection}
                    mapPos={this.props.mapPos}
                    eventSync={this.props.eventSync}
                    grid={this.state.grid}
                    showMessage={this.props.showMessage}
                    showPickHandout={this.props.showPickHandout}
                    selected={this.props.selected}
                    toggleSelected={this.props.toggleSelected}
                />
            </div>
            {this.state.handout?<ShowHandout handout={this.state.handout} onClick={this.hideHandout.bind(this)} size={this.props.size}/>:null}

        </div>;
    }

    hideHandout(){
        const handouts = Object.assign({}, campaign.getHandouts());
        handouts.showHandout = false;
        campaign.updateCampaignContent("adventure", handouts);
    }
}

class MapPane extends React.Component {
    constructor(props) {
        super(props);

        if (props.mapRef) {
            props.mapRef(this);
        }

        this.state = {
            fogTool:"rect",
            mode:"move",
            drawColor:"#000000",
            lineWidth:5,
            mapPane:{x:0,y:0, diameter:1},
        };
        Object.assign(this.state, this.getPreferences());
        this.updateMapAnnotationsFn = this.updateMapAnnotations.bind(this);
        this.updatePreferencesFn = this.updatePreferences.bind(this);
        this.updateSelectedMapFn = this.updateSelectedMap.bind(this);
    }

    getPreferences() {
        const prefs = {};
        const userSettings = campaign.getUserSettings();

        prefs.noMoveGroupPin = userSettings.noMoveGroupPin || false;
        return prefs;
    }

    updateSelectedMap() {
        const t=this;
        if (this.selectedMapTimer){
            clearTimeout(this.selectedMapTimer);
        }
        this.selectedMapTimer = setTimeout(function () {
            t.selectedMapTimer=null;
            const selectedMap = campaign.getPrefs().selectedMap;
            if (t.state.imageName != selectedMap) {
                t.loadInitialMap();
            }
        },200);
    }

    componentDidMount() {
        if (this.props.mapName) {
            this.loadImageByName(this.props.mapName);
        } else if (this.props.mapPos && this.props.mapPos.mapName) {
            const map = campaign.getMapInfo(this.props.mapPos.mapName);
            this.saveAnnotations({mapPos:this.props.mapPos});
            this.loadImage(map);
        } else if (!this.state.image && !this.props.useMapName) {
            this.loadInitialMap();
        }
        this.updatePopup();
        globalDataListener.onChangeCampaignContent(this.updateMapAnnotationsFn,"monsters");
        globalDataListener.onChangeCampaignContent(this.updateMapAnnotationsFn,"players");
        globalDataListener.onChangeCampaignContent(this.updateMapAnnotationsFn,"art");
        globalDataListener.onChangeCampaignContent(this.updateMapAnnotationsFn,"maps");
        globalDataListener.onChangeCampaignContent(this.updateMapAnnotationsFn,"mapextra");
        globalDataListener.onChangeCampaignContent(this.updateMapAnnotationsFn,"pins");
        globalDataListener.onChangeUserSettings(this.updatePreferencesFn);
        if (!this.props.useMapName) {
            globalDataListener.onChangeCampaignSettings(this.updateSelectedMapFn);
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.updateMapAnnotationsFn, "monsters");
        globalDataListener.removeCampaignContentListener(this.updateMapAnnotationsFn, "players");
        globalDataListener.removeCampaignContentListener(this.updateMapAnnotationsFn, "art");
        globalDataListener.removeCampaignContentListener(this.updateMapAnnotationsFn, "maps");
        globalDataListener.removeCampaignContentListener(this.updateMapAnnotationsFn, "mapextra");
        globalDataListener.removeCampaignContentListener(this.updateMapAnnotationsFn, "pins");
        globalDataListener.removeUserSettingsListener(this.updatePreferencesFn);
        if (!this.props.useMapName) {
            globalDataListener.removeCampaignSettingsListener(this.updateSelectedMapFn);
        }
        this.cancelPingCheck();
        this.stopMeasure();

        if (this.selectedMapTimer){
            clearTimeout(this.selectedMapTimer);
            this.selectedMapTimer=null;
        }
    }

    updateMapAnnotations() {
        if (this.state.imageName) {
            this.loadAnnotations(this.state.imageName);
        } 
    }

    updatePreferences() {
        this.setState(this.getPreferences());
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.mapName != prevProps.mapName) {
            this.loadImageByName(this.props.mapName);
        }

        if (this.props.mapPos != prevProps.mapPos) {
            const newMapPos = this.props.mapPos;
            if (newMapPos) {
                if (newMapPos.mapName && (newMapPos.mapName != this.state.imageName)) {
                    const map = campaign.getMapInfo(newMapPos.mapName);
                    this.loadImage(map);
                    this.saveAnnotations({mapPos:newMapPos});
                } else {
                    const setMapPos=Object.assign({}, this.state.mapPos||{});
                    Object.assign(setMapPos, newMapPos);
                    this.saveAnnotations({mapPos:setMapPos});
                }
            }
        }

        if (!this.props.noPopup && ((prevState.image != this.state.image) ||
            (prevState.pixelsPerFoot != this.state.pixelsPerFoot) ||
            (prevState.gridSize != this.state.gridSize) ||
            (prevState.gridxShift != this.state.gridxShift) ||
            (prevState.gridyShift != this.state.gridyShift) ||
            (prevState.imageName != this.state.imageName) ||
            (prevState.imageSrc != this.state.imageSrc) ||
            (prevProps.combatants != this.props.combatants) ||
            (prevState.coverObjs != this.state.coverObjs) || 
            (prevState.drawObjs != this.state.drawObjs) || 
            (prevState.pinList != this.state.pinList) ||
            (prevState.mapPos != this.state.mapPos))) {
            this.updatePopup();
        }
    }

    loadedImage() {
        this.setState({tokensLoaded:imageCache.counter});
    }

    onDragDropGroup(e) {
        e.cancelBubble = true;
        this.cancelPingCheck();
        const combatants = this.props.combatants;
        const imageName = this.state.imageName.toLowerCase();
        const ax = (e.target.x()-e.target.attrs.origX);
        const ay = (e.target.y()-e.target.attrs.origY);
        const updates=[];

        for (let i in combatants) {
            const c=combatants[i];
            const cInfo = getTokenInfo(c);
            if (c.tokenMap && (c.tokenMap.toLowerCase() == imageName) && ((c.state == "active")||!c.state) && 
                (cInfo.group=="pc")) {
                updates.push({index:i, x:c.tokenX+ax, y:c.tokenY+ay});
            }
        }
        this.props.onChangeCombatantPos(updates);
        this.updatePointer();
    }

    onDragSelection(e) {
        e.cancelBubble = true;
        this.cancelPingCheck();
        const {combatants,selected,onChangeCombatantPos} = this.props;
        const imageName = this.state.imageName.toLowerCase();
        const ax = (e.target.x()-e.target.attrs.origX);
        const ay = (e.target.y()-e.target.attrs.origY);
        const updates=[];

        if (selected) {
            for (let i in combatants) {
                const c=combatants[i];
                if (c.tokenMap && (c.tokenMap.toLowerCase() == imageName) && selected[c.id]) {
                    updates.push({index:i, x:c.tokenX+ax, y:c.tokenY+ay});
                }
            }
            onChangeCombatantPos(updates);
        }
        this.updatePointer();
    }

    onDragDropToken(i, e) {
        e.cancelBubble = true;
        const x=e.target.x();
        const y=e.target.y();
    
        this.props.onChangeCombatantPos(i, x, y);
        this.updatePointer();
    }

    onRotateToken(i, deg) {
        const c = this.props.combatants[i];
        this.props.onChangeCombatantPos(i, c.tokenX, c.tokenY, deg);
        this.updatePointer();
    }

    onDragMoveToken(i, e) {
        this.cancelPingCheck();
    }

    onTapToken(i, e) {
        if (this.props.onTapToken) {
            this.props.onTapToken(i, e);
        }
    }

    updatePopup() {
        if (this.props.noPopup) {
            return;
        }
        const t=this;

        const mapInfo = {
            image:this.state.image,
            imageName:this.state.imageName,
            imageSrc:this.state.imageSrc,
            pixelsPerFoot:this.state.pixelsPerFoot,
            gridSize:this.state.gridSize,
            gridxShift:this.state.gridxShift,
            gridyShift:this.state.gridyShift,
            coverObjs:this.state.coverObjs,
            drawObjs:this.state.drawObjs,
            mapPos:this.state.mapPos,
            combatants:this.props.combatants,
            pinList:this.state.pinList,
        }

        if (this.savePopup) {
            clearTimeout(this.savePopup);
        }

        this.savePopup = setTimeout(function() {
            popupState.setMap(mapInfo);
            t.savePopup=null;
        }, 10);
    }

    getCenter() {
        const mapPos = this.state.mapPos;

        if (!mapPos) {
            return {x:1,y:1};
        }

        var x = mapPos.x,
            y = mapPos.y;

        return {x:x, y:y};
    }

	render() {
        var xShift=0, yShift=0, canvasXShift=0, canvasYShift=0, xtraXCanvas=0;
        var imgScale=1;
        var popupHeight = this.props.popupHeight||0,
            popupWidth = this.props.popupWidth||0;
        var rectWidth=0,rectHeight=0;
        var sizeHeight = this.props.size.height - toolHeight;
        var sizeWidth = this.props.size.width;
        var mapPos = this.state.mapPos;
        var stage = null;
        const pixelsPerFoot = this.state.pixelsPerFoot||1;
        const width = this.state.imgWidth/pixelsPerFoot;
        const height = this.state.imgHeight/pixelsPerFoot;

        if (this.state.image) {
            if (!mapPos || isNaN(mapPos.x)|| isNaN(mapPos.y) || isNaN(mapPos.diameter)) {
                mapPos = this.getShowAllMapPos();
            } 
    
            if (!this.props.showPopup || !popupHeight || !popupWidth) {
                if (sizeWidth > sizeHeight) {
                    imgScale = (sizeHeight/(mapPos.diameter||50))||1;
                } else {
                    imgScale = (sizeWidth/(mapPos.diameter||50)) ||1;
                }
                canvasXShift = sizeWidth/2 - (mapPos.x*imgScale);
                canvasYShift = sizeHeight/2 - (mapPos.y*imgScale);
            } else {
                const divProp = sizeHeight/sizeWidth;
                const popupProp = popupHeight/popupWidth;

                if (divProp > popupProp){
                    rectWidth = sizeWidth;
                    rectHeight = rectWidth * popupHeight/popupWidth;
                } else {
                    rectHeight = sizeHeight;
                    rectWidth = rectHeight * popupWidth/popupHeight;
                }

                if (rectWidth > rectHeight) {
                    imgScale = (rectHeight/(mapPos.diameter||50))||1;
                } else {
                    imgScale = (rectWidth/(mapPos.diameter||50)) ||1;
                }

                canvasXShift = rectWidth/2 - (mapPos.x*imgScale) + xtraXCanvas;
                canvasYShift = rectHeight/2 - (mapPos.y*imgScale);

                xShift=mapPos.x-(rectWidth/2/imgScale);
                yShift=mapPos.y-(rectHeight/2/imgScale);;
                rectWidth = rectWidth/imgScale;
                rectHeight = rectHeight/imgScale;
            }

            this.scale = imgScale;
            this.canvasXShift=canvasXShift;
            this.canvasYShift=canvasYShift;
            this.rectWidth=rectWidth;
            this.rectHeight=rectHeight;
            this.rectXShift = xShift;
            this.rectYShift = yShift;

            stage = <Stage 
                height={sizeHeight||10} 
                width={sizeWidth||10} 
                onMouseMove={this.mouseMove.bind(this)}
                onMouseDown={this.mouseDown.bind(this)}
                onMouseUp={this.mouseUp.bind(this)}
                onContextMenu={this.onContextMenu.bind(this)}
                onMouseLeave={this.mouseLeave.bind(this)}
                onTouchMove={this.touchMove.bind(this)}
                onTouchStart={this.touchStart.bind(this)}
                onTouchEnd={this.touchEnd.bind(this)}
                onWheel={this.wheel.bind(this)}
                onDragMove={this.updatePointer.bind(this)}
                ref={this.saveStage.bind(this)}
                x={canvasXShift}
                y={canvasYShift}
                scaleX={imgScale}
                scaleY={imgScale}
            >
                <Layer>
                    <Image image={this.state.image} width={width} height={height}/>
                    {this.props.grid?<Grid scale={imgScale} gridxShift={this.state.gridxShift||0} gridyShift={this.state.gridyShift||0} width={sizeWidth} height={sizeHeight} mapX={canvasXShift} mapY={canvasYShift}/>:null}
                </Layer>
                <Layer>
                    {this.getDrawLayer()}
                </Layer>
                <Layer>
                    {this.getFogHitRegions()}
                    {this.getPinTokens(this.state.mode != "uncover")}
                    <Tokens
                        combatants={this.props.combatants}
                        imageName={this.state.imageName}
                        pixelsPerFoot={this.state.pixelsPerFoot}
                        dragFn={!this.doMeasure && this.onDragDropToken.bind(this)}
                        eventSync={this.props.eventSync}
                        moveFn={this.onDragMoveToken.bind(this)}
                        rotateFn={this.onRotateToken.bind(this)}
                        onTap={this.onTapToken.bind(this)}
                        onMoveGroup={this.onDragDropGroup.bind(this)}
                        moveObjects={(this.state.mode=="select")}
                        scale={this.scale}
                        noMoveGroupPin={this.state.noMoveGroupPin}
                        cancelPingCheck={this.cancelPingCheck.bind(this)}
                        selected={this.props.selected}
                        onMoveSelected={this.onDragSelection.bind(this)}
                        toggleSelected={this.props.toggleSelected}
                    />
                </Layer>
                {this.getFogLayer()}
                <Layer>
                    {(this.props.showPopup && rectWidth)?<Rect
                        width={rectWidth}
                        height={rectHeight}
                        strokeScaleEnabled={false}
                        x={xShift}
                        y={yShift}
                        shadowBlur={5}
                        stroke="yellow"
                        onMouseEnter={this.enterViewport.bind(this)}
                        onMouseLeave={this.leaveViewport.bind(this)}
                        listening={false}
                    />:null}
                    {this.getFogRegions()}
                    {this.getMarker()}
                    {this.getMeasureTool()}
                    <MapPings gridSize={this.state.gridSize} scale={imgScale}/>
                </Layer>
            </Stage>;
        } else if (this.state.loadingImage) {
            stage = <div className="pa2 defaultbackground titlecolor f2">Loading map {this.state.loadingImage}...</div>;
        } else if (this.props.showImagePick) {
            stage = <div className="pa2 defaultbackground">
                {this.props.showMessage}
                <div className="tc">
                    <MapPickList onPickMap={this.hidePicker.bind(this)}/>
                </div>
            </div>;
        }

        return <div className={this.props.className}>
            {this.getMapMenu()}
            <div onContextMenu={function(e){e.preventDefault()}} className="absolute" style={{backgroundColor:fogColor}}>
                {stage}
            </div>
            {this.getPinDetails()}
        </div>;
    }

    getMapMenu() {
        const mode = this.state.mode;
        const fogTool = this.state.fogTool;

        return  <div className="bb defaultbackground pa--2 titlecolor flex item-start" style={{height:toolHeight}}>
            <ToolButton hidden={this.props.noPopup || isMobile()} title="project" onMouseDown={this.togglePopup.bind(this)} selected={this.props.showPopup} icon="fas fa-share-square"/>
            <ToolButton title="select" selected={mode=="select"} onMouseDown={this.setMode.bind(this, "select")} icon="fas fa-mouse-pointer"/>
            <ToolButton title="move" selected={mode=="move"} onMouseDown={this.setMode.bind(this, "move")} icon="fas fa-arrows-alt"/>
            <HoverMenu placement="bottom" buttonMenu={<ToolButton  icon="fas fa-search-plus"/>}>{this.getSlider(this.scale, this.changeSliderScale.bind(this))}</HoverMenu>
            <ToolButton title="grid" selected={this.props.grid} onMouseDown={this.toggleGrid.bind(this)} icon="fas fa-square-full" selectedIcon="fas fa-th-large"/>
            {this.props.showImagePick?<MapPickList showButtonMenu onPickMap={this.hidePicker.bind(this)} onClickAltArtwork={this.onClickAltArtwork.bind(this)} onGoToPin={this.onGoToPin.bind(this)} currentMap={this.state.imageName}><ToolButton icon="fas fa-images"/></MapPickList>:null}
            <HoverMenu placement="bottom" buttonMenu={<ToolButton  icon="fas fa-map-marker"/>}>{this.getPinMenu()}</HoverMenu>
            {this.props.showPickHandout?<PickHandout allowDelete setActive><ToolButton title="pick handout" icon="fas fa-scroll"/></PickHandout>:null}
            <ToolButton title="center player view" hidden={this.props.noPopup} onMouseDown={this.synchMap.bind(this)} icon="fas fa-magnet"/>
            <div className="flex-auto"/>
            <HoverMenu placement="bottom" buttonMenu={<ToolButton hidden={this.props.noCover&&this.props.noBuildFogRegions} title="fog" icon="fas fa-eye"/>}>{this.getFogMenu()}</HoverMenu>
            {(!this.props.noBuildFogRegions&&mode=="uncover" && fogTool=="region")?<ToolButton title="fog regions" selected={mode=="uncover" && fogTool=="region"} onMouseDown={this.setFogTool.bind(this, "region",null)} icon="fas fa-object-ungroup"/>:null}
            <ToolButton title="polygon unfog" hidden={this.props.noCover} selected={mode=="uncover" && fogTool=="polygon"} onMouseDown={this.setFogTool.bind(this, "polygon",null)} icon="fas fa-draw-polygon"/>
            <ToolButton title="rectangle unfog" hidden={this.props.noCover} selected={mode=="uncover" && fogTool=="rect"} onMouseDown={this.setFogTool.bind(this, "rect",null)} icon="fas fa-vector-square"/>
            <HoverMenu placement="bottom" hidden={this.props.noCover} buttonMenu={<ToolButton title="paint unfog" selected={mode=="uncover" && fogTool=="circle"} onMouseDown={this.setFogTool.bind(this, "circle",this.state.diameter||5)} icon="fas fa-brush"/>}>{this.getPaintFogMenu()}</HoverMenu>
            <HoverMenu placement="bottom" buttonMenu={<ToolButton hidden={this.props.noCover&&this.props.noBuildFogRegions} selected={mode=="draw"} icon={(mode=="draw"&&this.state.drawColor=="clear")?"fas fa-eraser":"fas fa-pen"}/>}>{this.getDrawMenu()}</HoverMenu>
            <ToolButton title="settings" onMouseDown={this.showMapSettings.bind(this,true)} icon="fas fa-cog"/>
            <MapDialog open={this.state.showMapSettings} name={this.state.imageName} onClose={this.showMapSettings.bind(this,false)}/>
        </div>;
    }

    synchMap() {
        campaign.updateAdventureView({cversion:campaign.newUid()});
    }

    showMapSettings(showMapSettings) {
        this.setState({showMapSettings});
    }

    getSlider(scale, onScale) {
        const logValue = Math.log(scale)/Math.log(1.1);

        return <Paper>
            <div className="tc titlecolor pa1 minw25">{Math.trunc(scale*100)+"%"}</div>
            {this.combatantsOnMap()?<div className="tc titlecolor pa1 hover-bg-gray-50 " onClick={this.showFit.bind(this)}>fit</div>:null}
            <div className="tc titlecolor"><span className="far fa-plus-square f1 pa1 hover-bg-gray-50" onClick={function(e) {onScale(e,logValue+1)}}/></div>
            <div className="h6 flex mv1">
                <div className="flex-auto"/>
                <Slider classes={{root:"ph4"}} orientation="vertical" value={logValue} min={-30} max={30} step={1} onChange={onScale}/>
                <div className="flex-auto"/>
            </div>
            <div className="tc titlecolor"><span className="far fa-minus-square f1 pa1 hover-bg-gray-50" onClick={function(e) {onScale(e,logValue-1)}}/></div>
            <div className="tc titlecolor pa1 hover-bg-gray-50 " onClick={this.showAll.bind(this)}>all</div>
            <div className="tc titlecolor pa1 hover-bg-gray-50 " onClick={this.showZoom.bind(this)}>zoom</div>
        </Paper>;
    }

    getPinMenu() {
        return <Paper className="pv2">
            <div className="flex items-center pa1 hoverhighlight" onClick={this.togglePin.bind(this, "noMoveGroupPin")}>
                <span className={"fas fa-map-marker mr2"+(this.state.noMoveGroupPin?" flightgray": " forange")}/><span>Move Group</span>
            </div>
        </Paper>;
    }

    combatantsOnMap() {
        const combatants = this.props.combatants||[];
        if (!this.state.imageName) {
            return false;
        }

        for (let i in combatants) {
            const c = combatants[i];
            if (c.tokenMap && c.tokenMap.toLowerCase() == this.state.imageName.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    fitMapPos() {
        const combatants = this.props.combatants||[];
        var minX=1000000, maxX=-1000000, minY=1000000, maxY=-1000000;
        var found=false;
        if (!this.state.imageName) {
            return this.state.mapPos;
        }

        for (let i in combatants) {
            const c = combatants[i];
            if (c.tokenMap && (c.tokenMap.toLowerCase() == this.state.imageName.toLowerCase())) {
                found = true;
                minX = Math.min(minX, Number(c.tokenX));
                maxX = Math.max(maxX, Number(c.tokenX));
                minY = Math.min(minY, Number(c.tokenY));
                maxY = Math.max(maxY, Number(c.tokenY));
            }
        }

        if (!found) {
            return this.state.mapPos;
        }

        var diameter = Math.max(maxX-minX, maxY-minY)+10;

        // show at least 80 feet
        if (diameter < 80) {
            diameter = 80;
        }

        return {
            diameter:diameter,
            x:(maxX+minX)/2,
            y:(maxY+minY)/2
        };
    }

    togglePin(name) {
        const pref = {};
        pref[name] = !this.state[name];
        campaign.updateUserSettings(pref);
    }

    showAll() {
        const mapPos = this.getShowAllMapPos();

        this.updateMapPos(mapPos);
    }

    showFit() {
        const mapPos = this.fitMapPos();

        this.updateMapPos(mapPos);
    }

    showZoom() {
        const mapPos = Object.assign({}, this.state.mapPos);
        const width = this.state.imgWidth/72*5;
        const height = this.state.imgHeight/72*5;
        mapPos.diameter = Math.min(width,height);

        this.updateMapPos(mapPos);
    }

    changeSliderScale(e, value) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setScale(Math.pow(1.1, value));
    }

    updateMapPos(mapPos) {
        this.saveAnnotations({mapPos:mapPos});
    }

    setScale(newScale){
        const mapPos = Object.assign({}, this.state.mapPos);

        mapPos.diameter =this.scale/newScale*mapPos.diameter;
        this.updateMapPos(mapPos);
    }

    setCoverObjs(coverObjs) {
        this.saveAnnotations({coverObjs});
    }

    setDrawObjs(drawObjs) {
        this.saveAnnotations({drawObjs});
    }

    setCoverRegions(coverRegions){
        this.setState({coverRegions});

        const mapInfo=campaign.getMapInfo(this.state.imageName);
        if (!mapInfo) {
            return;
        }
        const newMapInfo=Object.assign({}, mapInfo);
        newMapInfo.coverRegions = coverRegions;
        
        campaign.updateCampaignContent("maps", newMapInfo);
    }

    toggleGrid() {
        if (this.props.onToggleGrid) {
            this.props.onToggleGrid();
        } else {
            popupState.setGrid(!this.props.grid);
        }
    }

    togglePopup(e) {
        if (e) {
            e.preventDefault();
        }
        if (!campaign.secondScreen) {
            displayMessage(<span>You need a subscription to activate the second screen.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable this capability.</span>);
            return;
        }
        popupState.setPopupState(!this.props.showPopup);
    }

    enterViewport(){
    }

    leaveViewport() {
        this.stage.container().style.cursor = 'default';
    }

    setMode(m, e) {
        this.setState({mode:m});
    }

    saveStage(r) {
        this.stage = r;
    }

    hidePicker(imageInfo) {
        if (imageInfo) {
            var newMapPos ={};
            const mapextra = campaign.getMapExtraInfo(imageInfo.name);
            const mapPos = mapextra?mapextra.mapPos:imageInfo.mapPos;
            if (mapPos) {
                Object.assign(newMapPos, mapPos);
            }

            newMapPos.mapName = imageInfo.name;
            this.props.onGoToMap(newMapPos);
        }
    }

    loadImage(imageInfo) {
        const t=this;
        if (imageInfo) {
            const mapextra = campaign.getMapExtraInfo(imageInfo.name);
            let useart = mapextra && mapextra.altImage;
            if (!useart || !(imageInfo.artList||[]).includes(useart)){
                useart = imageInfo.art;
            }

            const art = campaign.getArtInfo(useart)||imageInfo;
            const url = art.url;
            this.loadAnnotations(imageInfo.name);
            const image = imageCache.getImage(url, this.finishLoadImage.bind(this,imageInfo,url), function (err) {
                t.setState({image:null, imageName:null, imageSrc:null, imageInfo:null, coverObjs:[], loadingImage:false});
                console.log("error getting image src", err);
            });
            if (image) {
                setTimeout(function () {
                    t.finishLoadImage(imageInfo,url, image);
                }, 50);// do async to make sure that mapPos changes are there first.
            }
            this.setState({image:null, imageSrc:null, loadingImage:imageInfo.displayName});
            if (!this.props.useMapName) {
                if (campaign.getPrefs().selectedMap != imageInfo.name) {
                    campaign.setPrefs({selectedMap:imageInfo.name});
                }
            }
        } else {
            t.setState({image:null, imageName:null, imageInfo:null, coverObjs:[]});
        }
    }

    finishLoadImage(imageInfo, url, image) {
        const {mapPos} = this.state;
        
        if (!mapPos || isNaN(mapPos.x)|| isNaN(mapPos.y) || isNaN(mapPos.diameter)) {
            this.updateMapPos(this.getShowAllMapPos());
        } 

        this.setState({image, imageSrc:url, loadingImage:false});

        campaign.addMRUList("mruMaps", {description:imageInfo.displayName, mapName:imageInfo.name});
    }

    getShowAllMapPos() {
        const pixelsPerFoot = this.state.pixelsPerFoot||1;
        const width = this.state.imgWidth/pixelsPerFoot;
        const height = this.state.imgHeight/pixelsPerFoot;
        return {
            diameter:Math.max(width,height), 
            x:width/2, 
            y:height/2
        };
    }

    loadImageByName(map){
        if (map==this.state.imageName) {
            return;
        }
        this.loadImage(campaign.getMapInfo(map));
    }

    loadInitialMap() {
        const map = campaign.getPrefs().selectedMap;
        this.loadImage(campaign.getMapInfo(map));
    }

    loadAnnotations(name) {
        if (this.timerSave) {
            this.commitSaveAnnotations();
            clearTimeout(this.timerSave);
        }
        var mapInfo = campaign.getMapInfo(name) || {};
        const art = campaign.getArtInfo(mapInfo.art)||mapInfo;
        const mapextra = (!campaign.isDefaultCampaign())&&campaign.getMapExtraInfo(name);
        const setMapPos = mapextra?mapextra.mapPos:mapInfo.mapPos;

        const pixelsPerFoot = getPixelsPerFoot(mapInfo, art);
        const gridSize = getGridSizeInFeet(mapInfo);
        const gridScale = art.originalWidth?art.imgWidth/art.originalWidth:1;

        const newAnnotations = {
            coverObjs:(mapextra?mapextra.coverObjs:mapInfo.coverObjs)||[],
            drawObjs:(mapextra?mapextra.drawObjs:mapInfo.drawObjs)||[],
            coverRegions:mapInfo.coverRegions||[],
            pixelsPerGrid:mapInfo.pixelsPerGrid,
            pixelsPerFoot:pixelsPerFoot,
            gridxShift:(mapInfo.gridxShift||0)/pixelsPerFoot*gridScale,
            gridyShift:(mapInfo.gridyShift||0)/pixelsPerFoot*gridScale,
            gridSize,
            imgWidth:art.imgWidth,
            imgHeight:art.imgHeight,
            imageName:name,
            pinList:this.getMapPins(name),
        };
        if (setMapPos && !this.props.noSavePos) {
            newAnnotations.mapPos = setMapPos;
        }

        this.setState(newAnnotations);
    }

    getMapPins(name) {
        const pinList = [];
        const pins = campaign.getPins();

        if (name) {
            for (let i in pins) {
                const p = pins[i];

                if (p.mapPos.mapName==name) {
                    pinList.push(p);
                }
            }
        }
        return pinList;
    }

    saveAnnotations(changes) {
        const t=this;
        this.setState(changes);

        if (!this.state.imageName || this.props.noSavePos) {
            return;
        }
        if (this.timerSave) {
            clearTimeout(this.timerSave);
        }
        this.timerSave = setTimeout(function () {
            t.commitSaveAnnotations();
        },200);
    }

    commitSaveAnnotations() {
        if (!this.state.imageName) {
            return;
        }
        if (campaign.isDefaultCampaign()) {
            const mapInfo=campaign.getMapInfo(this.state.imageName);
            if (!mapInfo) {
                console.log("why is mapinfo blank?", this.state.imageName);
                return;
            }

            if (!areSameDeep(mapInfo.coverObjs, this.state.coverObjs) || !areSameDeep(mapInfo.drawObjs, this.state.drawObjs)) {
                const newMapInfo=Object.assign({}, mapInfo);
                newMapInfo.coverObjs = this.state.coverObjs||null;
                newMapInfo.drawObjs = this.state.drawObjs||null;

                campaign.updateCampaignContent("maps", newMapInfo);
            }
        } else {
            const newExtra=Object.assign({},campaign.getMapExtraInfo(this.state.imageName)||{name:this.state.imageName});
            newExtra.coverObjs = this.state.coverObjs||null;
            newExtra.mapPos = this.state.mapPos||null;
            newExtra.drawObjs = this.state.drawObjs||null;
            
            campaign.updateCampaignContent("mapextra", newExtra);
        }
        this.timerSave=null;
    }

    mouseLeave(e) {
        if (this.props.showPopup)
        {
            clearPointer();
        }
        this.setState({showFogPosition:false, showDrawPosition:false, movingPolygonPoint:false});
    }

    touchStart(e) {
        this.mouseDown(e);
        this.lastDistance = this.newDistance = getDistance(e);
    }

    touchMove(e) {
        if (this.state.doMove) {
            const dist = getDistance(e);
            this.lastDistance = this.newDistance;
            if (dist) {
                this.newDistance = dist;
            }
        }
        this.mouseMove(e);
    }

    touchEnd(e) {
        this.mouseUp(e);
    }

    mouseMove(e) {
        const movePos = this.stage.getPointerPosition();
        if (!movePos || !this.startMovePos || (Math.abs(movePos.x-this.startMovePos.x)>3) || (Math.abs(movePos.y - this.startMovePos.y)>3)) {
            this.mouseMoved = true;
            this.cancelPingCheck();
        }
        this.contextMoved = true;

        if (this.state.isDown) {
            if (!e.evt.buttons && !e.evt.touches){
                // no buttons now, cancel isdown
                this.updatePointer();
                this.cancelPingCheck();
                this.setState({isDown:false, doMove:false});
                return;
            }

            const pos = Object.assign({}, this.stage.getPointerPosition());

            if (this.state.doMove){
                var mapPos = Object.assign({}, this.state.mapPos);

                mapPos.x += (this.startMoveX - pos.x)/this.scale;
                mapPos.y += (this.startMoveY - pos.y)/this.scale;

                
                if (this.lastDistance && this.newDistance && (this.lastDistance != this.newDistance)) {
                    mapPos.diameter = mapPos.diameter * this.lastDistance / this.newDistance;
                }
    
                this.updateMapPos(mapPos);
                this.startMoveX =pos.x;
                this.startMoveY=pos.y;
                return;
            }

            pos.x=(pos.x-this.canvasXShift)/this.scale;
            pos.y=(pos.y-this.canvasYShift)/this.scale;

            if (this.doMeasure) {
                this.startMeasure();
                this.setState({measureEnd:pos});
                return;
            }
        
            switch (this.state.mode) {
                case "uncover":
                    this.doFogMouseMove(pos,e);
                    break;
                case "draw":
                    this.doDrawMouseMove(pos,e);
                    break;
                default:
                    console.log("unknown mode", this.state.mode);
            };
        } else {
            const pos = Object.assign({},this.stage.getPointerPosition());
            pos.x=(pos.x-this.canvasXShift)/this.scale;
            pos.y=(pos.y-this.canvasYShift)/this.scale;

            this.updatePointer();
            this.setState({regionHover:e.target.attrs.coverRegion||null});

            switch (this.state.mode) {
                case "uncover":
                    this.doFogMouseMove(pos,e);
                    break;
                case "draw":
                    this.doDrawMouseMove(pos,e);
                    break;
                default:
                    break;
            };
        }
    }

    mouseDown(e) {
        const spos = this.stage.getPointerPosition();
        const pos = {x:(spos.x-this.canvasXShift)/this.scale, y:(spos.y-this.canvasYShift)/this.scale}
        const doMove=(this.state.mode=="move"||e.evt.buttons==2||e.evt.ctrlKey);
        this.mouseMoved = false;
        this.contextMoved = false;
        this.doMeasure=false;
        this.startMovePos = Object.assign({},this.stage.getPointerPosition());
        this.startMapClick = pos;

        this.lastDistance = this.newDistance = 0;
        if (!(e.evt.buttons>1) && !["uncover", "draw"].includes(this.state.mode)) {
            this.startPingCheck();
        }
        //console.log("target", e.target.attrs);
        if (e.target.attrs.token) {
            // don't do actions over tokens
            this.mouseMoved = true;
            return;
        }

        if (e.target.attrs.pin) {
            // no tap on pins
            this.mouseMoved = true;
        }

        if (e.evt.buttons == 2) {
            // don't do tap with right button and instead use context menu
            this.mouseMoved = true;
        }

        if (doMove){
            this.startMoveX =spos.x;
            this.startMoveY=spos.y;

            this.setState({doMove:true, isDown:true});
            return;
        }

        switch (this.state.mode) {
            case "uncover":
                this.doFogMouseDown(pos,e);
                break;
            case "draw":
                this.doDrawMouseDown(pos,e);
                break;
            case "move":
                break;
            case "select":
                break;
            default:
                console.log("unknown mode", this.state.mode);
        }
    }

    mouseUp(e) {
        this.cancelPingCheck();
        if (this.state.isDown && this.doMeasure) {
            this.stopMeasure();
            this.doMeasure=false;
            this.setState({isDown:false, measureEnd:null});
            return;
        }
        this.doMeasure=false;
        if (this.state.isDown && this.mouseMoved) {
            if (!this.state.doMove) {
                switch (this.state.mode) {
                    case "uncover":
                        this.doFogMouseUp(e);
                        break;
                    case "draw":
                        this.doDrawMouseUp(e);
                        break;
                    default:
                        console.log("unknown mode", this.state.mode);
                }
            }
            this.setState({isDown:false, doMove:false});
            this.updatePointer();
        }
        if (!this.mouseMoved && this.props.onTapMap) {
            e.evt.preventDefault();
            e.evt.stopPropagation();

            const pos = Object.assign({},this.stage.getPointerPosition());
            pos.x=(pos.x-this.canvasXShift)/this.scale;
            pos.y=(pos.y-this.canvasYShift)/this.scale;
    
            this.props.onTapMap(pos, e, this, this.getCoverRegion(e.target.attrs.coverRegion));
        }
    }

    updatePointer() {
        if (this.props.showPopup)
        {
            const tpos = Object.assign({},this.stage.getPointerPosition());
            tpos.x=(tpos.x-this.canvasXShift)/this.scale;
            tpos.y=(tpos.y-this.canvasYShift)/this.scale;

            setPointer(tpos.x, tpos.y);
        }
    }

    startPingCheck() {
        const t=this;
        this.cancelPingCheck();
        this.pingCheckTimer = setTimeout(function () {
            const pos = t.stage.getPointerPosition();
            if (pos) {
                campaign.updateCampaignContent("adventure",{
                    name:campaign.newUid(),
                    type:"ping",
                    x:(pos.x-t.canvasXShift)/t.scale,
                    y:(pos.y-t.canvasYShift)/t.scale,
                    color:"red"
                });
            }
            t.setState({doMove:false, isDown:true, measureEnd:null});
            t.doMeasure = true;
            t.pingCheckTimer=null;
            t.pinClicked=null;
            t.mouseMoved=true;
        },500)

    }

    cancelPingCheck() {
        if (this.pingCheckTimer) {
            clearTimeout(this.pingCheckTimer);
            this.pingCheckTimer = null;
        }
    }

    startMeasure() {
        if (!this.measureTimer) {
            const t=this;
            localMeasureId = this.measureId = campaign.newUid();
            this.measureTimer = setInterval(function () {
                if (!t.doMeasure){
                    t.stopMeasure();
                } else if (t.state.measureEnd) {
                    campaign.updateCampaignContent("adventure",{
                        name:t.measureId,
                        type:"ping",
                        measure:true,
                        startX:t.startMapClick.x,
                        endX:t.state.measureEnd.x,
                        startY:t.startMapClick.y,
                        endY:t.state.measureEnd.y,
                        color:"red"
                    });
                }
            },1000);
        }
    }

    stopMeasure() {
        if (this.measureTimer) {
            campaign.deleteCampaignContent("adventure", this.measureId);
            clearInterval(this.measureTimer);
            this.measureTimer=null;
        }
    }

    onContextMenu(e) {
        if (this.props.onTapMap && !e.target.attrs.pin && !e.target.attrs.token && !this.contextMoved) {
            e.evt.preventDefault();
            e.evt.stopPropagation();
            const pos = Object.assign({},this.stage.getPointerPosition());
            pos.x=(pos.x-this.canvasXShift)/this.scale;
            pos.y=(pos.y-this.canvasYShift)/this.scale;

            this.props.onTapMap(pos, e, this, this.getCoverRegion(e.target.attrs.coverRegion));
        }

    }

    scaleView(factor){
        this.setScale(this.scale*factor);
    }

    wheel(e) {
        var factor;
        if (e.evt.deltaY < 0) {
            factor=1.1;
        } else {
            factor=1/1.1;
        }
        this.scaleView(factor);
        e.evt.preventDefault();
    }

    getPinTokens(listening) {
        const pinList = this.state.pinList;
        var ret = [];
        var tret = []
        if (!pinList) {
            return null;
        }
        const pinRules = this.props.pinRules||{};
        const pinsMoveable = (this.state.mode=="select");
   
        for (let i in pinList) {
            const p = pinList[i];
            let pinMoveable = pinsMoveable && pinRules[(p.type=="map")?"location":p.type];
            let ins = tret;
            const pinScale =getPinScale(p, this.state.gridSize, this.state.pixelsPerFoot);

            switch (p.type) {
                case "map":
                    // fall through to show the same as location
                case "location": 
                {
                    let width = 0;
                    width = defaultMeasure.measureText(p.displayName).width;
                    ins.push(<Group 
                        listening={listening}
                        key={"t"+i}
                        scaleX={pinScale}
                        scaleY={pinScale}
                        x={p.mapPos.x} 
                        y={p.mapPos.y} 
                        token={pinMoveable}
                        pin
                        draggable={pinMoveable}
                        onDragEnd={pinMoveable?this.onDragPin.bind(this, p.name):null}
                        onMouseDown={this.onPinMouseDown.bind(this, p)}
                        onMouseUp={this.onPinMouseUp.bind(this, p)}
                        onMouseMove={this.onPinMouseMove.bind(this)}
                        onTouchStart={this.onPinMouseDown.bind(this, p)}
                        onTouchEnd={this.onPinMouseUp.bind(this, p)}
                        onTouchMove={this.onPinMouseMove.bind(this)}
                        onContextMenu={this.onPinContextMenu.bind(this, p)}
                    >
                        <Rect 
                            x={-width/2-2}
                            y={-31}
                            width={width+4} 
                            height={22} 
                            fill="#58170D" 
                            cornerRadius={4}
                            opacity={0.5}
                            token={pinMoveable}
                            pin
                        />
                        <Text 
                            text={p.displayName} 
                            x={-width/2}
                            y={-28}
                            fontSize={18} 
                            fontFamily="Convergence, sans-serif" 
                            opacity={0.87}
                            align="left" 
                            fill={p.showPlayers?"white":"mediumspringgreen"} 
                            listening={false}
                            token={pinMoveable}
                            pin
                        />
                        <PinMarker pin={p} token={pinMoveable}/>
                    </Group>);
                    break;
                }
                case "link":
                default:
                    continue;
                    break;
            }

        }
        return tret.concat(ret);
    }

    getPinDetails() {
        if (!this.state.showPinDetails) {
            return null;
        }

        const pin = this.state.selectedPin;

        if (!pin)
            return;

        switch (pin.type) {
            case "location":
            case "map":
            {
                 return <PinMenu 
                    open
                    pin={pin} 
                    onClose={this.onClosePinDetails.bind(this)} 
                    anchorPos={this.state.pinAnchorPos}
                    onAddEncounter={this.props.onAddEncounter}
                    onGoToEncounter={this.props.onGoToEncounter}
                    onGoToBook={this.props.onGoToBook}
                    onGoToMap={this.gotoMapPin.bind(this)}
                    bookname={this.props.bookname}
                    chapter={this.props.chapter}
                    section={this.props.section}
                    subsection={this.props.subsection}
                />;
                break;

            }

            default:
                return null;
        }
    }

    gotoMapPin(name) {
        var newMapPos ={};
        newMapPos.mapName = name;
        this.props.onGoToMap(newMapPos);
    }

    onAddPrevious(name) {
        this.setState({showPinDetails:false});
        this.props.onAddPreviousEncounter(name);
    }

    onClickAltArtwork(art) {
        const newExtra=Object.assign({},campaign.getMapExtraInfo(this.state.imageName)||{name:this.state.imageName});
        newExtra.altImage = art;
        
        campaign.updateCampaignContent("mapextra", newExtra);
        const imageInfo = campaign.getMapInfo(this.state.imageName);
        this.loadImage(imageInfo);
    }

    onGoToPin(pin) {
        this.setState({showPinDetails:false});
        this.props.onGoToMap(pin.mapPos);
    }

    onClosePinDetails() {
        this.setState({showPinDetails:false});
    }

    onDragPin(name, e) {
        const newPin = Object.assign({},campaign.getPinInfo(name));

        newPin.mapPos = Object.assign({}, newPin.mapPos);
        newPin.mapPos.x = e.target.x();
        newPin.mapPos.y = e.target.y();
        newPin.mapPos.diameter = this.state.mapPos.diameter;
        campaign.updateCampaignContent("pins", newPin);
    }

    onPinMouseDown(pin, e) {
        if (e.evt.buttons!=2) {
            this.pinClicked = pin;
            this.pinStartMovePos = Object.assign({},this.stage.getPointerPosition());
        }
    }

    onPinMouseUp(pin, e) {
        if (this.pinClicked==pin) {
            this.setState({pinAnchorPos:getAnchorPos(e.evt), showPinDetails:true, selectedPin:pin})
        }
    }

    onPinContextMenu(pin, e) {
        this.setState({pinAnchorPos:getAnchorPos(e.evt), showPinDetails:true, selectedPin:pin})
    }

    onPinMouseMove(e) {
        const movePos = this.stage.getPointerPosition();
        if (!movePos || !this.pinStartMovePos || 
            (Math.abs(movePos.x-this.pinStartMovePos.x)>2) || (Math.abs(movePos.y - this.pinStartMovePos.y)>2)) {
            this.pinClicked=null;
            this.cancelPingCheck();
        }
    }

    getFogMenu() {
        return <Paper>
            {!this.props.noBuildFogRegions?<MenuItem onClick={this.setFogTool.bind(this, "region",null)}>
                Build Fog Regions
            </MenuItem>:null}
            <MenuItem onClick={this.undoFog.bind(this)}>
                Undo Fog
            </MenuItem>
            {!this.props.noCover?<MenuItem onClick={this.clearFog.bind(this)}>
                Clear All Fog
            </MenuItem>:null}
            {!this.props.noCover?<MenuItem onClick={this.resetFog.bind(this)}>
                Fog All
            </MenuItem>:null}
        </Paper>;
    }

    getDrawMenu() {
        const drawMode = this.state.mode=="draw";
        const drawColor = this.state.drawColor;
        const clear = drawColor=="clear";
        const clist =[];

        for (let r=0; r<4; r++) {
            const rlist=[];
            for (let c=0; c<4; c++) {
                const color = colorDrawList[r*4+c];

                rlist.push(<div key={color} className="pa--1">
                    <div style={{width:"20px", height:"20px", backgroundColor:color}} onClick={this.setDrawingTool.bind(this,color)}>
                        {drawColor==color?<span className={"fas fa-circle f6 pa--4 "+colorContrastMap[color]}/>:null}
                    </div>
                </div>);
            }
            clist.push(<div className="flex justify-between" key={r}>
                {rlist}
            </div>);
        }

        return <Paper>
            <MenuItem onClick={this.setDrawingTool.bind(this, this.state.drawColor||'#ffffff')}>
                <span className={drawMode&&!clear?"fas fa-check-square pr1":"far fa-square pr1"}/>Draw
            </MenuItem>
            <MenuItem>
                <div>
                    {clist}
                </div>
            </MenuItem>
            <MenuItem onClick={this.setDrawingTool.bind(this, "clear")}>
                <span className={drawMode&&clear?"fas fa-check-square pr1":"far fa-square pr1"}/>Erase
            </MenuItem>
            <MenuItem onClick={this.clearDrawing.bind(this)}>
                Clear Drawing
            </MenuItem>
            <MenuItem onClick={this.undoDrawing.bind(this)}>
                Undo Drawing
            </MenuItem>
        </Paper>;
    }

    setDrawingTool(drawColor) {
        this.setState({drawColor, mode:"draw", showDrawPosition:false});
    }

    clearDrawing() {
        this.setDrawObjs(null);
    }

    undoDrawing() {
        let newDrawObjs;
        newDrawObjs = (this.state.drawObjs||[]).concat([]);
        newDrawObjs.pop();
        this.setDrawObjs(newDrawObjs);
    }

    doDrawMouseDown(pos, e) {
        const dround = 100;
        const usePos={x:Math.round(pos.x*dround)/dround, y:Math.round(pos.y*dround)/dround};
        this.setState({drawPoints:[usePos.x, usePos.y,usePos.x+0.01, usePos.y],isDown:true});
        this.mouseMoved = true;
        e.evt.preventDefault();
    }

    getDrawDiameter() {
        let drawDiameter = 4/this.scale;
        if (this.state.drawColor=="clear") {
            drawDiameter *=5;
        }
        return drawDiameter;
    }

    doDrawMouseUp(e) {
        let newDrawObjs;
        newDrawObjs = (this.state.drawObjs||[]).concat([{
            color:this.state.drawColor, 
            diameter:this.getDrawDiameter(),
            points:this.state.drawPoints
        }]);
        this.setDrawObjs(newDrawObjs);
    }

    doDrawMouseMove(pos, e) {
        const dround = 100;
        const usePos={x:Math.round(pos.x*dround)/dround, y:Math.round(pos.y*dround)/dround};
        if (this.state.isDown) {
            const drawDiameter=this.getDrawDiameter();
            let drawPoints = (this.state.drawPoints||[]).concat();
            
            if ((drawPoints.length <2) || (Math.abs(drawPoints[drawPoints.length-2]-usePos.x)>drawDiameter) || (Math.abs(drawPoints[drawPoints.length-1]-usePos.y)>drawDiameter)) {
                drawPoints = drawPoints.concat([usePos.x, usePos.y]);
                this.setState({drawPoints});
            }
        }
        this.setState({drawShowX:usePos.x, drawShowY:usePos.y, showDrawPosition:true});
    }

    getDrawLayer() {
        if (this.props.noCover) {
            return null;
        }

        let draw=null;
        const clear = this.state.drawColor=="clear";
        if (this.state.isDown && (this.state.mode=="draw") && !this.state.doMove) {
            draw= <Line
                points={this.state.drawPoints}
                lineCap="round"
                lineJoin="round"
                tension={.1}
                stroke={clear?"white":this.state.drawColor}
                globalCompositeOperation={clear?"destination-out":"source-over"}
                strokeWidth={this.getDrawDiameter()}
                opacity={1}
            />
        }

        return <DrawObjs drawObjs={this.state.drawObjs}>
            {draw}
        </DrawObjs>
    }

    getPaintFogMenu() {
        return <Paper>
            <MenuItem onClick={this.setFogTool.bind(this, "circle",5)}>
                5ft diameter
            </MenuItem>
            <MenuItem onClick={this.setFogTool.bind(this, "circle",10)}>
                10ft diameter
            </MenuItem>
            <MenuItem onClick={this.setFogTool.bind(this, "circle",20)}>
                20ft diameter
            </MenuItem>
            <MenuItem onClick={this.setFogTool.bind(this, "circle",40)}>
                40ft diameter
            </MenuItem>
            <MenuItem onClick={this.setFogTool.bind(this, "circle",60)}>
                60ft diameter
            </MenuItem>
        </Paper>
    }

    setFogTool(fogTool,diameter) {
        this.setState({fogTool, mode:"uncover", diameter:diameter||this.state.diameter, fogPoints:null, showFogPosition:false, drawingPolygon:false, movingPolygonPoint:false});
    }

    resetFog() {
        this.setCoverObjs([]);
    }

    clearFog() {
        this.setCoverObjs([{type:"uncover", x:-5000000, y:-5000000, width:100000000, height:100000000}]);
    }

    undoFog() {
        if (this.state.mode=="uncover" && (this.state.fogTool=="polygon" || this.state.fogTool=="region") && this.state.drawingPolygon) {
            if (this.state.fogPoints.length <=2) {
                // cancel polygon
                this.setState({drawingPolygon:false});
                return;
            }
            const fogPoints = this.state.fogPoints.concat();
            fogPoints.splice(fogPoints.length-2,2);
            this.setState({fogPoints});
            return;
        }

        if (!this.props.noCover && (this.state.coverObjs||[]).length) {
            const newCoverObjs = (this.state.coverObjs||[]).concat();
            newCoverObjs.pop();
            this.setCoverObjs(newCoverObjs);
        }
    }

    doFogMouseMove(pos, e) {
        if (this.state.isDown) {
            switch (this.state.fogTool) {
                case "rect":{
                    this.setState({endX:pos.x, endY:pos.y});
                    break;
                }
                case "circle":{
                    const diameter = this.state.diameter;
                    const usePos=this.roundPos(pos);
                    let fogPoints = (this.state.fogPoints||[]).concat();
                    
                    if ((fogPoints.length <2) || (fogPoints[fogPoints.length-2]!=usePos.x) || (fogPoints[fogPoints.length-1]!=usePos.y)) {
                        fogPoints = fogPoints.concat([usePos.x, usePos.y]);
                    }
                    if (fogPoints.length >= 6) {
                        const minDist = diameter/20; //0.25 for 5 ft
                        const closePoint = diameter/5; //1 for 5ft
                        const p = fogPoints.length -6;
                        const x1 = fogPoints[p];
                        const y1 = fogPoints[p+1];
                        const x2 = fogPoints[p+2];
                        const y2 = fogPoints[p+3];
                        const x3 = fogPoints[p+4];
                        const y3 = fogPoints[p+5];
                        const dx = x3-x1;
                        const dy = y3-y1;
                        const d2x = x2-x1;
                        const d2y = y2-y1;
        
                        if ((Math.abs(d2x)<=closePoint) && (Math.abs(d2y)<=closePoint)) {
                            fogPoints.splice(p+2,2);
                        } else if (Math.abs(dy)>Math.abs(dx)) {
                            if ((dy>0)?((y2>=y1)&&(y3>=y2)):((y2<=y1)&&(y3<=y2))) {
                                const cx = d2y*dx/dy;
                                if (Math.abs(cx-d2x) < minDist) {
                                    fogPoints.splice(p+2,2);
                                }
                            }
                        } else {
                            if ((dx>0)?((x2>=x1)&&(x3>=x2)):((x2<=x1)&&(x3<=x2))) {
                                const cy = d2x*dy/dx;
                                if (Math.abs(cy-d2y) < minDist) {
                                    fogPoints.splice(p+2,2);
                                }
                            }
                        }
                    }
                    this.setState({fogPoints,fogShowX:usePos.x, fogShowY:usePos.y, showFogPosition:true});
                    break;
                }
                case "region":{
                    const usePos=this.roundPos(pos,10);

                    this.setState({fogShowX:usePos.x, fogShowY:usePos.y});
                    break;                
                }
            }
        } else {
            this.setState({fogShowX:pos.x, fogShowY:pos.y, showFogPosition:true, movingPolygonPoint:false});
        }
    }

    roundPos(pos,num){
        const round = num||Math.trunc(this.state.diameter/5);
        return {x:Math.round(pos.x*round)/round, y:Math.round(pos.y*round)/round};
    }

    getMeasureTool() {
        if (this.doMeasure && this.state.measureEnd) {
            const dist = Math.trunc(Math.sqrt(Math.pow(this.startMapClick.x-this.state.measureEnd.x, 2)+Math.pow(this.startMapClick.y-this.state.measureEnd.y, 2)));
            let distTxt;
            if (dist < 1000) {
                distTxt = dist+"ft.";
            } else {
                distTxt = (dist/5280).toFixed(2)+" miles";
            }
            if (dist > 3) {
                return <Group>
                    <Arrow
                        x={this.startMapClick.x}
                        y={this.startMapClick.y}
                        points={[0,0,this.state.measureEnd.x-this.startMapClick.x,this.state.measureEnd.y-this.startMapClick.y]}
                        stroke="red"
                        fill="red"
                        strokeWidth={2}
                        strokeScaleEnabled={false}
                        listening={false}
                        pointerLength={10/this.scale}
                        pointerWidth={10/this.scale}
                    />
                    <Text 
                        text={distTxt} 
                        x={this.startMapClick.x+(this.state.measureEnd.x-this.startMapClick.x)/2}
                        y={this.startMapClick.y+(this.state.measureEnd.y-this.startMapClick.y)/2}
                        scaleX={1/this.scale} 
                        scaleY={1/this.scale}
                        fontSize={18} 
                        fontFamily="Convergence, sans-serif" 
                        opacity={1}
                        shadowEnabled
                        shadowColor='black'
                        shadowBlur={10}
                        shadowOpacity={1}
                        fill={"white"} 
                        listening={false}
                    />
                </Group>;
            }
        }
        return null;
    }

    doFogMouseDown(pos, e) {
        const fogTool = this.state.fogTool;
        switch (fogTool) {
            case "rect":{
                this.setState({startX:pos.x, endX:pos.x, startY:pos.y, endY:pos.y, isDown:true, movingPolygonPoint:false});
                break;
            }
            case "circle":{
                const usePos=this.roundPos(pos);
                this.setState({fogPoints:[usePos.x, usePos.y, usePos.x+0.01, usePos.y],fogShowX:usePos.x, fogShowY:usePos.y, showFogPosition:true, isDown:true, movingPolygonPoint:false});
                this.mouseMoved = true;
                break;
            }
            case "region":
            case "polygon":{
                const upos = this.roundPos(pos, 10);
                if (!this.state.drawingPolygon) {
                    if (fogTool=="region" && e.target.attrs.regionPoint) {
                        // start drag of the point
                        this.setState({fogShowX:upos.x, fogShowY:upos.y, movingPolygonPoint:true, regionIndex:e.target.attrs.regionIndex, regionPointIndex:e.target.attrs.regionPointIndex, isDown:true});
                    } else {
                        // start a new region
                        this.setState({fogPoints:[upos.x, upos.y], fogShowX:upos.x, fogShowY:upos.y, isDown:false, drawingPolygon:true, movingPolygonPoint:false});
                    }
                } else {
                    const fogPoints = this.state.fogPoints.concat();

                    if ((Math.abs(fogPoints[0]-upos.x)<(4/this.scale)) && (Math.abs(fogPoints[1]-upos.y)<(4/this.scale))) {
                        if (fogPoints.length > 4) {
                            if (fogTool == "polygon") {
                                const newCoverObjs = (this.state.coverObjs||[]).concat([{
                                    type:"polygon", 
                                    points:fogPoints
                                }]);
                                this.setCoverObjs(newCoverObjs);
                            } else if (fogTool == "region") {
                                // need to save to map!
                                const newCoverRegions = (this.state.coverRegions||[]).concat([{
                                    type:"polygon", 
                                    points:fogPoints
                                }]);
                                this.setCoverRegions(newCoverRegions);
                            }
                        }
                        this.setState({isDown:false, fogShowX:upos.x, fogShowY:upos.y, drawingPolygon:false, movingPolygonPoint:false});
                    } else {
                        fogPoints.push(upos.x);
                        fogPoints.push(upos.y);
                        this.setState({fogPoints, fogShowX:upos.x, fogShowY:upos.y, isDown:false, drawingPolygon:true, movingPolygonPoint:false});
                    }
                }
                this.mouseMoved = true;
                break;
            }
            default:
                console.log("unknown fog on mouse down", this.state.fogTool);
                break
        }
        e.evt.preventDefault();
    }

    doFogMouseUp(e) {
        switch (this.state.fogTool) {
            case "rect":{
                if ((this.state.startX != this.state.endX) && (this.state.startY != this.state.endY)) {
                    const newCoverObjs = (this.state.coverObjs||[]).concat([{
                        type:"uncover", 
                        x:Math.min(this.state.startX, this.state.endX),
                        y:Math.min(this.state.startY, this.state.endY),
                        width:Math.abs(this.state.endX-this.state.startX),
                        height:Math.abs(this.state.endY-this.state.startY)
                    }]);
                    this.setCoverObjs(newCoverObjs);
                }
                break;
            }
            case "circle":{
                let newCoverObjs;
                if (this.state.regionHover) {
                    const cr = this.state.coverRegions[this.state.regionHover.index];
                    const points = cr.points;
                    const foundRegion = this.searchCover(this.state.coverObjs, cr);

                    if (foundRegion >= 0) {
                        const existingCover = this.state.coverObjs[foundRegion];
                        if (!existingCover.circlePoints) {
                            // region already unfogged
                            return;
                        }
                    }
                    newCoverObjs = (this.state.coverObjs||[]).concat([{
                        type:"polygon", 
                        diameter:this.state.diameter,
                        points:points,
                        circlePoints:this.state.fogPoints,
                        diameter:this.state.diameter
                    }]);
                } else {
                    newCoverObjs = (this.state.coverObjs||[]).concat([{
                        type:"circle", 
                        diameter:this.state.diameter,
                        points:this.state.fogPoints
                    }]);
                }
                //console.log("cover size", JSON.stringify(newCoverObjs).length);
                this.setCoverObjs(newCoverObjs);
                break;
            }
            case "polygon":
                break;
            case "region":{
                if (this.state.movingPolygonPoint) {
                    const newCoverRegions = (this.state.coverRegions||[]).concat();
                    const ri = this.state.regionIndex;
                    const rpi = this.state.regionPointIndex;
                    const region = Object.assign({}, newCoverRegions[ri]);
                    region.points = region.points.concat();
                    region.points[rpi] = this.state.fogShowX;
                    region.points[rpi+1] = this.state.fogShowY;
                    newCoverRegions[ri] = region;

                    this.setCoverRegions(newCoverRegions);
                    this.setState({movingPolygonPoint:false});
                }
                break;
            }
        }
    }

    getFogLayer() {
        if (this.props.noCover) {
            return null;
        }

        let addFog;

        if (this.state.isDown && (this.state.mode=="uncover") && !this.state.doMove) {
            switch (this.state.fogTool) {
                case "rect":{
                    addFog = <Rect
                        width={Math.abs(this.state.endX-this.state.startX)}
                        height={Math.abs(this.state.endY-this.state.startY)}
                        x={Math.min(this.state.startX, this.state.endX)}
                        y={Math.min(this.state.startY, this.state.endY)}
                        fill="gray"
                        opacity={1}
                        globalCompositeOperation="destination-out"
                        shadowColor="gray"
                        shadowBlur={2}
                        shadowOpacity={1}
                        shadowOffset={{ x: .00001, y: .00001 }}
                    />
                    break;
                }
                case "circle":{
                    if (this.state.regionHover) {
                        addFog = <PolyCircle 
                            region={this.state.coverRegions[this.state.regionHover.index].points}
                            points={this.state.fogPoints}
                            diameter={this.state.diameter}
                        />;
                    }else {
                        addFog = <Line
                            points={this.state.fogPoints}
                            lineCap="round"
                            lineJoin="round"
                            tension={.1}
                            stroke="gray"
                            strokeWidth={this.state.diameter}
                            opacity={1}
                            globalCompositeOperation="destination-out"
                            shadowColor="gray"
                            shadowBlur={2}
                            shadowOpacity={1}
                            shadowOffset={{ x: .00001, y: .00001 }}
        
                        />;
                    }
                    break;
                }
            }
        }

        return <CoverObjs 
            coverObjs={this.state.coverObjs} 
            extraFog={addFog} 
            noCover={this.props.sometimesCover&&this.state.mode!="uncover"}
            opacity={0.5}
        />
    }

    getFogRegions() {
        if (this.state.mode!="uncover" || this.state.fogTool != "region"){
            return;
        }
        const coverRegions = this.state.coverRegions;
        const list = [];

        for (let i in coverRegions) {
            const o = coverRegions[i];
            let fp = o.points;

            // skip 2 point regions since they don't uncover anything
            if (fp.length >4) {
                if (this.state.movingPolygonPoint && this.state.regionIndex==i && this.state.isDown) {
                    const rpi = this.state.regionPointIndex;

                    fp = fp.concat([]);
                    fp[rpi] = this.state.fogShowX;
                    fp[rpi+1] = this.state.fogShowY;
                }
        
                list.push(<Line
                    key={i}
                    points={fp}
                    lineCap="round"
                    lineJoin="round"
                    tension={0}
                    fill="rgb(255,255,255,0.6)"
                    opacity={0.5}
                    stroke="white"
                    strokeScaleEnabled={false}
                    listening={false}
                    closed
                />);

                for (let x=0; x<fp.length; x+=2) {
                    list.push(<Circle 
                        regionPoint
                        regionIndex={i}
                        regionPointIndex={x}
                        key={i+"."+x}
                        x={fp[x]} 
                        y={fp[x+1]} 
                        radius={5} 
                        stroke="white"
                        fill="red"
                        opacity={1}
                        strokeScaleEnabled={false}
                        strokeWidth={2}
                        scaleX={1/(this.scale)}
                        scaleY={1/(this.scale)}
                    />);
                }
            }
        }
        return list;
    }

    getFogHitRegions() {
        const coverRegions = this.state.coverRegions;
        const list = [];

        for (let i in coverRegions) {
            const o = coverRegions[i];

            list.push(<Line
                coverRegion={{index:i}}
                key={i}
                points={o.points}
                lineCap="round"
                lineJoin="round"
                tension={0}
                fill="black"
                opacity={0}
                closed
            />)
        }
        return list;
    }

    searchCover(coverObjs, cr) {
        for (let i in coverObjs) {
            const o = coverObjs[i];
            if (o.type=="polygon" && o.points.length == cr.points.length) {
                let eq = true;
                for (let x in o.points) {
                    if (o.points[x]!=cr.points[x]) {
                        eq=false;
                    }
                }
                if (eq) {
                    
                    return i;
                }
            }
        }
        return -1;
    }

    getCoverRegion(coverRegion) {
        if (!coverRegion) {
            return null;
        }
        const cr = this.state.coverRegions[coverRegion.index];
        const coverObjs = this.state.coverObjs;
        const coverObjIndex = this.searchCover(coverObjs, cr);
        if(coverObjIndex >=0) {
            const co = this.state.coverObjs[coverObjIndex];
            return {index:coverRegion.index, uncovered:!co.circlePoints, coverObjIndex};
        }

        return {index:coverRegion.index, uncovered:false};
    }

    toggleCover(coverRegion) {
        const newCoverObjs = (this.state.coverObjs||[]).concat();
        const cr = this.state.coverRegions[coverRegion.index];
        let pos = this.searchCover(newCoverObjs, cr);
        while (pos >= 0) {
            newCoverObjs.splice(pos,1);
            pos = this.searchCover(newCoverObjs, cr);
        }
        if (coverRegion.uncovered) {
        } else {
            newCoverObjs.push({type:"polygon", points:cr.points});
        }
        this.setCoverObjs(newCoverObjs);
    }

    deleteCover(coverRegion) {
        const newCoverObjs = (this.state.coverObjs||[]).concat();
        const cr = this.state.coverRegions[coverRegion.index];
        let pos = this.searchCover(newCoverObjs, cr);
        while (pos >= 0) {
            newCoverObjs.splice(pos,1);
            pos = this.searchCover(newCoverObjs, cr);
        }

        const newCoverRegions = (this.state.coverRegions||[]).concat();
        newCoverRegions.splice(coverRegion.index,1);

        this.setCoverRegions(newCoverRegions);

        // force a direct update of cover since default async mode overwrites
        if (campaign.isDefaultCampaign()) {
            const mapInfo=campaign.getMapInfo(this.state.imageName);
            if (!mapInfo) {
                console.log("why is mapinfo blank?", this.state.imageName);
            } else {
                const newMapInfo=Object.assign({}, mapInfo);
                newMapInfo.coverObjs = newCoverObjs;
                campaign.updateCampaignContent("maps", newMapInfo);
            }
        } else {
            const newExtra=Object.assign({},campaign.getMapExtraInfo(this.state.imageName)||{name:this.state.imageName});
            newExtra.coverObjs = newCoverObjs;
            campaign.updateCampaignContent("mapextra", newExtra);
        }

        this.setState({regionHover:null});
    }

    getMarker() {
        const list=[];
        if (this.state.mode=="uncover") {
            switch (this.state.fogTool) {
                case "rect":{
                    if (this.state.isDown && !this.state.doMove) {
                        list.push(<Rect
                            key="drawrect"
                            width={Math.abs(this.state.endX-this.state.startX)}
                            height={Math.abs(this.state.endY-this.state.startY)}
                            x={Math.min(this.state.startX, this.state.endX)}
                            y={Math.min(this.state.startY, this.state.endY)}
                            strokeScaleEnabled={false}
                            shadowBlur={5}
                            stroke="red"
                            opacity={1}
                            listening={false}
                        />);
                    } 
                    break;
                }
                case "circle": {
                    if (this.state.showFogPosition) {
                        list.push(<Circle
                            key="drawcircle"
                            radius={this.state.diameter/2}
                            x={this.state.fogShowX}
                            y={this.state.fogShowY}
                            strokeScaleEnabled={false}
                            shadowBlur={5}
                            stroke="red"
                            opacity={1}
                            listening={false}
                        />);
                    }
                    break;
                }
                case "region":
                case "polygon": {
                    if (this.state.drawingPolygon) {
                        list.push(<Line
                            key="lines"
                            points={this.state.fogPoints}
                            lineCap="round"
                            lineJoin="round"
                            tension={0}
                            stroke="red"
                            strokeScaleEnabled={false}
                            shadowBlur={5}
                            opacity={1}
                            listening={false}
                        />);

                        const fp = this.state.fogPoints;
                        for (let i=0; i<fp.length; i+=2) {
                            list.push(<Circle 
                                key={i}
                                x={fp[i]} 
                                y={fp[i+1]} 
                                radius={5} 
                                stroke="black"
                                fill="yellow"
                                opacity={1}
                                strokeScaleEnabled={false}
                                strokeWidth={2}
                                listening={false}
                                scaleX={1/(this.scale)}
                                scaleY={1/(this.scale)}
                            />)
                        }
                    }
                    break;
                }
            }
        } else if (this.state.mode=="draw") {
            if (this.state.showDrawPosition) {
                const drawColor=this.state.drawColor;
                const clear = drawColor=="clear";
                list.push(<Circle
                    key="drawcircle"
                    radius={this.getDrawDiameter()/2}
                    x={this.state.drawShowX}
                    y={this.state.drawShowY}
                    strokeScaleEnabled={false}
                    fill={clear?"gray":drawColor}
                    opacity={clear?0.8:1}
                    listening={false}
                />);
            }
        }


        if (this.state.regionHover) {
            const cr = this.state.coverRegions[this.state.regionHover.index];
            if (cr) {
                list.push(<Line
                    key="regionHighlight"
                    points={cr.points}
                    lineCap="round"
                    lineJoin="round"
                    tension={0}
                    opacity={0.5}
                    stroke="red"
                    strokeScaleEnabled={false}
                    closed
                    listening={false}
                />)
            }
        }
        return list;
    }

}

function ToolButton(props) {
    var className;
    var iconText;
    var icon;

    if (props.hidden) {
        return <div></div>;
    }

    if (props.selected && props.selectedIcon) {
        icon=props.selectedIcon;
    } else {
        icon = props.icon;
    }

    className="mr--2 hoverhighlight pa1 f2 "+icon;

    if (props.disabled) {
        className = className + " light-gray";
    } else if (props.selected && !props.selectedIcon) {
        className = className + " bg-gray-40";
    }

    return <Tooltip title={props.title||""}><span onClick={props.onMouseDown} className={className}>{iconText}</span></Tooltip>;
}


class DrawObjs extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const drawObjs = this.props.drawObjs;
        var ret=[];

        for (let i in drawObjs) {
            const o= drawObjs[i];
            const clear = o.color=="clear";

            ret.push(<Line
                key={i}
                points={o.points}
                lineCap="round"
                lineJoin="round"
                tension={.1}
                stroke={clear?"white":o.color}
                globalCompositeOperation={clear?"destination-out":"source-over"}
                strokeWidth={o.diameter}
                opacity={1}
            />);
        }

        return <Group 
            listening={false}
        >
            {ret}
            {this.props.children}
        </Group>
    }
}


class CoverObjs extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const coverObjs = this.props.coverObjs;
        var ret=[];

        if (!coverObjs)
            return null;
    
        for (let i in coverObjs) {
            const o= coverObjs[i];
            switch (o.type) {
                case "uncover":
                    ret.push(<Rect
                        key={i}
                        width={o.width}
                        height={o.height}
                        x={o.x}
                        y={o.y}
                        fill="gray"
                        opacity={1}
                        shadowColor="gray"
                        shadowBlur={2}
                        shadowOpacity={1}
                        shadowOffset={{ x: .00001, y: .00001 }}
                    />);
                    break;
                case "circle":
                    ret.push(<Line
                        key={i}
                        points={o.points}
                        lineCap="round"
                        lineJoin="round"
                        tension={.1}
                        stroke="gray"
                        strokeWidth={o.diameter}
                        opacity={1}
                        shadowColor="gray"
                        shadowBlur={2}
                        shadowOpacity={1}
                        shadowOffset={{ x: .00001, y: .00001 }}
                    />);
                    break;
                case "polygon":
                    if (o.circlePoints) {
                        ret.push(<PolyCircle key={i} region={o.points} diameter={o.diameter} points={o.circlePoints}/>)
                    }else {
                        ret.push(<Line
                            key={i}
                            points={o.points}
                            lineCap="round"
                            lineJoin="round"
                            tension={0}
                            fill="gray"
                            opacity={1}
                            closed
                            shadowColor="gray"
                            shadowBlur={0.5}
                            shadowOpacity={1}
                            shadowOffset={{ x: .00001, y: .00001 }}
                        />);
                    }
                    break;
                case "polycircle":
                    break;
                default:
                    console.log("unknown cover type");
            }
        }

        return <Layer 
            listening={false}
        >
            {this.props.noCover?null:<Rect
                x={-1000000}
                y={-1000000}
                width={50000000} 
                height={50000000} 
                fill={fogColor}
                opacity={this.props.opacity}
            />}
            <Group
                opacity={1}
                globalCompositeOperation="destination-out"
            >
                {ret}
                {this.props.extraFog}
            </Group>
            {this.props.children}
        </Layer>
    }
}

class PolyCircleClip extends React.Component {
    constructor(props) {
        super(props);
        this.state={imageData:null};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const points=this.props.region;

        return <Group
            clipFunc={function(ctx) {
                ctx.moveTo(points[0], points[1]);
                for (let i=2; i<points.length; i+=2){
                    ctx.lineTo(points[i], points[i+1]);
                }
                ctx.closePath();
            }}
        >
            <Line
                points={this.props.points}
                lineCap="round"
                lineJoin="round"
                tension={.1}
                stroke="gray"
                strokeWidth={this.props.diameter}
                opacity={1}
                globalCompositeOperation="destination-out"
                shadowColor="gray"
                shadowBlur={2}
                shadowOpacity={1}
                shadowOffset={{ x: .00001, y: .00001 }}

            />
        </Group>;
    }
}

class PolyCircleOffScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state={imageData:null};
    }

    componentDidMount() {
        this.computeImageData();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.region != this.props.region) || 
            (prevProps.points != this.props.points) ||
            (prevProps.diameter != this.props.diameter)
        ) {
            this.computeImageData();
        }
    }

    computeImageData() {
        const rp = this.props.region;
        let minX, maxX, minY, maxY;
        if (!rp || rp.length < 2) {
            this.setState({imageData:null});
            return;
        }
        minX = maxX = rp[0];
        minY = maxY = rp[1];
        for (let i=2; i<rp.length; i+=2) {
            minX = Math.min(minX, rp[i]);
            maxX = Math.max(maxX, rp[i]);
            minY = Math.min(minY, rp[i+1]);
            maxY = Math.max(maxY, rp[i+1]);
        }
        const x=minX-5;
        const y=minY-5;
        const width = ((maxX-minX+10)*10);
        const height = ((maxY-minY+10)*10);

        let offScreenCanvas= document.createElement('canvas');
        offScreenCanvas.width= width;
        offScreenCanvas.height= height;
        let ctx= offScreenCanvas.getContext("2d");

        ctx.fillStyle = "gray";

        ctx.lineWidth=this.props.diameter*10;
        ctx.strokeStyle="gray";
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        const points = this.props.points;

        ctx.beginPath();
        ctx.moveTo((points[0]-x)*10, (points[1]-y)*10);
        for (let i=2; i<points.length; i+=2){
            ctx.lineTo((points[i]-x)*10, (points[i+1]-y)*10);
        }
        ctx.stroke();

        ctx.globalCompositeOperation = "source-in";

        ctx.beginPath();
        ctx.moveTo((rp[0]-x)*10, (rp[1]-y)*10);
        for (let i=2; i<rp.length; i+=2){
            ctx.lineTo((rp[i]-x)*10, (rp[i+1]-y)*10);
        }
        ctx.closePath();
        ctx.fill();



//        ctx.fillRect(0,0,width,height)

        this.setState({imageData:offScreenCanvas, x:x, y:y, width:width/10, height:height/10})
    }

    render() {
        const imageData = this.state.imageData;
        if (!imageData) {
            return null;
        }
        return <Image
            x={this.state.x}
            y={this.state.y}
            width={this.state.width}
            height={this.state.height}
            image={imageData}
        />
    }
}

const PolyCircle = navigator.userAgent.includes("Firefox")?PolyCircleOffScreen:PolyCircleClip;

function Grid(props) {
    const scale = props.scale;
    const gridxShift = props.gridxShift;
    const gridyShift = props.gridyShift;
    const width = props.width/scale + 40;
    const height = props.height/scale + 40;
    const mapX = -props.mapX/scale;
    const mapY = -props.mapY/scale;
    var ret=[];

    if (5*scale < 10) {
        return null;
    }

    let xStart = mapX - (mapX%5) + (-(gridxShift||0)) % 5 - 20;
    let yStart = mapY - (mapY%5) + (-(gridyShift||0)) % 5 - 20;

    const vPoints = [0,0,0,height];
    for (let x=xStart; x<= xStart + width; x+=5){
        ret.push(<Line
            key={"w"+x}
            x={x}
            y={yStart}
            points={vPoints}
            stroke="black"
            opacity={0.7}
            strokeScaleEnabled={true}
            strokeWidth={0.1}
            listening={false}
        />);
    }

    const hPoints = [0,0,width,0];
    for (let y=yStart; y<= yStart+height; y+=5){
        ret.push(<Line
            key={"h"+y}
            x={xStart}
            y={y}
            points={hPoints}
            stroke="black"
            opacity={0.7}
            strokeScaleEnabled={true}
            strokeWidth={0.1}
            listening={false}
        />);
    }

    return ret;
}

var defaultMeasureCanvas = window.document.createElement("canvas");
var defaultMeasure = defaultMeasureCanvas.getContext("2d");
defaultMeasure.font = "18px Convergence, sans-serif";

class Tokens extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const settings = campaign.getGameState();
        const names = settings.names||"full";
        const shared = ((settings.sharing||"readonly")=="open");
        let {selected, windowToUse, rotateFn, scale, onMoveGroup, onMoveSelected, toggleSelected, onMoveGroupEnd, hideNames, combatants, imageName, pixelsPerFoot, dragFn, moveFn, onTap, noMoveGroupPin, pcOnly, hideHidden} = this.props;
        let retDead = [], retActive=[], retInactive = [], retTurn=[], retObj=[], retMove=[];
        combatants = combatants || [];
        let measure;
        let centerX=0, centerY=0, centerCount=0;
    
        if (windowToUse) {
            var measureCanvas = windowToUse.document.createElement("canvas");
            measure = measureCanvas.getContext("2d");
            measure.font = "18px Convergence, sans-serif"
        } else {
            measure=defaultMeasure;
        }
        if (imageName) {
            imageName=imageName.toLowerCase();
        }
    
        let num=1;
        for (let i in combatants) {
            const c=combatants[i];
            let cnum=null;
    
            if ((c.ctype != "object") && (!c.state || (c.state=="active")) && !c.hidden) {
                cnum=num;
                num++;
            }
    
            if ((!hideHidden || !c.hidden) && c.tokenMap && (c.tokenMap.toLowerCase() == imageName)) {
                let passOn = (!pcOnly || (c.ctype=="pc") || (c.ctype=="cmonster") || (c.ctype=="object" && c.playerControlled));
                if (passOn && !shared && pcOnly && ["pc","cmonster"].includes(c.ctype)) {
                    if (!campaign.getMyCharacterInfo(c.name)){
                        passOn=false;
                    }
                }

                const t = <CombatantToken 
                    key={c.id||i}
                    combatant={c}
                    index={i}
                    number={cnum}
                    pixelsPerFoot={pixelsPerFoot}
                    onTap={passOn?onTap:null}
                    onMove={passOn?this.onMove.bind(this):null}
                    onDrag={passOn&&dragFn?this.onDrag.bind(this):null}
                    onRotate={passOn?rotateFn:null}
                    measure={measure}
                    hideNames={hideNames}
                    eventSync={this.props.eventSync}
                    moveObjects={this.props.moveObjects}
                    scale={scale}
                    names={names}
                    selected={selected}
                    onMoveSelected={onMoveSelected}
                    toggleSelected={toggleSelected}
                    cancelPingCheck={this.props.cancelPingCheck}
                />;
    
                if (t) {
                    var ret;
    
                    if (this.state.isMoving && i==this.state.curMove) {
                        ret=retMove;
                    } else if (c.ctype == "object") {
                        ret = retObj;
                    } else {
                        switch (c.state) {
                            case "inactive":
                                ret = retInactive;
                                break;
                            case "active":
                            default:
                                const cInfo = getTokenInfo(c);
                                if (c.type && (cInfo.hp==0)) {
                                    ret = retDead;
                                } else {
                                    if (!c.type || (cInfo.group=="pc")) {
                                        centerX += c.tokenX;
                                        centerY += c.tokenY;
                                        centerCount ++;
                                    }
                                    ret = retActive;
                                }
                                break;
                        }
    
                        if (c.currentTurn) {
                            ret = retTurn;
                        }
                    }
    
                    ret.push(t);
                }
            }
        }
    
        if ((onMoveGroup||onMoveGroupEnd) && ((centerCount>1 && !noMoveGroupPin) || ((centerCount > 0) && scale < 3))) {
            const groupX = centerX/centerCount;
            const groupY = centerY/centerCount;
    
            retActive.push(<Path 
                key="movegroup"
                token
                offsetX={730.94}
                offsetY={1839.63}
                data="m730.94,1839.63c-38.76599,-190.30004 -107.11603,-348.67004 -189.90301,-495.44006c-61.40698,-108.86999 -132.54398,-209.35998 -198.36399,-314.93994c-21.97201,-35.24298 -40.93399,-72.47601 -62.04699,-109.05298c-42.21601,-73.13702 -76.444,-157.935 -74.26901,-267.93201c2.125,-107.47302 33.208,-193.68402 78.02999,-264.17202c73.719,-115.935 197.20102,-210.98899 362.88401,-235.969c135.466,-20.42399 262.47497,14.082 352.54303,66.748c73.59601,43.03801 130.59601,100.527 173.91595,168.28002c45.21997,70.716 76.35999,154.25998 78.96997,263.23196c1.34009,55.83002 -7.79992,107.53204 -20.67993,150.41803c-13.03003,43.409 -33.98999,79.69501 -52.64001,118.45398c-36.41004,75.659 -82.05005,144.98401 -127.85999,214.34399c-136.43701,206.60999 -264.49603,417.31006 -320.58002,706.03003z"
                scaleX={.015/(scale||1)}
                scaleY={.015/(scale||1)}
                fill="orange"
                draggable
                onDragMove={onMoveGroup}
                onDragEnd={onMoveGroupEnd}
                x={groupX}
                y={groupY}
                origX={groupX}
                origY={groupY}
            />);
        }
    
        return retObj.concat(retDead.concat(retInactive).concat(retActive).concat(retTurn).concat(retMove));
    }

    onMove(i,e){
        if (this.props.moveFn) {
            this.props.moveFn(i,e);
        }
        this.setState({isMoving:true, curMove:i});
    }

    onDrag(i,e) {
        this.props.dragFn(i,e);
        this.setState({isMoving:false});
    }
}


class CombatantToken extends React.Component {
    constructor(props) {
        super(props);
        this.state={hover:false,tokensLoaded:imageCache.counter};
        this.onDragMoveFn = this.onDragMove.bind(this);
        this.onDragEndFn = this.onDragEnd.bind(this);
        this.onMouseDownFn = this.onMouseDown.bind(this);
        this.onMouseUpFn = this.onMouseUp.bind(this);
        this.onMouseMoveFn = this.onMouseMove.bind(this);
        this.onMouseEnterFn = this.onMouseEnter.bind(this);
        this.onMouseLeaveFn = this.onMouseLeave.bind(this);
        this.onDragHandleFn = this.onDragHandle.bind(this);
        this.onDragHandleEndFn = this.onDragHandleEnd.bind(this);
        this.moved=true;
        this.hoverChangeFn = this.hoverChange.bind(this);
        this.ver=0;
    }

    componentDidMount() {
        if (this.props.eventSync) {
            this.props.eventSync.addListener("listToken", this.hoverChangeFn);
        }
    }

    componentWillUnmount() {
        if (this.props.eventSync) {
            this.props.eventSync.removeListener("listToken", this.hoverChangeFn);
        }
    }

    hoverChange(index) {
        const hover = (this.props.index == index);
        if (hover != this.state.hover) {
            this.setState({hover:hover});
        }
    }

    loadedImage() {
        this.setState({tokensLoaded:imageCache.counter});
    }

    render() {
        const c = this.props.combatant;
        const {names, selected} = this.props;
        const cInfo = getTokenInfo(c);
        let ret=null;
        const gridBaseSize = 70;
        const {tokenType} = cInfo;
        this.select = selected && selected[c.id];
        let rectColor=null;
        if (c.currentTurn) {
            rectColor = "yellow";
        } else if (this.state.hover) {
            rectColor = "red";
        } else if (this.select) {
            rectColor = "cyan";
        }

        if (tokenType == "nametoken") {
            if (!cInfo.tokenImageURL) {
                return null;
            }

            const skullImage = imageCache.getImage(skullUrl,this.loadedImage.bind(this));
            const heartbeat = imageCache.getImage(heartbeatUrl,this.loadedImage.bind(this));

            var name = maxLen(cInfo.displayName || "", 30);
            var opacity;

            const id=this.props.number;
            if (c.hideName || ((names=="hide") && (cInfo.group=="pc"))) {
                name = (id||"").toString();
            } else if ((names=="abbrev") && (cInfo.group=="pc")) {
                const s = name.split(" ");
                let an = "";
                for (let i in s) {
                    an += s[i].substr(0,1);
                }
                name=an;
                if (id) {
                    name = "("+id+") "+name;
                }
            } else {
                if (id) {
                    name = "("+id+") "+name;
                }
            }

            const s = (sizeScaleMap[cInfo.tokenSize]||1)*gridBaseSize;
            const textWidth = name.length?(this.props.measure.measureText(name||"").width+14):0;
            let health;
            let circleColor;
            let healthColor;
            let showDead;
            let almostDead;

            switch (c.state) {
                case "inactive":
                    opacity=0.85;
                    break;
                case "active":
                default:
                    if (c.type && (cInfo.hp==0)) {
                        opacity = 0.65;
                        showDead=true;
                    } else {
                        if (c.hidden) {
                            opacity=0.7;
                        } else {
                            opacity=1;
                        }
                        health = (cInfo.hp||0)/(cInfo.hpMax||1);
                        if (health) {
                            if (health > 0.75) {
                                //healthColor="green";
                            } else if (health > 0.5) {
                                //healthColor="gold";
                            } else if (health > 0.25) {
                                healthColor="orange";
                            } else {
                                healthColor="red";
                                // don't let bar get too small
                            }
                        }
                    }
                    break;
            }

            //console.log("cInfo", cInfo)
            if (cInfo.hp==0) {
                if ((c.ctype=="pc") && !cInfo.showDead) {
                    almostDead=true;
                } else {
                    showDead = true;
                }
            }
            
            if (showConditionIndicator(cInfo.conditions)) {
                circleColor="DodgerBlue";
            }

            ret= <Group 
                token 
                x={c.tokenX} y={c.tokenY} 
                origX={c.tokenX} origY={c.tokenY}
                scaleX={5/gridBaseSize} 
                scaleY={5/gridBaseSize}
                draggable={!!this.props.onDrag} 
                onDragEnd={this.onDragEndFn} 
                onDragMove={this.onDragMoveFn}
                onMouseDown={this.onMouseDownFn}
                onMouseUp={this.onMouseUpFn}
                onMouseMove={this.onMouseMoveFn}
                onTouchStart={this.onMouseDownFn}
                onTouchEnd={this.onMouseUpFn}
                onTouchMove={this.onMouseMoveFn}
                onMouseEnter={this.onMouseEnterFn}
                onMouseLeave={this.onMouseLeaveFn}
                onContextMenu={this.onContextMenu.bind(this)}
                opacity={opacity}
            >
                <Image token image={imageCache.getImage(cInfo.tokenImageURL,this.loadedImage.bind(this))} opacity={1} width={s} height={s} offsetX={s/2}  offsetY={s/2}/>
                {(showDead && skullImage)?<Image token image={skullImage} width={s} height={s} offsetX={s/2} offsetY={s/2} opacity={0.9} scaleX={0.9} scaleY={0.9}/>:null}
                {(almostDead && heartbeat)? <Image token image={heartbeat} width={s} height={s} offsetX={s/2} offsetY={s/2} opacity={0.9} scaleX={0.9} scaleY={0.9} shadowBlur={5} shadowColor="white"/>:null}
                {health&&healthColor?<Circle token radius={s/10} offsetX={s*0.39} offsetY={s*0.39} fill={healthColor} opacity={0} shadowBlur={2} shadowColor="black"/>:null}
                {health&&healthColor?<Circle token radius={s/2+1} stroke={healthColor} opacity={0.7} shadowBlur={2} strokeWidth={4}/>:null}
                {circleColor?<Group scaleX={s*0.0028} scaleY={s*0.0028} x={s*0.225} y={-s*0.5} opacity={0.8} shadowBlur={2} shadowColor="black">{warningSVG}</Group>:null}
                {rectColor?<Rect token width={s+8} height={s+18} offsetX={(s+8)/2} offsetY={(s+8)/2} stroke={rectColor} shadowBlur={5}/>:null}
                {textWidth?<Rect token x={-textWidth/2} y={s/2-6} width={textWidth} height={22} fill="gray" opacity={0.65} cornerRadius={4}/>:null}
                {textWidth?<Text token text={name} x={-textWidth/2+6} fontSize={18} fontFamily="Convergence, sans-serif" y={s/2-3} width={textWidth} align="left" fill="yellow"/>:null}
            </Group>;
        } else {
            const opacity= c.hidden?0.7:1;
            const rotation = this.state.overrideRotation?this.state.rotation:(c.rotation||0);
            const showHandle = this.props.onRotate && this.props.moveObjects&&!this.state.hideHandle;
            const listening = (this.props.moveObjects || c.showAsToken || tokenType=="imagetoken");

            if (["rectangle","cube","line","cone"].includes(tokenType)) {
                let height, width, offsetX, offsetY;
                let points;

                if (tokenType=="rectangle") {
                    height = Number(c.height)||5;
                    width = Number(c.width)||5;
                    offsetX = width/2;
                    offsetY = height/2;
                } else if (tokenType =="cube") {
                    height = width = Number(c.width)||5;
                    offsetX = width/2;
                    offsetY = height;
                } else if (tokenType == "line") {
                    height = Number(c.width)||5;
                    width = 5;
                    offsetX = width/2;
                    offsetY = height;
                } else if (tokenType == "cone") {
                    height = width = Number(c.width)||5;
                    offsetX = width/2;
                    offsetY = height;
                    points = [width/2,height,0,0,width,0];
                }

                if (!points) {
                    points = [0,0,0,height,width,height,width,0];
                }
                ret= <Group>
                    <Line 
                        token 
                        listening={listening}
                        draggable={!!this.props.onDrag} 
                        onDragEnd={this.onDragEndFn} 
                        onDragMove={this.onDragMoveFn}
                        onMouseDown={this.onMouseDownFn}
                        onMouseUp={this.onMouseUpFn}
                        onMouseMove={this.onMouseMoveFn}
                        onMouseEnter={this.onMouseEnterFn}
                        onMouseLeave={this.onMouseLeaveFn}
                        onTouchStart={this.onMouseDownFn}
                        onTouchEnd={this.onMouseUpFn}
                        onTouchMove={this.onMouseMoveFn}
                        onContextMenu={this.onContextMenu.bind(this)}
                        points={points}
                        closed
                        fill={c.fill||"white"} 
                        x={c.tokenX} y={c.tokenY} 
                        origX={c.tokenX} origY={c.tokenY}
                        offsetX={offsetX} offsetY={offsetY} 
                        opacity={0.6*opacity}
                        stroke={rectColor}
                        shadowBlur={rectColor?5/this.props.scale:0}
                        strokeWidth={rectColor?1:0}
                        strokeScaleEnabled={false}
                        cornerRadius={1/4}
                        rotation={rotation}
                        ref={this.saveBase.bind(this)}
                    />
                    {showHandle?<Line
                        x={c.tokenX} y={c.tokenY} 
                        offsetY={offsetY}
                        points={[0,0,0,-2]}
                        stroke="black"
                        strokeWidth={1}
                        strokeScaleEnabled={false}
                        rotation={rotation}
                    />:null}
                    {showHandle?<Rect
                        index={this.props.index}
                        x={c.tokenX} y={c.tokenY} 
                        offsetX={1/2}
                        offsetY={1/2+offsetY+2}
                        fill="white"
                        stroke="black"
                        strokeWidth={1}
                        strokeScaleEnabled={false}
                        width={1}
                        height={1}
                        rotation={rotation}
                    />:null}
                    {showHandle?<Circle
                        key={"h"+this.ver}
                        draggable
                        token 
                        ref={this.saveHandle.bind(this)}
                        onDragEnd={this.onDragHandleEndFn} 
                        onDragMove={this.onDragHandleFn}
                        x={c.tokenX} y={c.tokenY} 
                        offsetX={0}
                        offsetY={offsetY+2}
                        radius={1.5}
                        fill="red"
                        opacity={0}
                        rotation={rotation}
                    />:null}
                    {showHandle?<Circle 
                        x={c.tokenX} y={c.tokenY} 
                        radius={.5} 
                        fill={c.fill||"white"} 
                    />:null}
                </Group>;
            } else if (["circle","cylinder","sphere"].includes(tokenType)) {
                const radius = (tokenType=="circle")?((c.diameter||5)/2):(c.width||2.5);
                ret= <Circle 
                    token 
                    listening={listening}
                    draggable={!!this.props.onDrag} 
                    onDragEnd={this.onDragEndFn} 
                    onDragMove={this.onDragMoveFn}
                    onMouseDown={this.onMouseDownFn}
                    onMouseUp={this.onMouseUpFn}
                    onContextMenu={this.onContextMenu.bind(this)}
                    onMouseMove={this.onMouseMoveFn}
                    onMouseEnter={this.onMouseEnterFn}
                    onMouseLeave={this.onMouseLeaveFn}
                    onTouchStart={this.onMouseDownFn}
                    onTouchEnd={this.onMouseUpFn}
                    onTouchMove={this.onMouseMoveFn}
                    x={c.tokenX} y={c.tokenY}
                    origX={c.tokenX} origY={c.tokenY}
                    radius={radius} 
                    fill={c.fill||"white"} 
                    opacity={0.6*opacity}
                    stroke={rectColor}
                    strokeScaleEnabled={false}
                    shadowBlur={rectColor?5/this.props.scale:0}
                    strokeWidth={rectColor?1:0}
                />;
            } else if (["imagetoken","image"].includes(tokenType)) {
                if (cInfo.tokenImageURL) {
                    let iobj= {url:cInfo.tokenImageURL, width:cInfo.width, height:cInfo.height, opacity:cInfo.opacity};

                    if (!rectColor && showHandle) {
                        rectColor="black";
                    }
                    ret= <Group>
                        <Image 
                            token 
                            rotation={rotation}
                            listening={listening}
                            draggable={!!this.props.onDrag} 
                            onDragEnd={this.onDragEndFn} 
                            onDragMove={this.onDragMoveFn}
                            onMouseDown={this.onMouseDownFn}
                            onMouseUp={this.onMouseUpFn}
                            onContextMenu={this.onContextMenu.bind(this)}
                            onMouseMove={this.onMouseMoveFn}
                            onMouseEnter={this.onMouseEnterFn}
                            onMouseLeave={this.onMouseLeaveFn}
                            onTouchStart={this.onMouseDownFn}
                            onTouchEnd={this.onMouseUpFn}
                            onTouchMove={this.onMouseMoveFn}
                            opacity={(iobj.opacity||1)*opacity}
                            image={imageCache.getImage(iobj.url,this.loadedImage.bind(this))}
                            x={c.tokenX} y={c.tokenY}
                            origX={c.tokenX} origY={c.tokenY}
                            width={Number(iobj.width)} height={Number(iobj.height)} 
                            offsetX={iobj.width/2} offsetY={iobj.height/2}
                            stroke={rectColor}
                            shadowBlur={(rectColor&&(rectColor!="black"))?5/this.props.scale:0}
                            strokeWidth={(this.props.moveObjects || rectColor)?1:0}
                            strokeScaleEnabled={false}
                        />
                        {showHandle?<Line
                            x={c.tokenX} y={c.tokenY} 
                            offsetY={iobj.height/2}
                            points={[0,0,0,-2]}
                            stroke="black"
                            strokeWidth={1}
                            strokeScaleEnabled={false}
                            rotation={rotation}
                        />:null}
                        {showHandle?<Rect
                            index={this.props.index}
                            x={c.tokenX} y={c.tokenY} 
                            offsetX={1/2}
                            offsetY={1/2+iobj.height/2+2}
                            fill="white"
                            stroke="black"
                            strokeWidth={1}
                            strokeScaleEnabled={false}
                            width={1}
                            height={1}
                            rotation={rotation}
                        />:null}
                        {showHandle?<Circle
                            key={"h"+this.ver}
                            token 
                            draggable
                            ref={this.saveHandle.bind(this)}
                            onDragEnd={this.onDragHandleEndFn} 
                            onDragMove={this.onDragHandleFn}
                            x={c.tokenX} y={c.tokenY} 
                            offsetX={0}
                            offsetY={iobj.height/2+2}
                            radius={1.5}
                            fill="red"
                            opacity={0}
                            rotation={rotation}
                        />:null}
                    </Group>;
                }
            } else {
                console.log("unknown object type", tokenType, c.otype);
            }
        }

        let dist=0;
        let distTxt;
        if (this.doingDrag) {
            dist = Math.trunc(Math.sqrt(Math.pow(c.tokenX-this.dragX, 2)+Math.pow(c.tokenY-this.dragY, 2)));
            if (dist < 1000) {
                distTxt = dist+"ft.";
            } else {
                distTxt = (dist/5280).toFixed(2)+" miles";
            }
        }

        return <Group
        >
            {dist>5?<Line
                x={c.tokenX} y={c.tokenY} 
                points={[0,0,this.dragX-c.tokenX,this.dragY-c.tokenY]}
                stroke="red"
                strokeWidth={2}
                strokeScaleEnabled={false}
                listening={false}
            />:null}
            {ret}
            {dist>5?<Text 
                text={distTxt} 
                x={c.tokenX+(this.dragX-c.tokenX)/2}
                y={c.tokenY+(this.dragY-c.tokenY)/2}
                scaleX={1/this.props.scale} 
                scaleY={1/this.props.scale}
                fontSize={18} 
                fontFamily="Convergence, sans-serif" 
                opacity={1}
                shadowEnabled
                shadowColor='black'
                shadowBlur={10}
                shadowOpacity={1}
                fill={"white"} 
                listening={false}
            />:null}
        </Group>;
    }

    saveHandle(handle) {
        this.handle=handle;
    }

    saveBase(handle) {
        this.base=handle;
    }
    
    onDragMove(e) {
        this.moved=true;
        const {onMove, onMoveSelected} = this.props;
        if (this.select && onMoveSelected) {
            onMoveSelected(e);
        } else {
            if (onMove) {
                onMove(this.props.index,e);
            }
            this.doingDrag = true;
            this.dragX=e.target.x();
            this.dragY=e.target.y();
            this.startMeasure();
        }

        this.setState({hideHandle:true});
    }

    onDragEnd(e) {
        const {onDrag, onMoveSelected} = this.props;
        this.doingDrag = false;
        if (!this.select || !onMoveSelected) {
            this.stopMeasure();
            this.radius=0;
            if (onDrag) {
                onDrag(this.props.index,e);
            }
        }
        this.setState({hideHandle:false});
    }

    onMouseDown(e)
    {
        if (e.evt.ctrlKey && this.props.toggleSelected) {
            e.evt.preventDefault(); 
            e.evt.stopPropagation(); 
            const c = this.props.combatant;
            this.props.toggleSelected(c.id);
            this.moved=true;
            return;
        }
        if (e.evt.buttons ==2) {
            this.moved=true;
        } else {
            this.moved=false;
        }
    }

    onMouseUp(e) {
        if (!this.moved) {
            //e.evt.preventDefault(); 
            //e.evt.stopPropagation(); 
            if (this.props.onTap) {
                this.props.onTap(this.props.index, e);
            }
        }
        this.moved=true; 
    }

    onContextMenu(e) {
        if (this.props.onTap) {
            e.evt.preventDefault(); 
            e.evt.stopPropagation(); 
            this.props.onTap(this.props.index, e);
        }
        this.moved=true; 
    }

    onMouseMove() {
        //this.moved=true;
    }

    onMouseEnter() {
        if (this.props.eventSync) {
            this.props.eventSync.emit("mapToken", this.props.index);
        }
    }

    onMouseLeave() { 
        if (this.props.eventSync) {
            this.props.eventSync.emit("mapToken", -1);
        }
    }

    onDragHandle(e) {
        if (this.props.cancelPingCheck) {
            this.props.cancelPingCheck();
        }
        e.cancelBubble = true;
        const stage = e.target.getStage();
        const pos = stage.getPointerPosition();
        const c = this.props.combatant;
        const x = (pos.x-stage.x())/stage.scaleX();
        const y = (pos.y-stage.y())/stage.scaleY();

        const deg = Math.round((180-Math.atan2(x-c.tokenX, y-c.tokenY)*180/Math.PI)/5)*5;

        this.setState({overrideRotation:true, rotation:deg});
    }

    onDragHandleEnd(e){
        e.cancelBubble = true;

        this.ver++;
        this.props.onRotate(this.props.index, this.state.rotation);
        this.setState({overrideRotation:false});
    }

    startMeasure() {
        if (!this.measureTimer) {
            const t=this;
            const c = t.props.combatant;
            if (c.hidden) {
                return;
            }
            localMeasureId = this.measureId = campaign.newUid();
            this.measureTimer = setInterval(function () {
                if (!t.doingDrag){
                    t.stopMeasure();
                } else {

                    campaign.updateCampaignContent("adventure",{
                        name:t.measureId,
                        type:"ping",
                        measure:true,
                        startX:c.tokenX,
                        endX:t.dragX,
                        startY:c.tokenY,
                        endY:t.dragY,
                        color:"red"
                    });
                }
            },1000);
        }
    }

    stopMeasure() {
        if (this.measureTimer) {
            campaign.deleteCampaignContent("adventure", this.measureId);
            clearInterval(this.measureTimer);
            this.measureTimer=null;
        }
    }
}


class PopupMap extends React.Component {
    constructor(props) {
        super(props);

        this.state=this.getState();
        this.datachangeFn = this.datachange.bind(this);
    }

    componentDidMount() {
        popupState.subscribe(this.datachangeFn);
        pointerSync.on("changed", this.datachangeFn);
        globalDataListener.onChangeCampaignContent(this.datachangeFn,"adventure");
        this.datachange(); //  update with current state data
    }
  
    componentWillUnmount() {
        popupState.unsubscribe(this.datachangeFn);
        globalDataListener.removeCampaignContentListener(this.datachangeFn, "adventure");
        pointerSync.removeListener("changed", this.datachangeFn);

        if (this.popoutWindow){
            this.popoutWindow.removeEventListener("resize", this.popupResized.bind(this));
            this.popoutWindow=null;
        }
    }

    datachange(){
        this.setState(this.getState());
    }

    getState() {
        const handouts = campaign.getHandouts();
        return({
            popupWidth:popupState.popupWidth||0, 
            popupHeight:popupState.popupHeight||0,
            pointerX:pointerInfo.x, 
            pointerY:pointerInfo.y,
            showPopup:popupState.showPopup,
            mapInfo:popupState.maps.mapInfo,
            grid:popupState.grid,
            handout:(handouts.showHandout && handouts.mru && handouts.mru.length)?handouts.mru[0]:null
        });
    }

	render() {
        const t=this;
        const {popupHeight,popupWidth,pointerX,pointerY}= this.state;

        return <span>
            {this.state.showPopup?
                <PopoutWindow 
                    copyStyles={false}
                    name="ShardTabletopMapWindow"
                    title="Shard Tabletop (click in window to maximize)"
                    containerId="map"
                    window={{open:this.openNewWindow.bind(this), 
                        removeEventListener:function(a,b){if (t.popoutWindow) {return t.popoutWindow.removeEventListener(a,b)}},
                        addEventListener:function(a,b){if (t.popoutWindow) {return t.popoutWindow.addEventListener(a,b)}}
                    }}
                    url=""
                    onClosing={this.onClosing.bind(this)}
                >
                    <div className="lightT" onClick={this.onClick.bind(this)}>
                        <link href="https://fonts.googleapis.com/css2?family=Convergence&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap" rel="stylesheet"/>
                        <link rel="stylesheet" href={location.origin+"/purple3.min.css"}/>
                        <link rel="stylesheet" href={location.origin+"/base.css"}/>
                        {MapSharedView2?<MapSharedView2
                            readonly 
                            nomove 
                            matchView 
                            size={{height:popupHeight,width:popupWidth}}
                            pointerX={pointerX} 
                            pointerY={pointerY}
                            ignoreLocal
                        />:<MapSharedView 
                            readonly 
                            nomove 
                            matchView 
                            size={{height:popupHeight,width:popupWidth}}
                            pointerX={pointerX} 
                            pointerY={pointerY}
                            ignoreLocal
                        />}
                    </div>
                </PopoutWindow>
            : null
            }
        </span>;
    }

    onClosing() {
        popupState.setPopupState(false);
    }

    onClick(){
        const {popoutWindow} = this.state;
    
        if (popoutWindow) {
            toggleFullscreen(popoutWindow.document);
            this.popupResized();
        }
    }

    openNewWindow(url, title, strWindowFeatures) {
        if (this.popoutWindow){
            this.popoutWindow.removeEventListener("resize", this.popupResized.bind(this));
        }
        var newWindow = window.open(url, title, strWindowFeatures);
        newWindow.document.body.style.margin=0;

        this.setState({popoutWindow:newWindow});
        this.popoutWindow = newWindow;
        this.popoutWindow.addEventListener("resize", this.popupResized.bind(this));
        this.popupResized();
        return newWindow;
    }

    popupResized() {
        //console.log("resized", this.popoutWindow.innerWidth, this.popoutWindow.innerHeight);
        if (this.popoutWindow) {
            this.setState({popupWidth:this.popoutWindow.innerWidth, popupHeight:this.popoutWindow.innerHeight})
            popupState.setPopupWindowDimensions(this.popoutWindow.innerWidth, this.popoutWindow.innerHeight)
        }
    }
}

const pointerInfo = {x:0, y:0};
const pointerSync = new EventEmitter();

function setPointer(x,y) {
    pointerInfo.x =x;
    pointerInfo.y=y;
    pointerSync.emit("changed",pointerInfo);
}

function clearPointer() {
    pointerInfo.x=0;
    pointerInfo.y=0;
    pointerSync.emit("changed",pointerInfo);
}

class MapWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state={pointerX:pointerInfo.x, pointerY:pointerInfo.y};
        this.changePointerFn = this.changePointer.bind(this);
    }

    componentDidMount() {
        pointerSync.on("changed", this.changePointerFn);
    }

    componentWillUnmount() {
        pointerSync.removeListener("changed", this.changePointerFn);
    }

    changePointer() {
        this.setState({pointerX:pointerInfo.x, pointerY:pointerInfo.y});
    }

    render() {
        var width = this.props.size.width;

        return <div onClick={this.toggleFullscreen.bind(this)} style={{width:this.props.size.width, height:this.props.size.height, overflow:"hidden", position:"relative", backgroundColor:fogColor}}>
            <div style={{position:"absolute", top:0, left:0, width:width, height:this.props.size.height}}>
                <StageMap 
                    mapInfo={this.props.mapInfo} 
                    size={{width:width, height:this.props.size.height}} 
                    pointerX={this.state.pointerX} 
                    pointerY={this.state.pointerY}
                    popoutWindow={this.props.popoutWindow}
                    grid={this.props.grid}
                    ignoreLocal
                />
            </div>
            {this.props.handout?<ShowHandout handout={this.props.handout} onClick={this.props.clickHandout} size={this.props.size}/>:null}
        </div>;
    }

    toggleFullscreen() {
        const popoutWindow = this.props.popoutWindow;
    
        if (popoutWindow) {
            toggleFullscreen(popoutWindow.document);
        }
    }
}

class MapPings extends React.Component {
    constructor(props) {
        super(props);
        this.state={pingProgress:{}};
        this.datachangeFn = this.datachange.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.datachangeFn,"adventure");
        this.datachange();
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.datachangeFn, "adventure");
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    datachange(){
        const pingList = campaign.getPingList();

        if (pingList.length) {
            this.startPingUpdate();
        }
        this.setState({pingList});
    }

    startPingUpdate() {
        if (this.timer) {
            return;
        }
        const t=this;
        this.timer = setInterval(function () {
            const np = {};
            const pp = t.state.pingProgress;
            const pl = t.state.pingList;
            if (pl.length) {
                for (let i in pl) {
                    const p = pl[i];
                    const pv = (pp[p.version || p.name]||0)+1;
                    np[p.version || p.name] =pv;
                    if (pv >50) {
                        // delete old ping
                        delete np[p.version || p.name];
                        campaign.deleteCampaignContent("adventure", p.name);
                    }
                }
                t.setState({pingProgress:np});
            } else {
                clearInterval(t.timer);
                t.timer=null;
                t.setState({pingProgress:{}});
            }
        },100);
    }

    render() {
        const pp = this.state.pingProgress;
        const pl = this.state.pingList;
        const list = [];
        const mult = (this.props.gridSize||5)/5;

        for (let i in pl) {
            const p = pl[i];
            const pv = (pp[p.version || p.name]||1);
            if ((p.name == localMeasureId) && !this.props.ignoreLocal) {
                // ignore
            }else if (p.measure) {
                const dist = Math.trunc(Math.sqrt(Math.pow(p.startX-p.endX, 2)+Math.pow(p.startY-p.endY, 2)));
                let distTxt;
                if (dist < 1000) {
                    distTxt = dist+"ft.";
                } else {
                    distTxt = (dist/5280).toFixed(2)+" miles";
                }
                if (dist > 3) {
                    return <Group>
                        <Arrow
                            x={p.startX}
                            y={p.startY}
                            points={[0,0,p.endX-p.startX,p.endY-p.startY]}
                            stroke={p.color||"red"}
                            fill={p.color||"red"}
                            strokeWidth={2}
                            strokeScaleEnabled={false}
                            listening={false}
                            pointerLength={10/this.props.scale}
                            pointerWidth={10/this.props.scale}
                        />
                        <Text 
                            text={distTxt} 
                            x={p.startX+(p.endX-p.startX)/2}
                            y={p.startY+(p.endY-p.startY)/2}
                            scaleX={1/this.props.scale} 
                            scaleY={1/this.props.scale}
                            fontSize={18} 
                            fontFamily="Convergence, sans-serif" 
                            opacity={1}
                            shadowEnabled
                            shadowColor='black'
                            shadowBlur={10}
                            shadowOpacity={1}
                            fill={"white"} 
                            listening={false}
                        />
                    </Group>;
                }
            } else if (pv<10) {
                list.push(<Circle
                    key={p.name}
                    radius={pv*mult}
                    x={p.x}
                    y={p.y}
                    strokeScaleEnabled={false}
                    strokeWidth={5}
                    stroke={p.color||"red"}
                    opacity={1}
                    listening={false}
                />);
            }
        }
        return list;
    }
}

class StageMap extends React.Component {
    constructor(props) {
        super(props);
        this.state={tokensLoaded:imageCache.counter};
    }

    componentWillUnmount() {
        this.cancelPingCheck();
    }

    loadedImage() {
        this.setState({tokensLoaded:imageCache.counter});
    }

    render() {
        const props=this.props;
        var width=0, height=0;
        var imgScale=1;
        var xShift=0, yShift=0;
        const mapInfo = props.mapInfo;
        if (!mapInfo) {
            return null;
        }
        let pixelsPerFoot = mapInfo.pixelsPerFoot||1;

        const image = imageCache.getImage(mapInfo.imageSrc, this.loadedImage.bind(this));
        if (image && mapInfo.mapPos){
            const mapPos = mapInfo.mapPos;

            {
                const m = campaign.getMapInfo(mapInfo.imageName);
                //console.log("image info", image.naturalWidth, image.naturalHeight, mapInfo, m);
                if (m) {
                    const a = campaign.getArtInfo(m.art);
                    let cPixelsPerFoot;
                    if (a) {
                        const fake = {originalWidth:a.originalWidth||a.imgWidth, originalHeight:a.originalHeight||a.imgHeight, imgWidth:image.naturalWidth, imgHeight:image.naturalHeight};
                        cPixelsPerFoot = getPixelsPerFoot(m, fake);
                        //if (cPixelsPerFoot != pixelsPerFoot) {
                        //    console.log("pixels per foot", pixelsPerFoot, cPixelsPerFoot, a, image.naturalWidth, image.naturalHeight);
                        //}
                        pixelsPerFoot=cPixelsPerFoot;
                    }
                }
            }

            width=image.naturalWidth/pixelsPerFoot;
            height=image.naturalHeight/pixelsPerFoot;
            var popupHeight = props.size.height,
                popupWidth = props.size.width;

            if (popupWidth > popupHeight) {
                imgScale = popupHeight/(mapPos.diameter||50);
            } else {
                imgScale = popupWidth/(mapPos.diameter||50);
            }
            xShift = popupWidth/2 - (mapPos.x*imgScale);
            yShift = popupHeight/2 - (mapPos.y*imgScale);
            this.scale = imgScale;
        } else if (mapInfo.imageSrc) {
            return <div className="f2 pa2 defaultbackground titlecolor">Loading map...</div>;
        } else {
            return <div className="f2 pa2 defaultbackground titlecolor">No map currently selected</div>;
        }
        if (!imgScale) {
            imgScale=1;
        }
        this.xShift=xShift;
        this.yShift=yShift;
        this.imgScale=imgScale;
        
        //console.log("shift", xShift, yShift, imgScale, pixelsPerFoot, width, height,mapInfo, campaign.getMapInfo(mapInfo.imageName));
        return <Stage 
            width={props.size.width||10}
            height={props.size.height||10}
            x={xShift}
            y={yShift}
            scaleX={imgScale}
            scaleY={imgScale}
            onClick={props.onClick}
            onTap={props.onClick}
            onTouchStart={props.onDragStage?this.mouseDown.bind(this):null}
            onTouchEnd={this.touchEnd.bind(this)}
            onTouchMove={this.touchMove.bind(this)}
            onMouseDown={props.onDragStage?this.mouseDown.bind(this):null}
            onMouseMove={props.onDragStage?this.mouseMove.bind(this):null}
            onMouseUp={props.onDragStage?this.mouseUp.bind(this):null}
            onDragStart={props.onDragStage?this.cancelPingCheck.bind(this):null}
            onDragEnd={props.onDragStage?this.dragStage.bind(this):null}
            onWheel={props.onWheel}
            draggable={!!props.onDragStage && !this.state.noCanvasMove}
            ref={this.saveStage.bind(this)}
        >
            <Layer>
                <Image image={image} width={width} height={height}/>
                {this.props.grid?<Grid scale={imgScale} gridxShift={mapInfo.gridxShift||0} gridyShift={mapInfo.gridyShift||0} width={props.size.width} height={props.size.height} mapX={xShift} mapY={yShift}/>:null}
                {this.getPinTokens(props.showAllPins, pixelsPerFoot)}
            </Layer>
            <Layer>
                <DrawObjs drawObjs={mapInfo.drawObjs}/>
            </Layer>
            <Layer>
                <Tokens
                    windowToUse={props.popoutWindow}
                    hideNames
                    combatants={mapInfo.combatants} 
                    imageName={mapInfo.imageName} 
                    pixelsPerFoot={pixelsPerFoot}
                    dragFn={!this.state.noCanvasMove && props.onDrag}
                    moveFn={this.cancelPingCheck.bind(this)}
                    onTap={props.onTap}
                    rotateFn={props.onRotate}
                    onMoveGroup={props.onMoveGroup}
                    onMoveGroupEnd={props.onMoveGroupEnd}
                    moveObjects
                    pcOnly
                    hideHidden
                    scale={imgScale||1}
                    cancelPingCheck={this.cancelPingCheck.bind(this)}
                />
            </Layer>
            <CoverObjs 
                coverObjs={mapInfo.coverObjs}
                noCover={props.noCover}
                opacity={1}
            >
            </CoverObjs>
            <Layer>
                {!props.noCover?<MapPings gridSize={mapInfo.gridSize} scale={imgScale||1} ignoreLocal={this.props.ignoreLocal}/>:null}
                {(props.pointerX && props.pointerY)?<Circle x={props.pointerX} y={props.pointerY} radius={8/imgScale} fill="#b30000" opacity={0.7}/>:null}
                {(props.pointerX && props.pointerY)?<Circle x={props.pointerX} y={props.pointerY} radius={3/imgScale} fill="red" opacity={1}/>:null}
                {this.getMeasureTool()}
            </Layer>
        </Stage>;
    }

    getMeasureTool() {
        if (this.state.showMeasure && this.state.measureEnd) {
            const dist = Math.trunc(Math.sqrt(Math.pow(this.clickStartPosX-this.state.measureEnd.x, 2)+Math.pow(this.clickStartPosY-this.state.measureEnd.y, 2)));
            let distTxt;
            if (dist < 1000) {
                distTxt = dist+"ft.";
            } else {
                distTxt = (dist/5280).toFixed(2)+" miles";
            }
            if (dist > 3) {
                return <Group>
                    <Arrow
                        x={this.clickStartPosX}
                        y={this.clickStartPosY}
                        points={[0,0,this.state.measureEnd.x-this.clickStartPosX,this.state.measureEnd.y-this.clickStartPosY]}
                        stroke="orange"
                        fill="orange"
                        strokeWidth={2}
                        strokeScaleEnabled={false}
                        listening={false}
                        pointerLength={10/this.imgScale}
                        pointerWidth={10/this.imgScale}
                    />
                    <Text 
                        text={distTxt} 
                        x={this.clickStartPosX+(this.state.measureEnd.x-this.clickStartPosX)/2}
                        y={this.clickStartPosY+(this.state.measureEnd.y-this.clickStartPosY)/2}
                        scaleX={1/this.imgScale} 
                        scaleY={1/this.imgScale}
                        fontSize={18} 
                        fontFamily="Convergence, sans-serif" 
                        opacity={1}
                        shadowEnabled
                        shadowColor='black'
                        shadowBlur={10}
                        shadowOpacity={1}
                        fill={"white"} 
                        listening={false}
                    />
                </Group>;
            }
        }
        return null;
    }


    touchMove(e) {
        this.mouseMove(e);
        if (this.props.onTouchMove) {
            return this.props.onTouchMove(e);
        }
    }

    touchEnd(e) {
        this.mouseUp(e);
        if (this.props.onTouchEnd) {
            return this.props.onTouchEnd(e);
        }
    }

    saveStage(r) {
        this.stage = r;
    }

    dragStage(e) {
        const delta = {
            x:(this.xShift-e.target.x())/this.imgScale,
            y:(this.yShift-e.target.y())/this.imgScale
        }
        this.cancelPingCheck();
        this.props.onDragStage(e,delta);
    }

    mouseDown(e) {
        if (!(e.evt.buttons>1)) {
            const pos = this.stage.getPointerPosition();
            this.clickedToken = e.target.attrs.token;
            this.clickStartPosX=(pos.x-this.xShift)/this.imgScale;
            this.clickStartPosY=(pos.y-this.yShift)/this.imgScale;
            this.clickStartRawX = pos.x;
            this.clickStartRawY = pos.y;
            this.startPingCheck();
        }
    }

    mouseUp(e) {
        this.cancelPingCheck();
        this.stopMeasure();
        this.setState({noCanvasMove:false, showMeasure:false});
    }

    mouseMove(e) {
        const pos = this.stage.getPointerPosition();
        if (Math.abs(pos.x-this.clickStartRawX)>3 || Math.abs(pos.y-this.clickStartRawY)>3) {
            this.cancelPingCheck();
        }
        if (this.state.showMeasure) {
            this.startMeasure();
            const pos = this.stage.getPointerPosition();
            this.setState({measureEnd:{x:(pos.x-this.xShift)/this.imgScale, y:(pos.y-this.yShift)/this.imgScale}});
        }
    }

    startPingCheck() {
        if (!this.props.onDragStage || this.props.noping) {
            return;
        }
        const t=this;
        this.cancelPingCheck();
        this.pingCheckTimer = setTimeout(function () {
            const pos = t.stage.getPointerPosition();
            campaign.updateCampaignContent("adventure",{
                name:campaign.newUid(),
                type:"ping",
                x:(pos.x-t.xShift)/t.imgScale,
                y:(pos.y-t.yShift)/t.imgScale,
                color:"orange"
            });
            t.setState({noCanvasMove:true, showMeasure:!this.clickedToken, measureEnd:null});
            t.pingCheckTimer=null;
            t.mouseMoved=true;
        },500)
    }

    startMeasure() {
        if (!this.measureTimer) {
            const t=this;
            localMeasureId = this.measureId = campaign.newUid();
            this.measureTimer = setInterval(function () {
                if (!t.state.showMeasure){
                    t.stopMeasure();
                } else {
                    campaign.updateCampaignContent("adventure",{
                        name:t.measureId,
                        type:"ping",
                        measure:true,
                        startX:t.clickStartPosX,
                        endX:t.state.measureEnd.x,
                        startY:t.clickStartPosY,
                        endY:t.state.measureEnd.y,
                        color:"orange"
                    });
                }
            },1000);
        }
    }

    stopMeasure() {
        if (this.measureTimer) {
            campaign.deleteCampaignContent("adventure", this.measureId);
            clearInterval(this.measureTimer);
            this.measureTimer=null;
        }
    }

    cancelPingCheck() {
        if (this.pingCheckTimer) {
            clearTimeout(this.pingCheckTimer);
            this.pingCheckTimer = null;
        }
    }

    getPinTokens(showAllPins,pixelsPerFoot) {
        const mapInfo = this.props.mapInfo;
        const pinList = mapInfo.pinList;
        var tret = [];
        if (!pinList) {
            return null;
        }

        let measure=null;
        if (this.props.popoutWindow) {
            var measureCanvas = this.props.popoutWindow.document.createElement("canvas");
            measure = measureCanvas.getContext("2d");
            measure.font = "18px Convergence, sans-serif"
        } else {
            measure=defaultMeasure;
        }
    
        for (let i in pinList) {
            const p = pinList[i];

            if (p.showPlayers || showAllPins) {
                const pinScale =getPinScale(p, mapInfo.gridSize, pixelsPerFoot);

                switch (p.type) {
                    case "map":
                    case "location": {
                        let {width} = measure.measureText(p.displayName);
                        tret.push(<Group 
                            key={i}
                            scaleX={pinScale}
                            scaleY={pinScale}
                            x={p.mapPos.x} 
                            y={p.mapPos.y} 
                            listening={false}
                        >
                            <Rect 
                                x={-width/2-2}
                                y={-31}
                                width={width+4} 
                                height={22} 
                                fill="#58170D" 
                                cornerRadius={4}
                                opacity={0.5}
                            />
                            <Text 
                                text={p.displayName} 
                                x={-width/2}
                                y={-28}
                                fontSize={18} 
                                fontFamily="Convergence, sans-serif" 
                                opacity={0.87}
                                align="left" 
                                fill="white"
                                listening={false}
                            />
                            <PinMarker pin={p}/>
                        </Group>);
                        break;
                    }
                    case "link":
                    default:
                        continue;
                        break;
                }
            }
        }
        return tret;
    }
}

let localMeasureId;

/* View in fullscreen */
function openFullscreen(elem) {
    if (!elem) {
        elem = window.document.body;
    }

    if (elem.requestFullscreen) {
        var res=elem.requestFullscreen({navigationUI:'hide'});
        if (res) {
            res.catch(function (err) {
                console.log("fullscreen Error:"+err);
                //displayMessage("Error entering fullscreen mode:"+err);
            });
        }
    } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
    } else {
        //displayMessage("No fullscreen support found")
    }
}
  
  /* Close fullscreen */
function closeFullscreen(document) {
    if (!document) {
        document = window.document;
    }

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
    }
}

function isFullscreen(document) {
    if (!document) {
        document = window.document;
    }
    return !!document.fullscreenElement;
}

function toggleFullscreen(document) {
    if (isFullscreen(document)) {
        closeFullscreen(document);
    } else {
        openFullscreen(document?document.body:document);
    }
}

class PopupState {
    constructor() {
        this.eventSync = new EventEmitter();
        this.maps = {};
        this.showPopup=false;
    }

    setPopupState(showPopup) {
        this.showPopup = showPopup;
        this.eventSync.emit("changed");
    }

    setGrid(gridOn) {
        this.grid = gridOn;
        if (campaign.getPrefs().grid != gridOn) {
            campaign.setPrefs({grid:gridOn});
        }

        campaign.updateAdventureView({grid:this.grid||false});
        this.eventSync.emit("changed");
    }
    
    setPopupWindowDimensions(width, height) {
        this.popupWidth = width;
        this.popupHeight = height;
        this.eventSync.emit("changed");
    }

    setMap(mapInfo) {
        const curMapInfo = this.maps.mapInfo||{};

        this.maps.mapInfo=mapInfo;
        const saveView = {};
        const mi = this.maps.mapInfo;
        var update=false;

        if (!areSameDeep(curMapInfo.coverObjs,mapInfo.coverObjs)) {
            saveView.coverObjs = mi.coverObjs||null;
            update=true;
        }

        if (!areSameDeep(curMapInfo.drawObjs, mapInfo.drawObjs)) {
            saveView.drawObjs = mi.drawObjs||null;
            update=true;
        }

        if (curMapInfo.image != mapInfo.image) {
            saveView.imageSrc = mi.image?mi.image.src:null;
            saveView.imageName = mi.image?mi.imageName:null;
            update=true;
        }

        if (curMapInfo.pixelsPerFoot != mapInfo.pixelsPerFoot) {
            saveView.pixelsPerFoot = mi.pixelsPerFoot||0;
            update=true;
        }

        if (curMapInfo.gridSize != mapInfo.gridSize) {
            saveView.gridSize = mi.gridSize||5;
            update=true;
        }

        if (curMapInfo.gridxShift != mapInfo.gridxShift) {
            saveView.gridxShift = mi.gridxShift||0;
            update=true;
        }

        if (curMapInfo.gridyShift != mapInfo.gridyShift) {
            saveView.gridyShift = mi.gridyShift||0;
            update=true;
        }

        if (!areSameDeep(curMapInfo.mapPos,mapInfo.mapPos)) {
            saveView.mapPos =mi.mapPos||null;
            update=true;
        }

        if (!areSameDeep(curMapInfo.pinList,mapInfo.pinList)) {
            saveView.pinList =mi.pinList||null;
            update=true;
        }

        if (update) {
            //console.log("saveView", saveView);
            campaign.updateAdventureView(saveView);
        }

        this.eventSync.emit("changed");
    }

    subscribe(fn) {
        this.eventSync.on("changed", fn);
        return fn;
    }

    unsubscribe(fn) {
        this.eventSync.removeListener("changed", fn);
    }
}

class MapSharedView extends React.Component {
    constructor(props) {
        super(props);
        this.loadAdventureViewFn = this.loadAdventureView.bind(this);
        this.state={mapInfo:null};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.handout != prevProps.handout) {
            this.setState({handout:this.props.handout});
        }
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"adventureview");
        globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"adventure");
        globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"monsters");
        globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"art");
        globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"players");
        this.loadAdventureView();
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"adventureview");
        globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"adventure");
        globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"monsters");
        globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"art");
        globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"players");
    }

    loadAdventureView() {
        const av = campaign.getAdventureView();
        const handouts = campaign.getHandouts();

        const ns = {};

        ns.grid = av.grid||false;
        const newMapInfo = this.getMapInfo(av);
        if (!areSameDeep(this.state.mapInfo, newMapInfo)) {
            ns.mapInfo = newMapInfo
        }
       
        const handout = (handouts.showHandout && handouts.mru && handouts.mru.length)?handouts.mru[0]:null;
        if ((handouts.showHandout  && !this.state.lastShowHandout) || !areSameDeep(handout, this.state.lastShownHandout)) {
            ns.handout = handout;
            ns.lastShownHandout = handout;
            ns.lastShowHandout = handouts.showHandout;
        }
        this.setState(ns);
    }

    loadedImage() {
        this.setState({tokensLoaded:imageCache.counter});
    }

    getMapInfo(av) {
        const t=this;
        const mapInfo={};
        const adventure = campaign.getAdventure();

        mapInfo.combatants = adventure.combatants||[];
        mapInfo.coverObjs = av.coverObjs||[];
        mapInfo.drawObjs = av.drawObjs||[];
        mapInfo.imageName = av.imageName || null;
        mapInfo.imageSrc = av.imageSrc || null;
        mapInfo.pixelsPerFoot = av.pixelsPerFoot || 0;
        mapInfo.gridxShift = av.gridxShift || 0;
        mapInfo.gridyShift = av.gridyShift || 0;
        mapInfo.gridSize = av.gridSize;
        mapInfo.cversion = av.cversion||null;

        if (adventure.positions) {
            const positions = adventure.positions;
            const combatants = mapInfo.combatants.concat();
            mapInfo.combatants = combatants;

            for (let i in combatants) {
                let c = combatants[i];
                const p = positions[c.id];
                if (p) {
                    c = Object.assign({},c);
                    combatants[i]=c;
                    c.tokenX=p.tokenX;
                    c.tokenY=p.tokenY;
                    if (p.rotation != null) {
                        c.rotation=p.rotation;
                    } else {
                        delete c.rotation;
                    }
                }
            }
        }

        if (!this.state.mapInfo||(this.state.mapInfo.imageSrc != av.imageSrc)||!av.mapPos || (mapInfo.cversion != ((this.state.mapInfo||{}).cversion||null))) {
            mapInfo.mapPos = av.mapPos||{};
            this.setState({playerModified:false});
        } else if (!this.props.matchView || this.state.playerModified) {
            mapInfo.mapPos = (this.state.mapInfo && this.state.mapInfo.mapPos) || av.mapPos || {};
        } else {
            mapInfo.mapPos = av.mapPos||{};
        }
        mapInfo.pinList = av.pinList ||[];

        return mapInfo;
    }

    render() {
        const {readonly,nomove,size, ignoreLocal} = this.props;
        var {width,height} = size;

        return <div style={{height:"100%", width:"100%", overflow:"hidden", position:"relative",backgroundColor:fogColor}}>
            <div style={{position:"absolute", top:0, left:0, width, height}}>
                <StageMap 
                    mapInfo={this.state.mapInfo} 
                    size={{width, height}} 
                    popoutWindow={window}
                    onDrag={readonly?null:this.onDrag.bind(this)}
                    onRotate={readonly?null:this.onRotate.bind(this)}
                    onTap={readonly?null:this.onTap.bind(this)}
                    onDragStage={this.state.newDistance||nomove?null:this.onDragStage.bind(this)}
                    onClick={readonly?null:this.onClickStage.bind(this)}
                    onTouchMove={readonly?null:this.onTouchMove.bind(this)}
                    onTouchEnd={readonly?null:this.onTouchEnd.bind(this)}
                    onWheel={nomove?null:this.wheel.bind(this)}
                    grid={this.state.grid}
                    noping={readonly}
                    pointerX={this.props.pointerX} 
                    pointerY={this.props.pointerY}
                    ignoreLocal={ignoreLocal}
                />
            </div>
            {this.state.handout?<ShowHandout handout={this.state.handout} onClick={this.hideHandout.bind(this)} size={this.props.size} character={this.props.character}/>:null}
        </div>;
    }

    hideHandout() {
        this.setState({handout:null});
    }

    onClickStage(e) {
        if (e.target.attrs.token) return;
    }

    onDragStage(e, delta) {
        if (e.target.attrs.token) return;
        const mapInfo = Object.assign({}, this.state.mapInfo);
        const mapPos = Object.assign({},mapInfo.mapPos);
        mapInfo.mapPos=mapPos;
        mapPos.x += delta.x;
        mapPos.y += delta.y;
        this.setState({mapInfo,playerModified:true});
    }

    wheel(e) {
        var factor;
        if (e.evt.deltaY < 0) {
            factor=1/1.1;
        } else {
            factor=1.1;
        }
        const mapInfo = Object.assign({}, this.state.mapInfo);
        const mapPos = Object.assign({},mapInfo.mapPos);
        mapInfo.mapPos=mapPos;
        mapPos.diameter = mapPos.diameter * factor;
        this.setState({mapInfo,playerModified:true});
        e.evt.preventDefault();
    }

    onTouchMove(e) {
        const dist = getDistance(e);
        var lastDistance = this.state.lastDistance;
        var newDistance = this.state.newDistance;

        lastDistance = newDistance;
        if (dist) {
            newDistance = dist;

            if (lastDistance && newDistance && (lastDistance != newDistance)) {
                const mapInfo = Object.assign({}, this.state.mapInfo);
                const mapPos = Object.assign({},mapInfo.mapPos);
                mapInfo.mapPos=mapPos;
                mapPos.diameter = mapPos.diameter * lastDistance / newDistance;
                this.setState({mapInfo,playerModified:true});
            }
        }
        this.setState({lastDistance, newDistance});

    }

    onTouchEnd(e) {
        this.setState({lastDistance:0, newDistance:0});
    }

    onDrag(i, e) {
        e.cancelBubble = true;
        const x=e.target.x();
        const y=e.target.y();

        this.onChangeCombatantPos(i, x, y);
    }

    onRotate(i, deg) {
        const mapInfo = Object.assign({},this.state.mapInfo);
        const combatants = mapInfo.combatants||[];
        mapInfo.combatants = combatants;
        const c = Object.assign({},combatants[i]);
        const update={};
        c.rotation = deg;
        combatants[i]=c;

        update["positions."+c.id] = {tokenX:c.tokenX, tokenY:c.tokenY, rotation:deg};
        campaign.updateAdventure(update);
        this.setState({mapInfo});
    }

    onTap(i, e) {
        const combatants = this.state.mapInfo.combatants||[];

        this.props.onClickCharacter(combatants[i].name, i, e);
    }

    onChangeCombatantPos(i, x, y) {
        const mapInfo = Object.assign({},this.state.mapInfo);
        const combatants = mapInfo.combatants||[];
        mapInfo.combatants = combatants;
        const c = Object.assign({},combatants[i]);
        const update={};
        c.tokenX=x;
        c.tokenY=y;
        combatants[i]=c;

        update["positions."+c.id] = {tokenX:x, tokenY:y, rotation:(c.rotation!=null)?c.rotation:null};
        campaign.updateAdventure(update);
        this.setState({mapInfo});
    }
}

class InlineMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.updateMapFn = this.loadMap.bind(this);

    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.updateMapFn,"maps");
        globalDataListener.onChangeCampaignContent(this.updateMapFn,"pins");
        globalDataListener.onChangeCampaignContent(this.updateMapFn,"art");

        this.loadMap();
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.updateMapFn, "maps");
        globalDataListener.removeCampaignContentListener(this.updateMapFn, "pins");
        globalDataListener.removeCampaignContentListener(this.updateMapFn, "art");
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.mapInfo != prevProps.mapInfo) || (this.props.mapName != prevProps.mapName)) {
            this.loadMap();
        }
    }

    loadMap() {
        const map = this.props.mapInfo || campaign.getMapInfo(this.props.mapName);
        const t=this;

        if (!map) {
            if (this.props.onLoadMap) {
                this.props.onLoadMap(null);
            }
            this.setState({map:null, image:null, loading:false, pinList:null, lasturl:null});
            return;
        }

        const art = campaign.getArtInfo(map.art)||map;
        const url = art.url;
        const name=map.name;
        let image=null;
        let loading;
        if (this.state.lasturl != url) {
            image = imageCache.getImage(url, function (image) {
                if (t.props.onLoadMap) {
                    t.props.onLoadMap(image);
                }
                t.setState({image, loading:false});
            });
            if (t.props.onLoadMap) {
                t.props.onLoadMap(image);
            }
            loading=!image;
        } else {
            image=this.state.image;
        }

        const pins = campaign.getPins();
        const pinList = [];

        for (let i in pins) {
            const p = pins[i];

            if (p.mapPos.mapName==name) {
                pinList.push(p);
            }
        }

        this.setState({map, image, loading, pinList, lasturl:url});
    }

	render() {
        const map = this.state.map;
        if (!map) {
            return <div className="w-100">Map not found</div>;
        }

        const art = campaign.getArtInfo(map.art)||map;
        const image = this.state.image;
        const mapInfo = {image};

        const pixelsPerFoot = getPixelsPerFoot(map,art);
        const gridSize = getGridSizeInFeet(map);

        mapInfo.imageSrc = art.url;
        mapInfo.imageName = map.name;
        mapInfo.pixelsPerFoot = pixelsPerFoot;
        mapInfo.gridSize = gridSize;
        mapInfo.mapPos = {x:art.imgWidth/2/pixelsPerFoot, y:art.imgHeight/2/pixelsPerFoot};
        if (art.imgHeight > art.imgWidth) {
            mapInfo.mapPos.diameter = art.imgWidth/pixelsPerFoot;
        } else {
            mapInfo.mapPos.diameter = art.imgHeight/pixelsPerFoot;
        }
        mapInfo.pinList = this.state.pinList||[];

        const style = {paddingTop:Math.trunc(100*art.imgHeight/art.imgWidth)+"%", height:0, width:"100%", position:"relative", overflow:"hidden"};

        return <div style={style} onClick={this.props.onClick}>
            {image?<SizeStageMap
                className="h-100 w-100" 
                mapInfo={mapInfo} 
                noCover
                showAllPins
                noPlaceholder
            />:<div>loading map...</div>}
        </div>;
    }

}

function DoStageMapSize(props) {
    return <div style={{position:"absolute", width:"100%", height:"100%", top:0, bottom:0}}>
        <StageMap
            mapInfo={props.mapInfo} 
            size={props.size}
            onClick={props.onClick}
            noCover
            showAllPins
        />
    </div>
}

const SizeStageMap = sizeMe({monitorHeight:true, monitorWidth:true})(DoStageMapSize);

class PinMarker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

	render() {
        const pin = this.props.pin||{};
        const token=this.props.token;
        switch (pin.marker) {
            default:
            case "circle":
                return <Circle 
                    x={0} 
                    y={0} 
                    radius={6} 
                    stroke="black"
                    fill="white"
                    opacity={0.65}
                    strokeScaleEnabled={true}
                    strokeWidth={1.5}
                    token={token}
                    pin
                />;
            case "star":
                return <Star 
                    x={0} 
                    y={0} 
                    numPoints={5}
                    outerRadius={8} 
                    innerRadius={4} 
                    stroke="black"
                    fill="white"
                    opacity={0.65}
                    strokeScaleEnabled={true}
                    strokeWidth={1.5}
                    token={token}
                    pin
                />;
            case "diamond":
                return <Star 
                    x={0} 
                    y={0} 
                    numPoints={4}
                    outerRadius={8} 
                    innerRadius={4} 
                    stroke="black"
                    fill="white"
                    opacity={0.65}
                    strokeScaleEnabled={true}
                    strokeWidth={1.5}
                    token={token}
                    pin
                />;
            case "square":
                return <Rect 
                    x={-6} 
                    y={-6} 
                    width={12}
                    height={12}
                    stroke="black"
                    fill="white"
                    opacity={0.65}
                    strokeScaleEnabled={true}
                    strokeWidth={1.5}
                    token={token}
                    pin
                />;
            case "triangle":
                return <Star 
                    x={0} 
                    y={0} 
                    numPoints={3}
                    outerRadius={8} 
                    innerRadius={4} 
                    stroke="black"
                    fill="white"
                    opacity={0.65}
                    strokeScaleEnabled={true}
                    strokeWidth={1.5}
                    token={token}
                    pin
                />;
            case "none":
                return null;
        }
    }

}

function showConditionIndicator(conditions) {
    for (let i in conditions) {
        const c = conditions[i];
        if (typeof c == "object") {
            if (!c.hideIndicator) {
                return true;
            }
        } else {
            return true;
        }
    }
    return false;
}

function getDistance(e) {
    const t1 = e.evt.touches[0];
    const t2 = e.evt.touches[1];

    if (t1 && t2) {
        return Math.sqrt(Math.pow(t2.clientX - t1.clientX, 2) + Math.pow(t2.clientY - t1.clientY, 2));
    } else {
        return 0;
    }
}

function getPixelsPerFoot(mapInfo,art) {
    let pixels = 70;
    let gridSize = 5;
    
    if (mapInfo.pixelsPerGrid)
        pixels = Number(mapInfo.pixelsPerGrid);

    if (mapInfo.gridSize) {
        gridSize = Number(mapInfo.gridSize);
    }

    if (mapInfo.units == "miles") {
        gridSize = gridSize*5280;
    }

    if (art.originalWidth && (art.originalWidth != art.imgWidth)) {
        pixels = pixels*art.imgWidth/art.originalWidth;
    }

    return pixels/gridSize;
}


function getGridSizeInFeet(mapInfo) {
    let gridSize = 5;
    
    if (mapInfo.gridSize) {
        gridSize = Number(mapInfo.gridSize);
    }

    if (mapInfo.units == "miles") {
        gridSize = gridSize*5280;
    }

    return gridSize;
}

function getPinScale(pin, gridSize,pixelsPerFoot) {
    if (isNaN(pin.scale)) {
        const autoBase=0.3*(gridSize||5)/12;
        switch (pin.scale) {
            case "axl":
                return autoBase*2;
            case "al":
                return autoBase*1.5;
            case "as":
                return autoBase*0.75;
            case "axs":
                return autoBase*0.5;
            case "am":
            default:
                return autoBase;
        }
    } else {
        const fixBase=100/72/pixelsPerFoot;
        return Number(pin.scale)/18*fixBase;
    }
}

const popupState = new PopupState();


const warningSVG = [
    <Path key="s1"
        data="m46.356 0.062c-1.504 0.232-2.826 1.121-3.582 2.438l-42.108 72.949c-0.88 1.533-0.895 3.441 0 4.986 0.896 1.545 2.559 2.514 4.358 2.512h84.216c1.801 0.002 3.463-0.967 4.359-2.512 0.895-1.545 0.879-3.453 0-4.986l-42.109-72.949c-1.035-1.803-3.085-2.76-5.134-2.438z"
        fill="#FDEE1C"
    />,
    <Path key="s2"
      data="m46.744 2.121c-0.814 0.127-1.508 0.617-1.9 1.301l-42.4 73.449c-0.465 0.809-0.466 1.846 0 2.65 0.474 0.816 1.348 1.35 2.3 1.35h84.801c0.951 0 1.826-0.533 2.299-1.35 0.467-0.805 0.465-1.842 0-2.65l-42.4-73.449c-0.545-0.95-1.598-1.475-2.7-1.301zm0.4 8.449l36 63.4h-72.051l36.051-63.4z"
      fill="#010101"
    />,
    <Path key="s3"
      data="m46.932 34.322l-1.95 11.35-8-8.35 4.5 10.65-11.2-2.75 9.5 6.551-10.899 3.75 11.5 0.35-7.101 9.049 9.9-5.898-1.1 11.449 5.1-10.301 5.3 10.201-1.301-11.451 9.951 5.75-7.25-8.898 11.5-0.551-10.951-3.6 9.402-6.701-11.152 2.9 4.25-10.65-7.799 8.451-2.2-11.301zm0.2 11.701c3.514 0 6.35 2.836 6.35 6.35s-2.836 6.4-6.35 6.4c-3.515 0-6.351-2.887-6.351-6.4s2.837-6.35 6.351-6.35zm0 0.949c-3.002 0-5.4 2.398-5.4 5.4s2.398 5.449 5.4 5.449 5.451-2.447 5.451-5.449-2.449-5.4-5.451-5.4z"
      fill="#010101"
    />
];

const colorDrawList = [
    '#000000', //Black
    '#8B4513', //Brown
    '#194d33', //Green
    '#653294', //Purple

    '#4d4d4d', //Gray30
    '#D2B48C', //Tan
    '#808900', //Olive
    '#0062b1', //Blue

    '#999999', //Gray60
    '#fe9200', //Orange
    '#68bc00', //Christi Green
    '#73d8ff', //Sky Blue

    '#ffffff', //White
    '#fcc400', //Yellow
    '#d33115', //Red
    '#fda1ff', //Pink
];

const MapSize = sizeMe({monitorHeight:true, monitorWidth:true})(Map);
const MapPaneSize = sizeMe({monitorHeight:true, monitorWidth:true})(MapPane);
const MapSharedViewSize = sizeMe({monitorHeight:true, monitorWidth:true})(MapSharedView);

export {
    MapSize as Map,
    MapPaneSize as MapPane,
    MapSharedViewSize as MapSharedView,
    PopupMap, 
    openFullscreen, 
    closeFullscreen, 
    isFullscreen, 
    toggleFullscreen,
    popupState,
    InlineMap
};