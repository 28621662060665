require("@babel/polyfill");
const {firebase, getAuth} = require("./firebase.jsx");
const React = require('react');
import { createRoot } from 'react-dom/client';
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
const {Authenticate} = require('./authenticate.jsx');
const {displayMessage, snackMessage, ShowMessage} = require('./notification.jsx');
const {HeaderMenu} = require("./homepage.jsx");
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {getLocationInfo} = require('./renderhref.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent,getDialogCount} = require('./responsivedialog.jsx');
const {marketplace} = require('../lib/marketplace.js');
const {MarketSearch,MarketSearchBox,pathFromPage} = require('./marketplacesearch.jsx');
const {BuyButton,FailedPurchases,addToProducts,isInProducts} = require('./purchase.jsx');
const {ShardSubscriptions,GetShareLink} = require('./products.jsx');
const {theme} = require('./theme.jsx');

class MarketRoot extends React.Component {
    constructor(props) {
        super(props);
        const page = getLocationInfo(location.hash);
        let ref =null;
        if (page.refu || page.refc) {
            marketplace.setReferral(page.refu, page.refc);
            ref = (page.refu||"")+":"+(page.refc||"");
        }
        this.state= {page, loading:true};
        this.referrer = document.referrer || null;
        if (ref) {
            this.firstref=ref;
            gtag("set",{campaign:{source:ref,medium:"reference",name:ref}, 'page_referrer':this.referrer});
        } else {
            gtag({'page_referrer':this.referrer});
        }
        this.doInstrumentation(page);
        this.buyRef = React.createRef();
    }

    doInstrumentation(page) {
        const user = getAuth(firebase).currentUser;
        let path = page.page||"marketplace";
        if ((page.page == "product") && page.id) {
            path = path+"/"+page.id;
        }
        gtag('config', 'UA-159662255-2', {
            'page_title' : path,
            'page_path' : "/"+path,
            'page_location' : "https://"+location.hostname+"/"+path,
            'user_id': (user && user.uid)||null,
            'page_referrer':this.referrer
        });
    }

	componentDidMount() {
        let t = this;
		
        marketplace.load().then(function () {
            t.addPurchases(true);

            t.setState({loading:false});
        }, function (err) {
            displayMessage("Error loading catalog: "+err.message, reloadPage);
        });

        window.addEventListener('hashchange', function (event) { 
            const page=getLocationInfo(location.hash);
            if (page.refu || page.refc) {
                marketplace.setReferral(page.refu, page.refc);
                let ref = (page.refu||"")+":"+(page.refc||"");
                t.firstref=page.ref;
                gtag("set",{campaign:{source:ref,medium:"reference",name:ref}, 'page_referrer':this.referrer});
            }
            if (page.page != t.state.page) {
                t.doInstrumentation(page);
            }
            t.addPurchases();
            t.setState({page:page});
        });
        
        this.unregisterAuthObserver = getAuth(firebase).onAuthStateChanged(
            function (user) {
                const outstandingCharges = (marketplace.purchaseData||{}).outstandingCharges && !getDialogCount();
                t.setState({isSignedIn: !!user, knownSigninState:true, doAuth:false,showFailed:outstandingCharges});
                t.addPurchases();
            }
        );

        globalDataListener.setErrorListener(snackMessage, fatalError);
        this.doFailedWatchFn = this.doFailedWatch.bind(this);
        this.addPurchasesFn = this.addPurchases.bind(this);
        globalDataListener.on("purchases",this.doFailedWatchFn);
        globalDataListener.on("cart",this.addPurchasesFn);

        function fatalError(message) {
            displayMessage(message, function (){
                console.log("got an error",message);
            });
        }
        this.doFailedWatch();

    }
    
    componentWillUnmount() {
        globalDataListener.removeListener("purchases",this.doFailedWatchFn);
        globalDataListener.removeListener("cart",this.addPurchasesFn);
        if (this.failTest) {
            clearInterval(this.failTest);
        }
    }

    doFailedWatch() {
        const t=this;
        if (!this.failTest) {
            this.failTest = setInterval(function() {
                if ((marketplace.purchaseData||{}).outstandingCharges) {
                    const showFailed = !!(t.state.showFailed || !getDialogCount());
                    t.setState({showFailed});
                } else {
                    clearInterval(t.failTest);
                    t.failTest=null;
                    t.setState({showFailed:false});
                }
            }, 1000);
        }
    }

	render() {
        let render = "Need to do a marketplace render";
        let title = "Shard Tabletop Marketplace";
        const page = this.state.page;
        if (!marketplace.loaded) {
            render=null;
        } else if (page.page == 'shardsubscriptions') {
            render = <div className="ma2 flex" key="subs"><div className="flex-auto"/><div className="mw7 w-100"><ShardSubscriptions/></div><div className="flex-auto"/></div>;
        } else if (page.page == 'product') {
            render = <MarketSearch page={page} key="page"/>;
        } else {
            render = <MarketSearch page={page} key="search"/>;
        }
        document.body.classList.add("lightT");

		return(
            <StylesProvider injectFirst><MuiThemeProvider theme={theme}>
                <div className="lightT h-100 w-100">
                    <div className="flex flex-column h-100 w-100 overflow-y-auto overflow-x-hidden defaultbackground">
                        <ShowMessage/>
                        <div className="pv--2 flex titlecolor titletext bb f3">
                            <div className="flex-auto flex flex-wrap items-center">
                                <a href={this.getRefUrl("/#home")}><img src="/logo.svg" height="20px" className="ph1"/></a>
                                <span>
                                    {(typeof title =="string")?<span><span>{title}</span></span>:title}
                                </span>
                                <div className="flex-auto minw55 pl2">
                                    <MarketSearchBox page={page}/>
                                </div>
                                <span className="fas fa-share hoverhighlight pa1 mh1" onClick={this.showShareLink.bind(this,true)}/>
                            </div>
                            <BuyButton ref={this.buyRef}/>
                            <HeaderMenu onDoAuth={this.onSetAuth.bind(this,true)} refVal={this.getRef()}/>
                        </div>
                        <div className="pa0 w-100 h2 flex-auto overflow-y-auto overflow-x-hidden defaultbackground" key="approot">
                            {render}
                        </div>
                    </div>
                    {this.state.doAuth?<Authenticate onClose={this.onSetAuth.bind(this,false)}/>:null}
                    <Dialog open={this.state.loading}>
                        <DialogContent>
                            Loading Catalog...
                        </DialogContent>
                    </Dialog>
                    <FailedPurchases open={this.state.showFailed||false} onClose={this.hideFailed.bind(this)}/>
                    <GetShareLink open={this.state.showLink} page={page} onClose={this.showShareLink.bind(this,false)}/>
                </div>
            </MuiThemeProvider></StylesProvider>);
    }

    showShareLink(showLink) {
        this.setState({showLink});
    }

    hideFailed() {
        this.setState({showFailed:false});
    }

    onSetAuth(doAuth) {
        this.setState({doAuth});
    }

    back(){
        history.back();
    }

    getRef() {
        const referral = marketplace.getReferral();
        if (!referral) {
            return null;
        }
        return referral.code;
    }

    getRefUrl(url) {
        const ref= this.getRef();
        if (!ref) {
            return url;
        }
        return url+"?ref="+encodeURIComponent(ref);
    }

    addPurchases(loadingDone) {
        const page=getLocationInfo(location.hash);
        if (page.add && (!this.state.loading||loadingDone)) {
            if (campaign.currentUser) {
                if (marketplace.userLoaded && marketplace.cartLoaded) {
                    const cartProducts = (marketplace.cart.products||[]).concat([]);
                    let added = false;
                    const products = page.add.split(",").map(function (s){return s.trim().toLowerCase()});

                    for (let p of products) {
                        const product = marketplace.getProductInfo(p);
                        if (product) {
                            let owned = marketplace.isProductSubscriptionOwned(product);
                            if (owned && (product.purchaseType == "subscription")) {
                                const latest = marketplace.getLatestSubscriptionOwned(p);
                                if (latest) {
                                    const remaining = (latest-Date.now())/(1000*60*60*24);
                                    if (remaining < 7) { // if sub ends in next week, then extend sub
                                        owned = false;
                                    }
                                    console.log("remaining", owned, p, remaining, latest);
                                }
                            }

                            if (!owned) {
                                console.log("not owned", p)
                                if (!isInProducts(cartProducts, p)) {
                                    console.log("not in cart",p);
                                    addToProducts(cartProducts, p, !!product.yearlySalePrice);
                                }
                                added=true;
                            } else {
                                console.log("already owned", p, owned)
                            }
                        } else {
                            console.log("product not found", p);
                        }
                    }
                    if (added) {
                        const newCart = Object.assign({}, marketplace.cart);
                        newCart.products = cartProducts;
                        marketplace.setCart(newCart);
                        if (this.buyRef && this.buyRef.current) {
                            this.buyRef.current.showConfirmPurchase();
                        }
                    } else {
                        displayMessage("You already own all of the products.");
                    }
                    const pageInfo = pathFromPage(page);
                    let hash = page.page+ (pageInfo||"") +(page.id?((pageInfo?"&id=":"?id=")+page.id):"");
                    window.location.replace("/marketplace#"+hash);

                    console.log("add products", added, products, cartProducts);
                }
            } else {
                const t=this;
                setTimeout(function () {
                    if (!campaign.currentUser) {
                        t.setState({doAuth:true});
                    }
                }, 300);
            }
        }
    }
}

function reloadPage() {
    location.reload();
}

const el = document.getElementById('marketplace');
if (el) {
    const appRoot = React.createElement(MarketRoot, null);
    const appval = createRoot(el);
    appval.render(appRoot);
}