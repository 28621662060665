const React = require('react');
const {campaign,globalDataListener,replaceMetawords} = require('../lib/campaign.js');
const {Character,shouldDurationExpire,getTextFromDistanceStruct,nameFromFeatureParams} = require('../lib/character.js');
const {MonObj,fTemplateName, updateMonsterAttributes,updateTextActions,findFeatures,findDamageType,findFixedDamage,findAttack,labelUses,addUses,findSpellcasting,findSpellcastingLevel,findSave} = require('../lib/monobj.js');
const {displayMessage} = require('./notification.jsx');
const Parser = require("../lib/dutils.js").Parser;
import TextField from '@material-ui/core/TextField';
const {PickCondition,Condition, getDurationFromText,addConditionInfoToConditions,getConditionStruc} = require('./conditions.jsx');
const {Rendersource} = require("./rendersource.jsx");
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {EntityEditor,Renderentry,Renderstring} = require('./entityeditor.jsx');
const {DeleteWithConfirm, TextPlusEdit, PickVal, TextVal, SelectVal, CheckVal,SelectMultiTextVal,SelectTextVal, NumberAdjust, MaxNumberAdjust, defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter, getAnchorPos,alwaysArray} = require('./stdedit.jsx');
import {htmlFromEntry} from "../lib/entryconversion.js";
const {ArtZoomList, CreatureArt} = require('./renderart.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {doRoll,getAverageFromDice,getDiceFromString, DicePopup,damagesFromExtraDamage} = require('../src/diceroller.jsx');
const {ChatButton} = require('./renderchat.jsx');
const {Chat} = require('../lib/chat.js');
const {UsageListEdit,hasFeatureConfig,FeatureConfigure,RenderFeature} = require('./features.jsx');
const {AddObject} = require('./objects.jsx');
const {AddChatEntry,getBasicHref, LinkHref} = require('./renderhref.jsx');
const {getDurationFromSpell,getSpellAttributes,SpellPicker} = require('./renderspell.jsx');

const {crStats,
    conditionList,
    sizeList,
    damageTypesList,
    sensesList,
    abilitiesList,
    abilityNames,
    perceptionValuesList,
    armorClassValuesList,
    languagesList,
    monsterSkillsMap,
    allignmentTypeList,
    environmentTypeList,
    signedNum,
    gamesystemOptions,
    actionTypeMap,
    upperActionTypeMap,
    pluralString,
    abilitiesValues,
    oneTo20,
    zeroTo20,
    fullSpellInfo,
    pactSpellInfo,
    abilityNamesFull,
    abilityLongNames,
    upperFirst,
    getNumRange,
    monsterBasicTypeList
} = require('../lib/stdvalues.js');


class RenderMonsters extends React.Component {
    constructor(props) {
        super(props);

        this.showNewMonsterFn = this.showNewMonster.bind(this);
        this.handleOnDataChange = this.onDataChange.bind(this);
	    this.state= {};
    }

    componentDidMount() {
        if (this.props.pageSync){
            this.props.pageSync.on("action", this.showNewMonsterFn);
        }
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "monsters");
    }

    componentWillUnmount() {
        if (this.props.pageSync){
            this.props.pageSync.removeListener("action", this.showNewMonsterFn);
        }
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "monsters");
    }

    showNewMonster(action) {
        if (action != "newmonster") {
            return;
        }
        this.setState({showNewMonster:true});
    }

    onDataChange() {
        this.setState({monsters:campaign.getMonsterListByName()});
    }

	render() {
        return <div className="ph1 pb1 defaultbackground">
            <ListFilter 
                list={campaign.getMonsterListByName()}
                render={monsterListRender}
                filters={monsterListFilters}
                groupBy="cr"
                collapseDups
                convertGroup={function (g){ return "CR "+g}}
                onClick={this.onselectMonster.bind(this)}
                select="click"
                getListRef={this.saveRef.bind(this)}
            />
            <NewMonster open={this.state.showNewMonster} onClose={this.pickMonsterName.bind(this)}/>
            <MonsterDetails open={this.state.showEditMonster} monster={this.state.editMonster} editable onClose={this.closeEditMonster.bind(this)}/>
            <MonsterDetails open={this.state.showMonster} monster={this.state.showMonsterName} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.hideMonster.bind(this)}/>
        </div>;
    }

    hideMonster() {
        this.setState({showMonster:false});
    }

    onselectMonster(name){
        this.setState({showMonster:true, showMonsterName:name});
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(mon) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(mon))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.onselectMonster.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.onselectMonster.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    pickMonsterName(name) {
        if (!name) {
            this.setState({showNewMonster:false});
        } else {
            this.setState({showNewMonster:false, showEditMonster:true, editMonster:name});
        }
    }

    closeEditMonster() {
        this.setState({showEditMonster:false});
    }
}

function prepareMonster(mon){
    if (!mon) {
        console.log("no monster");
        return;
    }
    mon.crsort=getCRSortFromCR(mon.cr);
    simplifyMonster(mon);
}

function getCRSortFromCR(cr) {
    let crsort;
    switch (cr) {
        case "1/8":
            crsort=0.125;
            break;
        case "1/4":
            crsort=0.25;
            break;
        case "1/2":
            crsort=0.5;
            break;
        default:
            crsort = Number(cr);
            if (!(crsort >= 0 || crsort <=30)) {
                crsort = 100;
            }
            break;
    }
    return crsort;
}

const emptyMonster = {
    "size": "M",
    "type": {
        "type": "humanoid",
    },
    "alignment": [
        "A"
    ],
    "ac": [
        10
    ],
    "hp": {
        "average": 4,
        "formula": "1d8"
    },
    "speed": {
        "walk": 30
    },
    "str": 10,
    "dex": 10,
    "con": 10,
    "int": 10,
    "wis": 10,
    "cha": 10,
    "passive": 10,
    "cr": "0"
};


class MonsterPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state={selected:props.selected||{}};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open) {
            this.setState({selected:this.props.selected||{}});
        }
    }

    render () {
        if (!this.props.open) {
            return null;
        }
        const {restriction,noCharacters,scrollToSelected}=this.props;

        let list = campaign.getMonsterListByName();
        if (noCharacters || restriction) {
            const newList = [];
            for (let i in list) {
                const m = list[i];
                if ((!noCharacters || !m.npc) && (!restriction || matchRestriction(restriction, m))) {
                    newList.push(m);
                }
            }
            list=newList;
        }

        return <Dialog
            open
            scroll="paper"
            fullScreen={this.props.fullScreen}
            maxWidth="sm"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Pick Monster{(this.props.includeCount||this.props.multiSelect)?"s":""}</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={list}
                    render={monsterListRender}
                    filters={monsterListFilters}
                    defaultFilter={this.props.listFilter || (this.props.monsterType&&{monsterType:this.props.monsterType}) || null}
                    groupBy="cr"
                    collapseDups
                    convertGroup={function (g){ return "CR "+g}}
                    onClick={this.addMonster.bind(this)}
                    select={this.props.multiSelect?"list":this.props.includeCount?"counted":"list"}
                    single={!this.props.multiSelect&&!this.props.includeCount}
                    selected={this.state.selected}
                    onChangeFilter={this.props.onChangeFilter}
                    onSelectedChange={this.selectChange.bind(this)}
                    scrollToSelected={scrollToSelected}
                />
            </DialogContent>
            <DialogActions>
                {this.props.extraButtons&&this.props.extraButtons()}
                <Button onClick={this.newMonster.bind(this)} color="primary">
                    New
                </Button>
                <Button onClick={this.selectMonsters.bind(this)} color="primary">
                    {this.props.buttonLabel||((this.props.includeCount||this.props.multiSelect)?"Add Monsters":"Pick Monster")}
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <NewMonster open={this.state.showNewMonster} onClose={this.onCreateNewMonster.bind(this)}/>
            <MonsterDetails monster={this.state.monsterNew} open={this.state.showNewMonsterDetails} onClose={this.onCloseMonsterDetails.bind(this)} editable/>
            <MonsterDetails monster={this.state.showSelectedMonster} open={this.state.showMonsterDetails} onClose={this.onCloseMonster.bind(this)}/>
        </Dialog>;
    }

    onCloseMonster() {
        this.setState({showMonsterDetails:false});
    }

    selectChange(selected) {
        this.setState({selected});
    }

    newMonster(){
        this.setState({showNewMonster:true});
    }

    onCreateNewMonster(name) {
        this.setState({showNewMonster:false, monsterNew:name, showNewMonsterDetails:!!name});
    }

    onCloseMonsterDetails() {
        this.setState({showNewMonsterDetails:false});
        if (this.props.includeCount || this.props.multiSelect) {
            const selected = Object.assign({}, this.state.selected||{});
            selected[this.state.monsterNew.toLowerCase()]=1;
            this.setState({selected});
        } else {
            this.props.onClose(this.state.monsterNew);
        }
    }

    onClose() {
        this.props.onClose();
    }
    
    addMonster(name) {
        this.setState({showSelectedMonster:name, showMonsterDetails:true});
        return true;
    }

    selectMonsters(){
        const {selected} = this.state;
        const {multiSelect,includeCount}=this.props;

        if (multiSelect||includeCount) {
            this.props.onClose(selected);
        } else {
            this.props.onClose(Object.keys(selected)[0]);
        }
    }

    addOneMonster(){
        this.props.onClose({name:this.state.monName, count:1});
    }

    changeCount(value) {
        this.props.onClose({name:this.state.monName, count:value});
    }
}

function matchRestriction(restriction, mon) {
    const {maxCR, minCR, monsterTypes, selected, excludeMovement} = restriction;
    if (selected) {
        if (selected[mon.name.toLowerCase()]) {
            return true;
        }
        if (!monsterTypes && !maxCR && !excludeMovement && !minCR) {
            return false;
        }
    }
    if (monsterTypes) {
        if (!(monsterTypes.includes(basicMonsterTypeFromType(mon.type,mon)))) {
            return false;
        }
    }
    if (maxCR) {
        const mcr = getCRSortFromCR(maxCR);
        if (mcr < mon.crsort) {
            return false;
        }
    }
    if (minCR) {
        const mcr = getCRSortFromCR(minCR);
        if (mon.crsort < mcr) {
            return false;
        }
    }
    if (excludeMovement) {
        for (let i in excludeMovement) {
            const m = getSpeedVals(mon.speed, excludeMovement[i]);
            if (Number(m.number) > 0) {
                return false;
            }
        }
    }
    return true;
}

const monsterListFilters = [
    {
        filterName:"CR",
        fieldName:"crsort",
        getFieldDisplayName:function (v) {
            if (v == 0.125) {
                return "1/8";
            } else if (v==0.25) {
                return "1/4";
            } else if (v==0.5) {
                return "1/2";
            } else if (v > 30) {
                return "Unknown";
            }else {
                return v||0;
            }
        },
        useRange:true,
        sortFn: function (a, b) {
            return Number(a||0)-Number(b||0)
        }
    }, 
    {
        filterName:"Environment",
        fieldName:"environment",
        convertField: function (v) {
            if (!v || (Array.isArray(v) && !v.length)) {
                return "none";
            } else {
                return v;
            }
        }
    },
    {
        filterName:"Type",
        fieldName:"type",
        convertField: basicMonsterTypeFromType
    }, 
    {
        filterName:"Size",
        fieldName:"size",
        advancedOnly:true,
        convertField: function(size,mon){
            return Parser.sizeAbvToFull(size)||(mon.sizeNotes&&"Custom") || "None"
        },
        sortFn: function (a,b) {
            return (sizeSortOrder[a]||100)-(sizeSortOrder[b]||100);
        }
    }, 
    {
        filterName:"Alignment",
        fieldName:"alignment",
        advancedOnly:true,
        convertField: function(v,mon){
            if (mon.npc) {
                return mon.alignment?mon.alignment.toLowerCase():null;
            }
            let a = Parser.alignmentAbvToFull(mon)||"None";
            if (a && a.includes("(")) {
                let split = a.split("(");
                a = split[0].trim();
            }
            return a && a.toLowerCase();
        },
    }, 
    {
        filterName:"Usage",
        fieldName:"unique",
        advancedOnly:true,
        convertField: function (v, it) {
            const list=[];
            if (v||it.npc) {
                list.push("NPC");
                if (it.npc) {
                    list.push("Character Sheet");
                }
            } else {
                list.push("Common");
            }
            if (it.companion) {
                list.push("Companion");
            }
            return list;
        }
    }, 
    {
        filterName:"AC",
        fieldName:"ac",
        useRange:true,
        advancedOnly:true,
        convertField: function (ac, it) {
            return Parser.acToStruc(ac).ac;
        },
        sortFn: function (a, b) {
            return Number(a||0)-Number(b||0)
        }
    }, 
    {
        filterName:"Average HP",
        fieldName:"hp",
        useRange:true,
        advancedOnly:true,
        convertField: function (hp, it) {
            if (it.npc) {
                return it.maxhp||0;
            }
            return hp?.average||0;
        },
        sortFn: function (a, b) {
            return Number(a||0)-Number(b||0)
        }
    }, 
    {
        filterName:"Movement",
        fieldName:"speed",
        convertField: function(speed,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                return Object.keys(mchar.speed||{});
            }
            if (speed) {
                return Object.keys(speed).map(function (a){return (a=="canHover")?null:a});
            }
            return null;
        },
    }, 
    {
        filterName:"Senses",
        fieldName:"senses",
        advancedOnly:true,
        convertField: function(senses,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                return Object.keys(mchar.senses||{});
            }
            if (senses) {
                return senses.split(/(,|;|and)/g).map(function(a){return checkSensesList(a.trim().toLowerCase())});
            }
            return null;
        },
    }, 
    {
        filterName:"Legendary",
        fieldName:"legendary",
        advancedOnly:true,
        convertField: function(legendary){
            return legendary?"yes":"no";
        },
    }, 
    {
        filterName:"Has Lair",
        fieldName:"lairActions",
        advancedOnly:true,
        convertField: function(lair){
            return lair?"yes":"no";
        },
    }, 
    {
        filterName:"Vulnerabilities",
        fieldName:"vulnerable",
        advancedOnly:true,
        convertField: function(imres,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                imres = mchar.vulnerable;
                if (imres) {
                    return imres.split(",").map(function(a){return a.trim()});
                }

            }

            if (imres) {
                return Parser.monImmResToFull(imres).split(/(,|;| and)/g).map(function (a){return checkDamageTypes(a.trim().toLowerCase())});
            }
            return "none";
        },
    }, 
    {
        filterName:"Resistances",
        fieldName:"resist",
        advancedOnly:true,
        convertField: function(imres,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                imres = mchar.resist;
                if (imres) {
                    return imres.split(",").map(function(a){return a.trim()});
                }

            }

            if (imres) {
                return Parser.monImmResToFull(imres).split(/(,|;| and)/g).map(function (a){return checkDamageTypes(a.trim().toLowerCase())});
            }
            return "none";
        },
    }, 
    {
        filterName:"Immunities",
        fieldName:"immune",
        advancedOnly:true,
        convertField: function(imres,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                imres = mchar.immune;
                if (imres) {
                    return imres.split(",").map(function(a){return a.trim()});
                }

            }

            if (imres) {
                return Parser.monImmResToFull(imres).split(/(,|;| and)/g).map(function (a){return checkDamageTypes(a.trim().toLowerCase())});
            }
            return "none";
        },
    }, 
    {
        filterName:"Condition Immunities",
        fieldName:"conditionImmune",
        advancedOnly:true,
        convertField: function(imres,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                imres = mchar.conditionImmune;
                if (imres) {
                    return imres.split(",").map(function(a){return a.trim()});
                }

            }
            if (imres) {
                return Parser.monImmResToFull(imres).split(/(,|;| and)/g).map(function (a){return checkConditionTypes(a.trim().toLowerCase())});
            }
            return "none";
        },
    }, 
    {
        filterName:"Languages",
        fieldName:"languages",
        advancedOnly:true,
        convertField: function(langs,mon){
            if (mon.npc) {
                const mchar = new Character(mon,"monsters");
                langs = mchar.languages;
                if (langs) {
                    return langs.split(",").map(function(a){return a.trim()});
                }
            }
            if (langs) {
                langs = langs.toLowerCase().replace("&apos;","'").replace("’","'");

                return langs.split(/(,|;| and| plus)/g).map(function (a){return checkLanguagesList(a.trim().toLowerCase())});
            }
            return "none";
        },
    }, 
    {
        filterName:"Monster Sub-Type",
        fieldName:"subtype",
        advancedOnly:true,
        convertField: function(subtype, it){
            if (it.npc) {
                return it.originDisplayName || (!it.gamesystem?it.raceDisplayName:null);
            }
            let v = it.type?.tags||null;
            if (v) {
                v = v.map(function(val){
                    if (typeof val == "string") {
                        if (val && val.startsWith("(")) {
                            val = val.substring(1);
                            if (val.endsWith(")")) {
                                val = val.substring(0,val.length-1);
                            }
                        }
                        return val;
                    }
                    return null;
                });
            }
            
            return v;
        }
    }, 

    defaultSourceFilter,
    defaultBookFilter,
    defaultGamesystemFilter
];

function checkLanguagesList(v) {
    if (v) {
        if (v.startsWith("understands ")) {
            v = v.substr(12).trim();
        }
        if (v.startsWith("the ")||v.startsWith("an ")||v.startsWith("one ") || !v.match(/[a-z]/) || (v=="and") || (v=="plus") || v.startsWith("up to ")) {
            return null;
        }
        if (v.startsWith("all ")) {
            return "all";
        }
        if (v.startsWith("any ")) {
            return "any";
        }
        if (v.startsWith("none ")) {
            return "none";
        }
        const butPos = v.indexOf("but ");
        if (butPos >=0) {
            v=v.substr(0,butPos);
        }
        const pPos = v.indexOf("(");
        if (pPos >=0) {
            v=v.substr(0,pPos);
        }
        const nPos = v.search(/ [0-9]/);
        if (nPos >=0) {
            v=v.substr(0,nPos);
        }
        return v.trim();
    }
    return null;
}

function checkSensesList(v) {
    if (v) {
        for (let sv of sensesList) {
            if (v.startsWith(sv)) {
                return sv;
            }
        }
    }
    return null;
}
function checkDamageTypes(v) {
    if (v) {
        for (let dt of damageTypesList) {
            if (v.startsWith(dt)) {
                return dt;
            }
        }
    }
    return null;
}

function checkConditionTypes(v) {
    if (v) {
        for (let ct of conditionList) {
            if (v.startsWith(ct)) {
                return ct;
            }
        }
    }
    return null;
}

const sizeSortOrder={
    Fine:1,
    Diminutive:2,
    Tiny:3,
    Small:4,
    Medium:5,
    Large:6,
    Huge:7,
    Gargantuan:8,
    Colossal:9,
}

function basicMonsterTypeFromType(type,m) {
    if (m.npc) {
        return "character";
    }
    return Parser.monTypeToFullObj(type).type;
}

function monsterListRender(m) {
    const swim = (m.speed||{}).swim;
    const fly = (m.speed||{}).fly;
    let extraStr;
    
    if (m.npc) {
        extraStr = <span>{m.originDisplayName||(!m.gamesystem?m.raceDisplayName:null)} {m.backgroundDisplayName} Level {m.level} {m.classDisplayNames}</span>;
    } else {
        extraStr = Parser.sizeAbvToFull(m.size) +" "+Parser.monTypeToFullObj(m.type).asText+(swim?" swimming":"")+(fly?" flying":"");
    }

    return <div>
        {m.displayName} 
        <div className="mt--2 i f6">CR {m.cr} {extraStr}</div>
    </div>;
}

class MonsterBlock extends React.Component {

    constructor(props) {
        super(props);

        prepareMonster(props.monster);

        this.state= this.getMonObjs(props);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.monster != prevProps.monster)||(this.props.crow!=prevProps.crow)) {
            this.doAnnotateUses();
            this.setState(this.getMonObjs(this.props));
        }
    }

    getMonObjs(props) {
        const monObj = new MonObj(props.monster, props?.crow||null, true, props.onChangeInline, props.onChangeCombatant);
        const baseMonObj = (monObj.conditions||monObj.state.companionMod||monObj.state.fTemplate)?new MonObj(props.monster, props?.crow||null, false, "noupdate"):null;
        return {monObj, baseMonObj};
    }

    doAnnotateUses() {
        if (this.props.rollable && this.props.inlineEdit) {
            const t = this;
            if (t.annotateTimer) {
                clearTimeout(t.annotateTimer);
            }
            t.annotateTimer=setTimeout(function (){
                t.annotateFeatureUses();
                t.annotateTimer=null;
            }, 10);
        }
    }

    render() {
        const mon = this.props.monster;
        const {crow,character,inlineEdit,editable,eventSync,onChange,onClickToken,noname,addSpellToken,rollable,noSource} = this.props;
        const {monObj,showConfig}=this.state;
        if (!mon||!monObj) {
            return <div>Monster not found</div>
        }
        const bfVersion = (mon.gamesystem=="bf");
        const {ActionBlock} = require('./actionblock.jsx');

        if (editable) {
            return <EditMonsterBlock monster={mon} onChange={onChange} noname={noname} onClickToken={onClickToken}/>;
        }

        if (mon.npc) {
            const {CharacterSheet} = require('./charactersheet.jsx');
            if (editable || inlineEdit) {
                return <CharacterSheet name={mon.name} type="monsters" eventSync={eventSync} addSpellToken={addSpellToken} getDiceRoller={this.getDiceRoller.bind(this)} hideDice/>
            } else {
                return <CharacterSheet name={mon.name} type="monsters" eventSync={eventSync} readonly noNav hideDice/>;
            }
        }

        let tokenURL;
        const typeInfo = Parser.monTypeToFullObj(mon.type);
        const fTemplate = campaign.getCustom(fTemplateName,mon.fTemplate);
        const hide = fTemplate?.hide||{};
        const tokenArt = monObj.tokenArt;
        if (tokenArt) {

            const art = campaign.getArtInfo(tokenArt);
            if (art) {
                tokenURL = art.url;
            }
        } else if (mon.imageURL) {
            tokenURL=mon.imageURL;
        }
        const displayName = monObj.displayName;
        const {saveDice, skillDice} = monObj.d20Bonuses;
        const aList = [], anList=[];

        for (let a of abilitiesValues) {
            const ainfo = monObj.abilities[a];
            const av = mon[a];
            let inside;
            if (av && !hide[a]) {
                anList.push(<th key={a} className="ttu">{a}</th>);
                if (bfVersion) {
                    aList.push(<td width="16.66%" key={a} className={rollable?"hoverroll":null} onClick={rollable?this.doAbilityRoll.bind(this, ainfo.spellSave, a):null}>
                        <span className="pv--2 dib">{signedNum(ainfo.spellSave)}</span>
                    </td>);
                } else {
                    aList.push(<td width="16.66%" key={a} className={rollable?"hoverroll":null} onClick={rollable?this.doAbilityRoll.bind(this, ainfo.modifier, a):null}>
                        {ainfo.score||""}{<span className="f2"> ({signedNum(ainfo.modifier)})</span>}
                    </td>);
                }
            }
        }
        const blankScores = mon.hideBlank || !aList.length;

        const abilityBlock= blankScores?null:<div className={(inlineEdit?"":"bt ")+"titleborder bb bw2 pt1 mb1"}>
            <table className="w-100 tc mb1">
                <thead>
                    <tr className="titlecolor">{anList}</tr>
                </thead>
                <tbody>
                    <tr>
                        {aList}
                    </tr>
                </tbody>
            </table>
        </div>;

        const senses = combineStrings(mon.senses, getTextFromDistanceStruct(monObj.senses));

        const otherTraits = <div>
            {!bfVersion?<SavingsThrowsRollable monObj={monObj} rollable={rollable} character={character} crow={crow}/>:null}
            <SkillsRollable monObj={monObj} rollable={rollable} displayName={displayName} character={character} crow={crow}/>
            <TextPlusEdit label={bfVersion?"Vulnerable":"Damage Vulnerabilities"} text={combineStrings(Parser.monImmResToFull(mon.vulnerable), monObj.vulnerable)}/>
            <TextPlusEdit label={bfVersion?"Resistant":"Damage Resistances"} text={combineStrings(Parser.monImmResToFull(mon.resist), monObj.resist)}/>
            <TextPlusEdit label={bfVersion?"Immune":"Damage Immunities"} text={combineStrings(Parser.monImmResToFull(mon.immune), monObj.immune)}/>
            <TextPlusEdit label={bfVersion?"Condition Immune":"Condition Immunities"} text={combineStrings(Parser.monImmResToFull(mon.conditionImmune), monObj.conditionImmune)}/>
            {(((!bfVersion&&!blankScores&&monObj.wis) || senses)&&!hide.senses)?<TextPlusEdit label="Senses" text={senses||" "}>
                {bfVersion?null:<span>{(senses?", ":"")+((blankScores||!monObj.wis)?"":"passive perception "+monObj.passive)}</span>}
            </TextPlusEdit>:null}
            {bfVersion&&!blankScores&&(monObj.dex||monObj.wis||mon.stealthNotes)?<div className="mb1"><b>Perception</b> <span className="minw35 dib">{monObj.passive}</span> <b>Stealth</b> {monObj.stealth} {mon.stealthNotes}</div>:null}
            <TextPlusEdit label="Languages" text={combineStrings(mon.languages, monObj.languages)}/>
            <TextPlusEdit label="Initiative" text={mon.fixedInitiative}/>
            <TextPlusEdit label="Damage Threshold" text={mon.damageThreshold}/>
        </div>;

        const speed = mergeSpeedVals(getNormalizedSpeed(mon.speed),monObj.speed);
        const speedStr = getStringFromSpeed(speed);
        const alignment = Parser.alignmentAbvToFull(mon);
        const {actionsList, featureslist, attributes}=this.getTemplateFeatures();
        const hpStr = getHPString(mon, monObj.hpMod, bfVersion);

        return <div className="f5 nodrag">
            <div className="flex">
                <div className="flex-auto">
                    {!this.props.noname?<div className="flex item-start mb1 titletext titlecolor b small-caps f2">
                        <span className="flex-auto">{displayName}</span>
                        {bfVersion&&!hide.cr?<div>CR {Parser.monCrOnly(mon.cr)}</div>:null}
                    </div>:null}
                    <div className="i pb1">
                        {mon.unique&&!mon.hideBlank?<span>NPC </span>:null}
                        {hide.size?null:<span>{Parser.sizeAbvToFull(mon.size)} {mon.sizeNotes} </span>}
                        {inlineEdit&&fTemplate?<a onClick={this.showOptionsConfig.bind(this,true)}>{typeInfo.asText}</a>:<span>{typeInfo.asText}</span>}
                        {bfVersion||!alignment||hide.alignment?null:<span>, {alignment}</span>}
                    </div>
                    {blankScores?null:<div className="titleborder bb bw2 mb1"/>}
                    {hide.ac?null:<ArmorClass monster={mon}/>}
                    {hpStr&&!hide.hp?<div className="mb1"><b>Hit Points</b> {hpStr}</div>:null}
                    {!hide.speed&&speedStr?.length?<div className="mb1"><b>Speed</b> {speedStr}</div>:null}
                    {bfVersion?otherTraits:null}
                    {attributes}
                </div>
                {tokenURL||inlineEdit?<div>
                    <img width="100" height="100" src={tokenURL||"/blankmonster.png"} onClick={this.clickToken.bind(this, tokenURL)}/>
                </div>:null}
            </div>
            {inlineEdit?<div className="flex mb--2 titleborder bt bb bw2 greenbackground">
                <div className="br b--gray-60 tc flex-auto overflow-hidden ph1">
                    <div className="f3 titlecolor b">
                        AC
                    </div>
                    <div className="f3">
                        <PickVal isNum value={monObj.ac} onClick={this.changeAttribute.bind(this,"ac")} values={armorClassValuesList}>
                            <div className="hover-bg-contrast tc">{monObj.ac}</div>
                        </PickVal>
                    </div>
                </div>
                <div className="br b--gray-60 tc flex-auto overflow-hidden">
                    <div className="flex">
                        <div className="f3 mr1 flex-auto">
                            <NumberAdjust 
                                value={monObj.curHP} altText={<div className="hover-bg-contrast">
                                    <div className="titlecolor b">Hit Points</div>
                                </div>}
                                positive={false}
                                noneg 
                                onChange={this.damage.bind(this)}
                            />
                            <div className="flex justify-center">
                                <div className="ph1 w-30 tc flex-auto">
                                    <NumberAdjust 
                                        value={monObj.curHP}
                                        altText={<div className="hover-bg-contrast">
                                            {monObj.curHP}
                                        </div>} 
                                        positive={false}
                                        noneg 
                                        onChange={this.damage.bind(this)}
                                    />
                                </div>
                                <div>/</div>
                                <div className="ph1 w-30 tc flex-auto">
                                    <NumberAdjust 
                                        value={monObj.maxHP}
                                        altText={<div className="hover-bg-contrast">
                                            {monObj.maxHP}
                                        </div>} 
                                        noneg 
                                        onChange={this.changeAttribute.bind(this, "maxHP")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="f5 tc hover-bg-contrast pr1">
                            <NumberAdjust 
                                value={monObj.temphp} altText={<div>
                                    <div className="titlecolor pt--3 truncate">Temp HP</div>
                                    <div className="ph2">{monObj.temphp||"--"}</div>
                                </div>} 
                                noneg 
                                onChange={this.changeAttribute.bind(this, "temphp")}
                            />
                        </div>
                    </div>
                </div>
                <div className="tc flex-auto hover-bg-contrast">
                    <PickVal value={monObj.size} onClick={this.changeAttribute.bind(this,"size")} values={sizeList}>
                        <div className="titlecolor b f3">Size</div>
                        <div>{Parser.sizeAbvToFull(monObj.size)||"--"}</div>
                    </PickVal>
                </div>
                <div className="bl b--gray-60 tc flex-auto hover-bg-contrast">
                    <PickVal value="Short Rest" onClick={this.doRest.bind(this)} values={["Short Rest", "Long Rest"]}>
                        <div className="b titlecolor f3">Rest</div>
                        <div className="fas fa-bed titlecolor f3 pl1"/>
                    </PickVal>
                </div>
            </div>:null}
            {abilityBlock}
            {bfVersion?null:otherTraits}
            {(hide.cr||bfVersion)?null:<div className="mv1 pb1 titleborder bb bw2 mv1">
                <span><b>Challenge</b> {Parser.monCrToFull(mon.cr)} </span>
            </div>}
            {inlineEdit?<ActionBlock character={monObj} baseCharacter={character} getDiceRoller={this.getDiceRoller.bind(this)} doSubRoll={this.doSubRoll.bind(this)} addSpellToken={addSpellToken} excludeActions={["createItem", "summon", "shape", "convert"]}/>:null}
            {inlineEdit?this.getUsage():null}
            {featureslist}
            {this.getEntryRenderEdit("trait", null)}
            {this.getEntryRenderEdit("action", "Actions")}
            {actionsList}
            {this.getEntryRenderEdit("bonusaction", "Bonus Actions")}
            {this.getEntryRenderEdit("reaction", "Reactions")}
            {this.getEntryRenderEdit("legendary", "Legendary Actions")}
            {this.getEntryRenderEdit("lairActions", "Lair Actions")}
            {this.getEntryRenderEdit("regionalEffects", "Regional Effects")}
            {this.getEntryRenderEdit("variant")}
            {noSource?null:<Rendersource entry={mon}/>}
            {bfVersion?null:<Environment monster={mon}/>}
            <ArtZoomList 
                open={this.state.showArtZoom}
                artList={getMonsterArtList(mon)}
                onClose={this.onCloseArtZoom.bind(this)}
                pickToken preferToken
                defaultArt={mon.defaultArt}
                defaultToken={tokenArt}
                pageSync={eventSync}
                onSelectToken={inlineEdit?this.changeToken.bind(this):null}
                description={displayName}
                defaultSearch={mon.displayName}
                defaultType="Monster Token"
            />
            {this.getCastMenu()}
            {inlineEdit&&showConfig?<OptionsConfig open monObj={monObj} onClose={this.showOptionsConfig.bind(this,false)}/>:null}
        </div>
    }

    showOptionsConfig(showConfig) {
        this.setState({showConfig});
    }

    doAbilityRoll(bonus, ability) {
        const {monObj} = this.state;
        const {character, crow} = this.props;
        const {saveDice} = monObj.d20Bonuses;

        const dice = {D20:1, bonus};
        const {rolls} = doRoll(dice);
        const newRoll = {dice, rolls, source:abilityNames[ability], action:"Save"};
        Chat.addD20BonusRolls(character, newRoll, saveDice);

        if (character) {
            newRoll.playerDisplayName=monObj.displayName;
            Chat.addCharacterRoll(character, newRoll, null, true);
        } else if (crow) {
            Chat.addRoll(crow, newRoll)
        }else {
            newRoll.playerDisplayName=monObj.displayName;
            Chat.addGMRoll(newRoll);
        }
    }

    onCloseArtZoom() {
        this.setState({showArtZoom:false});
    }

    clickToken(tokenURL, event) {
        const tokenArt = this.state.monObj.tokenArt;
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (tokenArt) {
            this.setState({showArtZoom:true});
        } else {
            if (this.props.eventSync && tokenURL) {
                this.props.eventSync.emit("showImageHandout", {
                    type:"art",
                    art:this.props.monster.tokenArt||null,
                    url:tokenURL,
                    description:this.props.monster.displayName||null,
                    imgHeight:560,
                    imgWidth:560
                });
            }
        }
        return;
    }

    getTemplateFeatures() {
        const {inlineEdit, rollable,addSpellToken,showInstantRoll,character, monster} = this.props;
        const t=this;
        const {monObj, baseMonObj} = this.state;
        const handled = {}, nameShown={};
        const {UsageSlots} = require('./actionblock.jsx');

        let featureslist=[], actionsList=[], attributes=[];

        let uaAttacks=getMonAttacks(monObj);
        if (uaAttacks) {
            actionsList.push(<div className="mb1 stdcontent" key=".unarmed">
                <Renderentry
                    nodiv
                    entry={[{html:uaAttacks, type:"html"}]} 
                    depth="2" 
                    showInstantRoll={showInstantRoll}
                    getDiceRoller={rollable?t.getDiceRoller.bind(t):null}
                    doRoll={rollable?t.doTextRoll.bind(t):null}
                    doSubRoll={rollable?t.doSubRoll.bind(t):null}
                    addSpellToken={addSpellToken}
                    character={character}
                    extraScan={inlineEdit&&rollable?t.annotateActions.bind(t,"unarmed",null):null}
                />

            </div>);
        }

        monObj.traverseFeatures(function (params) {
            const e = params.feature;
            const {level,type, typeValue, usageId,fid, options} = params;
            const usage = e.usage;
            const tvn = typeValue?.name;

            if (usage && (usage.type !="hide")) {
                let dn = e.name;
                if (!dn && tvn && !nameShown[tvn]) {
                    dn = typeValue.displayName;
                    nameShown[tvn]=true;
                }
                featureslist.push(<div className="mb1 stdcontent" key={usageId}>
                    {dn?(e.noDiv?<span className="b i">{dn}. </span>:<h2>{dn}</h2>):null}
                    <UsageSlots 
                        character={monObj} 
                        noCounts={!inlineEdit}
                        usage={usage} 
                        level={level-1}
                        doRoll={rollable?t.doTextRoll.bind(t):null}
                        id={usageId}
                    /> <Renderentry
                        nodiv
                        entry={e.entries} 
                        depth="2" 
                        showInstantRoll={showInstantRoll}
                        getDiceRoller={rollable?t.getDiceRoller.bind(t):null}
                        doRoll={rollable?t.doTextRoll.bind(t):null}
                        doSubRoll={rollable?t.doSubRoll.bind(t):null}
                        addSpellToken={addSpellToken}
                        character={character}
                        extraScan={inlineEdit&&rollable?t.annotateActions.bind(t,usageId,e.name):null}
                    />
                </div>);
            }

            if (usage?.showAsAttribute) {
                if (usage?.usageName && !handled[usage.usageName.toLowerCase()]) {
                    const max = monObj.getUsageMax(usage,level-1);
                    if (max) {
                        attributes.push(<div className="mb1" key={"namedVal"+usage.usageName}><b>{usage.usageName}</b> <UsageSlots 
                            character={monObj} 
                            useNumbers
                            numOnly={!inlineEdit}
                            usage={usage} 
                            level={level-1}
                            id={usageId}
                        /> {usage.extraValue?replaceMetawords(usage.extraValue,monObj.namedValues):null}
                        </div>);
                        handled[usage.usageName.toLowerCase()]=true;
                    }
                } else if (usage?.valueName && usage.displayLevels && !handled[usage.valueName.toLowerCase()]) {
                    attributes.push(<div className="mb1" key={"namedVal"+usage.valueName}><b>{usage.valueName}</b> <UsageSlots 
                        character={monObj} 
                        noCounts
                        useNumbers
                        usage={usage} 
                        level={level-1}
                        id={usageId}
                    /> {usage.extraValue?replaceMetawords(usage.extraValue,monObj.namedValues):null}
                    </div>);
                    handled[usage.valueName.toLowerCase()]=true;
                }
            }

            if (e.customAttribute) {
                const prop = fid+ ".te."+e.customAttribute;
                attributes.push(<div className="mb1" key={prop}><b>{e.customAttribute}</b> {options[prop]||"--"}</div>);
            }
        }, ["fTemplate"])

        const es = getExtraSpells(monObj, inlineEdit);
        if (es) {
            actionsList.push(<div className="mb1 stdcontent" key="extraspells">
                <Renderentry
                    nodiv
                    entry={[{type:"html",html:es}]} 
                    depth="2" 
                    showInstantRoll={showInstantRoll}
                    getDiceRoller={rollable?t.getDiceRoller.bind(t):null}
                    doRoll={rollable?t.doTextRoll.bind(t):null}
                    doSubRoll={rollable?t.doSubRoll.bind(t):null}
                    addSpellToken={addSpellToken}
                    character={character}
                    extraScan={inlineEdit&&rollable?t.annotateActions.bind(t,"extraspells",null):null}
                />

            </div>);
        }

        return {actionsList, featureslist, attributes};
    }

    getEntryRenderEdit(field, title) {
        const {inlineEdit, rollable,addSpellToken,showInstantRoll,character, monster} = this.props;
        const {monObj, baseMonObj} = this.state;
        let value = monster[field]
        if (baseMonObj && monObj) {
            value = updateTextActions(value, baseMonObj, monObj);
        }
        let renderEntry =getBasicEntry(value);

        if (inlineEdit && !this.features) {
            this.features = {};
        }

        if (!renderEntry.html || renderEntry.html == "<p></p>") {
            if (inlineEdit) {
                delete this.features[field];
            }
            return null;
        }

        return <div className="stdcontent">
            {title?<h2>{title}</h2>:null}
            <Renderentry 
                entry={renderEntry} 
                depth="2" 
                showInstantRoll={showInstantRoll}
                getDiceRoller={rollable?this.getDiceRoller.bind(this):null}
                doRoll={rollable?this.doTextRoll.bind(this):null}
                doSubRoll={rollable?this.doSubRoll.bind(this):null}
                addSpellToken={addSpellToken}
                character={character}
                extraScan={inlineEdit&&rollable?this.annotateActions.bind(this,field,null):null}
            />
        </div>;
    }

    getCastMenu() {
        const {showCastMenu,spellInfo,anchorPos,monObj} = this.state;
        const {monster}=this.props;

        if (showCastMenu) {
            const {getSpellActionInfo} = require('./characterspells.jsx');
            const {getDamagesInfo} = require('./actionblock.jsx');
            const {spell,castInfo,noSlots}=spellInfo;
            const sa = getSpellAttributes(spell);
            const castOptions = [];

            const {saveAbility, saveVal, href, concentration, actionType, tempHp, attackRoll, damages, altdamages, castLevel, extraNotes, range,conditions,areaOfEffect} = 
                getSpellActionInfo(monObj, null, null, spellInfo);

            const time = spell.time||{};
            const dinfo = getDamagesInfo(damages);
            const adinfo = getDamagesInfo(altdamages);
            const duration = getDurationFromSpell(spell);
            const castingTime = (time.number||time.unit)?time.number+" "+ pluralString(time.unit, time.number):null;
            const hasAttack = saveAbility || attackRoll;
            const hasEffect = dinfo.dmgList.length||adinfo.dmgList.length;


            if (spell.level && castInfo) {
                let {id, useId, count} = castInfo;
                if ((id||useId) && (count > 0)) {
                    id = useId || (id+spell.name.toLowerCase());
                    castOptions.push(<div className="tc mv--2" key="each"><Button disabled={this.getCurrentUsed(id)>=count} color="primary" size="small" variant="outlined" onClick={this.castSpell.bind(this,spellInfo,null)}>cast</Button></div>)
                } else if (!noSlots) {
                    let foundOption, foundSlots;
                    for (let l=spell.level; l<=9; l++) {
                        const slots = this.spellSlots[l];
                        id = "slot."+l;
                        if (slots) {
                            const used = this.getCurrentUsed("slot."+l);
                            if (!foundSlots || ((used<slots)&&(!foundOption || sa.extraSpellLevels))) {
                                castOptions.push(<div className="tc mv--2" key={l}><Button disabled={used>=slots} color="primary" size="small" variant="outlined" onClick={this.castSpell.bind(this,spellInfo,l)}>cast {Parser.spLevelToFull(l)+"-Level"}</Button></div>)
                            } 
                            foundSlots=true;
                            if (used < slots) {
                                foundOption=true;
                            }
                        }
                    }
                }
            }
    

            return <Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.hideCastMenu.bind(this)}>
                <div className="ph3 pb1 f3 tc titletext titlecolor titleborder bb">
                    <LinkHref href={"#spell?id="+encodeURIComponent(spell.name)} onClose={this.hideCastMenu.bind(this)}>{spell.displayName}</LinkHref>
                    {concentration?<span className="inversespecial ml1 f5 b notetext">C</span>:null}
                </div>
                <div className="tc mh2 titletext">
                    <div className="mv1">{spell.level?(Parser.spLevelToFull(spell.level) + monObj.spellLevelName("-")):"Cantrip"}</div>
                    <div className="ma1">{castingTime} {range?<span>({isNaN(range)?null:"range: "}{range})</span>:null}</div>
                    {duration&&(castingTime!=duration)?<div className="mv1">Duration: {duration}</div>:null}
                </div>
                {hasAttack||hasEffect?<div>
                    <table className="w-100 stdcontent">
                        <tbody>
                            <tr></tr>
                            <tr className="f7">
                                {hasAttack?<td className="tc b">hit/dc</td>:null}
                                {hasEffect?<td className="tc b">effect</td>:null}
                            </tr>
                            <tr>
                                {hasAttack?<td>
                                    {saveAbility?<div className="tc"><span className="f7 ttu">{saveAbility}</span>&nbsp;{saveVal}</div>:null}
                                    {attackRoll?<div className="tc hoverroll b">{attackRoll}</div>:null}
                                </td>:null}
                                {hasEffect?<td>
                                    <div className="hoverroll tc"><b>{dinfo.dmgList}</b></div>
                                    {adinfo.dmgList.length?<div className="hoverroll tc bt b--gray-50"><b>{adinfo.dmgList}</b></div>:null}
                                </td>:null}
                            </tr>
                        </tbody>
                    </table>
                </div>:null}
                {castOptions.length?null:<div className="tc"><Button color="primary" size="small" variant="outlined" onClick={this.castSpell.bind(this,spellInfo,null)}>cast</Button></div>}
                {castOptions}
            </Menu>;
        }
    }

    castSpell(spellInfo, l) {
        const {getSpellActionInfo} = require('./characterspells.jsx');

        const {character, crow,monster} = this.props;
        const {monObj} = this.state;
        const {spell,castInfo} = spellInfo;
        const {saveAbility, saveVal, href, concentration, actionType, tempHp, attackRoll, damages, altdamages, castLevel, extraNotes, range,conditions,noShowDuration,areaOfEffect,duration} = 
            getSpellActionInfo(monObj, null, l?{level:l}:null, spellInfo);


        let actionTypeVal="Cast Spell";
        const conditionInfo = {};

        if (castLevel) {
            actionTypeVal = "Cast "+Parser.spLevelToFull(castLevel) + monObj.spellLevelName("-"," Spell");
        }

        const spellDuration = getDurationFromSpell(spell, castLevel);
        if (!noShowDuration && spellDuration && spell.displayName) {
            if (saveAbility) {
                conditionInfo.saveAbility = saveAbility;
                conditionInfo.saveVal = saveVal;
            }
            if (attackRoll) {
                conditionInfo.attackRoll = attackRoll;
            }
    
            conditionInfo.spellId = spell.name;
            conditionInfo.spellName = spell.displayName;
            if (extraNotes) {
                conditionInfo.extraNotes = extraNotes;
            }
            if (concentration) {
                conditionInfo.concentration=1;
            }
            if (!isNaN(range)) {
                conditionInfo.range=range;
            }
            if (actionType) {
                conditionInfo.actionType = actionType;
            }
            if (castLevel) {
                conditionInfo.spellLevel = castLevel;
            }
            if (damages){
                conditionInfo.damages = damages;
            }
            if (altdamages) {
                conditionInfo.altdamages=damages;
            }
            if (conditions) {
                conditionInfo.conditions=conditions;
            }
            const condition={duration:getDurationFromText(spellDuration), durationText:spellDuration, feature:spell.enableFeature||null, spell:conditionInfo,hideIndicator:true};

            const nc = Object.assign({}, monObj.conditions);
            nc[spell.displayName] = condition;

            //console.log("set cast spell", condition, nc);

            monObj.conditions=nc;
        }

        if (spell.summonMonsters) {
            console.log("pick monsters to summon");
//            this.setState({showSelectMonster:true, selectMonsterSpell:spell, lastSelected, selectMonsterRestriction:calcMonRestriction(spell.summonMonsters, castLevel||spell.level)});
        }

        if (spell.level && castInfo) {
            let {id, useId,  count, unit, useSlots} = castInfo;
            let max;
            if (useId) {
                max = count || 0;
                id = useId;
            } else if (id) {
                max = count || 0;
                id = id+spell.name.toLowerCase();
            } else {

                max = this.spellSlots[(l||spell.level)]||0;
                id = "slot."+(l||spell.level);
            }
            if (max && (this.getCurrentUsed(id) <max)) {
                this.adjustCurrentUsed(id, true, 1);
            }
        }
        Chat.addMonsterAction(monObj, crow, character, spell.displayName, href, attackRoll, damages, saveAbility, saveVal, actionTypeVal, conditions, tempHp, altdamages&&altdamages.length?[{damages:altdamages}]:null, true, areaOfEffect||{},duration);

        this.setState({showCastMenu:false});
    }

    hideCastMenu() {
        this.setState({showCastMenu:false});
    }

    getDiceRoller() {
        return <ChatButton character={this.props.character}/>;
    }

    doSubRoll(name, text){
        const {character, crow,doSubRoll} = this.props;
        const {monObj}=this.state;
        const isDirectRoll = name && (typeof name == "object");
        const displayName = monObj.displayName;

        if (doSubRoll) {
            if (isDirectRoll) {
                return doSubRoll(name);
            }
            return doSubRoll(displayName+(name?(": "+name):""), text);
        }

        let newRoll;
        if (isDirectRoll) {
            newRoll = name;
        } else {
            const dice = getDiceFromString(text);
            const {rolls} = doRoll(dice);
            let attack = (dice.D20==1);
            for (let i in dice) {
                if ((i!="D20") && (i!="bonus") && (i!="extraBonus")) {
                    attack = false;
                }
            }

            newRoll = {dice, rolls, action:attack?"to hit":null};
            if (name) {
                newRoll.source = name;
            }
        }

        if (character) {
            if (displayName) {
                newRoll.playerDisplayName = displayName;
            }
            return Chat.addCharacterRoll(character, newRoll, null, true);
        } else if (crow) {
            return Chat.addRoll(crow, newRoll)
        } else {
            if (displayName) {
                newRoll.playerDisplayName = displayName;
            }
            return Chat.addGMRoll(newRoll);
        }
    }

    doTextRoll(text){
        this.doSubRoll(null, text);
    }

    damage(value, adjust) {
        this.state.monObj.damageHeal(adjust);
    }

    doRest(rest) {
        const longrest = (rest== "Long Rest");
        const {monObj} = this.state;
        monObj.rest(longrest);

        if (longrest) {
            displayMessage("Long rest completed.  Hit points restored to max hit points.");
        } else {
            displayMessage("Short rest completed.  Half of max hit points restored.");
        }
    }

    changeToken(value){
        this.changeAttribute("tokenArt", value);
        this.setState({showArtZoom:false});
    }

    changeAttribute(attribute, value){
        this.state.monObj.setProperty(attribute, value);
    }

    getUsage() {
        const usages = this.state.monObj.usages;
        if (!usages) {
            return null;
        }
        const list = [];

        for (let i in usages) {
            const u = usages[i];

            if (!u.hidden) {
                list.push(<div key={i} className="mb1">
                    <b>{u.description}</b> <MaxNumberAdjust value={u.current} max={u.maximum} onAdjustValue={this.onAdjustUsage.bind(this, i)}/>
                </div>)
            }
        }
        return list;
    }

    onAdjustUsage(i, value) {
        if (!this.props.inlineEdit) {
            return;
        }
        const usages = this.state.monObj.usages.concat([]);

        usages[i] = Object.assign({}, usages[i]);
        usages[i].current = value;
        this.changeAttribute("usages", usages);
    }

    annotateActions(field, rootName, root) {
        const {monObj}=this.state;
        const t=this;
        const features = findFeatures(root.firstChild, rootName);
        const wrapper=root.firstChild;
        const bestAbility = monObj.getBestSave(["cha", "wis", "int"]);
        let lastAbility = bestAbility;

        for (const i in  features) {
            const f = features[i];
            const text = f.node.textContent;
            findAttack(f.node,f, this.clickDamage.bind(this),this.clickAttack.bind(this),this.clickRoll.bind(this));
            const spellAbility = findSpellcasting(text);
            f.spellAbility = f.name?spellAbility||bestAbility:spellAbility||lastAbility;
            const spellLevel = findSpellcastingLevel(text);
            if ((spellLevel||0) > (monObj.monsterSpellLevel||0)) {
                monObj.monsterSpellLevel=spellLevel;
            }
            lastAbility = f.spellAbility;
            f.save=findSave(text);
            f.order = i;
            f.field= field;
            f.noSlots = (/(at will|without expending a spell)/i).test(text||"") && !(/cantrips \(?at will/i).test(text);
            labelUses(f.node, field+(f.name||i));
            this.annotateSpells(f);
        }
        this.features[field] = features;
        //console.log("annotate actions", field, features);
        this.doAnnotateUses();
    }

    annotateFeatureUses() {
        const {monObj}=this.state;
        this.spellSlots = [];
        //console.log("annotate feature uses", this.features);
        for (let field in this.features) {
            const features=this.features[field];
            for (let i in features) {
                const f = features[i];

                if (f.node.getElementsByClassName) {
                    const monuses = f.node.getElementsByClassName("monuses");

                    for (let mu = 0; mu < monuses.length; mu++) {
                        const m = monuses[mu];
                        const d = m.dataset;
                        const unit = d.unit;
                        const id = d.useId;

                        switch (unit) {
                            case "slots":
                                const level = d.level;
                                const count = Math.max(this.spellSlots[level]||0,d.count);

                                this.spellSlots[level] = count;
                                break;
                            case "short":
                            case "long":
                                break;
                            case "recharge":
                                break;
                        }
                    }
                }
            }
        }
        for (let l in monObj.spellSlots) {
            this.spellSlots[Number(l)+1] = (this.spellSlots[Number(l)+1]||0)+ (monObj.spellSlots[l]||0);
        }

        for (let field in this.features) {
            const features=this.features[field];
            for (let i in features) {
                const f = features[i];

                if (f.node.getElementsByClassName) {
                    const monuses = f.node.getElementsByClassName("monuses");

                    for (let mu = 0; mu < monuses.length; mu++) {
                        const m = monuses[mu];
                        const d = m.dataset;
                        const unit = d.unit;
                        const id = d.useId;

                        switch (unit) {
                            case "slots":
                                id = "slot."+d.level;
                                const level = d.level;
                                const count = Math.max(this.spellSlots[level]||0,d.count);

                                this.spellSlots[level] = count;
                                addUses(m, this.getCurrentUsed(id), count, this.adjustCurrentUsed.bind(this, id, true));
                                break;
                            case "short":
                            case "long":
                                addUses(m, this.getCurrentUsed(id), d.count, this.adjustCurrentUsed.bind(this,id, unit=="long"));
                                break;
                            case "recharge":
                                const current = this.getCurrentUsed(id);
                                addUses(m, current, 1, this.adjustCurrentUsed.bind(this,id,false));
                                if (current) {
                                    const roll= document.createElement('b');
                                    roll.classList.add("pip","hoverhighlight","dodieroll","ph--2");
                                    roll.addEventListener("click",this.recharge.bind(this, id, d.die, d.min));
                                    m.after(roll);
                                }
                                break;
                        }
                    }

                    const {getLocationInfo}=require('./renderhref.jsx');

                    const list = f.node.getElementsByClassName("eachuses");
                    for (let i=0; i<list.length; i++) {
                        const node = list[i];
                        if ((node.host == window.location.host) && (node.pathname == "" || node.pathname=="/")) {
                            const page = getLocationInfo(node.hash);
                            if (page.page=="spell") {
                                const spell = campaign.getSpell(page.id);
                                if (spell) {
                                    if (spell.level) {
                                        const d = node.dataset;

                                        const sid = d.eachId+spell.name.toLowerCase();
                                        addUses(node, this.getCurrentUsed(sid), d.useCount, this.adjustCurrentUsed.bind(this,sid,d.useUnit=="long"));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    recharge(id, die, min, event) {
        event.preventDefault();
        event.stopPropagation();
        const c = this.doSubRoll("Recharge", "1d"+die);
        if (c.roll.rolls[0]>=min) {
            this.adjustCurrentUsed(id, false, -100);
        }
    }

    getCurrentUsed(id) {
        const {monObj}=this.state;
        const usages = monObj.usages||[];

        for (let u of usages) {
            if (u.id==id) {
                return u.current || 0;
            }
        }
        return 0;
    }

    adjustCurrentUsed(id, longrest, adjust) {
        const {monObj}=this.state;
        const usages = (monObj.usages||[]).concat([]);
        let found=usages.length;
        let current = 0;

        for (let i in usages) {
            const u=usages[i];
            if (u.id==id) {
                found = i;
                current = u.current || 0;
                break;
            }
        }
        let u = {
            id,
            current:Math.max(0,current + adjust),
            longrest,
            hidden:true
        };

        usages[found]=u;
        monObj.setProperty("usages",usages)
    }

    annotateSpells(feature) {
        const {getLocationInfo}=require('./renderhref.jsx');

        const list = feature.node.getElementsByTagName&&feature.node.getElementsByTagName("a") || [];
        for (let i=0; i<list.length; i++) {
            const node = list[i];
            if ((node.host == window.location.host) && (node.pathname == "" || node.pathname=="/")) {
                const page = getLocationInfo(node.hash);
                if (page.page=="spell") {
                    const spell = campaign.getSpell(page.id);
                    if (spell) {
                        const d = node.dataset;
                        node.addEventListener("click",this.clickSpell.bind(this, feature, {id:d.eachId, useId:d.useId, count:d.useCount, unit:d.useUnit}, spell ));
                    }
                }
            }
        }
    }

    clickSpell(feature, castInfo, spell, event) {
        //console.log("clicked spell link", feature,spell,castInfo);
        this.setState({anchorPos:getAnchorPos(event), spellInfo:{spell, castInfo, abilityDC:feature.spellAbility||"cha", noSlots:feature.noSlots}, showCastMenu:true});
        event.preventDefault();
        event.stopPropagation();
    }

    clickAttack(attack, feature, event) {
        //console.log("attack", attack, feature);
        const {character, crow} = this.props;
        const {monObj}=this.state;
        const fhtml = feature.node?.outerHTML;
        Chat.addMonsterAction(monObj, crow, character, feature.name, getHrefFromHtmlFeature(feature), attack.attackRoll, attack.damages, feature.save?.ability, feature.save?.dc," ",null,null,attack.altDamages?[{damages:attack.altDamages}]:null);
        event.preventDefault();
        event.stopPropagation();
    }
   
    clickDamage(dmg, dmgType, feature, event) {
        const {character, crow} = this.props;
        const {monObj}=this.state;
        //console.log("damage", character, crow, dmg, dmgType, feature);
        Chat.addMonsterDamageRoll(monObj, crow, character, feature.name, getHrefFromHtmlFeature(feature), [{dmg, dmgType}], null, feature.save?.ability, feature.save?.dc);
        event.preventDefault();
        event.stopPropagation();
    }

    clickRoll(rollText, feature, event) {
        this.doSubRoll(feature.name||null, rollText);
        event.preventDefault();
        event.stopPropagation();
    }

}

function getHrefFromHtmlFeature(feature) {
    if (feature?.name) {
        return "#charfeature?fhtml="+encodeURIComponent(feature.node?.outerHTML);
    }
    return null;
}

function getSpellText(monObj, recoveryType, feature, spells, castable) {
    const always=[], limited=[];
    let ret;

    let abilityDC="cha";
    let saveDC = feature.spellAbilityDC || (feature.usage&&feature.usage.saveDC) || ["cha","wis","int"];
    if (!isNaN(saveDC)) {
        saveDC=["cha","wis","int"];
    }

    if (Array.isArray(saveDC)) {
        let lastVal = 0;
        for (let i in saveDC) {
            const s = saveDC[i];
            const cv = isNaN(s)?((monObj.getAbility(s)||{}).modifier||0):-100;

            if (!abilityDC || (cv > lastVal)) {
                abilityDC = s;
                lastVal = cv;
            }
        }
    } else {
        abilityDC=saveDC;
    }
    const sAttack = (monObj.abilities[abilityDC]?.modifier||0)+monObj.proficiency;

    for (let s of spells) {
        const link = `<i><a href="#spell?id=${encodeURIComponent(s.name)}">${(s.displayName||"").toLowerCase()}</a></i>`;
        switch (recoveryType) {
            case "longslot":
                castable[s.name]=1;
            case "long":
            case "short":
            case "uses":
                if (!s.level) {
                    always.push(link);
                } else {
                    limited.push(link);
                }
                break;
            case "ritual":
            case "always":
                always.push(link);
                break;
            case "slot":
                castable[s.name]=1;
                break;
        }
    }

    let setup, alwaysTitle="<p>At will:", limitedTitle;

    switch (recoveryType) {
        case "longslot":
        case "long":
            alwaysTitle = "<p>At will: ";
            limitedTitle = `<p>1/day${limited.length>1?" each":""}: `;
            break;
        case "short":
            alwaysTitle = "<p>At will: ";
            limitedTitle = `<p>1/short rest${limited.length>1?" each":""}: `;
            break;
        case "uses":
            const maxUsage = monObj.getUsageMax(feature.usage, monObj.level-1);
            alwaysTitle = "<p>At will: ";
            limitedTitle = `<p>${maxUsage}/${feature.usage?.longrest?"day":"short rest"}${limited.length>1?" each":""}: `;
            break;
        case "ritual":
            alwaysTitle = "<p>As a ritual: ";
            break;
        case "always":
            alwaysTitle = "<p>At will: ";
            break;
        case "slot":
            break;
    }
    if (always.length||limited.length) {
        ret = `<p><b><i>${feature.name || "Innate Spellcasting"}. </i></b>The spellcasting ability is ${upperFirst(abilityNames[abilityDC])} (spell save DC ${sAttack+8}, <b>${signedNum(sAttack)}</b> to hit with spell attacks). Can cast the following spells:</p>`
        if (alwaysTitle && always.length) {
            ret = ret + alwaysTitle+always.join(", ")+"</p>";
        }
        if (limitedTitle && limited.length) {
            ret = ret + limitedTitle+limited.join(", ")+"</p>";
        }
    }
    return ret;
}

function setupCompanion(mon, character) {
    if (!mon.companion) {
        delete mon.companionMod;
        return;
    }

    const hp = mon.hp;
    let level = 0;
    let bonus =(mon.cbase||0);
    if (mon.class) {
        const classes = character.classes;
        for (let i in classes) {
            const cls = campaign.getClassInfo(classes[i].cclass);
            if (cls.displayName == mon.class) {
                level = classes[i].level;
            }
        }
    } else {
        level = character.level
    }

    if (mon.cability) {
        bonus += character.getAbility(mon.cability).modifier;
    }
    let hpSet = bonus + level*(mon.cmultiple||1);
    if (!(hpSet>0)) {
        hpSet=1;
    }
    if (hp.maxHP != hpSet) {
        if ((hp.curHP==null) || hp.curHP>hpSet) {
            hp.curHP = hpSet;
        }
        hp.maxHP = hpSet;
    }
    mon.companionMod = {proficiencyBonus:character.proficiency - getProficiency(mon)};

    //console.log("setup companion", mon);
}

function printMonster(id,noTitle,header) {
    const list=[];
    const mon = campaign.getMonster(id);
    if (!mon) {
        return;
    }
    if (mon.npc) {
        const character = new Character(mon, "monsters", true);
        return printCharacter(character,noTitle, header);
    }
    const bfVersion = (mon.gamesystem=="bf");
    if (!noTitle) {
        list.push(`<h${header}>${mon.displayName} ${bfVersion?`CR ${Parser.monCrOnly(mon.cr)}`:""}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(mon.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else if (!mon.npc) {
        let tokenURL;
        const monObj = new MonObj(mon, null, true, null, "noupdate");

        const typeInfo = Parser.monTypeToFullObj(mon.type);
        const fTemplate = campaign.getCustom(fTemplateName,mon.fTemplate);
        const blankScores = mon.hideBlank;
        if (monObj.tokenArt) {
            const art = campaign.getArtInfo(monObj.tokenArt);
            if (art) {
                tokenURL = art.url;
            }
        } else if (mon.imageURL) {
            tokenURL=mon.imageURL;
        }
        const displayName = monObj.displayName;
        const aList = [];

        for (let a of abilitiesValues) {
            const ainfo = monObj.abilities[a];
            const av = mon[a]
            let inside;
            if (bfVersion) {
                aList.push(`<td width="16.66%">${av?signedNum(ainfo.spellSave):""}</td>`);
            } else {
                aList.push(`<td width="16.66%">${av&&ainfo.score||""} ${av?`(${signedNum(ainfo.modifier)})`:""}</td>`);
            }
        }

        const abilityBlock= blankScores?"":`<table width="100%" style="text-align: center"><tr><th>STR</th><th>DEX</th><th>CON</th><th>INT</th><th>WIS</th><th>CHA</th></tr><tr>${aList.join("")}</tr></table>`;

        const senses = combineStrings(mon.senses, getTextFromDistanceStruct(monObj.senses));

        const otherTraits = [];

        //savings throws
        if (!bfVersion) {
            const ablist =[];
    
            for (let i of abilitiesValues) {
                const ab = monObj.abilities[i];
                const save = ab.spellSave;
                if (save != ab.modifier) {
                    ablist.push(abilityNames[i.toLowerCase()]+" "+signedNum(Number(save)));
                }
            }
            if (ablist.length) {
                otherTraits.push(`<p><b>Saving Throws</b> ${ablist.join(", ")}</p>`);
            }
        }

        // skills
        {
            const mskill = monObj.state.skill;
            const skills = monObj.skills;
            const slist =[];
            const sn = Object.keys(mskill||{}).sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())});
            const os = Object.keys(skills).sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())});
            const handled = {};
        
            for (let i of sn) {
                const skillName=monsterSkillsMap[i.toLowerCase()]||i;
                const val = skills[skillName]?.modifier || mskill[i];
                handled[skillName] = true;
                slist.push(skillName+" "+signedNum(Number(val)));
            }
            for (let i of os) {
                const s = skills[i];
                const ai = monObj.abilities[s.ability];
                if (!handled[i] && (ai.modifier != s.modifier) && ((i!="Stealth")||!bfVersion)) {
                    slist.push(i+" "+signedNum(s.modifier));
                }
            }
    
            if (slist.length) {
                otherTraits.push(`<p><b>Skills</b> ${slist.join(", ")}`);
            }
        }
    
        otherTraits.push(printTextBlock(bfVersion?"Vulnerable":"Damage Vulnerabilities", combineStrings(Parser.monImmResToFull(mon.vulnerable), monObj.vulnerable)));
        otherTraits.push(printTextBlock(bfVersion?"Resistant":"Damage Resistances", combineStrings(Parser.monImmResToFull(mon.resist), monObj.resist)));
        otherTraits.push(printTextBlock(bfVersion?"Immune":"Damage Immunities", combineStrings(Parser.monImmResToFull(mon.immune), monObj.immune)));
        otherTraits.push(printTextBlock(bfVersion?"Condition Immune":"Condition Immunities", combineStrings(Parser.monImmResToFull(mon.conditionImmune), monObj.conditionImmune)));
        if ((!bfVersion&&!blankScores&&monObj.wis) || senses) {
            otherTraits.push(printTextBlock("Senses", senses||" ", bfVersion?null:`${senses?", ":""}${(blankScores||!monObj.wis)?"":"passive perception "+monObj.passive}`));
        }
        if (bfVersion&&!blankScores&&(monObj.dex||monObj.wis||mon.stealthNotes)) {
            otherTraits.push(`<p><b>Perception</b> ${monObj.passive} <b>Stealth</b> ${monObj.stealth||""} ${mon.stealthNotes||""}</p>`);
        }
        
        otherTraits.push(printTextBlock("Languages", combineStrings(mon.languages, monObj.languages)));
        otherTraits.push(printTextBlock("Initiative", mon.fixedInitiative));
        otherTraits.push(printTextBlock("Damage Threshold", mon.damageThreshold));
        
        const speed = mergeSpeedVals(getNormalizedSpeed(mon.speed),monObj.speed);
        const speedStr = getStringFromSpeed(speed);
        const alignment = Parser.alignmentAbvToFull(mon);
        const {actionsList, featureslist, attributes}=getPrintTemplateFeatures(monObj);

        if (tokenURL) {
            list.push(`<img width="100" height="100" src="${tokenURL}" style="float:right;padding: 5px"/>`)
        }
        list.push(`<p><i></i>${mon.unique&&!mon.hideBlank?"NPC ":""}${Parser.sizeAbvToFull(mon.size)||""} ${mon.sizeNotes||""} ${typeInfo.asText}${bfVersion||!alignment?"":`, ${alignment||""}`}</p>`);
        const acFull = Parser.acToFull(mon.ac); 
        if (acFull) {
            list.push(`<p><b>Armor Class</b> ${acFull}</p>`);
        }
        if (mon.hp) {
            if (mon.unique) {
                list.push(`<p><b>Hit Points</b> ${mon.hp.maxHP||1}</p>`);
            } else if (mon.hp.average || mon.hp.formula || mon.hp.special) {
                list.push(`<p><b>Hit Points</b> ${mon.hp.special?mon.hp.special:(`${mon.hp.average||""}`+((mon.hp.formula&&mon.hp.formula.length&&!bfVersion&&(mon.hp.formula!=mon.hp.average))?` (${mon.hp.formula||""})`:""))}</p>`);
            }
        }
        if (speedStr?.length) {
            list.push(`<p><b>Speed</b> ${speedStr}</p>`);
        }
        if (bfVersion) {
            list.push(otherTraits.join("\n"));
        }
        list.push(attributes.join("\n"));
        list.push('<div style="clear: both"></div>');// this will bound the float for the artwork
        list.push(abilityBlock);
        if (!bfVersion) {
            list.push(otherTraits.join("\n"));
        }
        if (!bfVersion&&(!blankScores || mon.crSort)) {
            list.push(`<p><b>Challenge</b> ${Parser.monCrToFull(mon.cr)}</p>`);
        }
        list.push(featureslist.join("\n"));

        if (mon.trait && (mon.trait.html != "<p></p>")) {
            list.push(`${htmlFromEntry(mon.trait)}`)
        }
        if (mon.action && (mon.action.html != "<p></p>")) {
            list.push(`<h${header+1}>Actions</h${header+1}>${htmlFromEntry(mon.action)}`)
        }
        list.push(actionsList.join("\n"));
        if (mon.bonusaction && (mon.bonusaction.html != "<p></p>")) {
            list.push(`<h${header+1}>Bonus Actions</h${header+1}>${htmlFromEntry(mon.bonusaction)}`)
        }
        if (mon.reaction && (mon.reaction.html != "<p></p>")) {
            list.push(`<h${header+1}>Reactions</h${header+1}>${htmlFromEntry(mon.reaction)}`)
        }
        if (mon.legendary && (mon.legendary.html != "<p></p>")) {
            list.push(`<h${header+1}>Legendary Actions</h${header+1}>${htmlFromEntry(mon.legendary)}`)
        }
        if (mon.lairActions && (mon.lairActions.html != "<p></p>")) {
            list.push(`<h${header+1}>Lair Actions</h${header+1}>${htmlFromEntry(mon.lairActions)}`)
        }
        if (mon.regionalEffects && (mon.regionalEffects.html != "<p></p>")) {
            list.push(`<h${header+1}>Regional Effects</h${header+1}>${htmlFromEntry(mon.regionalEffects)}`)
        }
        if (mon.variant && (mon.variant.html != "<p></p>")) {
            list.push(`${htmlFromEntry(mon.variant)}`)
        }
        if (!bfVersion && mon.enviornment?.length) {
            list.push(`<p><b>Environments.</b> ${mon.environment.join(", ")}</p>`)
        }
    } else {
        list.push("<p>Character sheet not printable.</p>");
    }
    return list.join("\n");
}

function getPrintTemplateFeatures(monObj) {

    let featureslist=[], actionsList=[], attributes=[];

    actionsList.push(getMonAttacks(monObj)||"");

    monObj.traverseFeatures(function (params) {
        const e = params.feature;
        const {level,type, typeValue, usageId,fid, options} = params;
        const usage = e.usage;

        if (usage && (usage.type !="hide")) {
            const fhtml = htmlFromEntry(e.entries,2,false,false,true);
            const hasP = fhtml.includes("<p>");
            featureslist.push(`${hasP?"<div>":"<p>"}${(e.name||typeValue?.displayName)?`<b><i>${(e.name||typeValue?.displayName)}. </i></b>`:""}${fhtml}${hasP?"</div>":"</p>"}`);
        }

        if (e.customAttribute) {
            const prop = fid+ ".te."+e.customAttribute;
            attributes.push(`<p><b>${e.customAttribute}</b> ${options[prop]||"--"}</p>`);
        }
    }, ["fTemplate"])

    actionsList.push(getExtraSpells(monObj)||"");

    return {actionsList, featureslist, attributes};
}

function printNPC(id, noTitle, header) {
    const char = campaign.getMyCharacterInfo(id);
    if (!char) {
        return;
    }
    return printCharacter(new Character(char, "mycharacters", true), noTitle, header);
}

function printCharacter(character,noTitle, header) {
    const list=[];
    if (!noTitle) {
        list.push(`<h${header}>${character.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(character.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        let tokenURL;

        if (character.tokenArt) {
            const art = campaign.getArtInfo(character.tokenArt);
            if (art) {
                tokenURL = art.url;
            }
        }
        const aList = [];

        for (let a of abilitiesValues) {
            const ainfo = character.abilities[a];
            let inside;
            aList.push(`<td width="16.66%">${ainfo.score||""} ${ainfo.score?`(${signedNum(ainfo.modifier)})`:""}</td>`);
        }

        const abilityBlock= `<table width="100%" style="text-align: center"><tr><th>STR</th><th>DEX</th><th>CON</th><th>INT</th><th>WIS</th><th>CHA</th></tr><tr>${aList.join("")}</tr></table>`;

        const senses = getTextFromDistanceStruct(character.senses);

        const otherTraits = [];

        //savings throws
        {
            const ablist =[];
    
            for (let i of abilitiesValues) {
                const ab = character.abilities[i];
                const save = ab.spellSave;
                if (save != ab.modifier) {
                    ablist.push(abilityNames[i.toLowerCase()]+" "+signedNum(Number(save)));
                }
            }
            if (ablist.length) {
                otherTraits.push(`<p><b>Saving Throws</b> ${ablist.join(", ")}</p>`);
            }
        }

        // skills
        {
            const skills = character.skills;
            const slist =[];
            const os = Object.keys(skills).sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())});
        
            for (let i of os) {
                const s = skills[i];
                const ai = character.abilities[s.ability];
                if (ai.modifier != s.modifier) {
                    slist.push(i+" "+signedNum(s.modifier));
                }
            }
    
            if (slist.length) {
                otherTraits.push(`<p><b>Skills</b> ${slist.join(", ")}`);
            }
        }
    
        otherTraits.push(printTextBlock("Damage Vulnerabilities", character.vulnerable));
        otherTraits.push(printTextBlock("Damage Resistances", character.resist));
        otherTraits.push(printTextBlock("Damage Immunities", character.immune));
        otherTraits.push(printTextBlock("Condition Immunities", character.conditionImmune));
        if (senses) {
            otherTraits.push(printTextBlock("Senses", senses||" ", `${senses?", ":""}passive perception ${character.passive}`));
        }
        otherTraits.push(`<p><b>Perception</b> ${character.passive}</p>`);
        
        otherTraits.push(printTextBlock("Languages", character.languages));
        
        const speedStr = getStringFromSpeed(character.speed);

        if (tokenURL) {
            list.push(`<img width="100" height="100" src="${tokenURL}" style="float:right;padding: 5px"/>`)
        }
        list.push(`<div><i>${character.getOriginDisplayName()||""} ${character.backgroundDisplayName||""}</i></div>`);
        list.push(`<p><i>Level ${character.level||0} ${character.classDisplayNames||""}</i></p>`);
        if (character.ac) {
            list.push(`<p><b>Armor Class</b> ${character.ac}</p>`);
        }
        if (character.maxhp) {
            list.push(`<p><b>Hit Points</b> ${character.maxhp}</p>`);
        }
        if (speedStr?.length) {
            list.push(`<p><b>Speed</b> ${speedStr}</p>`);
        }
        list.push('<div style="clear: both"></div>');// this will bound the float for the artwork
        list.push(abilityBlock);
        list.push(otherTraits.join("\n"));
    }
    return list.join("\n");
}

function getExtraSpells(monObj, inlineEdit) {
    const list=[];
    const castable={};
    monObj.traverseFeatures(function (params) { 
        const {feature, fid:base, usageId:optionsBase, options, type, id, typeValue, level} = params;

        const castableSpells = feature.castableSpells||{};

        if (castableSpells) {
            const recoveryType = feature.ritualOnly?"ritual":(feature.recoveryType||"long");
            const spellList = [];
            
            for (let i in castableSpells) {
                const s = castableSpells[i];
                const spell = campaign.getSpell(s.name);
                if (spell && (!s.grantLevel || (s.grantLevel <= (level||1)))) {
                    spellList.push(spell);
                }
            }

            const st = getSpellText(monObj, recoveryType, feature, spellList, castable);
            if (st) {
                list.push(st);
            }
        }

        if (feature.spellPick) {
            const recoveryType = feature.spellPick.ritualOnly?"ritual":(feature.spellPick.recoveryType||"long");
            const spellPick = (options||{})[optionsBase+".spellPick"]||[];
            const spellList = [];

            for (let i in spellPick) {
                const spell = campaign.getSpell(i);
                if (spell) {
                    spellList.push(spell);
                }else {
                    //console.log("can't find spell pick", i);
                }
            }
            const st = getSpellText(monObj, recoveryType, feature, spellList, castable);
            if (st) {
                list.push(st);
            }
        }

        if (feature.extraSpells) {
            for (let i in feature.extraSpells) {
                const spell = feature.extraSpells[i];
                castable[spell] =1;
            }
        }

        if (feature.selectableSpells) {
            for (let i in feature.selectableSpells) {
                const spell = feature.selectableSpells[i];
                castable[spell] =1;
            }
        }

    });


    const levelStr = ((monObj.state.gamesystem=="bf"))?"Circle":"level";

    const sa = monObj.spellcastingAbility||"cha";
    const scLevel = Parser.spLevelToFull(monObj.level);
    const uA = upperFirst(abilityNames[sa]);
    const sAttack = (monObj.abilities[sa]?.modifier||0)+monObj.proficiency;
    let res;
    if (monObj.usePactCasting) {
        res = `<p><i><strong>Spellcasting. </strong></i>The ${monObj.displayName} is a ${scLevel}-level pact spellcaster. Its spellcasting ability is ${uA} (spell save DC ${sAttack+8}, <b>${signedNum(sAttack)}</b> to hit with spell attacks). The ${monObj.displayName} has the following spells:</p>`;
    } else {
        res = `<p><i><strong>Spellcasting. </strong></i>The ${monObj.displayName} is a ${scLevel}-level spellcaster. Its spellcasting ability is ${uA} (spell save DC ${sAttack+8}, <b>${signedNum(sAttack)}</b> to hit with spell attacks). The ${monObj.displayName} has the following spells:</p>`;
    }

    let found;
    let maxLevel=0;
    for (let l=1; l<=9; l++) {
        if (monObj.spellSlots[l-1]) {
            maxLevel=l;
        }
    }

    for (let l=0; l<=maxLevel; l++) {
        const sl=[];
        const slots = l?(monObj.spellSlots[l-1]||0):0;
        for (let i in castable) {
            const s = campaign.getSpell(i);
            if (s && ((s.level||0)==l)) {
                sl.push(`<i><a href="#spell?id=${encodeURIComponent(i)}">${(s.displayName||"").toLowerCase()}</a></i>`);
                found=true;
            }
        }
        const spells = sl.join(", ");
        if (l) {
            if (sl.length || slots) {
                res = res + `<p>${Parser.spLevelToFull(l)} ${levelStr} (${inlineEdit?0:slots} slots): ${spells}</p>`;
            }
        } else if (sl.length) {
            res = res + `<p>Cantrips (at will): ${spells}</p>`;
        }
    }
    if (found) {
        list.push(res)
    }

    if (list.length) {
        return list.join("");
    }
    return null
}

function printTextBlock(label,text, other) {
    if (!text) {
        return ""
    }
    return `<p><b>${label}</b> ${text}${other||""}</p>`;
}

function getMonAttacks(monObj) {
    let uaAttacks=[];
    const unarmedAttacks = monObj.unarmedAttacks;
    let ua="";
    unarmedAttacks.sort(function (a,b) {return (a.name||"unarmed strike").toLowerCase().localeCompare((b.name||"unarmed strike").toLowerCase())});

    for (let i in unarmedAttacks) {
        const ua = unarmedAttacks[i];
        const dmgType = ua.damageType||"bludgeoning";
    
        let damageBonus=0;
        const ability = ua.ability || ["str","dex"];
        if (Array.isArray(ability)) {
            for (let a of ability) {
                damageBonus = Math.max(damageBonus,monObj.getAbility(a).modifier||0);
            }
        } else {
            damageBonus = monObj.getAbility(ability).modifier||0;
        }
    
        const hitBonus = damageBonus + monObj.proficiency;
        damageBonus += monObj.damageBonus;
        const damages =damagesFromExtraDamage(ua.damage+(damageBonus?signedNum(damageBonus):""), dmgType,{});
        monObj.resolveDamages(damages);


        const dmgList = [];
        for (let d of damages||[]) {
            dmgList.push(`${getAverageFromDice(getDiceFromString(d.dmg||"",0,true))} (<b>${d.dmg}</b>) ${d.dmgType}`);
        }

        uaAttacks.push(`<p><b><i>${ua.name}.</i></b> <i>Melee Weapon Attack</i> <b>${signedNum(hitBonus)}</b> to hit, one target. <i>Hit</i>: ${dmgList.join(", ")} damage.</p>`);
    }
    if (uaAttacks.length) {
        return uaAttacks.join("\n");
    }
    return null;
}

class EditMonsterBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state= {};
    }

    updateMonster(mon){
        prepareMonster(mon);
        updateMonsterAttributes(this.props.monster, mon);
        this.props.onChange(mon);
    }

    changeAttribute(a, v){
        if (a=="size" && v=="Custom") {
            this.setState({showMonsterSize:true});
            return;
        }

        let mon=Object.assign({}, this.props.monster);

        if (typeof a == "object") {
            for (let an in a) {
                updateProp(an, a[an]);
            }
        } else {
            updateProp(a,v);
        }

        function updateProp(attribute, value) {
            if (["cability","class"].includes(attribute) && ["(none)", "any"].includes(value)) {
                value=null;
            }

            if ((attribute=="proficiency")&&(value==defaultProficiency(mon))) {
                value = 0;
            }

            if (attribute=="wis") {
                delete mon.passive;
            }
            if (attribute=="dex") {
                delete mon.stealth;
            }
            mon[attribute] = value;
        }
        
        this.updateMonster(mon);
    }

    changeAttributeArray(attribute, value){
        let mon=Object.assign({}, this.props.monster);
        mon[attribute] = [value];
        this.updateMonster(mon);
    }

    render() {
        const mon = this.props.monster;
        if (!mon) {
            return <div>Monster not found</div>
        }
        const bfVersion = (mon.gamesystem=="bf");
        const cr = mon.cr;
        const clist=[];
        let tokenURL;
        if (mon.imageURL) {
            tokenURL=mon.imageURL;
        } else if (mon.tokenArt) {
            const art = campaign.getArtInfo(mon.tokenArt);
            if (art) {
                tokenURL = art.url;
            }
        }
        if (mon.companion) {
            const classes = campaign.getClassesListByName();
            for (let i in classes) {
                clist.push(classes[i].displayName);
            }
        }

        return <div className="f5 nodrag">
            <div className="flex">
                <div className="flex-auto">
                    {!this.props.noname?<div className="flex item-start mb1">
                        <span className="titletext titlecolor b small-caps f2">{mon.displayName}</span>
                    </div>:null}
                    <div className="i pb1">
                        {mon.unique?<span>NPC </span>:null}
                        <PickVal value={mon.size} includeVal="Custom" onClick={this.changeAttribute.bind(this,"size")} values={sizePickList}>
                            <span className="hover-bg-contrast">{Parser.sizeAbvToFull(mon.size)||"{no size}"} {mon.sizeNotes} </span>
                        </PickVal>
                        <MonsterType mon={mon} onChange={this.changeAttribute.bind(this)}/>
                        <PickVal value={mon.alignment} onClick={this.changeAttribute.bind(this,"alignment")} values={allignmentTypeList}>
                            <span className="hover-bg-contrast">{Parser.alignmentAbvToFull(mon)||"{no alignment}"}</span>
                        </PickVal>
                    </div>
                    <div className="titleborder bb bw2 mb1"/>
                    <ArmorClass monster={mon} editable onChange={this.changeAttribute.bind(this, "ac")}/>
                    {mon.unique?<HitPointsMax monster={mon} editable onChange={this.changeAttribute.bind(this, "hp")}/>:<HitPoints monster={mon} editable onChange={this.changeAttribute.bind(this, "hp")}/>}
                    <Movement monster={mon} editable onChange={this.changeAttribute.bind(this, "speed")}/>
                </div>
                <div>
                    <CreatureArt 
                        onPickToken={this.pickToken.bind(this)}
                        onClick={this.props.onClickToken}
                        defaultType="Monster Token"
                        defaultSearch={mon.displayName}
                        defaultName={mon.displayName}>
                        {tokenURL?<img width="100" height="100" src={tokenURL}/>:null}
                    </CreatureArt>
                </div>
            </div>
            <div className="titleborder bb bw2 mb1"/>
            <table className="w-100 tc mb1">
                <thead>
                    <tr className="titlecolor"><th>STR</th><th>DEX</th><th>CON</th><th>INT</th><th>WIS</th><th>CHA</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="16.66%"><Ability ability={mon.str} displayName={mon.displayName} abilityName="strength" editable onChange={this.changeAttribute.bind(this, "str")}/></td>
                        <td width="16.66%"><Ability ability={mon.dex} displayName={mon.displayName} abilityName="dexterity" editable onChange={this.changeAttribute.bind(this, "dex")}/></td>
                        <td width="16.66%"><Ability ability={mon.con} displayName={mon.displayName} abilityName="constitution" editable onChange={this.changeAttribute.bind(this, "con")}/></td>
                        <td width="16.66%"><Ability ability={mon.int} displayName={mon.displayName} abilityName="intelligence" editable onChange={this.changeAttribute.bind(this, "int")}/></td>
                        <td width="16.66%"><Ability ability={mon.wis} displayName={mon.displayName} abilityName="wisdom" editable onChange={this.changeAttribute.bind(this, "wis")}/></td>
                        <td width="16.66%"><Ability ability={mon.cha} displayName={mon.displayName} abilityName="charisma" editable onChange={this.changeAttribute.bind(this, "cha")}/></td>
                    </tr>
                </tbody>
            </table>
            <div className="titleborder bb bw2 mb1"/>
            <SavingsThrows monster={mon} onChange={this.changeAttribute.bind(this, "save")} displayName={mon.displayName}/>
            <Skills monster={mon} onChange={this.changeAttribute.bind(this, "skill")} displayName={mon.displayName}/>
            <TextPlusEdit label="Damage Vulnerabilities" onChange={this.changeAttributeArray.bind(this, "vulnerable")} values={damageTypesList} text={mon.vulnerable?Parser.monImmResToFull(mon.vulnerable):""} editable/>
            <TextPlusEdit label="Damage Resistances" onChange={this.changeAttributeArray.bind(this, "resist")} values={damageTypesList} text={mon.resist?Parser.monImmResToFull(mon.resist):""} editable/>
            <TextPlusEdit label="Damage Immunities" onChange={this.changeAttributeArray.bind(this, "immune")} values={damageTypesList} text={mon.immune?Parser.monImmResToFull(mon.immune):""} editable/>
            <TextPlusEdit label="Condition Immunities" onChange={this.changeAttributeArray.bind(this, "conditionImmune")} values={conditionList} text={mon.conditionImmune?Parser.monImmResToFull(mon.conditionImmune):""} editable/>
            <TextPlusEdit label="Senses" onChange={this.changeAttribute.bind(this, "senses")} values={sensesList} text={mon.senses||""} editable>
                <PickVal 
                    short
                    value={mon.passive}
                    onClick={this.changeAttribute.bind(this,"passive")}
                    values={perceptionValuesList}
                >
                    <span className="hover-bg-contrast">{(mon.senses?", ":"")+"passive perception "+getPassive(mon)}</span>
                </PickVal>
                {bfVersion?<PickVal 
                    short
                    value={getStealth(mon)}
                    onClick={this.changeAttribute.bind(this,"stealth")}
                    values={perceptionValuesList}
                >
                    <span className="hover-bg-contrast"> Stealth {getStealth(mon)}</span>
                </PickVal>:null}
            </TextPlusEdit>
            {bfVersion?<TextPlusEdit label="Stealth Notes" onChange={this.changeAttributeArray.bind(this, "stealthNotes")} text={mon.stealthNotes||""} editable/>:null}
            <TextPlusEdit editable label="Languages" onChange={this.changeAttribute.bind(this, "languages")} values={languagesList} text={mon.languages}/>
            <PickVal isNum value={mon.fixedInitiative} onClick={this.changeAttribute.bind(this,"fixedInitiative")} values={getNumRange(0,30)}>
                <div className="hover-bg-contrast mb1"><b>Initiative</b> {mon.fixedInitiative}</div>
            </PickVal>
            <PickVal isNum value={mon.damageThreshold} onClick={this.changeAttribute.bind(this,"damageThreshold")} values={getNumRange(0,30)}>
                <div className="hover-bg-contrast mb1"><b>Damage Threshold</b> {mon.damageThreshold}</div>
            </PickVal>
            <div className="mv1">
                <PickVal value={cr} onClick={this.changeAttribute.bind(this,"cr")} values={Parser.CRS}>
                    <span className="hover-bg-contrast"><b>Challenge</b> {Parser.monCrToFull(mon.cr)} </span>
                </PickVal>
                <PickVal value={getProficiency(mon)} onClick={this.changeAttribute.bind(this,"proficiency")} isNum values={[1,2,3,4,5,6,7,8,9]}>
                    <span className="ml2 hover-bg-contrast"><b>Proficiency Bonus</b> {getProficiency(mon)} </span>
                </PickVal>
            </div>
            <Environment onChange={this.changeAttribute.bind(this, "environment")} monster={mon} editable/>
            {mon.usages?<div className="stdcontent">
                <h2>Feature Usage</h2>
                <UsageListEdit features={mon.usages} onChange={this.changeAttribute.bind(this, "usages")}/>
            </div>:null}
            <div className="titleborder bb bw2 mv1"/>
            {this.getEntryRenderEdit("trait", "Traits")}
            {this.getEntryRenderEdit("action", "Actions")}
            {this.getEntryRenderEdit("bonusaction", "Bonus Actions")}
            {this.getEntryRenderEdit("reaction", "Reactions")}
            {this.getEntryRenderEdit("legendary", "Legendary Actions")}
            {this.getEntryRenderEdit("lairActions", "Lair Actions")}
            {this.getEntryRenderEdit("regionalEffects", "Regional Effects")}
            {this.getEntryRenderEdit("variant")}
            <div>
                <CheckVal value={!!mon.companion} onChange={this.changeAttribute.bind(this,"companion")} label="Set companion statistics based on character"/>
            </div>
            {mon.companion?<div className="hk-well mv1">
                <div className="mb1">When this creature is added as a character companion, the Hit Points and proficiency will be calculated from the character.</div>
                <div className="mb1">base HP + character ability modifier + HP per level times character level</div>
                <div>
                    <SelectVal className="mr2" value={mon.cbase||0} isNum values={[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]} onClick={this.changeAttribute.bind(this, "cbase")} helperText="base HP"/>
                    <SelectVal className="mr2" value={mon.cability||"(none)"} includeVal="(none)" values={abilityNames} onClick={this.changeAttribute.bind(this, "cability")} helperText="character ability"/>
                    <SelectVal className="mr2" value={mon.cmultiple||1} isNum values={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]} onClick={this.changeAttribute.bind(this, "cmultiple")} helperText="HP per level"/>
                    <SelectVal value={mon.class||"any"} includeVal="any" values={clist} onClick={this.changeAttribute.bind(this, "class")} helperText="character class"/>
                </div>
            </div>:null}
            <div className="pt2">
                <SelectVal value={mon.gamesystem||"5e"} values={gamesystemOptions} onClick={this.changeAttribute.bind(this,"gamesystem")} helperText="Game System"/>
            </div>
            <div>
                <CheckVal value={!!mon.hideBlank} onChange={this.changeAttribute.bind(this,"hideBlank")} label="Hide ability scores"/>
            </div>
            <MonsterSize open={this.state.showMonsterSize} size={mon.size} sizeNotes={mon.sizeNotes} onClose={this.closeMonsterSize.bind(this)}/>
        </div>
    }

    closeMonsterSize(sizeProps) {
        if (sizeProps) {
            this.changeAttribute(sizeProps);
        }
        this.setState({showMonsterSize:false});
    }

    clickLoadToken(tokenURL){
        if (this.props.noClick) {
            return;
        }
        if (this.props.eventSync && tokenURL) {
            this.props.eventSync.emit("showImageHandout", {
                type:"art",
                art:this.props.monster.tokenArt||null,
                url:tokenURL,
                description:this.props.monster.displayName||null,
                imgHeight:560,
                imgWidth:560
            });
        } else {
            this.setState({showPickToken:true});
        }
    }

    pickToken(token) {
        if (token){
            let mon=Object.assign({}, this.props.monster);

            mon.tokenArt = token.name;
            delete mon.imageURL;
            this.updateMonster(mon);
        } 
        this.setState({showPickToken:false});
    }

    changeEntry(field, value) {
        let mon=Object.assign({}, this.props.monster);
        if (value)
            mon[field] = value;
        else
            delete mon[field];
        if (field == "variant"){
            delete mon.dragonCastingColor;
            delete mon.spellcasting;
        } else if (field == "trait"){
            delete mon.spellcasting;
        }
        this.updateMonster(mon);
    }

    getEntryRenderEdit(field, title) {
        let renderEntry = getBasicEntry(this.props.monster[field]);
        let button;

        if ((field=="variant") && (!renderEntry.html)) {
            return null;
        }

        switch (field) {
            case "action":{
                button = <span>
                    <Button className="mb1" onClick={this.showAddAttack.bind(this,true)} size="small" variant="outlined" color="primary">
                        +Attack
                    </Button>{" "}
                    <AddSpells monster={this.props.monster} onAdd={this.onAddSpells.bind(this)}/>
                    {this.getAttackMenu()}
                </span>
                break;
            }
        }

        return <div className="stdcontent">
            {title?<h2>{title} {button} <AddUsageTracking  onAdd={this.onAddUsage.bind(this, field)}/></h2>:null}
            <EntityEditor onChange={this.changeEntry.bind(this, field)} entry={renderEntry} depth="2"/>
        </div>;
    }

    getAttackMenu() {
        const {showAddAttack,anchorEl} = this.state;
        if (!showAddAttack) {
            return null;
        }

        const {getDamageString} = require('./items.jsx');
        const ml=[];
        const equipment = naturalEquip.concat(campaign.getSortedItemsList());
        let last;
        for (let it of equipment) {
            if ((it.rarity=="None"||!it.rarity) && !it.extraFeatures && it.value && it.dmg1) {
                if (["M","R"].includes(it.type) && (last!=it.displayName)) {
                    const damage = getDamageString(it.dmg1);
                    const damage2 = getDamageString(it.dmg2||"");
                    ml.push(<MenuItem key={it.name} onClick={this.addAttack.bind(this, damage,damage2,it)}><span className="flex-auto">{it.displayName}</span><b>{damage}</b></MenuItem>);
                    if (it.displayName != "template") {
                        last = it.displayName;
                    }
                }
            }
        }

        return <Menu 
            open 
            anchorEl={anchorEl} 
            onClose={this.showAddAttack.bind(this,false)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {ml}
        </Menu>
    }

    addAttack(damage, damage2, it) {
        const {monster} = this.props;
        let actionHtml = getBasicEntry(monster.action).html||"";
        let ability="str";

        if ((it.type=="R") || (monster.dex >monster.str)) {
            ability = "dex";
        }
        const baseBonus = Math.trunc((monster[ability]||10)/2)-5;
        const bonus =  baseBonus + getProficiency(monster);
        const damageType=Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||"slashing";
        const displayName = it.displayName;
        if (baseBonus){
            if (damage) {
                damage = damage+signedNum(baseBonus);
            }
            if (damage2) {
                damage2 = damage2+signedNum(baseBonus);
            }
        }
        const average = getAverageFromDice(getDiceFromString(damage||"",0,true));
        const average2 = getAverageFromDice(getDiceFromString(damage2||"",0,true));
        let extraDamage="";
        let group = (it.type=="M")?"Melee Weapon Attack":"Ranged Weapon Attack";
        let range;

        if (it.type == "R") {
            range = "range "+it.range;
        } else {
            range = "reach "+(it.property && it.property.includes("R")?10:5);
            if (it.property && it.property.includes("T")) {
                group = "Melee Weapon or Ranged Weapon Attack";
                range = range + " ft. or range "+it.range;
            }
        }

        if (it.property && it.property.includes("V") && damage2) {
            extraDamage = ` or ${average2} (<b>${damage2}</b>) ${damageType} damage if used with two hands to make a melee attack`;
        }

        actionHtml += `<p><b><i>${displayName}.</i></b> <i>${group}</i>: <b>${signedNum(bonus)}</b> to hit, ${range} ft., one target. <i>Hit</i>: ${average} (<b>${damage}</b>) ${damageType} damage${extraDamage}.</b></p>`;

        this.changeAttribute("action", {html:actionHtml, type:"html"});
        this.showAddAttack(false);
    }

    showAddAttack(showAddAttack,e) {
        this.setState({showAddAttack, anchorEl:showAddAttack?e?.target:null});
    }

    onAddSpells(spells) {
        const {monster} = this.props;
        let actionHtml = getBasicEntry(monster.action).html||"";

        actionHtml += spells;
        this.changeAttribute("action", {html:actionHtml, type:"html"});
    }

    onAddUsage(field, text) {
        const {monster} = this.props;
        let actionHtml = getBasicEntry(monster[field]).html||"";

        actionHtml += text;
        this.changeAttribute(field, {html:actionHtml, type:"html"});

    }
}

const naturalEquip = [
    {name:"natd4", displayName:"template", dmg1:"1d4", type:"M",value:1},
    {name:"natd6", displayName:"template", dmg1:"1d6", type:"M",value:1},
    {name:"natd8", displayName:"template", dmg1:"1d8", type:"M",value:1},
    {name:"nat2d6", displayName:"template", dmg1:"2d6", type:"M",value:1},
    {name:"nat2d8", displayName:"template", dmg1:"2d8", type:"M",value:1},
]


function simplifyMonster(mon) {
    mon.trait = convertEntryToHtml(mon.trait);
    mon.action = convertEntryToHtml(mon.action);
    mon.reaction = convertEntryToHtml(mon.reaction);
    mon.legendary = convertEntryToHtml(mon.legendary);
    mon.lairActions = convertEntryToHtml(mon.lairActions);
    mon.regionalEffects = convertEntryToHtml(mon.regionalEffects);
    mon.variant = convertEntryToHtml(mon.variant);
}

function convertEntryToHtml(entry) {
    if (!entry) {
        return null;
    }

    if (entry.type=="html") {
        return entry;
    }

    const ret = {type:"html", html:htmlFromEntry(entry, 2)};
    return ret;
}

class Ability extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClick(v) {
        if (this.props.onChange){
            this.props.onChange(v);
        }
    };
    
    render() {
        let abilityMod = Parser.getAbilityModNumber(this.props.ability||10);
        if (abilityMod >= 0)
            abilityMod="+"+abilityMod;

        return <span>
            <div 
                className="hover-bg-contrast"
            >
                <PickVal 
                    short={true}
                    value={this.props.ability}
                    onClick={this.handleClick.bind(this)}
                    values={getNumRange(0,30)}
                    disablepick={!this.props.editable}
                >
                    <span>{this.props.ability}<span className="f2"> ({abilityMod})</span></span>
                </PickVal>
            </div>
        </span>
    }
}

class ArmorClass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false
        };
    }

    showMenu(event){
        if (this.props.editable) {
            let acinfo = Parser.acToStruc(this.props.monster.ac);
            this.setState({ showmenu:true, ac:acinfo.ac, extra:acinfo.extra });
        }
    }

    handleClose(savechanges, event) {
        if (savechanges && this.props.onChange){
            this.props.onChange([{ac:this.state.ac, from:[this.state.extra]}]);
        }
        this.setState({showmenu:false});
    };

    onChangeAC(value) {
        this.setState({ac:value});
    }

    onChangeExtra(value) {
        this.setState({extra:value});
    }

    render() {
        const {editable, monster} = this.props;

        if (!editable && !monster.ac) {
            return null;
        }
        const {showmenu, extra, ac} = this.state;

        const shield = (extra||"").toLowerCase().includes("shield");
        let helperMessage;
        let cr = monster.cr;
        if (editable && cr) {
            let crInfo = crStats[cr];
            
            if (crInfo) {
                if (crInfo) {
                    helperMessage = "Based on monster CR of "+cr+
                        ", the monster should have an armor class of "+crInfo.ac+".";
                }
            }
        }

        const acFull = Parser.acToFull(this.props.monster.ac);
            
        return <div className="mb1">
            {editable||acFull?<span className={editable?"hover-bg-contrast":""} onClick={this.showMenu.bind(this)}><b>Armor Class</b> <Renderstring entry={acFull}/></span>:null}
            <Dialog
                open={showmenu}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Armor Class</DialogTitle>
                <DialogContent>
                    <div className="flex items-end">
                        <div className="flex-auto"></div>
                        <SelectVal value={ac} onClick={this.onChangeAC.bind(this)} values={armorClassValuesList} helperText="AC"/>
                        <TextVal
                            helperText="Armor Class Type"
                            text={extra}
                            onChange={this.onChangeExtra.bind(this)}
                            fullWidth
                        />
                        <div className="flex-auto"></div>
                    </div>
                    <div className="mv1 tc">
                        <Button onClick={this.showAddArmor.bind(this,true)} size="small" variant="outlined" color="primary">
                            Pick Armor
                        </Button>
                        <Button className="ml2" onClick={this.toggleShield.bind(this,shield)} size="small" variant="outlined" color="primary">
                            {shield?"Remove Shield":"Add Shield"}
                        </Button>
                    </div>
                    {helperMessage?<div className="mt2 hk-well"><div className="f5 mw7 center lh-copy"></div>{helperMessage}</div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
                {this.getArmorMenu(shield)}
            </Dialog>
        </div>;
    }

    getArmorMenu(shield) {
        const {showAddArmor,anchorEl} = this.state;
        if (!showAddArmor) {
            return null;
        }

        const ml=[];
        const equipment = campaign.getSortedItemsList();
        let last;
        ml.push(<MenuItem key="n" onClick={this.addArmor.bind(this, {displayName:"", ac:10, type:"LA"},shield)}><span className="flex-auto">None</span></MenuItem>);
        for (let it of equipment) {
            if ((it.rarity=="None"||!it.rarity) && !it.extraFeatures && it.value &&it.ac && it.displayName) {
                if (["LA","MA", "HA"].includes(it.type) && (last!=it.displayName)) {
                    ml.push(<MenuItem key={it.name} onClick={this.addArmor.bind(this, it,shield)}><span className="flex-auto">{it.displayName}</span><b>AC{it.ac}</b></MenuItem>);
                    last = it.displayName;
                }
            }
        }

        return <Menu 
            open 
            anchorEl={anchorEl} 
            onClose={this.showAddArmor.bind(this,false)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {ml}
        </Menu>
    }

    addArmor(it,shield) {
        const {monster} = this.props;
        let ac,extra;

        ac = Number(it.ac);
        const dexMod = Math.trunc((monster.dex||10)/2)-5;
        if (it.type == "LA") {
            ac += dexMod;
        } else {
            ac += Math.min(2, dexMod);
        }
        if (shield) {
            ac+=2;
        }
        extra = it.displayName.toLowerCase();
        if (shield) {
            if (extra.length) {
                extra += ", shield";
            } else {
                extra = "shield";
            }
        }
        this.setState({showAddArmor:false, ac, extra});
    }

    showAddArmor(showAddArmor,e) {
        this.setState({showAddArmor, anchorEl:showAddArmor?e?.target:null});
    }

    toggleShield(shield) {
        let {ac, extra} = this.state;

        if (shield) {
            extra = extra.replace(/,*\s*shield/i, "");
            ac -= 2;
        } else {
            if (extra) {
                extra += ", shield";
            } else {
                extra = "shield";
            }
            ac+=2;
        }
        this.setState({ac, extra});
    }
}

class HitPoints extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false
        };
    }

    showMenu(event){
        if (this.props.editable) {
            const hp = Object.assign({},this.props.monster.hp||{});
            delete hp.special;

            this.setState({ showmenu:true, formula:hp.formula||hp.average||"", hp});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges && this.props.onChange){
            let newHP = Object.assign({}, this.state.hp);
            this.props.onChange(newHP);
        }
        this.setState({showmenu:false});
    };

    onChange(prop, val) {
        const hp=Object.assign({}, this.state.hp);
        const newstate = {hp};

        hp[prop]=val;

        hp.average = getAverageFromDice(getDiceFromString(hp.formula||"",0,true),true);
        this.setState(newstate);
    }

    render() {
        const {editable, monster,bf, hpMod}=this.props;
        const propHp = monster.hp;
        if (!editable && !(propHp?.average||propHp?.special||propHp?.formula)) {
            return null;
        }

        let hp = this.state.hp;
        let helperMessage;
        let clist=[];

        if (this.state.showmenu) {
            helperMessage = getHitPointHelperMessage(monster);
            
            const classes = campaign.getClassesListByName();
            for (let i in classes) {
                clist.push(classes[i].displayName);
            }
        }

        return <div className="mb1">
            <span className={editable?"hover-bg-contrast":""} onClick={this.showMenu.bind(this)}>
                <b>Hit Points </b>
                {getHPString(monster, 0, bf)}
            </span>
            {this.state.showmenu?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Hit Points</DialogTitle>
                <DialogContent>
                    <TextVal  
                        helperText="Formula" 
                        text={hp.formula} 
                        onChange={this.onChange.bind(this,"formula")}
                        fullWidth
                    />
                    <div className="pv2 tc">Average Hit Points {hp.average}</div>
                    {helperMessage?<div className="hk-well mb2">{helperMessage}</div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </div>;
    }
}

function getHPString(mon, hpMod, bf) {
    const hp = mon.hp;
    if (!hp) {
        if (hpMod) {
            return hpMod.toString();
        }
        return null;
    }
    let ret="";
    if (!mon.unique) {
        if (hp.special) {
            return hp.special;
        }
        ret = hpMod?(Number(hp.average||0)+hpMod).toString():hp.average||"";
        if (!bf && (hp.formula||"").length && (hp.formula != hp.formula)) {
            ret = `${ret} (${propHp.formula})`
        }
    } else if (hp.maxHP || hpMod) {
        ret = (Number(hp.maxHP||0)+(hpMod||0)).toString();
    }
    return ret;
}

class HitPointsMax extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false
        };
    }

    showMenu(event){
        if (this.props.editable) {
            const hp= this.props.monster.hp||{};
            this.setState({ showmenu:true, curHP:hp.curHP||0, maxHP:hp.maxHP||1});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges && this.props.onChange){
            this.props.onChange({curHP:this.state.curHP, maxHP:this.state.maxHP});
        }
        this.setState({showmenu:false});
    };

    onChange(prop, val) {
        const newstate = {};
        if (prop == "maxHP" && this.state.curHP == this.state.maxHP) {
            newstate.curHP = val;
        }
        newstate[prop]=val;

        this.setState(newstate);
    }

    render() {
        if (!this.props.editable && !this.props.monster.hp)
            return null;

        let helperMessage;
        if (this.props.editable) {
            helperMessage = getHitPointHelperMessage(this.props.monster);
        }

        return <div className="mb1">
            <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.showMenu.bind(this)}>
                <b>Hit Points </b>
                {this.props.monster.hp.maxHP||1}
            </span>
            <Dialog
                open={this.state.showmenu}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Hit Points</DialogTitle>
                <DialogContent>
                    <div className="flex mb2">
                        <div className="flex-auto"></div>
                        <TextVal isNum 
                            helperText="Current HP" 
                            text={this.state.curHP||0} 
                            onChange={this.onChange.bind(this,"curHP")}
                            className="w45"
                        />
                        <TextVal isNum 
                            text={this.state.maxHP} 
                            helperText="Maximum HP" 
                            onChange={this.onChange.bind(this, "maxHP")} 
                            className="w45"
                        />
                        <div className="flex-auto"></div>
                    </div>
                    {helperMessage?<div className="hk-well"><div className="f5 mw7 center lh-copy"></div>{helperMessage}</div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

function getHitPointHelperMessage(mon) {
    if (!mon) {
        return null;
    }
    let cr = mon.cr;
    if (cr) {
        let crInfo = crStats[cr];
        
        if (crInfo) {
            if (crInfo) {
                let mult=1;
                let multMsg = "";

                if (mon.immune) {
                    mult = crInfo.hpImmunMult;
                    multMsg = " and a "+mult+"X divisor for damage immunity"
                } else if (mon.resist){
                    mult = crInfo.hpImmunMult;
                    multMsg = " and a "+mult+"X divisor for damage resistance"
                }

                if (mult==1)
                    multMsg="";

                return "Based on monster CR of "+cr+multMsg+
                    ", the monster should have between "+Math.trunc(crInfo.hpmin/mult)+
                    " and "+Math.trunc(crInfo.hpmax/mult)+" hit points.";
            }
        }
    }
    return null;
}

const dieSidesByType={
    4:"d4",
    6:"d6",
    8:"d8",
    10:"d10",
    12:"d12",
    20:"d20"
}

class SavingsThrowsRollable extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {rollable, monObj} = this.props;
        const list =[];

        for (let i of abilitiesValues) {
            const ab = monObj.abilities[i];
            const save = ab.spellSave;
            if (save != ab.modifier) {
                list.push(<span key={i}>
                    {list.length?", ":""}
                    <span className={rollable?"hoverroll ttc":"ttc"} onClick={rollable?this.doRoll.bind(this, abilityNames[i], save):null}>{abilityNames[i.toLowerCase()]} {signedNum(Number(save))}</span>
                </span>);
            }
        }
        if (!list.length) {
            return null;
        }

        return <div className="mv1">
            <b>Saving Throws</b> {list}
        </div>;
    }

    doRoll(name, mod, event) {
        const {character, crow, monObj} = this.props;
        let abilityMod = Number(mod);
        const dice = {D20:1, bonus:abilityMod};
        const {rolls} = doRoll(dice);
        const newRoll = {dice, rolls, source:name, action:"Save"};
        Chat.addD20BonusRolls(character, newRoll, monObj.d20Bonuses.saveDice);

        if (character) {
            newRoll.playerDisplayName=monObj.displayName;
            Chat.addCharacterRoll(character, newRoll,null,true);
        } else if (crow) {
            Chat.addRoll(crow, newRoll)
        } else {
            newRoll.playerDisplayName=monObj.displayName;
            Chat.addGMRoll(newRoll);
        }
    }
}

class SavingsThrows extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, save:this.props.monster.save||{}});
    }

    handleClose(savechanges, event) {
        if (savechanges && this.props.onChange){
            let {save}=this.state;
            if (!Object.keys(save).length) {
                save = null;
            }
            this.props.onChange(save);
        }
        if (!savechanges) {
            this.setState({showmenu:false});
        } else {
            this.setState({showmenu:false});
        }
        event.stopPropagation();
    };

    onChangeSave(ability, defaultv, value) {
        const {monster}=this.props;
        const save = Object.assign({}, this.state.save||{});
        if (value == defaultv) {
            delete save[ability];
        } else {
            save[ability]=value;
        }
        this.setState({save});
    }

    render() {
        const {monster} = this.props;
        const saves = monster.save;
        const {showmenu, save} = this.state;
        const list =[];
        let inside=[];
    
        if (saves) {
            for (let i of abilitiesValues) {
                if (saves[i] != null) {
                    list.push(<span key={i}>
                        {list.length?", ":""}
                        <span className="ttc">{abilityNames[i.toLowerCase()]} {signedNum(Number(saves[i]))}</span>
                    </span>);
                }
            }
        }

        if (showmenu) {
            const prof = getProficiency(monster);
            for (let a of abilitiesValues) {
                const notSet = (save[a] == null);
                const defaultv = Math.trunc(Number((monster[a]||10))/2)-5;
                const val = notSet?defaultv:save[a];
                const proficient = (val>=(defaultv+prof));
                const values = [];
                for (let v=-5; v<30; v++) {
                    values.push({name:(v==defaultv)?<span className="gray-80">{signedNum(v)} *</span>:signedNum(v), value:v})
                }

                inside.push(<tr key={a} className="mb1">
                    <td>{a.toUpperCase()}</td>
                    <td><SelectVal className="mh2 mw4" value={val} values={values} onClick={this.onChangeSave.bind(this, a, defaultv)}/></td>
                    <td><CheckVal value={proficient} onChange={this.onChangeSave.bind(this,a, defaultv, proficient?defaultv:defaultv+prof)} label="proficient"/></td>
                </tr>);
            }
        }
        
        return <div className="mv1">
            <span className="hover-bg-contrast" onClick={this.showMenu.bind(this)}>
                <b>Saving Throws</b> {list}
            </span>
            <Dialog
                open={!!showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Saving Throws</DialogTitle>
                <DialogContent>
                    <table className="w-100">
                        <tbody>
                            {inside}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class Environment extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null, 
            environment:props.monster.environment||[]
        };
    }

    showMenu(event){
        if (this.props.editable) {
            this.setState({ showmenu:true, anchorEl:event.currentTarget });
        }
    }

    handleClose(savechanges, event) {
        if (savechanges && this.props.onChange){
            this.props.onChange(this.state.environment);
        }
        if (!savechanges) {
            this.setState({showmenu:false, environment:this.props.monster.environment||[]});
        } else {
            this.setState({showmenu:false});
        }
        event.stopPropagation();
    };

    onChange(event) {
        const val = event.target.value;
        let newenvironment;

        if (this.state.environment.includes(val)) {
            newenvironment=this.state.environment.filter(function (e) { return e!=val});
        } else {
            newenvironment = this.state.environment.concat([val]);
        }
        this.setState({environment:newenvironment});
    }

    getEnvironmentsMenus() {
        let i, ret=[];
        const checkStyle={width:"105px"};
    
        for (i in environmentTypeList){
            const e=environmentTypeList[i];
            ret.push( <FormControlLabel
                control={<Checkbox checked={this.state.environment.includes(e)} onChange={this.onChange.bind(this)} value={e} />}
                label={e} style={checkStyle} key={e}
            />);
        }
        return ret;
    }

    render() {
        if (!this.props.editable && !this.props.monster.environment)
            return null;
        
        return <div className="mv1">
            <span className={this.props.editable?"hover-bg-contrast titletext i":"titletext i"} onClick={this.showMenu.bind(this)}><b>Environments.</b> {(this.props.monster.environment||[]).join(", ")}</span>
            <Dialog
                open={this.state.showmenu}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Monster Environments</DialogTitle>
                <DialogContent>
                    {this.getEnvironmentsMenus()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class Movement extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null,
            speed:props.monster.speed
        };
    }

    showMenu(event){
        if (this.props.editable) {
            this.setState({ showmenu:true, anchorEl:event.currentTarget });
        }
    }

    handleClose(savechanges, event) {
        event.stopPropagation();

        if (savechanges && this.props.onChange){
            this.props.onChange(this.state.speed);
        }

        if (!savechanges) {
            this.setState({showmenu:false, speed:Object.assign({}, this.props.monster.speed)});
        } else {
            this.setState({showmenu:false});
        }
    };

    onChange(speed) {
        this.setState({speed:speed});
    }

    render() {
        if (!this.props.editable && !this.props.monster.speed)
            return null;
        
        return <div className="mb1">
            <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.showMenu.bind(this)}><b>Speed</b> {getStringFromSpeed(getNormalizedSpeed(this.props.monster.speed))}</span>
            <Dialog
                open={this.state.showmenu}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Movement Speeds</DialogTitle>
                <DialogContent>
                    <EditSpeed speed={this.state.speed} onChange={this.onChange.bind(this)} speedtype='walk'/>
                    <EditSpeed speed={this.state.speed} onChange={this.onChange.bind(this)} speedtype='burrow'/>
                    <EditSpeed speed={this.state.speed} onChange={this.onChange.bind(this)} speedtype='climb'/>
                    <EditSpeed speed={this.state.speed} onChange={this.onChange.bind(this)} speedtype='fly'/>
                    <EditSpeed speed={this.state.speed} onChange={this.onChange.bind(this)} speedtype='swim'/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class EditSpeed extends React.Component {
    onChange(field, event) {
        let speed = this.getSpeedVals();
        let val = event.target.value;
        let newSpeed;

        if (field == 'speed') {
            if (!val) {
                speed.number = null;
            } else if (/[0-9]*/.exec(val) == val) {
                speed.number = val;
            } 
        } else { // field should be "notes"
            speed.condition = val;
        }

        if (typeof this.props.speed == 'object') {
            newSpeed = Object.assign({}, this.props.speed);
        } else {
            newSpeed = {walk:this.props.speed};
        }

        if (!speed.number && !speed.condition){
            delete newSpeed[this.props.speedtype];
        } else {
            newSpeed[this.props.speedtype] = speed;
        }

        if (this.props.onChange)
            this.props.onChange(newSpeed);
    }

    getSpeedVals() {
        return getSpeedVals(this.props.speed, this.props.speedtype);
    }

    render() {
        let val = this.getSpeedVals()
        const speed=val.number, notes=val.condition;

        return <div className="mv1 flex items-end">
        <div className="ttc pa2 w4">{this.props.speedtype}</div>
        <TextField
          label="Speed (ft.)"
          value={speed}
          onChange={this.onChange.bind(this, "speed")}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          style={{marginLeft:10,marginRight:10, width:64}}
        />
        <TextField
          label="Notes"
          value={notes}
          onChange={this.onChange.bind(this, "notes")}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          style={{marginLeft:10,marginRight:10, width:160}}
        />
        </div>;
    }
}

const speedTypes = ['walk','burrow','climb','fly','swim'];

function getNormalizedSpeed(speed) {
    const ns = {};
    for (let s of speedTypes) {
        const sv = getSpeedVals(speed, s);
        if (sv.number || sv.condition) {
            ns[s] = sv;
        }
    }
    return ns;
}

function mergeSpeedVals(s1, s2) {
    const ns = Object.assign({},s1);
    for (let s in s2) {
        const s1V = s1[s];
        const s2V = s2[s];
        if (!s1V) {
            ns[s]=s2V;
        } else if (s2V.number > s1V.number) {
            const nsv = Object.assign({},s1V);
            nsv.number = s2V.number;
            ns[s]=nsv;
        }
    }
    return ns;
}

function getStringFromSpeed(speed) {
    const list = [];
    for (let s of speedTypes) {
        const sv = speed[s];
        if (sv?.number || sv?.condition) {
            list.push(((s=="walk")?"":(s+" "))+(sv.number||0)+" ft."+(sv.condition?" "+sv.condition:""));
        }
    }
    return list.join(", ");
}

function getSpeedVals(speedVal, speedtype) {
    let speed;
    let notes;
    let monspeed;

    if (!speedVal) {
        return {number:0, condition:""};
    }

    if (typeof speedVal == 'object') {
        monspeed = speedVal[speedtype];
    } else {
        if (speedtype == 'walk') {
            monspeed = speedVal;
        } else {
            monspeed = "";
        }
    }

    if (typeof monspeed =='object') {
        speed = monspeed.number || "0";
        notes = monspeed.condition || "";
    } else {
        speed=monspeed || "";
        notes="";
    }
    return {number:speed, condition:notes};
}

class SkillsRollable extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {monObj, rollable} = this.props;
        const mskill = monObj.state.skill;
        const skills = monObj.skills;
        const list =[];
        const sn = Object.keys(mskill||{}).sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())});
        const os = Object.keys(skills).sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())});
        const handled = {};
    
        for (let i of sn) {
            const skillName=monsterSkillsMap[i.toLowerCase()]||i;
            const val = skills[skillName]?.modifier || mskill[i];
            handled[skillName] = true;
            list.push(<span key={i}>
                {list.length?", ":""}
                <span className={rollable?"hoverroll ttc":"ttc"} onClick={rollable?this.doRoll.bind(this, skillName, val):null}>{skillName} {signedNum(Number(val))}</span>
            </span>);
        }
        for (let i of os) {
            const s = skills[i];
            const ai = monObj.abilities[s.ability];
            if (!handled[i] && (ai.modifier != s.modifier) && ((i!="Stealth")||(monObj.state.gamesystem != "bf"))) {
                list.push(<span key={i}>
                    {list.length?", ":""}
                    <span className={rollable?"hoverroll ttc":"ttc"} onClick={rollable?this.doRoll.bind(this, i, s.modifier):null}>{i} {signedNum(s.modifier)}</span>
                </span>);
            }
        }

        if (list.length) {
            return <div className="mb1">
                <b>Skills</b> {list}
            </div>;
        }
        return null;
    }

    doRoll(name, mod,event) {
        const {character, displayName, crow, monObj} = this.props;
        let skillMod = Number(mod);
        const dice = {D20:1, bonus:skillMod};
        const {rolls} = doRoll(dice);
        const newRoll = {dice, rolls, source:name, action:"Check"};
        Chat.addD20BonusRolls(character, newRoll, monObj.d20Bonuses.skillDice);

        if (character) {
            newRoll.playerDisplayName=displayName;
            Chat.addCharacterRoll(character, newRoll, null,true);
        } else if (crow) {
            Chat.addRoll(crow, newRoll)
        } else {
            newRoll.playerDisplayName=displayName;
            Chat.addGMRoll(newRoll);
        }
    }
}

class Skills extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, skill:this.props.monster.skill||{} });
    }

    handleClose(savechanges, event) {
        event.stopPropagation();

        if (savechanges && this.props.onChange){
            let {skill} = this.state;
            if (!Object.keys(skill).length) {
                skill = null;
            }

            this.props.onChange(skill);
        }

        if (!savechanges) {
            this.setState({showmenu:false, skill:Object.assign({}, this.props.monster.skill||{})});
        } else {
            this.setState({showmenu:false});
        }
    };

    onChangeSkill(skillName, defaultv, value) {
        const {monster}=this.props;
        skillName=skillName.toLowerCase();
        let skill=Object.assign({}, this.state.skill);
        if (value == defaultv) {
            delete skill[skillName];
        } else {
            skill[skillName]=value;
        }
        this.setState({skill});
    }

    render() {
        const {monster} = this.props;
        const {showmenu, skill} = this.state;
        let skills = [];
        let inside=[];

        if (showmenu) {
            const skillVals = campaign.getAllSkillsWithAbilities();
            const prof = getProficiency(monster);

            for (let i in skillVals){
                const s = skillVals[i];

                const sval = skill[s.skill.toLowerCase()];
                const notSet = (sval == null);
                const defaultv = Math.trunc(Number((monster[s.mod]||10))/2)-5;
                const val = notSet?defaultv:Number(sval);
                const proficient = (val>=(defaultv+prof));
                const values = [];
                for (let v=-5; v<30; v++) {
                    values.push({name:(v==defaultv)?<span className="gray-80">{signedNum(v)} *</span>:signedNum(v), value:v})
                }

                inside.push(<tr key={i} className="mb1">
                    <td>{s.mod.toUpperCase()} - {s.skill}</td>
                    <td><SelectVal className="mh2 mw4" value={val} values={values} onClick={this.onChangeSkill.bind(this, s.skill, defaultv)}/></td>
                    <td><CheckVal value={proficient} onChange={this.onChangeSkill.bind(this,s.skill, defaultv, proficient?defaultv:defaultv+prof)} label="proficient"/></td>
                </tr>);
            }
        }

        const mskill = monster.skill;
        const list =[];
        const sn = Object.keys(mskill||{});
        sn.sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())})
    
        for (let i of sn) {
            const skillName=monsterSkillsMap[i.toLowerCase()]||i;
            list.push(<span key={i}>
                {list.length?", ":""}
                <span className="ttc">{skillName} {signedNum(Number(mskill[i]))}</span>
            </span>);
        }

        
        return <div className="mb1">
            <span className="hover-bg-contrast" onClick={this.showMenu.bind(this)}>
                <b>Skills</b> {list}
            </span>
            <Dialog
                open={!!showmenu}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Skills</DialogTitle>
                <DialogContent>
                    <table className="w-100">
                        <tbody>
                            {inside}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class MonsterType extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    showMenu(event){
        const {mon}=this.props;
        this.setState({ showmenu:true, anchorEl:event.currentTarget, editType:Parser.monTypeToFullObj(mon.type), fTemplate:mon.fTemplate, options:mon.featureOptions});
    }

    handleClose(savechanges, event) {
        event.stopPropagation();

        if (savechanges){
            const {editType,fTemplate,options} = this.state;
            const {onChange} = this.props;

            onChange({
                "type":{type:(editType.type||"").trim(), tags:editType.tags},
                "fTemplate":fTemplate||null,
                "featureOptions":options||null
            });
        }

        this.setState({showmenu:false});
    };

    onChangeVal(prop,val) {
        const editType = Object.assign({}, this.state.editType);
        editType[prop]=val;
        this.setState({editType});
    }

    render() {
        const {mon} = this.props;
        const {type} = mon;
        const {showmenu, editType,showPickTemplate,showConfig,options, fTemplate:ftVal} = this.state;
        let inside,monObj;

        if (showmenu) {
            const {CustomPicker} = require('./customtable.jsx');
            const fTemplate = campaign.getCustom(fTemplateName,ftVal);
            const selectedTemplate = {};
            const basicTypes=monsterBasicTypeList.concat([]), tagOptions=[];
            const allMonsters = campaign.getAllMonsters();
            const eType = (editType.type||"").toLowerCase();
            const list=[];


            if (fTemplate) {
                selectedTemplate[fTemplate.id] = 1;
                const monUpdate = Object.assign({}, mon);
                monUpdate.featureOptions = options||{};
                monUpdate.fTemplate = ftVal;

                monObj = new MonObj(monUpdate, null, true, this.onChangeMonOptions.bind(this));

                const baseName ="f.";
                const r = fTemplate.features;
                const opts=[];
                const crLevel = Math.min(30,Math.trunc(monUpdate.crsort));

                list.push(<div key="level" className="mb1">
                    <SelectVal value={options?.levelOverride||0} isNum fullWidth values={[{name:`Based on CR (Level ${crLevel})`, value:0}].concat(oneTo20)} onClick={this.changeOptionVal.bind(this, monObj,"levelOverride")} helperText="Level"/>
                </div>);

                for (let i in r) {
                    const f= r[i];
                    if (hasFeatureConfig(f) && monObj.checkRestriction(f.restriction)) {
                        const featureBaseName = baseName+(f.id||f.name||"");
                        list.push(<FeatureConfigure key={featureBaseName+i}
                            character={monObj}
                            feature={f}
                            baseName={featureBaseName}
                            options={options||{}}
                            onChange={this.changeOptionVal.bind(this,monObj)}
                        />);
                    }
                }
    
            }
    
            for (let i in allMonsters) {
                const m = allMonsters[i];
                const mType = Parser.monTypeToFullObj(m.type);
                if (mType.type?.length) {
                    const ltype = mType.type.toLowerCase();
                    if (!basicTypes.includes(ltype)) {
                        basicTypes.push(ltype);
                    }
                    if (ltype == eType) {
                        for (let tag of mType.tags) {
                            const ltag=tag.toLowerCase();
                            if (!tagOptions.includes(ltag)) {
                                tagOptions.push(ltag);
                            }
                        }
                    }
                }
            }
            basicTypes.sort();
            tagOptions.sort();
            inside=<div>
                <SelectTextVal text={editType.type} values={basicTypes} onChange={this.onChangeVal.bind(this,"type")} fullWidth helperText="Type"/>
                <SelectMultiTextVal freeSolo value={editType.tags} values={tagOptions} onChange={this.onChangeVal.bind(this,"tags")} fullWidth helperText="Sub-Types"/>
                {fTemplate?<div className="mt2">
                    <a onClick={this.showOptionsConfig.bind(this,true)}>{fTemplate.displayName}</a>
                    <Button className="ml2" color="secondary" variant="outlined" size="small" onClick={this.onClosePickTemplate.bind(this,{})}>Delete</Button>
                    <div className="stdcontent">
                        {list}
                    </div>
                </div>:null}
                <CustomPicker 
                    open={showPickTemplate}
                    known={1}
                    type={fTemplateName} 
                    onClose={this.onClosePickTemplate.bind(this)}
                    selected={selectedTemplate}
                />
                <OptionsConfig open={showConfig} monObj={monObj} onClose={this.showOptionsConfig.bind(this,false)}/>
            </div>
        }

        return <span>
            <span className="hover-bg-contrast" onClick={this.showMenu.bind(this)}>
                {(Parser.monTypeToFullObj(type).asText||"{unassigned}")}
            </span>
            ,&nbsp;
            <Dialog
                open={!!showmenu}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Monster Type</DialogTitle>
                <DialogContent>
                    {inside}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.showPickTemplate.bind(this)}>Custom Stat Block</Button>
                    <Button disabled={!editType?.type?.length} onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>;
    }

    onChangeMonOptions(mon) {
        this.setState({options:mon.featureOptions||null});
    }

    changeOptionVal(monObj, option, value) {
        const options = monObj.featureOptions;
        const opts = Object.assign({}, options);

        opts[option] = value;

        monObj.setProperty("featureOptions", opts);
    }

    showPickTemplate() {
        this.setState({showPickTemplate:true});
    }

    onClosePickTemplate(selectedCustom) {

        if (selectedCustom) {
            const fTemplate = Object.keys(selectedCustom)[0]||null;
            const t = campaign.getCustom(fTemplateName,fTemplate);

            this.setState({fTemplate});
            if (t) {
                this.onChangeVal("type",t.displayName);
            }
        }
        this.setState({showPickTemplate:false});
    }

    showOptionsConfig(showConfig) {
        this.setState({showConfig});
    }
}

class MonsterSize extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        const {open, size, sizeNotes}=this.props;
        if (open && (open!= prevProps.open)) {
            this.setState({size, sizeNotes});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            const {size, sizeNotes} = this.state;

            this.props.onClose({size, sizeNotes});
        }
        this.props.onClose(null);
    };

    onChangeVal(prop,val) {
        const state={};
        state[prop]=val;
        this.setState(state);
    }

    render() {
        const {open}= this.props;
        if (!open) {
            return null;
        }
        const {size,sizeNotes} = this.state;

        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Monster Size</DialogTitle>
            <DialogContent>
                <SelectVal value={size} fullWidth values={sizeList} onClick={this.onChangeVal.bind(this, "size")} helperText="size"/>
                <TextVal
                    helperText="Size Notes"
                    text={sizeNotes||""}
                    onChange={this.onChangeVal.bind(this, "sizeNotes")}
                    fullWidth
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    }
}

class MonsterDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state= {monster:campaign.getMonster(props.monster)};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open!= prevProps.open)||(this.props.monster!=prevProps.monster)) {
            this.setState({editable:false,monster:campaign.getMonster(this.props.monster)});
        }
    }

	render() {
        let mon = this.state.monster;
        if (!this.props.open || !mon) {
            return null;
        }

        if (mon.npc && (this.props.editable||this.state.editable)) {
            const {DialogPlayer} = require('./renderplayers.jsx');
            return <DialogPlayer show name={mon.name} type="monsters" hideDice onClose={this.props.onClose}/>;
        }
        const {PickRandomEncounterInfoDialog,PickRandomCount} = require('./renderrandomtables.jsx');
        const {extraButtonsFn} = this.props;

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>
                {(this.props.editable||this.state.editable)?<TextVal    
                    text={mon.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeName.bind(this)}
                />:<div className="flex"><div className="flex-auto">{mon.displayName}</div>{campaign.defaultGamesystem=="bf"?<div>CR {Parser.monCrOnly(mon.cr)}</div>:null}</div>}
            </DialogTitle>
            <DialogContent key={mon?.name}>
                <div>
                    <MonsterBlock 
                        noname 
                        monster={mon} 
                        showInstantRoll 
                        rollable={!!this.props.doSubRoll} 
                        doSubRoll={this.props.doSubRoll} 
                        editable={this.props.editable||this.state.editable} 
                        onChange={this.onChange.bind(this)}
                        override={this.props.override} 
                        onClickToken={this.showExtraArt.bind(this)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {!this.props.editable && !this.state.editable && this.props.getDiceRoller?this.props.getDiceRoller():null}
                {!this.props.editable && !this.state.editable?<AddChatEntry type="Monster" displayName={mon.displayName} href={getBasicHref("monster",mon.name)}/>:null}
                {!this.props.editable && !this.state.editable && extraButtonsFn && mon && extraButtonsFn(mon.name)}
                {this.props.character&&!this.props.editable&&!this.state.editable?<Button onClick={this.clickShapeChange.bind(this)} color="primary">
                    Shape Change
                </Button>:null}
                {this.props.character&&!this.props.editable&&!this.state.editable?<Button onClick={this.clickAddCompanion.bind(this)} color="primary">
                    Add companion
                </Button>:null}
                {this.props.addToEncounter&&!this.props.editable&&!this.state.editable?<Button onClick={this.clickAddMonster.bind(this)} color="primary">
                    ADD TO ENCOUNTER
                </Button>:null}
                {!this.props.editable&&!this.state.editable&&!campaign.isSharedCampaign()?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                {(this.props.editable||this.state.editable)?<Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>:null}
                {(this.props.editable||this.state.editable)?<Button disabled={!mon.displayName} onClick={this.clickSave.bind(this)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <PickRandomEncounterInfoDialog open={this.state.showAddMonster} row={this.state.addMonsterRow} onClose={this.closeAddMonster.bind(this)} addToEncounter={this.props.addToEncounter}/>
            <PickRandomCount open={this.state.showAddCompanion} dice={this.state.addDice} onClose={this.closeAddCompanion.bind(this)} title="Add Companion">
                {mon.displayName}
            </PickRandomCount>
            <ArtZoomList editable open={this.state.showExtraArtDialog} artList={getMonsterArtList(mon)} onClose={this.onSaveArtwork.bind(this)} pickToken defaultArt={mon.defaultArt} defaultToken={mon.tokenArt} defaultSearch={mon.displayName} defaultType="Monster Token"/>
        </Dialog>;
    }

    showExtraArt() {
        if (this.props.editable|| this.state.editable) {
            this.setState({showExtraArtDialog:true});
        }
    }

    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            let mon=Object.assign({}, this.state.monster);
            mon.artList=artList||null;
            mon.defaultArt=defaultArt||null;
            mon.tokenArt = defaultToken||null;
            if (defaultToken) {
                delete mon.imageURL;
            }
            this.onChange(mon);
        }
        this.setState({showExtraArtDialog:false})
    }

    clickShapeChange() {
        const mon = this.state.monster;
        const character = this.props.character;
        simplifyMonster(mon, true);

        character.setShapeWithHistory(mon);
        displayMessage("Successfully changed shape");

        this.props.onClose();
    }

    clickAddCompanion() {
        const {previousDice} = this.props;
        const dice = getDiceFromString(previousDice||1,0,true);
        this.setState({showAddCompanion:true, addDice:dice});
    }

    closeAddCompanion(count) {
        if (count) {
            this.doAddCompanion(count);
            displayMessage("Successfully added to companions");
        }
        this.setState({showAddCompanion:false});
    }
    
    doAddCompanion(count) {
        const character = this.props.character;
        const companions = Object.assign({}, character.companions);

        const m = this.state.monster;
        for (let i=0; i<count; i++) {
            const newm = Object.assign({},m);
            newm.hp=Object.assign({},newm.hp);
            if (!newm.unique) {
                newm.hp.maxHP =m.hp.average;
                newm.unique=true;
            }
            setupCompanion(newm, character);
            newm.hp.curHP = (newm.hp.maxHP||1);
            newm.id = campaign.newUid();
            companions[newm.id] = newm;
        }

        character.companions=companions;

        this.props.onClose();
    }

    onChangeName(name){
        const mon = Object.assign({},this.state.monster);
        mon.displayName=name;
        this.setState({monster:mon});
    }

    onChange(monster){
        this.setState({monster});
    }

    clickAddMonster() {
        const row = {monsters:{}};
        const {previousDice} = this.props;
        const dieRoll={};
        if (previousDice) {
            dieRoll.dice = getDiceFromString(previousDice,0,true);
        } else {
            dieRoll.diePlus=1;
        }
        row.monsters[this.state.monster.name]=dieRoll;
        this.setState({showAddMonster:true, addMonsterRow:row});
    }

    closeAddMonster(row) {
        if (row){
            this.props.addToEncounter(row);
            this.props.onClose();
        }
        this.setState({showAddMonster:false, addMonsterRow:null});
    }
    
    clickSave() {
        if (this.state.monster.unique && campaign.isCampaignGame()) {
            campaign.updateCampaignContent("npcs", this.state.monster);
        } else {
            campaign.updateCampaignContent("monsters", this.state.monster);
        }

        if (this.props.extraButtonsFn) {
            this.setState({editable:false});
        } else {
            this.props.onClose();
        }
    }
    
    clickEdit() {
        this.setState({editable:true});
    }
}

function getMonsterArtList(mon) {
    let artList = mon.artList||[];
    if (mon.tokenArt && !artList.includes(mon.tokenArt)) {
        artList = artList.concat([mon.tokenArt]);
    }
    return artList;
}

const monUniqueVals = [{name:"Common Monster",value:0},{name:"Non-Player Character (NPC)", value:1}];

class NewMonster extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:"", monsterName:null, unique:false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({text:"", monsterName:null, unique:false, companion:false});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const newMon = {};
            const monsterName = this.state.monsterName;
            const templateMon = campaign.getMonster(monsterName);

            if (monsterName && templateMon) {
                Object.assign(newMon, templateMon);
                simplifyMonster(newMon, true);
            } else if (!this.state.unique || !this.state.npc) {
                Object.assign(newMon, emptyMonster);
            }

            if (this.state.unique) {
                newMon.unique = true;
                if (this.state.npc && !templateMon) {
                    newMon.npc = true;
                    newMon.cr="0";
                    newMon.crsort=0;
                }
                if (!newMon.npc && newMon.unique) {
                    if (newMon.hp.average) {
                        newMon.hp={curHP:newMon.hp.average||1, maxHP:newMon.hp.average||1};
                    }
                }
            }

            newMon.displayName = this.state.text;
            newMon.name=campaign.newUid();
            if (!templateMon) {
                newMon.gamesystem = campaign.defaultGamesystem;
            }
            campaign.updateCampaignContent((newMon.unique && campaign.isCampaignGame())?"npcs":"monsters", newMon);
            this.props.onClose(newMon.name);
        } else {
            this.props.onClose();
        }

        event.stopPropagation();
    };

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({text:event.target.value});
        }
    }

    setUnique(unique) {
        this.setState({unique, npc:false});
    }

    setNPC(npc) {
        this.setState({npc});
    }

    render() {
        if (!this.props.open)
            return null;

        const name = this.state.text;
        const baseMonster = campaign.getMonster(this.state.monsterName);
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Create Monster</DialogTitle>
            <DialogContent>
                {baseMonster?<div className="f3"><b>Based on:</b> {baseMonster.displayName}</div>:null}
                <TextField
                    fullWidth
                    value={name||""}
                    onChange={this.onChange.bind(this)}
                    margin="dense"
                    helperText="New Monster Name"
                />
                {(baseMonster?.unique||baseMonster?.npc)?null:<div>
                    <SelectVal fullWidth value={this.state.unique||0} values={monUniqueVals} onClick={this.setUnique.bind(this)}/>
                    {this.state.unique?<div>
                        <div className="mv1 hk-well">
                            NPCs can appear multiple times across a campaign and maintain their stats across encounter boundaries. {baseMonster?null:"NPCs can use either a monster stat block or a full character sheet."}<br/> Note: you can only add one instance of an NPC to an encounter.
                        </div>
                        {baseMonster?null:<div className="mb1">
                            <CheckVal value={this.state.npc} label="Use Character sheet" onChange={this.setNPC.bind(this)}/>
                        </div>}
                    </div>:null}
                </div>}
                <MonsterPicker onClose={this.pickMonster.bind(this)} open={this.state.showMonsterPicker||false}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showMonsterPicker.bind(this)} color="primary">
                    Pick Monster to Copy
                </Button>
                <Button disabled={!name || name==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    Create
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    showMonsterPicker() {
        this.setState({showMonsterPicker:true});
    }

    pickMonster(mon) {
        if (mon) {
            const baseMonster = campaign.getMonster(mon);
            this.setState({monsterName:mon, text:this.state.text||((baseMonster.displayName||"")+" copy")});
        }

        this.setState({showMonsterPicker:false});
    }
}

class NPCList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                Campaign NPCs
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={campaign.getNPCs()}
                    collapseDups
                    render={this.npcRender.bind(this)}
                    onClick={this.clickNPC.bind(this)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <MonsterDetails monster={this.state.selMon} open={this.state.selMon} onClose={this.clickNPC.bind(this,null)}/>
        </Dialog>;
    }

    clickNPC(selMon) {
        this.setState({selMon});
    }

    npcRender(m) {
        const swim = (m.speed||{}).swim;
        const fly = (m.speed||{}).fly;
        let extraStr;
        
        if (m.npc) {
            extraStr = <span>{m.originDisplayName||(!m.gamesystem?m.raceDisplayName:null)} {m.backgroundDisplayName} Level {m.level} {m.classDisplayNames}</span>;
        } else {
            extraStr = Parser.sizeAbvToFull(m.size) +" "+Parser.monTypeToFullObj(m.type).asText+(swim?" swimming":"")+(fly?" flying":"");
        }
    
        return <div className="flex">
            <div className="flex-auto">
                {m.displayName} 
                <div className="mt--2 i f6">CR {m.cr} {extraStr}</div>
            </div>
            <DeleteWithConfirm name={m.displayName} onClick={this.deleteMonster.bind(this, m.name)} className="pa1"/>
        </div>;
    }

    deleteMonster(name) {
        campaign.deleteCampaignContent("npcs", name);
        this.setState({npcs:campaign.getNPCs()});
    }
}
const addSpellTypes = {
    innate:"Add innate spells",
    spellcaster:"Add as a slot spellcaster",
    pact:"Add as a pact spellcaster",
}

class AddSpells extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {addType:"innate",spellcasterLevel:1, perDay:1,ability:"charisma"};
    }

    render() {
        const {showDialog, addType,spellcasterLevel,perDay, showPick,selectedSpells,ability} = this.state;

        const sl = [];
        for (let i in selectedSpells) {
            const s = campaign.getSpell(i);
            if (s) {
                sl.push(s.displayName);
            }
        }

        let maxSpellLevel=20;
        switch (addType) {
            case "spellcaster":
                maxSpellLevel=fullSpellInfo[spellcasterLevel-1].maxSpellLevel;
                break;
            case "pact":
                maxSpellLevel=pactSpellInfo[spellcasterLevel-1].maxSpellLevel;
                break;
        }

        return <span>
            <Button className="mb1" onClick={this.setProp.bind(this,"showDialog")} size="small" variant="outlined" color="primary">
                +Spells
            </Button>
            <Dialog
                open={!!showDialog}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Add Spellcasting</DialogTitle>
                <DialogContent>
                    <SelectVal fullWidth value={addType} onClick={this.setProp.bind(this,"addType")} values={addSpellTypes} />
                    <SelectVal fullWidth value={ability} onClick={this.setProp.bind(this,"ability")} values={abilityNamesFull} helperText="Spellcasting Ability"/>
                    {addType=="innate"?
                        <SelectVal fullWidth isNum value={perDay} onClick={this.setProp.bind(this,"perDay")} helperText="Cast each per day" values={zeroTo20}/>
                        :
                        <SelectVal fullWidth isNum value={spellcasterLevel} onClick={this.setProp.bind(this,"spellcasterLevel")} helperText="Spellcasting level" values={oneTo20}/>
                    }
                    <div className="mv1 tc">
                        <Button onClick={this.setProp.bind(this,"showPick")} size="small" variant="outlined" color="primary">
                            Pick Spells
                        </Button>
                    </div>
                    <div className="stdcontent">
                        <Renderentry entry={{html:this.getString(), type:"html"}}/>
                    </div>
                    <div className="hk-well mt1">
                        In Campaign,
                        {addType=="innate"?" each leveled spell will include usage bubbles. ":" spell slot counts will convert count to usage bubbles. "}
                        Clicking spell links will allow casting spell and tracking usage.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Add
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <SpellPicker 
                open={showPick} 
                maxSpellLevel={maxSpellLevel}
                selectedSpells={selectedSpells}
                hideCounts
                onClose={this.closePicker.bind(this)}
            />
        </span>;
    }

    handleClose(save) {
        if (save) {
            this.props.onAdd(this.getString())
        }
        this.setState({showDialog:false});
    }

    closePicker(selectedSpells) {
        if (selectedSpells) {
            this.setState({selectedSpells});
        }
        this.setState({showPick:false});
    }

    setProp(prop,value) {
        const s = {};
        s[prop] = value;
        this.setState(s);
    }

    getString() {
        const {monster} =this.props;
        const name = monster.displayName||"monster"
        const {addType,spellcasterLevel,perDay, selectedSpells, ability} = this.state;
        const uA = upperFirst(ability);
        const aVal = monster[abilityLongNames[ability]]||10;
        const sAttack = Math.trunc(aVal/2)-5+getProficiency(monster);
        let res;
        const levelStr = ((monster?.gamesystem=="bf")||(campaign.defaultGamesystem=="bf"))?"Circle":"level";
    

        switch (addType) {
            case "spellcaster": {
                const level = Parser.spLevelToFull(spellcasterLevel);
                const spellcast = fullSpellInfo[spellcasterLevel-1];
                res = `<p><i><strong>Spellcasting. </strong></i>The ${name} is a ${level}-level spellcaster. Its spellcasting ability is ${uA} (spell save DC ${sAttack+8}, <b>${signedNum(sAttack)}</b> to hit with spell attacks). The ${name} has the following spells:</p>`;
                for (let l=0; l<=spellcast.maxSpellLevel; l++) {
                    const sl=[];
                    for (let i in selectedSpells) {
                        const s = campaign.getSpell(i);
                        if (s && ((s.level||0)==l)) {
                            sl.push(`<i><a href="#spell?id=${encodeURIComponent(i)}">${(s.displayName||"").toLowerCase()}</a></i>`);
                        }
                    }
                    const spells = sl.join(", ");
                    if (l) {
                        res = res + `<p>${Parser.spLevelToFull(l)} ${levelStr} (${spellcast.spellSlots[l-1]} slots): ${spells}</p>`;
                    } else if (sl.length) {
                        res = res + `<p>Cantrips (at will): ${spells}</p>`;
                    }
                }
        
                break;
            }
            case "pact": {
                const level = Parser.spLevelToFull(spellcasterLevel);
                const spellcast = pactSpellInfo[spellcasterLevel-1];
                res = `<p><i><strong>Spellcasting. </strong></i>The ${name} is a ${level}-level pact spellcaster. Its spellcasting ability is ${uA} (spell save DC ${sAttack+8}, <b>${signedNum(sAttack)}</b> to hit with spell attacks). The ${name} has the following spells:</p>`;
                for (let l=0; l<=spellcast.maxSpellLevel; l++) {
                    const sl=[];
                    for (let i in selectedSpells) {
                        const s = campaign.getSpell(i);
                        if (s && ((s.level||0)==l)) {
                            sl.push(`<i><a href="#spell?id=${encodeURIComponent(i)}">${(s.displayName||"").toLowerCase()}</a></i>`);
                        }
                    }
                    const spells = sl.join(", ");
                    if (l) {
                        if (l==spellcast.maxSpellLevel) {
                            res = res + `<p>${Parser.spLevelToFull(l)} ${levelStr} (${spellcast.pactSlots} slots): ${spells}</p>`;
                        } else {
                            res = res + `<p>${Parser.spLevelToFull(l)} ${levelStr}: ${spells}</p>`;
                        }
                    } else if (sl.length) {
                        res = res + `<p>Cantrips (at will): ${spells}</p>`;
                    }
                }
        
                break;
            }
            case "innate": {
                res =`<p><b><i>Innate Spellcasting. </i></b>The ${name}'s spellcasting ability is ${uA} (spell save DC ${sAttack+8}, <b>${signedNum(sAttack)}</b> to hit with spell attacks). It can innately cast the following spells:</p>`;
                const slC=[], slS=[];
                for (let i in selectedSpells) {
                    const s = campaign.getSpell(i);
                    if (s) {
                        const sl = (s.level && perDay)?slS:slC;
                        sl.push(`<i><a href="#spell?id=${encodeURIComponent(i)}">${(s.displayName||"").toLowerCase()}</a></i>`);
                    }
                }
                if (slC.length) {
                    res = res + `<p>At will: ${slC.join(", ")}</p>`;
                }
                if (slS.length) {
                    res = res + `<p>${perDay}/day each: ${slS.join(", ")}</p>`;
                }
                break;
            }
        }
        return res;
    }

}

class OptionsConfig extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const {monObj} = this.props;
        const featureOptions = monObj.featureOptions;
        const fTemplate = campaign.getCustom(fTemplateName,monObj.state.fTemplate);
        const list=[];
        const features=[];

        if (fTemplate) {
            const baseName ="f.";
            const r = fTemplate.features;
            const opts=[];

            const crLevel = Math.min(30,Math.trunc(monObj.state.crsort));
            list.push(<div key="level" className="mb1">
                <SelectVal value={featureOptions.levelOverride||0} isNum fullWidth values={[{name:`Based on CR (Level ${crLevel})`, value:0}].concat(oneTo20)} onClick={this.changeOptionVal.bind(this, "levelOverride")} helperText="Level"/>
            </div>);

            for (let i in r) {
                const f= r[i];
                if (hasFeatureConfig(f) && monObj.checkRestriction(f.restriction)) {
                    const featureBaseName = baseName+(f.id||f.name||"");
                    list.push(<FeatureConfigure key={featureBaseName+i}
                        character={monObj}
                        feature={f}
                        baseName={featureBaseName}
                        options={featureOptions}
                        onChange={this.changeOptionVal.bind(this)}
                    />);
                }
                features.push(<div key={i} className={f?.noDiv?"mb1":null}><RenderFeature h3 feature={f} noDiv={f?.noDiv}/></div>);
            }
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>{fTemplate?.displayName}</DialogTitle>
            <DialogContent>
                {fTemplate?<div className="stdcontent">
                    <Renderentry entry={fTemplate.description}/>
                    {list}
                    {features}
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeOptionVal(option, value) {
        const {monObj} = this.props;
        const options = monObj.featureOptions;
        const opts = Object.assign({}, options);

        opts[option] = value;

        monObj.setProperty("featureOptions", opts);
    }
}

const addUsageTypes = {
    long:"Add usages per day",
    short:"Add usages per short rest",
    recharge:"Add usages that recharge",
}
const dieOptions = [4,6,8,10,12];

class AddUsageTracking extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {addType:"long", count:1,die:6, dieMin:6};
    }

    render() {
        const {showDialog, addType,count, die, dieMin} = this.state;

        return <span>
            <Button className="mb1" onClick={this.setProp.bind(this,"showDialog")} size="small" variant="outlined" color="primary">
                +Usage
            </Button>
            <Dialog
                open={!!showDialog}
                maxWidth="xs"
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Add Usage</DialogTitle>
                <DialogContent>
                    <SelectVal fullWidth value={addType} onClick={this.setProp.bind(this,"addType")} values={addUsageTypes} />
                    {addType=="recharge"?<div className="flex items-center mv1">
                        <div>Recharge </div>
                        <SelectVal className="mh1 flex-auto" isNum value={dieMin} onClick={this.setProp.bind(this,"dieMin")} values={getNumRange(1,die)}/>
                        -
                        <SelectVal className="ml1 flex-auto" isNum value={die} onClick={this.setProp.bind(this,"die")} values={dieOptions}/>
                    </div>:
                    <SelectVal fullWidth isNum value={count} onClick={this.setProp.bind(this,"count")} helperText="Usage Count" values={oneTo20}/>}
                    <div className="stdcontent">
                        <Renderentry entry={{html:this.getString(), type:"html"}}/>
                    </div>
                    <div className="hk-well mt1">
                        Edit usage name, description, and counts as needed. Usage tracking will convert counts to tracking bubbles when viewing the monster in a campaign. Recharge will show option to roll a recharge after usage.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Add
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>;
    }

    handleClose(save) {
        if (save) {
            this.props.onAdd(this.getString())
        }
        this.setState({showDialog:false});
    }

    setProp(prop,value) {
        const s = {};
        s[prop] = value;
        if (prop=="die") {
            s.dieMin=value;
        }
        this.setState(s);
    }

    getString() {
        const {addType,count,die,dieMin} = this.state;
        let res;

        switch (addType) {
            case "long": {
                res = `<p><i><strong>Feature Name (${count}/day). </strong></i>Description</p>`;
                break;
            }
            case "short": {
                res = `<p><i><strong>Feature Name (${count}/short or long rest). </strong></i>Description</p>`;
                break;
            }
            case "recharge": {
                if (dieMin==die) {
                    res = `<p><i><strong>Feature Name (Recharge ${die}). </strong></i>Description</p>`;
                } else {
                    res = `<p><i><strong>Feature Name (Recharge ${dieMin}-${die}). </strong></i>Description</p>`;
                }
                break;
            }
        }
        return res;
    }

}

function MonsterHeader(props) {
    return <span>
        Monsters
        <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={triggerEvent.bind(null, props.pageSync,"action","newmonster")}>New</Button>
    </span>;
}

function getProficiency(mon) {
    return mon.proficiency || defaultProficiency(mon);
}

function defaultProficiency(mon) {
    const level = Math.min(30,Math.trunc(mon.crsort));
    return ((level>1)?(Math.trunc((level-1)/4) +2):2);
}

function getPassive(mon) {
    if (mon.passive) {
        return Number(mon.passive);
    }
    let perceptionBonus = Math.floor(Number(mon.wis||10)/2)-5;
    const skill = mon.skill;
    for (let s in skill) {
        if (s.toLowerCase() == "perception") {
            perceptionBonus=Number(skill[s]);
        }
    }
    return 10+perceptionBonus;
}

function getStealth(mon) {
    if (mon.stealth) {
        return Number(mon.stealth);
    }
    let stealthBonus = Math.floor(Number(mon.dex||10)/2)-5;
    if (mon.save?.dex && (mon.gamesystem=="bf")) {
        stealthBonus = Number(mon.save.dex);
    }
    const skill = mon.skill;
    for (let s in skill) {
        if (s.toLowerCase() == "stealth") {
            stealthBonus=Number(skill[s]);
        }
    }
    return 10+stealthBonus;
}

function triggerEvent(eventSync, event, data) {
    eventSync.emit(event, data);
}

function getBasicEntry(entry) {
    let renderEntry;

    if (!entry||entry.length==0) {
        return {};
    }

    if (entry && entry.length==1)
        renderEntry= entry[0];
    else
        renderEntry = entry;

    return renderEntry;
}

function combineStrings(a,b) {
    if (a && a.length) {
        if (b && b.length) {
            const aVals = a.split(",");
            const bVals = b.split(",");
            for (let as of bVals) {
                const asLC = as.trim().toLowerCase();
                if (!aVals.find(function (x) {return x.trim().toLowerCase()==asLC})) {
                    a = a+", "+as.trim();
                }
            }
            return a;
        }
        return a;
    }
    return b;
}

const sizePickList=sizeList.concat([
    {name:"None", value:null}
]);


export {
    RenderMonsters,
    MonsterPicker,
    ArmorClass,
    MonsterBlock,
    printMonster,
    printNPC,
    simplifyMonster,
    MonsterDetails,
    MonsterHeader,
    NewMonster,
    NPCList,
    setupCompanion,
    getMonsterArtList,
    monsterListFilters,
    matchRestriction,
    getPassive,
    getStealth,
    getProficiency,
    getCRSortFromCR
}