const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import sizeMe from 'react-sizeme';
import Button from '@material-ui/core/Button';
const {displayMessage} = require('./notification.jsx');
const {AddObject} = require('./objects.jsx');
const {TextBasicEdit, CheckVal, SelectVal, getAnchorPos,defaultSourceFilter,DeleteWithConfirm} = require('./stdedit.jsx');
const EventEmitter = require('events'); 
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {PinDialog} = require('./pins.jsx');
const {ListFilter} = require('./listfilter.jsx');

const {MonsterPicker} = require("./rendermonster.jsx");
const {MapPane} = require('./map.jsx');
const {EncounterMonsterList, calcXPTotals} = require('./encountermonsterlist.jsx');
const {ItemListPicker} = require('./items.jsx');
const {SearchEverything} = require('./search.jsx');

let Book;

function getSuggestedPlayerInfo() {
    const players = campaign.getPlayers();
    var pc=0, plt=0;

    for (let i in players) {
        const p=players[i];
        pc++;
        plt += p.level||1;
    }

    if (!pc || campaign.isDefaultCampaign()) {
        const {playersTarget, levelTarget} = campaign.getUserSettings();
        return {playersTarget:playersTarget||4, levelTarget:levelTarget||1};
    }

    return {playersTarget:pc, levelTarget:Math.round(plt/pc)};
}

class EncounterViewBase extends React.Component {
    constructor(props) {
        if (!Book) {
            const  b = require('./book.jsx');
            Book = b.Book;
        }

        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.clickContentFn = this.clickContent.bind(this);

        const eventSync = new EventEmitter();
        eventSync.setMaxListeners(100);

        eventSync.on("clickcontent", this.clickContentFn);
        this.state=this.getEncounterState(props, true, true);
        this.state.eventSync = eventSync;
        this.state.selectedTab = 1; // default to map
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.encounter != prevProps.encounter) {
            if (this.saveTimer) {
                clearTimeout(this.saveTimer);
                this.doUpdate();
            }
    
            this.setState(this.getEncounterState(this.props, true, true));
        }
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "plannedencounters");
    }
  
    componentWillUnmount() {
        if (this.state.eventSync) {
            this.state.eventSync.removeAllListeners();
        }
        if (this.saveTimer) {
            clearTimeout(this.saveTimer);
            this.doUpdate();
        }
        this.state.eventSync.removeListener("clickcontent", this.clickContentFn);
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "plannedencounters");
    }

    onDataChange() {
        if (this.saveTimer) {
            clearTimeout(this.saveTimer);
            this.doUpdate();
            this.saveTime=null;
        }
        this.setState(this.getEncounterState(this.props));
    }

    clickContent(data) {
        if (data.contentType=="Map") {
            this.setState({mapPos:{mapName:data.id}});
        }
    }

    getEncounterState(props, forceMaps,init) {
        let ename = props.encounter;
        let br = null;

        let encounter;
        let mapPos=null;
        encounter = campaign.getPlannedEncounterInfo(ename);

        if (encounter) {
            if (!encounter.playersTarget)
                encounter.playersTarget = 4;

            if (!encounter.levelTarget)
                encounter.levelTarget = 1;

            if (forceMaps) {
                if (this.props.mapPos) {
                    mapPos = this.props.mapPos;
                } else {
                    const pin = campaign.findEncounterPin(ename);
                    if (pin) {
                        mapPos = pin.mapPos;
                    }
                }
            }
            for (let i in encounter.combatants) {
                delete (encounter.combatants[i]||{}).cInfo;
            }

            if (init) {
                br = encounter.bookReference;
            }
        }

        if (!mapPos && this.props.mapPos) {
            mapPos = this.props.mapPos;
        }

        const ret = {
            encounter:encounter, 
            mapPos:mapPos,
        };

        if (br) {
            ret.book = br.book;
            if (br.fragment ) {
                const {getChapterInfoFromFragment} = require('./book.jsx');
                const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);

                ret.chapter = chaptInfo.chapter;
                ret.section = chaptInfo.section;
                ret.subsection = chaptInfo.subsection;
            } else {
                ret.chapter = br.chapter;
                ret.section = br.section;
                ret.subsection = br.subsection;
            }
        } else if (init) {
            ret.book = null;
        }

        return ret;
    }

    doUpdate() {
        const encounter=this.state.encounter;
        if (encounter) {
            let newPin;
            for (let i in encounter.combatants) {
                delete (encounter.combatants[i]||{}).cInfo;
            }

            // switch encounter to point to pin instead of pin to encounter
            if (!encounter.pinName) {
                let pin = campaign.findEncounterPin(encounter.name);
                if (pin && pin.links) {
                    pin=Object.assign({}, pin);
                    for (let i in pin.links){
                        const pl = pin.links[i];
                        if ((pl.type == "encounter") && (pl.name==encounter.name)) {
                            pin.links = pin.links.concat([]);
                            pin.links.splice(i,1);
                            // move encounter pin tracking to the encounter
                            //console.log("updating pin", pin)
                            newPin=pin;
                            encounter.pinName = pin.name;
                        }
                    }
                } 
            }

            campaign.updateCampaignContent("plannedencounters", encounter);
            if (newPin) {
                campaign.updateCampaignContent("pins", newPin);
            }
        }
    }

	render() {
        if (this.props.nodetails) {
            return <div className="w-100 h-100 flex">{this.getDetailsPannel()}</div>;
        }
     
        if (!this.state.encounter) {
            return null;
        }
        let useTabs = this.props.size.width < 1280;
        const bookDetails =this.getDetailsBook();
        const extra = <span>
            <MonsterPicker includeCount onClose={this.hideMonsterPicker.bind(this)} open={this.state.showMonsterPicker} extraButtons={this.getMonsterPickerExtraButtons.bind(this)}/>
            <ItemListPicker open={this.state.showTreasurePicker} onClose={this.closeTreasurePicker.bind(this)} pickToken/>
        </span>;

        if (useTabs) {
            let tab =this.state.selectedTab||0;
            const selectedTab = "ph2 pv1 titlecolor defaultbackground br--top br2 nudge-down--2";
            const unselectedTab = "ph2 pv1 hoverhighlight"

            return <div className="w-100 flex flex-column h-100">
                <div className="flex titlebackground titlecolorcontrast">
                    <div className={tab==0?selectedTab:unselectedTab} onClick={this.changeTab.bind(this,0)}>Book</div>
                    <div className={tab==1?selectedTab:unselectedTab} onClick={this.changeTab.bind(this,1)}>Map</div>
                    <div className={tab==2?selectedTab:unselectedTab} onClick={this.changeTab.bind(this,2)}>Tracker</div>
                </div>
                <div className={(tab==0)?"flex-auto h1 overflow-hidden":"h0 overflow-hidden"}>
                    <div className="h-100 w-100">
                        {bookDetails}
                    </div>
                </div>
                <div className={(tab==1)?"flex-auto h1 overflow-hidden":"h0 overflow-hidden"}>
                    <div className="h-100 w-100">
                        {this.getDetailsMaps()}
                    </div>
                </div>
                <div className={(tab==2)?"flex-auto h1 overflow-hidden":"h0 overflow-hidden"}>
                    <div className="h-100 w-100">
                        {this.getDetailsPannel()}
                    </div>
                </div>
                {extra}
            </div>
        } else {
            return <div className="h-100 w-100 overflow-hidden flex items-stretch">
                {bookDetails?<div className="h-100 w-100 mw7 flex-2 pt1 pr1 overflow-y-auto overflow-x-hidden">
                    {bookDetails}
                </div>:null}
                <div className="flex-2 h-100 titlebordercolor bl br">
                    {this.getDetailsMaps()}
                </div>
                <div className="minw55 h-100 flex-1">
                    {this.getDetailsPannel()}
                </div>
                {extra}
            </div>;
        }
    }

    getMonsterPickerExtraButtons() {
        return <CheckVal labelClass="titlecolor f7" label="SELECT TOKENS" value={this.state.showPickMonsterTokens} onChange={this.setShowPickMonsterTokens.bind(this, !this.state.showPickMonsterTokens)}/>
    }

    setShowPickMonsterTokens(showPickMonsterTokens){
        this.setState({showPickMonsterTokens});
    }

    changeTab(selectedTab) {
        this.setState({selectedTab});
    }

    getDetailsPannel(){
        const {playersTarget, levelTarget,combatants} = this.state.encounter;
        const {target} = calcXPTotals(combatants||[],levelTarget||1, playersTarget||4);

        return <div className="h-100 w-100 flex flex-column overflow-y-auto overflow-x-hidden pt1 pl1">
            <div className="pb1 titleborder bb w-100">
                <div>
                    <Button className="minw2" color="secondary" variant="outlined" size="small" onClick={this.showMonsterPicker.bind(this)}>add monsters</Button>
                    <AddObject onAddObject={this.addObject.bind(this)}/>
                    <Button className="minw2" color="secondary" variant="outlined" size="small" onClick={this.showTreasurePicker.bind(this)}>add treasure</Button>
                </div>
                <div className="flex mt1 items-center">
                    <SelectVal className="mr2 tr" value={playersTarget||4} isNum values={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]} onClick={this.updateEncounterProp.bind(this, "playersTarget")} helperText="Character Count"/>
                    <SelectVal className="tr" value={levelTarget||1} isNum values={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]} onClick={this.updateEncounterProp.bind(this, "levelTarget")} helperText="Character Level"/>
                    <div className="flex-auto f6 ba titleborder mh1">
                        <div className="tc pv--2 titleborder bb">
                            Adjusted XP
                        </div>
                        <div className="flex items-center">
                            <div className="w-25 tc easyEncounter pv--2 ph1">{target.easy.toLocaleString()}<div>easy</div></div>
                            <div className="w-25 tc mediumEncounter pv--2 ph1">{target.medium.toLocaleString()}<div>medium</div></div>
                            <div className="w-25 tc hardEncounter pv--2 ph1">{target.hard.toLocaleString()}<div>hard</div></div>
                            <div className="w-25 tc deadlyEncounter pv--2 ph1">{target.deadly.toLocaleString()}<div>deadly</div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100 overflow-y-auto overflow-x-hidden flex-auto">
                <EncounterMonsterList 
                    ref={this.saveEncounterList.bind(this)}
                    members={combatants||[]}
                    eventSync={this.state.eventSync}
                    planning
                    onChange={this.updateEncounterCombatants.bind(this)}
                    players={playersTarget}
                    level={levelTarget}
                    getMapRef={this.getMapRef.bind(this)}
                    defaultShown
                />
            </div>
        </div>;
    }

    saveEncounterList(r) {
        this.encounterList = r;
        if (this.props.saveEncounterList) {
            this.props.saveEncounterList(r);
        }
    }

    onPickBook(book, chapter, section, subsection) {
        if (!book) {
            return;
        }
        
        const newEncounter = Object.assign({},this.state.encounter);
        const {getFragmentFromChapterSection} = require('./book.jsx');
        const fragment = getFragmentFromChapterSection(book, chapter, section, subsection);

        newEncounter.bookReference = {book, fragment};

        this.saveCampaign(newEncounter);
        this.setState({book, chapter, section, subsection});
    }

    onDeleteFromArray(prop, index) {
        const newEncounter = Object.assign({},this.state.encounter);
        var a = newEncounter[prop].concat([]);

        a.splice(index, 1);
        newEncounter[prop] = a;

        this.saveCampaign(newEncounter);
    }

    addPinToMap(e, specificPos) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.mapRef && this.mapRef.state.imageName) {
            const name = this.state.encounter.name;
            var pin = campaign.findEncounterPin(name);

            if (pin) {
                pin = {type:"location", name:pin.name, displayName:pin.displayName, links:[{name:name, type:"encounter"}]};
            } else {
                pin = {type:"location", name:campaign.newUid(), displayName:this.state.encounter.displayName, links:[{name:name, type:"encounter"}]};
            }
            const mapPos = Object.assign({}, specificPos || this.mapRef.state.mapPos);
            mapPos.mapName = this.mapRef.state.imageName;
            if (this.mapRef) {
                mapPos.diameter = this.mapRef.state.mapPos.diameter;
            }
            pin.mapPos = mapPos;
            campaign.updateCampaignContent("pins", pin);
        } else {
            displayMessage("Select a map first.");
        }
    }

    getDetailsMaps() {
        const br = this.state.encounter.bookReference;
        let chapter,section,subsection;
        const pinRules ={location:true};
        if (br) {
            if (br.fragment) {
                const {getChapterInfoFromFragment} = require('./book.jsx');
                const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);

                chapter=chaptInfo.chapter;
                section=chaptInfo.section;
                subsection=chaptInfo.subsection;
            }else {
                chapter=br.chapter;
                section=br.section;
                subsection=br.subsection;
            } 
        }

        return <div key="mapl" className="h-100 w-100 flex-2">
            <MapPane 
                mapIndex={0} 
                numPanes={1} 
                showImagePick
                noPopup 
                noCover 
                className="h-100 w-100" 
                mapRef={this.setMapRef.bind(this)}
                eventSync={this.state.eventSync}
                combatants={this.state.encounter.combatants} 
                onChangeCombatantPos={this.onChangeCombatantPos.bind(this)} 
                useMapName
                grid={this.state.grid}
                onToggleGrid={this.onToggleGrid.bind(this)}
                onGoToBook={this.onGoToBook.bind(this)}
                onTapToken={this.onTapToken.bind(this)}
                onGoToEncounter={this.props.onGoToEncounter}
                bookname={br && br.book}
                chapter={chapter}
                section={section}
                subsection={subsection}
                mapPos={this.state.mapPos}
                pinRules={pinRules}
                onTapMap={this.onTapMap.bind(this)}
                onGoToMap={this.onGoToMap.bind(this)}
                noSavePos
            />
            {this.getMapContextMenu()}
            <PinDialog 
                open={this.state.showNewPin} 
                bookname={br && br.book}
                chapter={chapter}
                section={section}
                subsection={subsection}
                pin={this.state.newPin}
                onClose={this.setNewPin.bind(this,false)}
            />
        </div>;
    }

    onToggleGrid(){
        this.setState({grid:!this.state.grid});
    }

    onGoToMap(mapPos) {
        this.setState({mapPos:mapPos,showMapContextMenu:false, selectedTab:1});
    }

    onTapToken(i, e) {
        if (this.encounterList) {
            this.encounterList.displayContextMenu(i, getAnchorPos(e.evt, true));
        }
    }

    getMapContextMenu() {
        const t=this;
        if (!this.state.showMapContextMenu)
            return null;

        return <Menu open={true} disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={function(){t.setState({showMapContextMenu:false})}}>
            <MenuItem onClick={this.onCenterMap.bind(this)}>Center</MenuItem>
            <MenuItem onClick={this.onAddMonsterToMap.bind(this)}>Add Monsters</MenuItem>
            <AddObject onAddObject={this.addObjectToMap.bind(this)} useMenu/>
            <MenuItem onClick={this.onHuddleMap.bind(this, "monster")}>Huddle Monsters</MenuItem>
            <MenuItem onClick={this.setNewPin.bind(this,true)}>New Pin</MenuItem>
            <MenuItem onClick={this.onSetEncounterLocation.bind(this)}>Reset Encounter Pin Location</MenuItem>
            {this.state.coverRegion?<DeleteWithConfirm useMenu onClick={this.onDeleteCoverRegion.bind(this)} altText="Delete Fog Region" name="fog region"/>:null}
        </Menu>;
    }

    setNewPin(showNewPin) {
        let newPin=null;
        if (showNewPin) {
            const mapPos = Object.assign({}, this.state.actionPos||this.mapRef.state.mapPos);
            mapPos.mapName = this.mapRef.state.imageName;
            if (this.mapRef) {
                mapPos.diameter = this.mapRef.state.mapPos.diameter;
            }
            newPin = {
                name:campaign.newUid(),
                type:"location", 
                mapPos: mapPos,
            }

            if (this.state.book) {
                const {getFragmentFromChapterSection} = require('./book.jsx');
                const fragment = getFragmentFromChapterSection(this.state.book, this.state.chapter, this.state.section, this.state.subsection);
        
                newPin.links=[{
                    name:this.state.book, 
                    type:"book", 
                    book:this.state.book, 
                    fragment
                }];
            }
        }
        this.setState({showNewPin,newPin,showMapContextMenu:false});
    }

    onToggleCoverRegion() {
        this.state.actionMap.toggleCover(this.state.coverRegion);
        this.setState({showMapContextMenu:false});
    }

    onDeleteCoverRegion() {
        this.state.actionMap.deleteCover(this.state.coverRegion);
        this.setState({showMapContextMenu:false});
    }

    onCenterMap() {
        const newPos = Object.assign({}, this.state.actionMap.state.mapPos);
        newPos.x = this.state.actionPos.x;
        newPos.y = this.state.actionPos.y;
        this.onGoToMap(newPos);
        this.setState({showMapContextMenu:false});
    }

    onHuddleMap(group) {
        this.encounterList.doHuddle(this.state.actionPos.x, this.state.actionPos.y, group, "active", this.state.actionMap, this.state.actionMap.state.imageName, -1);
        this.setState({showMapContextMenu:false});
    }

    onTapMap(pos, e, mapRef, coverRegion) {
        this.setState({showMapContextMenu:true, actionPos:pos, actionMap:mapRef, anchorPos:getAnchorPos(e.evt, true), coverRegion});
    }

    onAddMonsterToMap() {
        this.setState({showMapContextMenu:false, showMonsterPicker:true});
    }

    addObjectToMap(obj) {
        this.encounterList.addObject(obj, this.state.actionPos, this.state.actionMap);
        this.setState({showMapContextMenu:false});
    }

    onSetEncounterLocation() {
        this.addPinToMap(null, this.state.actionPos);
        this.setState({showMapContextMenu:false});
    }

    onChangeCombatantPos(index, x, y, rotation) {
        this.encounterList.onChangeCombatantPos(index, x, y, rotation);
    }

    setMapRef(ref) {
        this.mapRef = ref;
    }

    getMapRef() {
        return this.mapRef;
    }

    getDetailsBook() {
        if (!this.state.book){
            return null;
        }

        return <Book
            key={this.state.book} 
            pageSync={this.state.eventSync}
            bookname={this.state.book} 
            chapter={this.state.chapter} 
            section={this.state.section} 
            subsection={this.state.subsection} 
            editable
            onClickEncounter={this.props.onGoToEncounter}
            onClickLink={this.onGoToMap.bind(this)}
            onClick={this.changeBookSelection.bind(this)} 
        />;
    }

    navToEncounter(encounter) {
        location.assign("/#plannedencounter?id="+encodeURIComponent(encounter));
    }


    changeBookSelection(chapter, section, subsection) {
        this.setState({chapter, section, subsection});
    }

    gotoBookReference() {
        const br = this.state.encounter.bookReference;
        let chapter,section,subsection;
        if (br) {
            if (br.fragment) {
                const {getChapterInfoFromFragment} = require('./book.jsx');
                const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);

                chapter=chaptInfo.chapter;
                section=chaptInfo.section;
                subsection=chaptInfo.subsection;
            } else {
                chapter=br.chapter;
                section=br.section;
                subsection=br.subsection;
            }
        }

        this.setState({book:br.book, chapter, section, subsection});
    }

    onGoToBook(book, chapter, section, subsection) {
        this.setState({book, chapter, section, subsection, showBook:true, selectedTab:0});
    }

    toggleBookEdit(){
        this.setState({bookeditable:!this.state.bookeditable});
    }

    onSaveAnnotations(name, newMapInfo) {
        const newEncounter = Object.assign({},this.state.encounter);

        const index =this.state.encounter.mapList.findIndex(function (m) {return !(m.name.localeCompare(name))});
        if (index < 0) {
            console.log("could not find map to update", name)
            return;
        }

        newEncounter.mapList = newEncounter.mapList.concat([]);
        var mapInfo = Object.assign({}, newEncounter.mapList[index]);
        Object.assign(mapInfo, newMapInfo);
        newEncounter.mapList[index]=mapInfo;

        this.saveCampaign(newEncounter);
    }

    showMonsterPicker(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({showMonsterPicker:true, actionPos:null, actionMap:null});
    }

    hideMonsterPicker(selList) {
        if (selList) {
            this.encounterList.addMonsters(selList, this.state.actionPos, this.state.actionMap, this.state.showPickMonsterTokens);
        }
        this.setState({showMonsterPicker:false,showPickMonsterTokens:false});
    }

    showTreasurePicker() {
        this.setState({showTreasurePicker:true});
    }

    closeTreasurePicker(items, coins, save, selectedToken) {
        if (save) {
            const art = campaign.getArtInfo(selectedToken);
            if (art) {
                const ao = {
                    ctype:"object",
                    otype:"image",
                    name:"Treasure",
                    tokenArt:art.name,
                    treasure:{items, coins}
                };
    
                this.encounterList.addObject(ao);
                campaign.setPrefs({selectedTreasureToken:selectedToken});
            }
        }
        this.setState({showTreasurePicker:false});
    }

    addMonsterClick(sel) {
        this.addMonster(sel.id, 1);
        return true;
    }

    addMonster(monster, count, pos, map) {
        this.encounterList.addMonster(monster,count, pos, map);
    }

    addObject(obj) {
        this.encounterList.addObject(obj);
    }

    updateEncounterCombatants(newList) {
        const newEncounter = Object.assign({},this.state.encounter);
        newEncounter.combatants = newList;
        this.saveCampaign(newEncounter);
    }

    updateEncounterProp(prop, value) {
        const newEncounter = Object.assign({},this.state.encounter);
        newEncounter[prop] = value;
        this.saveCampaign(newEncounter);
        if (["playersTarget", "levelTarget"].includes(prop)) {
            campaign.updateUserSettings({playersTarget:newEncounter.playersTarget||4, levelTarget:newEncounter.levelTarget||1})
        }
    }

    onChangeDescription(e) {
        this.updateEncounterProp("description", e.target.value);
    }

    saveCampaign(newEncounter) {
        const t=this;

        this.setState({encounter:newEncounter});

        if (this.saveTimer) {
            clearTimeout(this.saveTimer);
        }
        this.saveTimer = setTimeout(function () {
            t.doUpdate();
            t.saveTimer=null;
        }, 2000);
    }

}

const EncounterView =sizeMe({monitorHeight:true, monitorWidth:true})(EncounterViewBase);

class EncounterDialog extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {open:this.props.open, createNew:this.props.createNew, encounter:this.props.encounter};
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.open != prevProps.open) || (this.props.encounter!=prevProps.encounter)) {
            this.setState({open:this.props.open, createNew:this.props.createNew, encounter:this.props.encounter});
        }
    }

	render() {
        const encounter = campaign.getPlannedEncounterInfo(this.state.encounter);
        const {getChapterInfoFromFragment,PickBookDialog} = require('./book.jsx');
        let chapter,section,subsection,bookname;
    
        if (encounter) {
            const br=encounter.bookReference;
            if (br && campaign.getBookInfo(br.book)) {
                bookname=br.book;
                if (br.fragment) {
                    const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);

                    chapter=chaptInfo.chapter;
                    section=chaptInfo.section;
                    subsection=chaptInfo.subsection;
                } else {
                    chapter=br.chapter;
                    section=br.section;
                    subsection=br.subsection;
                }
            }
        }    

        return <span>
            <Dialog
                classes={{paper:"minvh-95"}}
                open={(this.state.open && !this.state.createNew)||false}
                fullWidth
                maxWidth={false}
                minSize={1400}
                noDrag
            >
                <DialogTitle onClose={this.props.onClose}>
                    <div className="flex">
                        {encounter?(encounter.displayName||"Encounter Build"):"Encounter Build"}
                        {encounter?<Button className="ml1 minw2" color="secondary" variant="outlined" size="small" onClick={this.clickRename.bind(this)}>Rename</Button>:null}
                        <div className="flex-auto"/>
                        <SearchEverything addToEncounter={this.addToEncounter.bind(this)}/>
                    </div>
                </DialogTitle>
                <div className="flex-auto w-100 h4 overflow-hidden pb2 pt0 ph0 bt bb titleborder mt1 relative ignoreDrag">
                    <div className="absolute t00 h-100 w-100">
                        <EncounterView 
                            encounter={this.state.encounter} 
                            mapPos={this.props.mapPos}
                            saveEncounterList={this.saveEncounterList.bind(this)}
                            onGoToEncounter={this.props.onGoToEncounter || this.autoOnGoToEncounter.bind(this)}
                        />
                    </div>
                </div>
                <DialogActions>
                    {encounter?<Button className="ml1 minw2" color="primary" onClick={this.showPickBook.bind(this)}>Book Reference</Button>:null}
                    <Button className="ml1 minw2" color="primary" onClick={this.deleteEncounter.bind(this)}>Delete</Button>
                    <Button onClick={this.props.onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <TextBasicEdit show={this.state.open && this.state.createNew} label="New Encounter Name" text={this.state.encounter} onChange={this.onNewEncounter.bind(this)} />
            <TextBasicEdit show={this.state.showRename} label="Rename Encounter" text={encounter&&encounter.displayName} onChange={this.onRename.bind(this)} />
            <PickBookDialog
                open={this.state.showPickBook}
                onClose={this.onClosePickBook.bind(this)}
                bookname={bookname||null}
                chapter={chapter}
                section={section}
                subsection={subsection}
            />
        </span>;
    }

    saveEncounterList(r) {
        this.encounterList = r;
    }

    addToEncounter(row) {
        this.encounterList.addToEncounter(row);
    }

    deleteEncounter() {
        deletePlannedEncounter(this.state.encounter);
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            window.location.href = "#home";
        }
    }

    showPickBook() {
        this.setState({showPickBook:true});
    }

    onClosePickBook(bookname, chapter, section, subsection) {
        if (bookname) {
            const {getFragmentFromChapterSection} = require('./book.jsx');
            const fragment = getFragmentFromChapterSection(bookname, chapter, section, subsection);
            const encounter = Object.assign({}, campaign.getPlannedEncounterInfo(this.state.encounter)||{});
            if (encounter.name) {
                encounter.bookReference={book:bookname, fragment};
                campaign.updateCampaignContent("plannedencounters", encounter);
            }
        }
        this.setState({showPickBook:false});
    }

    clickRename(){
        this.setState({showRename:true});
    }

    onRename(name) {
        if (name) {
            const encounter = Object.assign({}, campaign.getPlannedEncounterInfo(this.state.encounter)||{});
            if (encounter.name) {
                encounter.displayName = name;
                campaign.updateCampaignContent("plannedencounters", encounter);
            }
        }
        this.setState({showRename:false});
    }

    autoOnGoToEncounter(encounter) {
        this.setState({encounter});
    }

    onNewEncounter(name) {
        if (!name){
            this.props.onClose();
            return;
        }
        let pin;

        if (this.props.pin) {
            pin = this.props.pin;
        } else {
            const mapPos = Object.assign({}, this.props.mapPos);

            pin = {type:"location", name:campaign.newUid(), displayName:name, links:[]};
            
            if (this.mapRef) {
                mapPos.diameter = this.mapRef.state.mapPos.diameter;
            }
        
            pin.mapPos = mapPos;
            campaign.updateCampaignContent("pins", pin);
        }

        const newEncounter = Object.assign({name:campaign.newUid(), displayName:name, pinName:pin.name}, getSuggestedPlayerInfo());
        if (this.props.startingBook) {
            const {getFragmentFromChapterSection} = require('./book.jsx');
            const fragment = getFragmentFromChapterSection(this.props.startingBook, this.props.startingChapter||0, this.props.startingSection||0, this.props.startingSubsection||0);
    
            newEncounter.bookReference = {book:this.props.startingBook, fragment}
        }
        campaign.updateCampaignContent("plannedencounters", newEncounter);


        this.setState({createNew:false, encounter:newEncounter.name});
    }
}

class EncounterHeader extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        const encounter = campaign.getPlannedEncounterInfo(this.props.id);
        const {getChapterInfoFromFragment,PickBookDialog} = require('./book.jsx');
        let chapter,section,subsection,bookname;
    
        if (encounter) {
            const br=encounter.bookReference;
            if (br && campaign.getBookInfo(br.book)) {
                bookname=br.book;
                if (br.fragment) {
                    const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);

                    chapter=chaptInfo.chapter;
                    section=chaptInfo.section;
                    subsection=chaptInfo.subsection;
                } else {
                    chapter=br.chapter;
                    section=br.section;
                    subsection=br.subsection;
                }
            }
        }    
    
        return <span>
            {encounter?(encounter.displayName||"Encounter Build"):"Encounter Build"}
            {encounter?<Button className="ml1 minw2" color="secondary" variant="outlined" size="small" onClick={this.clickRename.bind(this)}>Rename</Button>:null}
            {encounter?<Button className="ml1 minw2" color="secondary" variant="outlined" size="small" onClick={this.showPickBook.bind(this)}>Book Reference</Button>:null}
            <Button className="ml1 minw2" color="secondary" variant="outlined" size="small" onClick={this.deleteEncounter.bind(this)}>Delete</Button>
            <TextBasicEdit show={this.state.showRename} label="Rename Encounter" text={encounter&&encounter.displayName} onChange={this.onRename.bind(this)} />
            <PickBookDialog
                open={this.state.showPickBook}
                onClose={this.onClosePickBook.bind(this)}
                bookname={bookname||null}
                chapter={chapter}
                section={section}
                subsection={subsection}
            />
        </span>;
    }

    deleteEncounter() {
        deletePlannedEncounter(this.props.id);
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            window.location.href = "#home";
        }
    }

    showPickBook() {
        this.setState({showPickBook:true});
    }

    onClosePickBook(bookname, chapter, section, subsection) {
        if (bookname) {
            const {getFragmentFromChapterSection} = require('./book.jsx');
            const fragment = getFragmentFromChapterSection(bookname, chapter, section, subsection);
            const encounter = Object.assign({}, campaign.getPlannedEncounterInfo(this.props.id)||{});
            if (encounter.name) {
                encounter.bookReference={book:bookname, fragment};
                campaign.updateCampaignContent("plannedencounters", encounter);
            }
        }
        this.setState({showPickBook:false});
    }

    clickRename(){
        this.setState({showRename:true});
    }

    onRename(name) {
        if (name) {
            const encounter = Object.assign({}, campaign.getPlannedEncounterInfo(this.props.id)||{});
            if (encounter.name) {
                encounter.displayName = name;
                campaign.updateCampaignContent("plannedencounters", encounter);
            }
        }
        this.setState({showRename:false});
    }
}

function getDefaultBookName(book, bkchapter, bksection, bksubsection) {
    var text = book;

    if (!text) {
        return null;
    }

    const bookinfo = campaign.getBookInfo(text);

    if (bookinfo) {
        const chapter = bookinfo.chapters[bkchapter||0];

        if (chapter) {
            text = chapter.name;
            if (bksection >= 0) {
                const section = chapter.sections[bksection];

                if (section) {
                    text = section.name;
                    if (bksubsection >= 0) {
                        const subsection =section.subsections[bksubsection];
                        if (subsection && subsection.name) {
                            text= subsection.name;
                        }
                    }
                } 
            }
        } else {
            text = bookinfo.displayName;
        }
    }

    return text;
}

class RenderEncounters extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getPlannedEncounters()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "pins");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "pins");
    }

	render() {
        const encounters = campaign.getPlannedEncounters();
        const list = [];
        for (let i in encounters) {
            const e = Object.assign({}, encounters[i]);
            let pin;
            if (e.pinName) {
                pin = campaign.getPinInfo(e.pinName);
            } else {
                const pins = campaign.getPins();
                for (let x in pins) {
                    const p = pins[x];
                    for (let y in p.links) {
                        const pl = p.links[y];
                        if (pl.name==e.name) {
                            pin = p;
                        }
                    }
                }
            }
            if (pin) {
                const map = campaign.getMapInfo(pin.mapPos.mapName);
                if (map) {
                    e.mapname = map.displayName||"Unknown";
                }
            } else {
                e.mapname="Unknown";
            }
            list.push(e);
        }
        list.sort(function (a,b){
            const mapsort = (a.mapname||"").toLowerCase().localeCompare((b.mapname||"").toLowerCase());
            if (mapsort != 0) {
                return mapsort;
            }
            return (a.displayName||"").toLowerCase((b.displayName||"").toLowerCase());
        });

        return <div className="notecontent">
            <ListFilter 
                list={list}
                filters={encounterListFilters}
                onClick={this.onClick.bind(this)}
                groupBy="mapname"
                select="click"
            />
            <EncounterDialog open={this.state.show} encounter={this.state.encounter} onClose={this.hideDetails.bind(this)}/>
        </div>;
    }

    onClick(name) {
        this.setState({show:true, encounter:name});
    }

    hideDetails() {
        this.setState({show:false});
    }
}

const encounterBookFilter = {
    filterName:"Books",
    fieldName:"bookReference",
    convertField(br) {
        if (br) {
            const book = campaign.getBookInfo(br.book);
            if (book && book.displayName) {
                return book.displayName;
            }
            return "unknown";
        }
        return "none"
    }
}

const encounterListFilters = [
    {
        filterName:"Map",
        fieldName:"mapname",
    },
    defaultSourceFilter,
    encounterBookFilter
];


class PlannedEncounterPicker extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }
    
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Pick Encounter</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={campaign.getPlannedEncounters()}
                    render={plannedEncounterListRender}
                    filters={plannedEncounterListFilters}
                    onClick={this.props.onClose}
                    select="click"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClose(e) {
        e.stopPropagation();
        e.preventDefault();
        this.props.onClose();
    }
}

function plannedEncounterListRender(e) {
    const book = campaign.getBookInfo(e.bookReference && e.bookReference.book);
    return <div>
        {e.displayName} 
        {book?<div className="mt--2 i f6">{book.displayName}</div>:null}
    </div>;
}

const plannedEncounterListFilters = [
    defaultSourceFilter,
    encounterBookFilter,
]


function deletePlannedEncounter(name) {
    const e = campaign.getPlannedEncounterInfo(name);
    if (e) {
        if (e.edited) {
            campaign.deleteCampaignContent("plannedencounters", name, true);
        } else {
            const newe = Object.assign({}, e);
            newe.pinName = null;
            const pin = campaign.findEncounterPin(name);
            if (pin) {
                const newPin = Object.assign({},pin);
                let found;
                if (newPin.links) {
                    newPin.links = newPin.links.concat([]);
                    for (let i in newPin.links) {
                        const pl = newPin.links[i];
                        if (pl.type=="encounter" && pl.name.toLowerCase()==name) {
                            found=true;
                            newPin.links.splice(i,1);
                        }
                    }
                }
                if (found) {
                    campaign.updateCampaignContent("pins", newPin);
                }
            }
            newe.pinName = null;
            newe.bookReference=null;
            campaign.updateCampaignContent("plannedencounters",newe);
        }
    }
}
const EncounterViewSize = sizeMe({monitorHeight:true, monitorWidth:true})(EncounterView);
export {
    EncounterViewSize as EncounterView, 
    EncounterDialog,
    EncounterHeader,
    getDefaultBookName,
    getSuggestedPlayerInfo,
    RenderEncounters,
    deletePlannedEncounter,
    PlannedEncounterPicker
}