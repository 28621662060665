const React = require('react');
import {Timestamp, serverTimestamp} from 'firebase/firestore';
const {campaign,globalDataListener,sleep,areSameDeep, sortDisplayName} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
const {Rendersource} = require("./rendersource.jsx");
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
import { getStorage, ref,uploadBytes,deleteObject } from "firebase/storage";
const EventEmitter = require('events'); 

const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
const {TextVal, SelectVal, CheckVal, defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
const {nameEncode} = require('../lib/stdvalues.js');
const {ListFilter} = require('./listfilter.jsx');
const {displayMessage} = require('./notification.jsx');
const {uploadUserBlob} = require('./renderart.jsx')
const {CreatureArt} = require('./renderart.jsx');

const defaultThumb = "/audiothumb.png";

class RenderAudioList extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
    
	    this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "audio");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "audio");
    }

    onDataChange() {
        this.setState({audio:campaign.getAudio()});
    }

	render() {
        return <div className="h-100 w-100 pa1">
            <ListFilter 
                list={campaign.getAudio()}
                filters={audioListFilters}
                onClick={this.clickAudio.bind(this)}
                select="click"
                render={audioListRender}
                getListRef={this.saveRef.bind(this)}
                open
            />
            <AudioDialog open={this.state.showAudio} name={this.state.pickAudio} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.onCloseAudio.bind(this)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(cname) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(cname))||{});

        return <span>
            <Button disabled={!prev} onClick={prev?this.clickAudio.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickAudio.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    clickAudio(name){
        if (this.props.onClick) {
            this.props.onClick(campaign.getAudioInfo(name));
        } else {
            this.setState({showAudio:true, pickAudio:name});
        }
    }

    onCloseAudio() {
        this.setState({showAudio:false});
    }
}

class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state={ };
        this.updateAudioFn = this.updateAudio.bind(this);
    }

    componentDidMount() {
        sounds.onAudioEvents(this.updateAudioFn);
    }

    componentWillUnmount() {
        sounds.removeAudioListener(this.updateAudioFn);
    }

    updateAudio() {
        this.setState({playStatus:sounds.getPlayStatus(this.props.audio?.name)});
    }

    render() {
        const {audio} = this.props;
        const p = sounds.getPlayStatus(audio?.name);
        let pos=0, showPlay, showStop, showProgress;

        if (p) {
            showStop=true;
            showProgress=true;
            switch (p.state) {
                case "playing":{
                    pos = (p.progress*100)%100;
                    break;
                }
                case "loading":{
                    pos=-1;
                    break;
                }
                default:
                    console.log("unknown play state", p.state);
            }
        } else {
            showPlay=true;
        }

        const progress = <LinearProgress classes={{colorPrimary:(showProgress?null:"bg-transparent"),barColorPrimary:(showProgress?null:"bg-transparent")}} variant={pos<0?"indeterminate":"determinate"} value={pos<0?null:pos}/>;
        switch (this.props.variant) {
            default:
            case "top":
                return <div>
                    <span onClick={showPlay?this.play.bind(this):showStop?this.stop.bind(this):null}>
                        {this.props.children}
                    </span>
                    <div className="ph1">
                        {progress}
                    </div>
                </div>;
            case "control":
                return <span>
                    {showPlay?<span className="fas fa-play pa1 hoverhighlight" onClick={this.play.bind(this)}/>:
                    showStop?<span className="fas fa-stop pa1 hoverhighlight" onClick={this.stop.bind(this)}/>:
                    null}
                </span>;
            case "progress":
                return progress;
            case "volume":
                if (p?.source?.gainNode) {
                    return <Slider className="nudge-down--3" value={p.source.gainNode.gain.value *100} onChange={this.onChangeVolume.bind(this)} onChangeCommitted={this.onCommitVolume.bind(this)}/>
                } else {
                    return null;
                }

        }
    }
    
    onChangeVolume(event, volume) {
        const {audio} = this.props;
        const p = sounds.getPlayStatus(audio?.name);

        if (p && p.source?.gainNode) {
            p.source.gainNode.gain.value = volume/100;
        }
        this.setState({changeVolume:volume});
    }

    onCommitVolume() {
        const {audio} = this.props;
        const p = sounds.getPlayStatus(audio?.name);

        if (p.source?.gainNode) {
            sounds.setVolume(audio.name, p.source.gainNode.gain.value *100);
        }
    }

    play(evt) {
        const {audio} = this.props;
        const {name} = (audio||{});
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        if (name) {
            sounds.playAudio(name);
        }

    }

    stop(evt) {
        const {audio} = this.props;
        const {name} = (audio||{});
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        if (name) {
            sounds.stopAudio(name);
        }
    }
}

class RenderAudio extends React.Component {
    constructor(props) {
        super(props);

        this.state={ };
    }

    render() {
        let audio = campaign.getAudioInfo(this.props.name);
        if (!audio) {
            audio ={name:this.props.name, description:"audio clip not found"};
        }

        if (this.props.useThumb) {
            return <div className="tc" style={{width:150}}>
                <AudioPlayer audio={audio}>
                    <div className="ph1 pt1">
                        <img src={getAudioThumbnail(audio)} width="100"/>
                    </div>
                </AudioPlayer>
                <div className="pb1">
                    {audio.displayName} <span className="f7">({audio.duration?durationString(audio.duration):null})</span>
                </div>
            </div>;
        } else {
            return <div className={"flex w-100 items-center "+(this.props.className||"")}>
                <div className="flex-auto">
                    <AudioPlayer audio={audio} variant="control"/>
                    <a onClick={this.showAudio.bind(this,true)}>{audio.displayName} <span className="f7">({audio.duration?durationString(audio.duration):null})</span></a>
                </div>
                <div className="w-40">
                    <AudioPlayer audio={audio} variant="progress"/>
                </div>             
                {this.state.showAudio?<AudioDialog open name={audio.name} onClose={this.showAudio.bind(this, false)}/>:null}
            </div>;
        }
    }
    
    showAudio(showAudio) {
        this.setState({showAudio});
    }
}

function printAudio(id,noTitle,header) {
    const list=[];
    const it = campaign.getAudioInfo(id);
    if (!it) {
        return;
    }
    return `<p>${it.displayName||""} (${durationString(it.duration)})</p>`
}

function audioListRender(it, onClick) {
    return <div className="overflow-hidden" onClick={onClick}>
        <div>{it.displayName} <span className="f5">({durationString(it.duration)})</span></div>
        {it.keywords?<div className="i f5"> {it.keywords}</div>:null}
    </div>
}


const audioListFilters = [
    {
        filterName:"Type",
        fieldName:"type",
        convertField: function (v) {
            return audioTypeDescription(v);
        }
    },
    {
        filterName:"Keywords",
        fieldName:"keywords",
        convertField: function (v,it) {
            const ret= (v||"none").split(",").map(function (a){return a.trim()});
            return ret;
        }
    },
    defaultSourceFilter,
    defaultBookFilter
];

const audioTypeValues = [
    {name:"Sound Effect", value:"effect"},
    {name:"Background Mood/Music", value:"background"},
    {name:"Voice-Over", value:"voice"},
];

const groupPlayValues = {
    "all":"Play all",
    "one":"Randomly pick"
}

function audioTypeDescription(type) {
    for (let v of audioTypeValues) {
        if (v.value==type){
            return v.name;
        }
    }
}

class AudioDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
        this.idKey = campaign.newUid();
        this.playingClips={};
    }

    componentDidMount() {
        if (this.props.open){
            this.setDefaultState();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (((prevProps.open != this.props.open) || (prevProps.name!=this.props.name)) && this.props.open) {
            this.setDefaultState();
        }
    }

    componentWillUnmount() {
        this.stopPlaying();
        this.stopClip();
    }

    setDefaultState() {
        this.stopPlaying();
        this.stopClip();
        let audio;
        let dirty=false;
        if (this.props.name) {
            audio =campaign.getAudioInfo(this.props.name);
        } else {
            audio={};
            audio.displayName=this.props.defaultName||"";
            audio.name=campaign.newUid();
            if (this.props.isGroup) {
                audio.isGroup=true;
                audio.type="background";
                audio.groupType="all";
            } else {
                audio.type="effect";
            }
            dirty=true;
        }
        this.setState({
            audio,
            dirty,
            editable:dirty,
            decodedData:null,
            hideMenu:false
        });
        if (this.props.file) {
            this.loadFile(this.props.file);
        }
    }

    render() {
        const {audio,dirty, loading, loadingMessage, uploadSrc,editable,decodedData,offset,hideMenu} = this.state;
        if (!this.props.open) {
            return null;
        }
        if (!audio) {
            return <Dialog 
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    Audio not found
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose.bind(this)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>;
        }

        if (this.props.linkElement && !hideMenu && campaign.isGMCampaign()) {
            return this.showPlayMenu();
        }

        const url = (audio.url);
        const extraButtonsFn = this.props.extraButtonsFn;
        const showEdit = !campaign.isSharedCampaign()&&!this.props.disableEdit&&!editable;
        let artUrl = getAudioThumbnail(audio);

        return <Dialog 
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>{editable?"Audio Clip":<div>
                <div className="nudge-left--5"><span className="nodrag"><AudioPlayer audio={audio} variant="control"/></span>{audio.displayName}</div>
                <AudioPlayer audio={audio} variant="progress"/>
            </div>}</DialogTitle>
            <DialogContent>
                {editable?<div>
                    <TextVal fullWidth inputProps={{className:"f1 titletext titlecolor"}} className="mb2" text={audio.displayName} onChange={this.onChangeField.bind(this,"displayName")} helperText="Name"/>
                    <SelectVal fullWidth className="mb1 titletext titlecolor" value={audio.type||"effect"} values={audioTypeValues} onClick={this.onChangeField.bind(this, "type")} helperText="Audio Type"/>
                    {audio.isGroup?<SelectVal fullWidth className="mb1 titletext titlecolor" value={audio.groupType} values={groupPlayValues} onClick={this.onChangeField.bind(this, "groupType")} helperText="Group Play"/>:null}
                    <TextVal fullWidth className="mb1" text={audio.keywords} onChange={this.onChangeField.bind(this, "keywords")} helperText="Keywords (comma separated)"/>
                    <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={audio.description} placeholder="Description"/>
                </div>:<div className="stdcontent">
                    <div className="i">{audio.isGroup?((groupPlayValues[audio.groupType]||"")+" "):null}{audioTypeDescription(audio.type)}</div>
                    {audio.keywords?<div><span className="b i">Keywords.</span> {audio.keywords}</div>:null}
                    <Renderentry entry={audio.description}/>
                </div>}
                <div className="tc">
                    {editable?<CreatureArt onPickToken={editable?this.onPickArtwork.bind(this):null} defaultType="Audio Token">
                        {artUrl?<div className="pa2">
                            <img src={artUrl} height="100"/>
                        </div>:null}
                    </CreatureArt> : artUrl?<div className="pa2">
                        <img src={artUrl} height="100"/>
                    </div>:null}
                    {!audio.isGroup&&editable?<div className="tc mv1 flex">
                        <div className="flex-auto"/>
                       <table>
                            <tbody>
                                {decodedData||url?<tr>
                                    <td>
                                        {this.source?<span className="titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 fas fa-stop" onClick={this.stopPlaying.bind(this)}/>:
                                        <span className="titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 fas fa-play" onClick={this.play.bind(this,0)}/>}
                                    </td>
                                    <td>
                                        <Slider className="w6 mh3" value={offset||0} onChange={this.onChangeOffset.bind(this)} onChangeCommitted={this.onCommitOffset.bind(this)}/>
                                    </td>
                                    <td>
                                        {durationString(audio.duration)}

                                    </td>
                                </tr>:null}

                                <tr>
                                    <td>
                                        <span className="titlecolor f2 minw15 dib v-top pa1 fas fa-volume-up "/>
                                    </td>
                                    <td>
                                        <Slider className="w6 mh3" disabled={!editable} value={audio.volume||100} onChange={this.onChangeVolumeField.bind(this)}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="flex-auto"/>
                    </div>:<div className="tc">
                        {durationString(audio.duration)}
                    </div>}
                </div>
                {editable?<div className="hk-well mt2">
                    {!audio.isGroup?<input
                        key={this.idKey}
                        accept="audio/*"
                        className="dn"
                        id={"audio-file"+this.idKey}
                        type="file"
                        onChange={this.selectFile.bind(this)}
                    />:null}
                    {!audio.isGroup?<label htmlFor={"audio-file"+this.idKey}>
                        <Button color="primary" component="span" size="small" variant="outlined">
                            {url?"Change":"Upload"} Audio Clip
                        </Button>
                    </label>:<Button color="primary" variant="outlined" size="small" onClick={this.onShowPickClips.bind(this,)}>
                        Pick Clips
                    </Button>}
                    {audio.art?<Button className="ml2" color="primary" variant="outlined" size="small" onClick={this.onChangeField.bind(this, "art", null)}>
                            Delete Token
                    </Button>:null}
                </div>:null}
                {audio.isGroup&&editable?this.getGroupDetails():null}
                <Rendersource entry={audio}/>
            </DialogContent>
            <DialogActions>
                {!dirty && extraButtonsFn && extraButtonsFn(audio.name)}
                {dirty?<Button onClick={this.onSave.bind(this)} color="primary" disabled={!audio.displayName}>
                    Save
                </Button>:null}
                {showEdit?<Button onClick={this.onEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    {dirty?"Cancel":"Close"}
                </Button>
            </DialogActions>
            {loading?<Dialog
                open={loading}
            >
                <DialogContent>
                    {loadingMessage}
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    showPlayMenu() {
        const {linkElement, name} = this.props;
        const p = sounds.getPlayStatus(name);

        return <Menu open disableAutoFocusItem anchorEl={linkElement} onClose={this.onClose.bind(this)}>
            <MenuItem onClick={this.doPlay.bind(this, !p)}>{p?"Stop":"Play"}</MenuItem>
            <MenuItem onClick={this.showDetails.bind(this)}>View Audio {this.state.audio.isGroup?"Group":"Clip"}</MenuItem>
        </Menu>
    }

    doPlay(play) {
        const {name} = this.props;
        if (play) {
            sounds.playAudio(name);
        } else {
            sounds.stopAudio(name);
        }
        this.onClose();
    }

    showDetails() {
        this.setState({hideMenu:true});
    }

    getGroupDetails() {
        const {audio,editable} = this.state;
        const list=[], ret=[];
        let playing;

        for (let i in audio.clips) {
            const c = audio.clips[i];
            const a = campaign.getAudioInfo(i);
            if (a) {
                list.push({a, c});
            }
        }
        list.sort(function(a,b) {
            return (a.a.displayName||"").toLowerCase().localeCompare((b.a.displayName||"").toLowerCase())
        });
        for (let i in list) {
            const {a,c} = list[i];
            const pc = this.playingClips[a.name]||{};
            if (pc.source) {
                playing=true;
            }
            ret.push(<tr key={a.name}>
                <td className="w-100">
                    {pc.source?<span className="fas fa-stop pa1 hoverhighlight" onClick={this.stopClip.bind(this,a.name)}/>:
                    editable?<span className="fas fa-play pa1 hoverhighlight" onClick={this.playClip.bind(this,a)}/>:null}
                    {a.displayName} <span className="f5">({a.duration?durationString(a.duration):null})</span>
                    {a.keywords?<div className="i f5"> {a.keywords}</div>:null}
                </td>
                <td>{pc.source?<LinearProgress className="w4" variant="determinate" value={pc.offset||0}/>:null}</td>
                <td>
                    <div className="ph2"><Slider className="w5" disabled={!editable} min={0} value={c.volume||a.volume||100} onChange={this.onChangeClipVolumeField.bind(this,a.name)}/></div>
                </td>
            </tr>);
        }

        return <div>
            {ret.length?<div className="stdcontent tl mt1">
                <table className="w-100">
                    <tbody>
                        <tr>
                            <td className="b">
                                {playing?<span className="fas fa-stop pa1 hoverhighlight" onClick={this.stopClip.bind(this,null)}/>:
                                (audio.groupType=="all")&&editable?<span className="fas fa-play pa1 hoverhighlight" onClick={this.playAll.bind(this)}/>:
                                null}
                                Clips
                            </td>
                            <td/>
                            <td className="b tc">Volume</td>
                        </tr>

                        {ret}
                    </tbody>
                </table>
            </div>:null}
            <AudioPicker open={this.state.pickClips} selected={audio.clips} onClose={this.closePickClips.bind(this)}/>
        </div>
    }

    onShowPickClips() {
        this.setState({pickClips:true});
    }

    closePickClips(selected) {
        if (selected) {
            this.stopClip();
            this.onChangeField("clips", selected);
        }
        this.setState({pickClips:false});
    }

    onChangeClipVolumeField(name, evt, volume) {
        const {editable, audio} = this.state;

        if (editable) {
            const clips = Object.assign({}, audio.clips);
            const pc = this.playingClips[name];
            const a = campaign.getAudioInfo(name);
            if (pc?.gain) {
                pc.gain.gain.value = (volume||a?.volume||100)/100
            }
            clips[name] = Object.assign({}, clips[name]);
            clips[name].volume = volume;
            this.onChangeField("clips", clips);
        }
    }

    onPickArtwork(art) {
        if (art) {
            this.onChangeField("art", art.name);
        }
    }

    onChangeVolumeField(evt, volume) {
        if (this.state.editable) {
            this.onChangeField("volume", volume||100);
        }
    }

    onChangeOffset(event, offset) {
        this.stopPlaying();
        this.setState({offset});
    }

    onCommitOffset() {
        this.play(this.state.offset);
    }

    onEdit() {
        this.stopPlaying();
        this.stopClip();
        this.setState({editable:true});
    }

    onClose() {
        this.stopPlaying();
        this.stopClip();
        this.props.onClose();
    }

    onDoSave(name) {
        if (this.props.name && this.props.extraButtonsFn) {
            this.setDefaultState();
        } else {
            this.stopPlaying();
            this.stopClip();
            this.props.onClose(name);
        }
    }

    onChangeField(prop, val) {
        const audio=Object.assign({}, this.state.audio);

        if (prop=="volume" && this.gain) {
            this.gain.gain.value = (val||100)/100;
        }
        audio[prop] = val;
        if (audio.clips) {
            // recompute the duration
            const playAll = (audio.groupType == "all");
            const repeat = (audio.type == "background");
            let duration=0, altDuration=0;
            for (let i in audio.clips) {
                const a = campaign.getAudioInfo(i);
                if (a) {
                    if (playAll && !repeat && (a.type == "background")) {
                        altDuration = Math.min(altDuration||100000000, a.duration);
                    } else {
                        duration = Math.max(duration, a.duration);
                    }
                }
            }

            audio.duration = duration || altDuration;
        }
        this.setState({audio, dirty:true});
    }

    onSave() {
        const audio=Object.assign({}, this.state.audio);
        
        if (!this.state.uploadFile) {
            campaign.updateCampaignContent("audio", audio);
            this.onDoSave(audio.name);
        } else {
            this.uploadFiles(audio);
        }
    }

    selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            this.loadFile(e.target.files[0]);
        }
    }

    stopPlaying() {
        if (this.source) {
            this.source.stop();
            this.source.disconnect();
            this.source=null;
        }
        this.stopTimer();
        const {audio} = this.state;
        if (audio && !campaign.isGMCampaign()){
            sounds.stopAudio(audio.name);
        }
        this.setState({now:Date.now(), offset:0});
    }

    async play(offset) {
        if (!sounds.audioCtx) {
            return;
        }
        let {decodedData,audio} = this.state;
        let backTime=0;
        this.stopPlaying();
        if (offset){
            this.setState({offset});
        }
        if (!decodedData && audio.url) {
            decodedData = await sounds.getSourceData(audio.url);
            if (this.state.audio.name == audio.name) {
                this.setState({decodedData});
            } else {
                return;
            }
        }
        if (decodedData) {
            this.stopPlaying();
            this.source = sounds.mediaSourceFromSourceData(decodedData);
            if (!this.gain) {
                this.gain=sounds.audioCtx.createGain();
                this.gain.gain.value = (audio.volume||100)/100;
                this.gain.connect(sounds.audioCtx.destination);
            }
            this.source.connect(this.gain);
            backTime=(offset||0)/100*decodedData.duration;

            this.source.start(0,backTime);
            backTime *= 1000;
            this.startTimer();
        }
        this.startPlay = Date.now()-backTime;
        this.setState({offset:offset||0, startPlay:this.startPlay});
    }

    startTimer() {
        if (!this.update) {
            const t=this;
            const duration = this.state.decodedData.duration;
            this.update = setInterval(function(){
                const offset = ((Date.now()-t.startPlay)/1000)/duration*100;
                t.setState({offset});
                if (offset > 100) {
                    t.stopPlaying();
                }
            },1000);
        }
    }

    stopTimer() {
        if (this.update) {
            clearInterval(this.update);
            this.update=null;
        }
    }

    async loadFile(file) {
        if (!sounds.audioCtx){
            return;
        }
        this.stopPlaying();
        this.setState({
            loading:true, 
            loadingMessage:"Loading Audio "+file.name, 
            uploadFile:null,
            uploadSrc:null,
            decodedData:null
        });

        try {
            const decodedData = await sounds.fetchFileSourceData(file);
            const uploadSrc = await getFileUrl(file);

            this.setState({loading:false, uploadFile:file, dirty:true,uploadSrc,decodedData});
            this.onChangeField("duration", decodedData.duration);
        }catch (err) {
            displayMessage("Error loading file.  Format may not be supported.");
            this.setState({loading:false});
            console.log("error setting audio src", err);
        }
    }

    async uploadFiles(audio) {

        this.setState({loading:true, 
            loadingMessage:"Loading Files"
        });
        try {
            const {uploadFile} = this.state;
            if (uploadFile) {
                audio.versionId = campaign.newUid();
                audio.url = await uploadUserBlob(uploadFile, uploadFile.type, "/emptycampaign/"+audio.versionId);
            }
            campaign.updateCampaignContent("audio", audio);
            this.onDoSave(audio.name);
        } catch(err) {
            this.errorSelectingFile("Error uploading audio files", err);
        }
        this.setState({loading:false});
    }

    async playAll() {
        if (!sounds.audioCtx) {
            return;
        }
        const {audio} = this.state;
        for (let i in audio.clips) {
            const a = campaign.getAudioInfo(i);
            if (a) {
                let pc = this.playingClips[i];
                if (!pc) {
                    pc = {};
                    this.playingClips[i]=pc;
                }
                if (!pc.decodedData && a.url) {
                    pc.decodedData = await sounds.getSourceData(a.url);
                }
            }
        }
        for (let i in audio.clips) {
            const a = campaign.getAudioInfo(i);
            if (a) {
                this.playClip(a);
            }
        }
    }

    async playClip(clip) {
        if (!sounds.audioCtx){
            return;
        }
        const {audio} = this.state;
        const playAll = audio.groupType == "all";
        if (!playAll) {
            this.stopClip();
        }
        const repeat = (playAll && (clip.type == "background")) || (audio.type== "background");

        let pc = this.playingClips[clip.name];
        if (!pc) {
            pc = {};
            this.playingClips[clip.name]=pc;
        }

        if (!clip.url) {
            return;
        }
        if (!pc.decodedData){
            pc.decodedData = await sounds.getSourceData(clip.url);
        }

        if (pc.decodedData) {
            pc.source = sounds.mediaSourceFromSourceData(pc.decodedData);
            if (!pc.gain) {
                pc.gain=sounds.audioCtx.createGain();
                pc.gain.gain.value = (audio.clips[clip.name].volume||100)/100;
                pc.gain.connect(sounds.audioCtx.destination);
            }
            if (repeat) {
                pc.source.loop=true;
            }
            pc.source.connect(pc.gain);
            pc.source.start(0);
            pc.offset = 0;
            this.startClipTimer();
        }
        pc.startPlay = Date.now();
        this.setState({playing:Date.now()});
    }

    startClipTimer() {
        if (!this.updateClip) {
            const t=this;
            this.update = setInterval(function(){
                let {audio} = t.state;
                let playing;
                const repeat = (audio.type == "background");
                const playAll = (audio.groupType == "all");
                for (let i in t.playingClips) {
                    const pc = t.playingClips[i];
                    if (pc.source) {
                        let duration = pc.decodedData.duration;
                        if (!repeat && playAll && pc.source.loop) {
                            duration=audio.duration;
                        }
                        pc.offset = ((Date.now()-pc.startPlay)/1000)/duration*100;
                        if (!repeat && (pc.offset >= 100)) {
                            pc.source.stop();
                            pc.source.disconnect();
                            pc.source=null;
                            pc.gain.disconnect();
                            pc.gain=null;
                        } else {
                            pc.offset = pc.offset%100;
                            playing=true;
                        }
                    }
                }
                t.setState({playing:Date.now()});
                if (!playing) {
                    t.stopClipTimer();
                }
            },1000);
        }
    }

    stopClipTimer() {
        if (this.updateClip) {
            clearInterval(this.updateClip);
            this.updateClip=null;
        }
    }

    stopClip(name) {
        let {audio} = this.state;
        let playing;
        for (let i in this.playingClips) {
            const pc = this.playingClips[i];
            if (pc.source) {
                if (!name || i==name) {
                    pc.source.stop();
                    pc.source.disconnect();
                    pc.source=null;
                    pc.gain.disconnect();
                    pc.gain=null;
                } else {
                    playing=true;
                }
            }
        }
        if (!playing) {
            this.stopClipTimer();
        }
        this.setState({playing:Date.now()});
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+(err?("\nError:"+err.message):""));
        console.log(msg, err);
        this.setState({loading:false});
    }
}

class AudioPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selected:this.props.selected||{}});
        }
    }

    handleClose(save) {
        if (save) {
            this.props.onClose(this.state.selected);
        } else {
            this.props.onClose();
        }
        this.setState({selected:null});
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const character = this.props.character;
        const l = campaign.getAudio();
        const list = [];

        for (let i in l) {
            const li = l[i];
            if (!li.isGroup) {
                list.push(li);
            }
        }

        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth={true}
            open
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                {this.props.type}
            </DialogTitle>
            <DialogContent>
                <div className="stdcontent" key={this.props.id}>
                    <ListFilter 
                        list={list}
                        select={"list"}
                        extraSelectInfo
                        selected={this.state.selected}
                        render={audioListRender}
                        onClick={this.clickAudio.bind(this)}
                        onSelectedChange={this.onSelectedChange.bind(this)}
                        filters={audioListFilters}
                        getListRef={this.saveRef.bind(this)}
                    >
                    </ListFilter>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <AudioDialog open={this.state.selectedId} name={this.state.selectedId} onClose={this.clickAudio.bind(this,null)} extraButtonsFn={this.getExtraButtons.bind(this)}/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(id) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(id))||{});
        const selected = (this.state.selected||{})[id];

        return <span>
            <Button disabled={!prev} onClick={prev?this.clickAudio.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickAudio.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            <Button onClick={this.toggleSelected.bind(this,id)} color="primary">{selected?"Unselect":"Select"}</Button>
        </span>
    }

    toggleSelected(id) {
        const it = campaign.getAudio(id);
        if (it) {
            this.listfilter.selectItem(it.id.toLowerCase(), it.displayName);
        }
    }

    clickAudio(selectedId){
        this.setState({selectedId});
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }
}


class AudioHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
        this.idKey = campaign.newUid();
    }

    render() {
        return <span>
            Audio Clips
            <input
                key={this.idKey}
                accept="audio/*"
                className="dn"
                id={"audio-file"+this.idKey}
                type="file"
                onChange={this.selectFile.bind(this)}
                multiple={campaign.allowSpecialArtTypes}
            />
            <label htmlFor={"audio-file"+this.idKey}>
                <Button className="ml2 minw2" color="secondary" variant="outlined" size="small"  component="span">
                    Upload Audio Clip
                </Button>
            </label>
            {campaign.getAudio().length?<Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.newGroup.bind(this)}>
                New Audio Group
            </Button>:null}
            <BulkAudioCreator open={this.state.bulkFiles} files={this.state.bulkFiles} onClose={this.setState.bind(this, {bulkFiles:null}, null)}/>
            <AudioDialog open={this.state.showNewAudio} onClose={this.onCloseNew.bind(this)} defaultName={this.state.defaultName} isGroup={this.state.isGroup} file={this.state.file}/>
        </span>;
    }

    onCloseNew() {
        this.setState({showNewAudio:false})
    }

    newGroup() {
        this.setState({showNewAudio:true, file:null, defaultName:null, isGroup:true});
    }

    selectFile(e) {
        this.idKey = campaign.newUid();
        const length = e.target.files.length;

        if (length == 1) {
            const file = e.target.files[0];
            this.setState({showNewAudio:true, file, defaultName:cleanFilename(file.name), isGroup:false});
        } else if (length > 1) {
            this.setState({bulkFiles:e.target.files});
        }
    }
}

class BulkAudioCreator extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state={newList:null};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "audio");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "audio");
    }

    onDataChange() {
        const oldList = this.state.newList;

        if (oldList) {
            const newList = [];
            for (let it of oldList) {
                const newIt = campaign.getAudioInfo(it.name);
                if (newIt){
                    newList.push(newIt);
                }
            }
            this.setState({newList});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({newList:null, loading:false, uploadName:null});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const {newList, uploadName, type, keywords} = this.state;

        return <Dialog
            open
            scroll="paper"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.onDone.bind(this)}>Bulk Create Audio Clips</DialogTitle>    
            <DialogContent>
                {newList?<ListFilter 
                    list={newList}
                    onClick={this.onClickAudio.bind(this)}
                    select="click"
                    render={audioListRender}
                    getListRef={this.saveRef.bind(this)}
                />:<div>
                    <p>Upload {this.props.files.length} audio clips</p>
                    <div>
                        <SelectVal fullWidth className="mb2 titletext titlecolor" value={type||"effect"} values={audioTypeValues} onClick={this.onChangeField.bind(this, "type")} helperText="Audio Type"/>
                        <TextVal fullWidth className="mb2" text={keywords} onChange={this.onChangeField.bind(this, "keywords")} helperText="Keywords (comma separated)"/>
                    </div>
                    <Button color="primary" variant="outlined" onClick={this.bulkCreate.bind(this)}>
                        Start Bulk Upload
                    </Button>
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onDone.bind(this)} color="primary">
                   Close
                </Button>
            </DialogActions>
            <AudioDialog open={this.state.showAudio} name={this.state.showAudio} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.onClickAudio.bind(this,null)}/>
            {this.state.loading?<Dialog
                open
            >
                <DialogContent>
                    Uploading audio clip {uploadName}...
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    onChangeField(prop,val) {
        const set={};
        set[prop]=val;
        this.setState(set);
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(cname) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(cname))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.onClickAudio.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.onClickAudio.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    onClickAudio(name) {
        this.setState({showAudio:name});
    }

    onDone() {
        this.props.onClose();
    }

    async bulkCreate() {
        const {files} = this.props;
        const {type,keywords} = this.state;
        const results = [];
    
        this.setState({loading:true});
        try {
            for (let file of files) {
                const decodedData = await sounds.fetchFileSourceData(file);
                const audio = {
                    name:campaign.newUid(),
                    displayName:cleanFilename(file.name),
                    duration:decodedData.duration,
                    versionId:campaign.newUid(),
                    type:type||"effect",
                    keywords:keywords||null
                };
                this.setState({uploadName:file.name});

                audio.url = await uploadUserBlob(file, file.type, "/emptycampaign/"+audio.versionId);
                campaign.updateCampaignContent("audio", audio);

                results.push(audio);
            }
        } catch (err) {
            console.log("error loading audio clip", err);
            displayMessage("Error loading audio clip: "+err.message);
            this.props.onClose();
        }
        results.sort(sortDisplayName)
        this.setState({loading:false, newList:results});
    }
}

class SoundVolume extends React.Component {
    constructor(props) {
        super(props);
    
	    this.state= {masterVolume:100};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        const {backgroundVolume, effectVolume, voiceVolume, diceVolume} = campaign.volumeSettings;
        this.setState({backgroundVolume, effectVolume, voiceVolume, diceVolume, masterVolume:sounds.masterVolume});
    }

    componentDidMount() {
        this.onDataChange();
        globalDataListener.onChangeUserSettings(this.handleOnDataChange);
        sounds.onAudioEvents(this.handleOnDataChange,"volume");
    }

    componentWillUnmount() {
        globalDataListener.removeUserSettingsListener(this.handleOnDataChange);
        sounds.removeAudioListener(this.handleOnDataChange,"volume");
    }

	render() {
        const {alwaysExpand, includeDice} = this.props;
        const {expand,backgroundVolume, effectVolume, voiceVolume, diceVolume,masterVolume, masterAdjust} = this.state;
        const master = (masterAdjust==null)?this.getMasterVal():masterAdjust;
        const playSounds=campaign.playSounds;

        return <div>
            <Tooltip title={masterVolume?"Mute Tab":"Unmute Tab"}><span className={"titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 "+(masterVolume?"fas fa-volume-up ":"fas fa-volume-mute")} onClick={this.toggleVolume.bind(this,"masterVolume")}/></Tooltip>
            <Slider className="w6 mh3" value={master} min={0} onChange={this.onChangeMaster.bind(this)} onChangeCommitted={this.onCommitChangeVolume.bind(this)}/>
            {!alwaysExpand?<span className={"hoverhighlight pa1 titlecolor f2 dib v-top "+(expand?"fas fa-caret-up":"fas fa-caret-down")} onClick={this.setExpand.bind(this, !expand)}/>:null}
            {expand||alwaysExpand?<div>
                <div className="relative titlecolor f5" style={{top:10, left:40}}>Background mood/music</div>
                <span className={"titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 "+(backgroundVolume?"fas fa-volume-up ":"fas fa-volume-mute")} onClick={this.toggleVolume.bind(this,"backgroundVolume")}/>
                <Slider className="w6 mh3" value={backgroundVolume||0} min={0} onChange={this.onChangeVolume.bind(this, "backgroundVolume")} onChangeCommitted={this.onCommitChangeVolume.bind(this)}/>
                <div className="relative titlecolor f5" style={{top:10, left:40}}>Sound effects</div>
                <span className={"titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 "+(effectVolume?"fas fa-volume-up ":"fas fa-volume-mute")} onClick={this.toggleVolume.bind(this,"effectVolume")}/>
                <Slider className="w6 mh3" value={effectVolume||0} min={0} onChange={this.onChangeVolume.bind(this, "effectVolume")} onChangeCommitted={this.onCommitChangeVolume.bind(this)}/>
                <div className="relative titlecolor f5" style={{top:10, left:40}}>Voice-overs</div>
                <span className={"titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 "+(voiceVolume?"fas fa-volume-up ":"fas fa-volume-mute")} onClick={this.toggleVolume.bind(this,"voiceVolume")}/>
                <Slider className="w6 mh3" value={voiceVolume||0} min={0} onChange={this.onChangeVolume.bind(this, "voiceVolume")} onChangeCommitted={this.onCommitChangeVolume.bind(this)}/>
            </div>:null}
            {includeDice?<div className="mt1">
                <Divider/>
                <div className="relative titlecolor f5" style={{top:10, left:40}}>Dice</div>
                <span className={"titlecolor minw15 f2 dib v-top hoverhighlight pv1 pl1 "+(playSounds?"fas fa-volume-up":"fas fa-volume-mute")} onClick={this.setPlaySounds.bind(this,!playSounds)}/>
                <Slider className="w6 mh3" disabled={!playSounds} value={diceVolume||0} min={0} onChange={this.onChangeVolume.bind(this, "diceVolume")} onChangeCommitted={this.onCommitChangeVolume.bind(this)}/>
            </div>:null}
        </div>;
    }

    getMasterVal() {
        const {backgroundVolume, effectVolume, voiceVolume} = this.state;
        const sum = (backgroundVolume || 0) + (effectVolume || 0) + (voiceVolume || 0);
        return (sum)/3;
    }

    onChangeMaster(evt, masterAdjust) {
        let {backgroundVolume, effectVolume, voiceVolume, masterStart, backgroundStart, effectStart, voiceStart} = this.state;

        if (masterStart==null) {
            masterStart = this.getMasterVal();
            backgroundStart = backgroundVolume;
            effectStart = effectVolume;
            voiceStart = voiceVolume;
        }

        let mult;
        if (masterAdjust >= masterStart) {
            mult = (masterAdjust - masterStart)/(100 - masterStart);
            backgroundVolume = backgroundStart + (100-backgroundStart)*mult;
            effectVolume = effectStart + (100-effectStart)*mult;
            voiceVolume = voiceStart + (100-voiceStart)*mult;
        } else {
            mult = (masterStart - masterAdjust) / masterStart;
            backgroundVolume = backgroundStart - backgroundStart*mult;
            effectVolume = effectStart -effectStart*mult;
            voiceVolume = voiceStart -voiceStart*mult;
        }

        this.setState({backgroundVolume, effectVolume, voiceVolume, masterStart, backgroundStart, effectStart, voiceStart, masterAdjust})
    }

    setExpand(expand) {
        this.setState({expand});
    }

    toggleVolume(type) {
        const vol = this.state[type];
        if (type == "masterVolume") {
            sounds.setMasterVolume(vol?0:100);
        } else {
            const set = {};
            set[type] = vol?0:100;
            campaign.updateUserSettings(set);            
        }

    }

    onChangeVolume(type, evt, vol) {
        const set = {};
        set[type]=vol;
        this.setState(set);
    }

    setPlaySounds(playSounds) {
        campaign.updateUserSettings({playSounds});
        this.setState({playSounds});
    }

    onCommitChangeVolume(evt) {
        const {backgroundVolume, effectVolume, voiceVolume, masterVolume,diceVolume} = this.state;
        campaign.updateUserSettings({backgroundVolume, effectVolume, voiceVolume, diceVolume});
        this.setState({masterAdjust:null,masterStart:null});
    }
}

function getAudioThumbnail(audio) {
    let artUrl;
    if (audio.art) {
        const art = campaign.getArtInfo(audio.art);
        artUrl = art?.thumb||art?.url;
    }
    if (!artUrl) {
        artUrl = defaultThumb;
        switch (audio.type) {
            case "background":
                artUrl = '/audiobackground.png'
                break;
            case "voice":
                artUrl = '/audiovoiceover.png'
                break;

            default:
            case "effect":
                artUrl = '/audiosoundeffect.png'
                break;
        }
    }
    return artUrl;
}

class SoundTool extends React.Component {
    constructor(props) {
        super(props);
    
	    this.state= {};
    }

	render() {
        const {showVolume, anchorEl} = this.state;

        return <span>
            <Tooltip title="Volume"><span className="titlecolor f2 hoverhighlight pa1 fas fa-volume-up" onClick={this.showVolume.bind(this,!showVolume)}/></Tooltip>
            <Popover
                anchorEl={anchorEl}
                open={!!showVolume}
                onClose={this.showVolume.bind(this,false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="pa1">
                    <SoundVolume includeDice/>
                </div>
            </Popover>
        </span>;
    }

    showVolume(showVolume, evt) {
        this.setState({showVolume, anchorEl:evt.target});
    }

}

function deleteAudio(name) {
    const storage = getStorage(firebase);
    const userId = campaign.currentUser.uid;
    const audio = campaign.getAudioInfo(name)||{};

    const fileRef = ref(storage,"users/"+userId+"/emptycampaign/"+(audio.versionId||name));
    
    deleteObject(fileRef).then(function () {
        //console.log("successfully deleted obj", name);
    }).catch(function (err) {
        //console.log("error deleting audio", name);
    });

    campaign.deleteCampaignContent("audio", name);
}

function cleanFilename(name) {
    const pos = name.indexOf(".");
    if (pos > 1) {
        name = name.substr(0,pos);
    }
    return name.replace(/[_\-\.]/g, " ");
}

function getFileUrl(file) {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = readerEvent => {
            resolve(readerEvent.target.result);
        };
        reader.readAsDataURL(file);
    });
}

function durationString(duration) {
    duration = duration ||0;
    const hours = Math.trunc(duration/3600);
    const minutes = Math.trunc((duration-hours*3600)/60);
    const seconds = Math.trunc(duration)%60;
    return (hours>0?(hours+":"):"")+padZero(minutes)+":"+padZero(seconds);
}

function padZero(num) {
    return num>=10?num:"0"+num;
}

const maxSoundHistory=50;

class RenderSounds extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {list:this.getSoundsList(), mode:"playing"};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:this.getSoundsList(),allAudio:campaign.getAudio()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "sounds");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "sounds");
    }

	render() {
        const {mode,list} = this.state;

        const header = <div className="tc mb2">
            <div className="flex tl">
                <div className="flex-auto"/>
                <SoundVolume/>
                <div className="flex-auto"/>
            </div>
            <Button disabled={!this.playing} className="mr2 minw2" size="small" color="primary" variant="outlined" onClick={this.stopAll.bind(this)}>
                    <span className="fas fa-stop pa1"/>
            </Button>
            <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                <Button variant={(mode=="playing")?"contained":null} onClick={this.clickMode.bind(this, "playing")}>Now Playing</Button>
                <Button variant={(mode=="all")?"contained":null} onClick={this.clickMode.bind(this, "all")}>All</Button>
            </ButtonGroup>
        </div>;

        return <div className="pa1 defaultbackground">
            {(mode=="playing")?<ListFilter
                key="playing"
                hideSearch
                groupBy="stopped"
                convertGroup={convertSoundGroup}
                list={this.state.list}
                render={soundListRender}
                onClick={null}
            >
                {header}
            </ListFilter>:<ListFilter 
                key="all"
                list={campaign.getAudio()}
                filters={audioListFilters}
                render={soundListRender}
                onClick={null}
            >
                {header}
            </ListFilter>}
        </div>;
    }

    clickMode(mode){
        this.setState({mode});
    }

    getSoundsList() {
        const list = campaign.getSounds();
        let sc = 0;
        let playing;
        for (let i in list) {
            list[i] = Object.assign(getSoundState(list[i]), list[i])
            if (list[i].stopped) {
                sc++;
            } else {
                playing=true;
            }
        }
        list.sort(function(a,b) {
            const sd = (a.stopped?1:0)-(b.stopped?1:0);
            if (sd) {return sd}
            return ((b.playTime||0) - (a.playTime||0))
        });

        if (sc > maxSoundHistory) {
            for (let i=list.length-1; sc > maxSoundHistory; i--) {
                //console.log("deleting", list[i]);
                campaign.deleteCampaignContent("sounds", list[i].name);
                sc--;
            }
        }
        this.playing = playing;
        return list;
    }

    stopAll() {
        const {list} = this.state;
        for (let i in list) {
            if (!list[i].stopped) {
                sounds.stopAudio(list[i].name);
            }
        }
    }
}

function convertSoundGroup(stopped) {
    return stopped?"Previous Audio Clips":"Playing";
}

const soundListFilters = [
    {
        filterName:"Type",
        fieldName:"type",
        convertField: function (v) {
            return audioTypeDescription(v);
        }
    },
    {
        filterName:"Keywords",
        fieldName:"keywords",
        convertField: function (v,it) {
            const ret= (v||"none").split(",").map(function (a){return a.trim()});
            return ret;
        }
    }
];


function soundListRender(it, onClick) {
    return <div className="overflow-hidden flex w-100 items-center" onClick={onClick}>
        <div className="flex-auto">
            <div>
                <AudioPlayer audio={it} variant="control"/>
                {it.displayName} <span className="f5">({durationString(it.duration)})</span>
            </div>
        </div>
        <div className="w-20 pr2">
            <AudioPlayer audio={it} variant="volume"/>
        </div>                
        <div className="w-20">
            <AudioPlayer audio={it} variant="progress"/>
        </div>                
    </div>
}

const playTypes=["diceVolume", "backgroundVolume", "effectVolume", "voiceVolume"];
class Sounds {
    constructor(max) {
        this.loaded = {};
        this.count=0;
        this.max = max || 50;
        this.masterVolume = 100;
        this.gainNodes={};

        try {
            this.audioCtx = new AudioContext();
            this.masterGain=this.audioCtx.createGain();
            this.masterGain.connect(this.audioCtx.destination);
            for (let t of playTypes) {
                this.gainNodes[t] = this.audioCtx.createGain();
                this.gainNodes[t].connect(this.masterGain);
            }
        } catch (err) {
            console.log("no sounds");
        }
        this.playing = {};
        this.firstTime = this.currentTime;

        this.eventSync = new EventEmitter();
        this.eventSync.setMaxListeners(500);

        globalDataListener.onChangeCampaignContent(this.handleOnSoundsChange.bind(this), "sounds");
        globalDataListener.onChangeUserSettings(this.changeVolume.bind(this));
    }

    changeVolume() {
        if (!this.audioCtx) {
            return;
        }
        const vs = campaign.volumeSettings;
        for (let t of playTypes) {
            this.gainNodes[t].gain.value = vs[t]/100;
        }
    }

    setMasterVolume(masterVolume) {
        if (!this.audioCtx) {
            return;
        }
        this.masterVolume=masterVolume;
        this.masterGain.gain.value = masterVolume/100;
        this.sendEvent("volume");
    }

    onAudioEvents(fn, event) {
        return this.eventSync.on(event||"audio", fn);
    }

    removeAudioListener(fn,event) {
        const pre = this.eventSync.listenerCount(event||"audio");
        this.eventSync.removeListener(event||"audio", fn);
        if (pre == this.eventSync.listenerCount(event||"audio")) {
            console.log("remove not correct",event||"audio", new Error(""));
        }
    }

    sendEvent(name) {
        this.eventSync.emit(name||"audio");
    }

    get currentTime() {
        
        return this.audioCtx?this.audioCtx.currentTime:0;
    }

    async fetchSourceData(url) {
        let retryCount=0;
        do {
            try {
                const response = await fetch(url);
                const buffer = await response.arrayBuffer();
                const decodedData = await this.audioCtx.decodeAudioData(buffer);

                return decodedData;
            } catch (err) {
                if (retryCount < 5) {
                    retryCount++;
                    await sleep(Math.random()*retryCount*1000);
                } else {
                    console.log("error getting audio source", url, err.status, err);
                    throw(err);
                }
            }
        } while (true);
    }

    async fetchFileSourceData(file) {
        try {
            const buffer = await file.arrayBuffer();

            const decodedData = await this.audioCtx.decodeAudioData(buffer);

            return decodedData;
        } catch (err) {
            console.log("error loading audio file", file, err);
            throw(err);
        }
    }

    mediaSourceFromSourceData(decodedData) {
        const source = new AudioBufferSourceNode(this.audioCtx);
        source.buffer = decodedData;
        return source;
    }

    playSourceData(type, decodedData, offset,loop, vol) {
        if (this.audioCtx && decodedData) {
            const source = this.mediaSourceFromSourceData(decodedData);
            if (loop) {
                source.loop=true;
            }
            let gainNode = this.gainNodes[type+"Volume"] || this.gainNodes.effectVolume;
            const newGain = this.audioCtx.createGain();
            newGain.gain.value = (vol||100)/100;
            source.connect(newGain);
            this.connectToSource(newGain, type);
            source.start(0,offset);
            source.gainNode = newGain;
            return source;
        }
    }

    connectToSource(source, type) {
        if (this.audioCtx) {
            const gainNode = this.gainNodes[type+"Volume"] || this.gainNodes.effectVolume;
            source.connect(gainNode);
        }
    }

    getSourceData(url) {
        const t=this;
        return new Promise(function (resolve, reject) {
            if (!url) {
                return null;
            }
            let li = t.loaded[url];
            let doLoad;
            if (li) {
                if (li.sourceData) {
                    li.lastAccessed = Date.now();
                    resolve(li.sourceData);
                    return;
                }
            } else {
                li = {success:[], failed:[]};
        
                t.loaded[url]=li;
                doLoad=true;
                t.count++;
            }

            if ((t.count >= t.max) && !t.reapTimer) {
                t.startReaper();
            }

            li.success.push(resolve);
            li.failed.push(reject);

            if (doLoad) {
                t.fetchSourceData(url).then(function(sourceData) {
                    const nli = t.loaded[url];
                    if (nli) {
                        nli.sourceData = sourceData;
                        nli.lastAccessed=Date.now();
                        const success = nli.success;
                        nli.success=[];
                        nli.failed=[];

                        for (let i in success) {
                            success[i](sourceData);
                        }
                    }
                }, function (err){
                    const nli = t.loaded[url];
                    delete t.loaded[url];
                    t.count--;
                    if (nli) {
                        const failed = nli.failed;
                        for (let i in failed) {
                            failed[i](err);
                        }
                    }
                });
            }
        });
    }

    startReaper() {
        this.reapTimer = setTimeout(this.doReaping.bind(this),60000);
    }

    doReaping() {
        const loaded = this.loaded;
        let newest = 0;
        for (let i in loaded) {
            const li = loaded[i];
            newest = Math.max(newest, li.lastAccessed);
        }

        let didReaping = true;

        while (didReaping && (this.count > this.max)) {
            let oldest = 0;
            didReaping = false;
            for (let i in loaded) {
                const li = loaded[i];
                oldest = Math.min(oldest||li.lastAccessed, li.lastAccessed);
            }
    
            if ((newest-oldest)>30000) {
                for (let i in loaded) {
                    const li = loaded[i];
                    if (li.lastAccessed == oldest) {
                        delete loaded[i];
                        this.count--;
                    }
                }
            }
        }

        this.reapTimer = null;
    }

    getPlayStatus(name) {
        return this.playing[name];
    }

    startUpdateProgress() {
        if (!this.updateTimer) {
            const t=this;
            this.updateTimer = setInterval(function(){
                t.doUpdateProgress();
            }, 1000);
        }
    }

    doUpdateProgress() {
        const ct = this.currentTime;
        let foundPlaying;
        //console.log("updating progress", this.playing)
        for (let i in this.playing) {
            const p = this.playing[i];
            if (p.state == "playing") {
                p.progress = (p.duration- (p.end-ct))/p.duration;
                foundPlaying=true;
            }
        }
        if (!foundPlaying) {
            clearInterval(this.updateTimer);
            this.updateTimer=null;
        } else {
            if (ct == this.firstTime){
                this.handleOnSoundsChange();
            }

            this.sendEvent();
        }
    }

    playAudio(name) {
        if (campaign.isGMCampaign()) {
            return this.sharePlayAudio(name);
        }
        return this.doPlayAudio(name);
    }

    stopAudio(name) {
        if (campaign.isGMCampaign()) {
            return this.shareStopAudio(name);
        }
        return this.doStopAudio(name);
    }

    setVolume(name, volume) {
        if (campaign.isGMCampaign()) {
            return this.shareSetVolume(name,volume);
        }
        const p = this.playing[name];
        if (p && p.source?.gainNode) {
            p.source.gainNode.gain.value = volume/100;
        }
    }

    async doPlayAudio(name, soundData, startTime) {
        if (!this.audioCtx) {
            return;
        }
        let p = this.playing[name];
        if (p&&p.source && (this.currentTime == this.firstTime)) {
            p.source.stop();
            p.source.disconnect();
            p=null;
        }
        if (!p) {
            //console.log("play audio", name);
            const audio = campaign.getAudioInfo(name);
            if (!audio) {
                return;
            }

            p = {audio, state:"loading"};
            this.playing[name] = p;
            this.sendEvent();

            try {
                if (!audio.isGroup || audio.groupType == "one") {
                    let url, volume=soundData?.volume || audio.volume;
                    if (soundData?.url) {
                        url = soundData.url;
                        volume = soundData.volume;
                    } else {
                        if (audio.isGroup && audio.groupType == "one") {
                            const clips = Object.keys(audio.clips||{});
                            if (!clips.length) {
                                throw (new Error("No clips to play"));
                            }
                            const c = Math.trunc(Math.random()*clips.length);
                            const playAudio = campaign.getAudioInfo(clips[c]);

                            if (playAudio) {
                                url = playAudio.url;
                                volume = audio.clips[clips[c]].volume || playAudio.volume || 100;
                            } else {
                                throw (new Error("No clips to play"));
                            }
                        } else {
                            url = audio.url
                        }
                    }

                    const decodedData = await this.getSourceData(url);
                    if (this.playing[name]==p) { // make sure playback wasn't stopped while loading
                        p.duration = decodedData.duration;
                        const offset = startTime?((Date.now()-startTime)/1000)%p.duration:0;
                        const source = this.playSourceData(audio.type, decodedData,offset, audio.type=="background", volume);
                        source.onended = this.onEnded.bind(this, name, source);
        
                        Object.assign(p, {decodedData, end:this.currentTime+p.duration-(offset||0), source, state:"playing", progress:offset/p.duration});
                    }
                } else {
                    const source = new GroupSource(audio, soundData?.volume);
                    await source.load();
                    if (this.playing[name]==p) { // make sure playback wasn't stopped while loading
                        p.duration = audio.duration;
                        const offset = startTime?((Date.now()-startTime)/1000)%p.duration:0;
                        this.connectToSource(source, audio.type);
                        source.start(0, offset);
                        source.onended = this.onEnded.bind(this, name, source);
        
                        Object.assign(p, {end:this.currentTime+p.duration-(offset||0), source, state:"playing", progress:offset/p.duration});
                    }
                }
                this.sendEvent();
                this.startUpdateProgress();
                //console.log("playing audio", audio);

            } catch (err) {
                console.log("error loading sound", err);
                delete this.playing[name];
                this.sendEvent();
            }
        }
    }

    doStopAudio(name) {
        let p = this.playing[name];
        if (p) {
            if (p.source) {
                p.source.stop();
                p.source.disconnect();
            }
            delete this.playing[name];
            this.sendEvent();
        }
    }

    sharePlayAudio(name) {
        const audio = campaign.getAudioInfo(name);

        if (!audio) {return}

        let newSound;
        if (audio.isGroup && audio.groupType=="one") {
            const clips = Object.keys(audio.clips||{});
            let url, volume;
            if (!clips.length) {
                return;
            }
            const c = Math.trunc(Math.random()*clips.length);
            const playAudio = campaign.getAudioInfo(clips[c]);
            if (playAudio) {
                url = playAudio.url;
                volume = audio.clips[clips[c]].volume || playAudio.volume || 100;
                newSound={
                    name,
                    url,
                    volume,
                    displayName:audio.displayName||"",
                    playTime:Date.now(),
                    serverPlayTime:serverTimestamp(),
                    type:audio.type||"effect",
                    state:"playing",
                    playId:campaign.newUid(),
                    duration:audio.duration
                }
            } else {
                return;
            }
        } else {
            if (!audio.url && !audio.isGroup) {
                return;
            }
            const oldSound = campaign.getSoundsInfo(name);
            newSound={
                name,
                displayName:audio.displayName||"",
                playTime:Date.now(),
                serverPlayTime:serverTimestamp(),
                type:audio.type||"effect",
                state:"playing",
                playId:campaign.newUid(),
                duration:audio.duration
            }
            if (oldSound?.volume) {
                newSound.volume = oldSound.volume;
            }
        }
        campaign.updateCampaignContent("sounds", newSound);
    }

    shareStopAudio(name) {
        const sound = campaign.getSoundsInfo(name);
        if (sound) {
            const newSound = Object.assign({}, sound);
            newSound.state="stopped";
            campaign.updateCampaignContent("sounds", newSound);
        }
    }

    shareSetVolume(name,volume) {
        const sound = campaign.getSoundsInfo(name);
        if (sound) {
            const newSound = Object.assign({}, sound);
            newSound.volume=volume;
            
            campaign.updateCampaignContent("sounds", newSound);
        }
    }

    onEnded(name, source) {
        let p = this.playing[name];
        if (p?.source == source) {
            this.stopAudio(name);
        }
    }

    handleOnSoundsChange() {
        const sounds = campaign.getWatchKey()?[]:campaign.getSounds();
        const now= Date.now();
        const found = {};

        for (let i in sounds) {
            const s = sounds[i];
            const {stopped, recentlyStarted, startTime} = getSoundState(s);
            
            if (!stopped) {
                //console.log("check sound", stopped, offset, recentlyStarted, s);
                found[s.name]=true;
                const p = this.playing[s.name];
                if (!p || (this.currentTime == this.firstTime)) {
                    this.doPlayAudio(s.name, s, recentlyStarted?0:startTime);
                } else if (s.volume && p.source?.gainNode) {
                    p.source.gainNode.gain.value = s.volume/100;
                }
            } else {
                //console.log("stopped", stopped, recentlyStarted, s);
            }
        }
        for (let i in this.playing) {
            if (!found[i]) {
                //console.log("stopping", i, found, sounds);
                this.doStopAudio(i);
            }
        }
    }
}

function getClipsFromAudio(audio) {
    const clips=[];
    const loopAlways = audio.type=="background";
    for (let i in audio.clips) {
        const c = audio.clips[i];
        const a = campaign.getAudioInfo(i);
        if (a?.url) {
            clips.push({url:a.url, volume:c.volume, loop:loopAlways || (a.type=="background"), duration:audio.duration});
        }
    }
    return clips;
}

class GroupSource {
    constructor(audio, volume) {
        this.clips = (clips||[]).concat([]);

        const clips=[];
        this.loopAlways = audio.type=="background";
        for (let i in audio.clips) {
            const c = audio.clips[i];
            const a = campaign.getAudioInfo(i);
            if (a?.url) {
                clips.push({url:a.url, volume:c.volume || a.volume || 100, loop:this.loopAlways || (a.type=="background"), duration:audio.duration});
            }
        }

        this.audio=audio;
        this.clips = clips;
        this.gainNode = sounds.audioCtx.createGain();
        this.gainNode.gain.value=(volume||100)/100;
    }

    async load() {
        for (let i in this.clips) {
            const c = this.clips[i];
            c.decodedData = await sounds.getSourceData(c.url);
        }
    }

    connect(node) {
        this.gainNode.connect(node);
    }

    disconnect() {
        this.gainNode.disconnect();
    }

    start(d, offset) {
        if (this.started) {
            throw(new Error("cannot restart"));
        }
        this.started=true;

        offset = offset ||0;
        for (let i in this.clips) {
            const c = this.clips[i];
            c.source = sounds.mediaSourceFromSourceData(c.decodedData);
            c.gain = sounds.audioCtx.createGain();
            c.gain.gain.value = (c.volume||100)/100;
            c.source.connect(c.gain);
            c.gain.connect(this.gainNode);

            if (c.loop) {
                c.source.loop = true;
                offset = offset % c.decodedData.duration;
            }

            c.source.start(0, offset);
            if (!this.loopAlways) {
                this.finishTimer = setTimeout(this.finished.bind(this), this.audio.duration*1000);
            }
        }
    }

    finished() {
        this.stop();
        if (this.onended) {
            this.onended();
        }
    }

    stop() {
        for (let i in this.clips) {
            const c = this.clips[i];
            if (c.source) {
                c.source.stop();
                c.source.disconnect();
                c.source=null;
            }
            if (c.gain) {
                c.gain.disconnect();
                c.gain = null;
            }
        }
        if (this.finishTimer) {
            clearTimeout(this.finishTimer);
            this.finishTimer=null;
        }
    }
}

function getSoundState(s) {
    const now= Date.now();
    let startTime = s.playTime;
    if (s.serverPlayTime?.nanoseconds) {
        startTime = s.serverPlayTime.toMillis()- campaign.serverTimeSkew;
    }
    const offset = Math.max(0,now-startTime)/1000;
    const recentlyStarted = (offset < 5)&&(s.type!="background");
    const stopped = (s.state=="stopped") || 
        (!recentlyStarted &&(offset > s.duration)&&(s.type!="background"));
    return {stopped, recentlyStarted, startTime};
}

const sounds = new Sounds();

export {
    RenderAudioList,
    RenderAudio,
    printAudio,
    RenderSounds,
    AudioHeader,
    AudioDialog,
    deleteAudio,
    sounds,
    SoundTool,
    getAudioThumbnail,
    audioListFilters
}