const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import TextField from '@material-ui/core/TextField';
const {Rendersource} = require("./rendersource.jsx");
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {getAnchorPos, AskYesNo, SelectVal,DeleteWithConfirm,TextVal,NumberAdjustPlusMinus,CheckVal, defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {oneTo20, pluralString,environmentTypeList} = require('../lib/stdvalues.js');
const {MonsterPicker,MonsterDetails} = require('./rendermonster.jsx');
const {LinkHref} = require('./renderhref.jsx');
const {DicePopup,getDiceFromString,doRoll} = require('../src/diceroller.jsx');
import Tooltip from '@material-ui/core/Tooltip';
const {ItemDialog} = require('./items.jsx');
const {CustomDialog} = require('./customtable.jsx');

let PickBookDialog;

class RenderRandomTables extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state={};
    }

    onDataChange() {
        this.setState({list:campaign.getRandomTables()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "randomtables");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "randomtables");
    }

	render() {
        return <div>
            <ListFilter 
                list={campaign.getRandomTables()}
                filters={randomEncountersFilters}
                onClick={this.doClick.bind(this)}
            />
            <RandomTableDialog open={!!this.state.showRandomTable} randomtable={this.state.showRandomTable} onClose={this.hideDetails.bind(this)} addRandomEncounter={this.props.addRandomEncounter?this.addRandomEncounter.bind(this):null}/>
        </div>;
    }

    doClick(name) {
        if (this.props.onClick) {
            this.props.onClick(name);
        } else {
            this.setState({showRandomTable:name});
        }
    }

    addRandomEncounter(row) {
        this.setState({showRandomTable:false});
        this.props.addRandomEncounter(row);
    }

    hideDetails(){
        this.setState({showRandomTable:null});
    }
}

const randomEncountersFilters=[
    {
        filterName:"Environment",
        fieldName:"environments",
        convertField: function (v) {
            if (!v || (Array.isArray(v) && !v.length)) {
                return "none";
            } else {
                return v;
            }
        }
    },
    {
        filterName:"Level",
        fieldName:"levels",
        sortFn: function (a,b) { return Number(a)-Number(b)}
    },
    defaultSourceFilter,
    defaultBookFilter
];

class RandomTable extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({info:campaign.getRandomTableInfo(this.props.randomtable)})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "randomtables");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "randomtables");
    }

	render() {
        const table = campaign.getRandomTableInfo(this.props.randomtable);
        if (!table) {
            return null;
        }
        const rows = table.rows || [];
        const list = [];
        const showAddEncounter = (this.props.pageSync && this.props.pageSync.listenerCount("addRandomEncounter")) || this.props.addRandomEncounter || this.props.addToEncounter;
        let sum=0;
        let dieHeader="d100";
        let usePercent = true;

        for (let i in rows) {
            const row = rows[i];
            sum = sum+(row.weight||1);
        }

        if ([4,6,8,10,12,20].includes(sum)) {
            usePercent=false;
            dieHeader="d"+sum;
        }

        let runTotal = 0;
        for (let i in rows) {
            const row = rows[i];
            const monsters=row.monsters||{};
            const monsterlist = [];
            let min = Math.trunc(runTotal+1);

            if (usePercent) {
                runTotal += (row.weight||1)*100/sum;
            } else {
                runTotal+=(row.weight||1);
            }

            let max = Math.trunc(runTotal);

            if (usePercent) {
                min = percentFmt(min);
                max = percentFmt(max)
            }

            for (let x in monsters) {
                const mon=monsters[x];
                const monInfo = campaign.getMonster(x)

                monsterlist.push(<span key={x}>
                    {monsterlist.length?", ":null}
                    {mon.dieCount?mon.dieCount+"d"+mon.dieType:null}
                    {mon.dieCount&&mon.diePlus?"+":null}
                    {mon.diePlus?mon.diePlus:null} <LinkHref href={"#monster?id="+encodeURIComponent(x)}>{pluralString((monInfo&&monInfo.displayName)||"", mon.dieCount*2+mon.diePlus)}</LinkHref>
                </span>);
            }

            list.push(<tr key={i}>
                {showAddEncounter?<td className="notetext hoverhighlight" onClick={this.addRandomEncounter.bind(this, row)}><Tooltip title="add random encounter"><div className="ph2 fas fa-dragon"/></Tooltip></td>:null}
                <td className="tc nowrap"><div className="ph1">{(min==max)?min:(min+"-"+max)}</div></td>
                <td>{row.bookname?<LinkHref href={"#book?id="+encodeURIComponent(row.bookname)+"&chapter="+encodeURIComponent(row.chapter)+"&section="+encodeURIComponent(row.section)+"&subsection="+encodeURIComponent(row.subsection)}><span className="hoverhighlight pa1 fas fa-book-open"/></LinkHref>:null}</td>
                <td className="w-100">
                    {monsterlist}{row.text&&monsterlist.length?". ":null}{row.text}
                </td>
            </tr>);
        }

        return <div className="stdcontent">
            {this.props.noTitle?null:<h3>{table.displayName}</h3>}
            {(table.description||(table.environments&&table.environments.length))?<div className="mb1">
                {table.description}
                {((table.startLevel||1)==(table.endLevel||1))?" Level "+(table.startLevel||1):" Levels "+table.startLevel+"-"+table.endLevel}.&nbsp;
                {table.environments&&table.environments.length?<span> Environments: {table.environments.join(", ")}</span>:null}
            </div>:null}
            
            <table className="w-100">
                <tbody>
                    <tr>{showAddEncounter?<td></td>:null}<td className="tc b"><span className="dodieroll" onClick={this.doDieRoll.bind(this,dieHeader)}>{dieHeader}</span></td><td></td><td className="b">Encounter</td></tr>
                    {list}
                </tbody>
            </table>
            {this.props.noSource?null:<Rendersource entry={table}/>}
            <DicePopup open={this.state.showDice} roll={this.state.showRoll} anchorPos={this.state.anchorPos} onClose={this.hideRoll.bind(this)}/>
            <PickRandomEncounterInfoDialog open={this.state.showAdd} row={this.state.addRow} onClose={this.closeAdd.bind(this)}/>
        </div>;
    }

    doDieRoll(droll){
        const dice = getDiceFromString(droll);
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls};

        this.setState({showRoll:roll, showDice:true, anchorPos:getAnchorPos(event)});
    }

    hideRoll(){
        this.setState({showDice:false});
    }

    addRandomEncounter(tablerow) {
        const row=Object.assign({}, tablerow);
        const table = campaign.getRandomTableInfo(this.props.randomtable);

        row.tableDisplayName = table.displayName;

        if (this.props.addRandomEncounter) {
            this.props.addRandomEncounter(row);
        } else if (this.props.addToEncounter) {
            this.setState({showAdd:true, addRow:row});
        } else {
            this.props.pageSync.emit("addRandomEncounter", row);
        }
    }

    closeAdd(row) {
        if (row){
            this.props.addToEncounter(row);
        }
        this.setState({showAdd:false, addRow:null});
    }
}

function printRandomTable(id, noTitle, header) {
    const table = campaign.getRandomTableInfo(id);
    if (!table) {
        return null;
    }
    const list=[];
    if (!noTitle) {
        list.push(`<h${header}>${table.displayName}</h${header}>`);
    }
    const rows = table.rows || [];
    let sum=0;
    let dieHeader="d100";
    let usePercent = true;

    for (let i in rows) {
        const row = rows[i];
        sum = sum+(row.weight||1);
    }

    if ([4,6,8,10,12,20].includes(sum)) {
        usePercent=false;
        dieHeader="d"+sum;
    }

    const tlist=[];
    let runTotal = 0;
    for (let i in rows) {
        const row = rows[i];
        const monsters=row.monsters||{};
        const monsterlist = [];
        let min = Math.trunc(runTotal+1);

        if (usePercent) {
            runTotal += (row.weight||1)*100/sum;
        } else {
            runTotal+=(row.weight||1);
        }

        let max = Math.trunc(runTotal);

        if (usePercent) {
            min = percentFmt(min);
            max = percentFmt(max)
        }

        for (let x in monsters) {
            const mon=monsters[x];
            const monInfo = campaign.getMonster(x)

            monsterlist.push(`${mon.dieCount?mon.dieCount+"d"+mon.dieType:""}${mon.dieCount&&mon.diePlus?"+":""}${mon.diePlus?mon.diePlus:""} ${pluralString((monInfo&&monInfo.displayName)||"", mon.dieCount*2+mon.diePlus)}`);
        }

        tlist.push(`<tr><td style="text-align:center">${(min==max)?min:(min+"-"+max)}</td><td>${monsterlist.join(", ")}${row.text&&monsterlist.length?". ":""}${row.text||""}</td></tr>`);
    }

    if (table.description||(table.environments&&table.environments.length)) {
        list.push(`<p>${table.description||""}${((table.startLevel||1)==(table.endLevel||1))?" Level "+(table.startLevel||1):" Levels "+table.startLevel+"-"+table.endLevel}.&nbsp;${table.environments?.length?` Environments: ${table.environments.join(", ")}`:""}</p>`)
    }

    list.push(`<table><tr><td style="text-align:center"><b>${dieHeader||""}</b></td><td><b>Encounter</b></td></tr>${tlist.join("\n")}</table>`);
    return list.join("\n");
}

function percentFmt(n) {
    if (n < 10){
        return "0"+n;
    } if (n==100) {
        return "00";
    }
    return n.toString();
}

class RandomTableDialog extends React.Component {
    constructor(props) {
        super(props);

           this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:false});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        if (this.state.editable) {
            return <EditRandomTable open randomtable={this.props.randomtable} onClose={this.props.onClose}/>
        }
        const table = campaign.getRandomTableInfo(this.props.randomtable);

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>{(table&&table.displayName) || this.props.randomtable}</DialogTitle>
            <DialogContent>
                <RandomTable noTitle randomtable={this.props.randomtable} addRandomEncounter={this.props.addRandomEncounter}/>
            </DialogContent>
            <DialogActions>
                {!this.props.addRandomEncounter?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    clickEdit() {
        this.setState({editable:true});
    }
}
    
class PickRandomEncounterInfoDialog extends React.Component {
    constructor(props) {
        super(props);

           this.state= { };
    }

    componentDidMount() {
        if (this.props.open){
            this.rollCounts();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open!= prevProps.open)) {
            this.rollCounts();
        }
    }

    rollCounts() {
        const monsters = this.props.row.monsters||{};
        const items = this.props.row.items||{};
        const coins = this.props.row.coins||{};
        const custom = this.props.row.custom;
        const monCounts = {};
        const itemCounts = {};
        const coinCounts = {};
        const customCounts = [];
        let foundRolls=false;
        for (let i in monsters) {
            const mon=monsters[i];
            let sum = mon.diePlus;
            if (mon.dice) {
                sum = doRoll(mon.dice).sum;
                if (Object.keys(mon.dice).length>2) { // has at least on die roll above bonus,extrabonus
                    foundRolls=true;
                }
            } else {
                for (let x=0; x<(mon.dieCount||0); x++) {
                    sum += Math.trunc(Math.random()*mon.dieType+1);
                    foundRolls=true;
                }
            }
            monCounts[i]=sum;
        }
        for (let i in items) {
            const it=items[i];
            let sum = doRoll(it.dice).sum;
            if (Object.keys(it.dice).length>2) { // has at least on die roll above bonus,extrabonus
                foundRolls=true;
            }
            itemCounts[i]=sum;
        }
        for (let i in coins) {
            let sum = doRoll(coins[i]).sum;
            if (Object.keys(coins[i]).length>2) { // has at least on die roll above bonus,extrabonus
                foundRolls=true;
            }
            coinCounts[i]=sum;
        }
        for (let i in custom) {
            const c = custom[i];
            let sum = doRoll(c.dice).sum;
            if (Object.keys(c.dice).length>2) { // has at least on die roll above bonus,extrabonus
                foundRolls=true;
            }
            customCounts[i]=sum;
        }
        this.setState({monCounts,itemCounts,coinCounts,customCounts,foundRolls})
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const list = [];
        const items = this.props.row.items||{};
        const custom = this.props.row.custom||[];
        const monCounts = this.state.monCounts;
        const itemCounts = this.state.itemCounts;
        const coinCounts = this.state.coinCounts;
        const customCounts = this.state.customCounts;
        this.treasureOnly=true;

        for (let i in coinCounts) {
            list.push(<tr key={i}>
                <td className="tc nowrap">
                    <NumberAdjustPlusMinus value={coinCounts[i]} onChange={this.changeCoin.bind(this, i)}/>
                </td>
                <td className="w-100">
                    {i.toUpperCase()}
                </td>
            </tr>);
        }
        for (let i in monCounts) {
            const mon = campaign.getMonster(i);
            list.push(<tr key={i}>
                <td className="tc nowrap">
                    <NumberAdjustPlusMinus value={monCounts[i]} onChange={this.changeMonster.bind(this, i)}/>
                </td>
                <td className="w-100">
                    <a onClick={this.onShowMonster.bind(this,i)}>{pluralString((mon&&mon.displayName)||"", monCounts[i])}</a>
                </td>
            </tr>);
            this.treasureOnly=false;
        }

        for (let i in itemCounts) {
            const it = items[i];
            list.push(<tr key={i}>
                <td className="tc nowrap">
                    <NumberAdjustPlusMinus value={itemCounts[i]} onChange={this.changeItem.bind(this, i)}/>
                </td>
                <td className="w-100">
                    <a onClick={this.onShowItem.bind(this,it.item)}>{pluralString((it.item.displayName)||"", itemCounts[i])}</a>
                </td>
            </tr>);
        }

        for (let i in customCounts) {
            const c = custom[i];
            const it = campaign.getCustom(c.type, c.id);
            if (it) {
                list.push(<tr key={"c"+i}>
                    <td className="tc nowrap">
                        <NumberAdjustPlusMinus value={customCounts[i]} onChange={this.changeCustom.bind(this, i)}/>
                    </td>
                    <td className="w-100">
                        <a onClick={this.onShowCustom.bind(this,c.type, c.id)}>{pluralString((it.displayName)||"", customCounts[i])}</a>
                    </td>
                </tr>);
                this.treasureOnly=false;
            }
        }

        return  <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.doClose.bind(this)}>Add to Encounter</DialogTitle>
            <DialogContent>
                <table className="w-100 stdlist">
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                {this.state.foundRolls?<Button onClick={this.rollCounts.bind(this)} color="primary">
                    Reroll
                </Button>:null}
                <Button onClick={this.clickAdd.bind(this)} color="primary">
                    Add
                </Button>
                <Button onClick={this.doClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ItemDialog open={this.state.showItem} item={this.state.showItem} onClose={this.onShowItem.bind(this,null)}/>
            <MonsterDetails open={this.state.showMonster} monster={this.state.showMonster} onClose={this.onShowMonster.bind(this,null)}/>
            <CustomDialog open={this.state.showCustomType} type={this.state.showCustomType} id={this.state.showCustomId} onClose={this.onShowCustom.bind(this,null, null)}/>
            <AskYesNo open={this.state.showQuestion} onClose={this.answerQuestion.bind(this)}>Do you want to add treasure directly to shared treasure?</AskYesNo>
        </Dialog>;
    }

    onShowItem(showItem){
        this.setState({showItem});
    }

    onShowMonster(showMonster){
        this.setState({showMonster});
    }

    onShowCustom(type,id){
        this.setState({showCustomType:type, showCustomId:id});
    }

    doClose() {
        this.props.onClose();
    }

    changeCoin(coin, val) {
        const coinCounts = Object.assign({}, this.state.coinCounts);
        coinCounts[coin]=val;
        this.setState({coinCounts})
    }

    changeMonster(mon, val) {
        const monCounts = Object.assign({}, this.state.monCounts);
        monCounts[mon]=val;
        this.setState({monCounts})
    }

    changeItem(item, val) {
        const itemCounts = Object.assign({}, this.state.itemCounts);
        itemCounts[item]=val;
        this.setState({itemCounts})
    }

    changeCustom(i, val) {
        const customCounts = this.state.customCounts.concat([]);
        customCounts[i]=val;
        this.setState({customCounts})
    }

    clickAdd() {
        const {addRowToCombat} = require('./encountermonsterlist.jsx');
        if (this.props.addToEncounter && (this.props.addToEncounter ==addRowToCombat) && this.treasureOnly) {
            this.setState({showQuestion:true})
        } else {
            this.doAdd(true);
        }
    }

    answerQuestion(addToSharedTreasure) {
        this.doAdd(!addToSharedTreasure);
        this.setState({showQuestion:false})
    }

    doAdd(noSharedTreasure) {
        const row = Object.assign({}, this.props.row);
        row.monsters = this.state.monCounts;
        row.itemCounts= this.state.itemCounts||{};
        row.coins=this.state.coinCounts;
        row.customCounts = this.state.customCounts;
        row.noSharedTreasure = noSharedTreasure||false;
        this.props.onClose(row);
    }

}
    
class PickRandomCount extends React.Component {
    constructor(props) {
        super(props);

           this.state= { };
    }

    componentDidMount() {
        if (this.props.open){
            this.rollCounts();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open!= prevProps.open)) {
            this.rollCounts();
        }
    }

    rollCounts() {
        this.setState({count:doRoll(this.props.dice).sum,foundRolls:(Object.keys(this.props.dice).length>2)});
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        return  <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.cancel.bind(this)}>{this.props.title}</DialogTitle>
            <DialogContent>
                <div className="mv1">
                    <NumberAdjustPlusMinus value={this.state.count} onChange={this.changeCount.bind(this)}/> {this.props.children}
                </div>
            </DialogContent>
            <DialogActions>
                {this.state.foundRolls?<Button onClick={this.rollCounts.bind(this)} color="primary">
                    Reroll
                </Button>:null}
                <Button onClick={this.clickAdd.bind(this)} color="primary">
                    Add
                </Button>
                <Button onClick={this.cancel.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    cancel(){
        this.props.onClose();
    }

    clickAdd() {
        this.props.onClose(this.state.count);
    }

    changeCount(val) {
        this.setState({count:val})
    }
}

class EditRandomTable extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {table:props.randomtable?campaign.getRandomTableInfo(props.randomtable):{name:campaign.newUid()}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.randomtable != prevProps.randomtable)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({table:this.props.randomtable?campaign.getRandomTableInfo(this.props.randomtable):{name:campaign.newUid()}});
        }
    }

    render() {
        const table = this.state.table;
        if (!this.props.open || !table) {
            return null;
        }
        const rows = table.rows || [];
        const environments = table.environments||[];
        const type = table.type;
        const list = [];
        const envList=[];

        for (let i in environmentTypeList) {
            const e=environmentTypeList[i];
            envList.push(<div key={e} className="w-16 minw4"><CheckVal label={e} value={environments.includes(e)} onChange={this.toggleEnvironment.bind(this, e)}/></div>);
        }

        for (let i in rows) {
            const row = rows[i];
            list.push(<EditRandomRow key={i} row={row} type={type} onChange={this.onChangeRow.bind(this, i)} onDeleteRow={this.onDeleteRow.bind(this,i)} onChangeFilter={this.onChangeFilter.bind(this)} listFilter={this.state.listFilter}/>);
        }
        list.push(<EditRandomRow key={rows.length} row={{}} type={type} onChange={this.onChangeRow.bind(this, rows.length)} onChangeFilter={this.onChangeFilter.bind(this)} listFilter={this.state.listFilter}/>);

        return  <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                Random Encounter Table
            </DialogTitle>
            <DialogContent className="stdcontent">
                <TextVal fullWidth inputProps={{className:"titletext titlecolor f2"}} text={table.displayName} multiline rows={1} onChange={this.onChangeField.bind(this, "displayName")} helperText="Display Name"/>
                <TextVal fullWidth inputProps={{className:"titletext titlecolor"}} className="mv2" variant="outlined" text={table.description} multiline rows={1} onChange={this.onChangeField.bind(this, "description")} label="Description"/>
                <div>
                    Start Level <SelectVal isNum values={oneTo20} value={table.startLevel||1} onClick={this.onChangeField.bind(this, "startLevel")}/>&nbsp;
                    End Level <SelectVal isNum values={oneTo20} value={table.endLevel||1} onClick={this.onChangeField.bind(this, "endLevel")}/>
                </div>
                <div className="hk-well mv1">
                    <div className="flex flex-wrap tl">
                        {envList}
                    </div>
                </div>
                <h2>Random Table</h2>
                <table className="w-100">
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.saveData.bind(this)} color="primary" disabled={!table.displayName}>
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onChangeFilter(listFilter) {
        this.setState({listFilter});
    }

    saveData() {
        const table = Object.assign({}, this.state.table);
        const startLevel = table.startLevel||1;
        let endLevel = table.endLevel||1;
        const levels = [];

        if (!(startLevel < endLevel )) {
            table.endLevel = endLevel = startLevel;
        }

        for (let i=startLevel; i<=endLevel; i++){
            levels.push(i.toString());
        }
        table.levels = levels;

        campaign.updateCampaignContent("randomtables", table);
        this.props.onClose(table.name);
    }

    onChangeField(field,val) {
        const table = Object.assign({}, this.state.table);
        if (val == "none") {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }

        table[field] = val;
        if (field == "startLevel") {
            if (!(table.startLevel <= table.endLevel)) {
                table.endLevel=table.startLevel
            }
        } else if (field == "endLevel") {
            if (!(table.startLevel <= table.endLevel)) {
                table.startLevel=table.endLevel;
            }
        }
        this.setState({table});
    }

    toggleEnvironment(env) {
        const table=Object.assign({}, this.state.table);
        const environments = (table.environments||[]).concat([]);
        const pos = environments.indexOf(env);
        if (pos<0) {
            environments.push(env);
        } else {
            environments.splice(pos,1);
        }
        table.environments = environments;
        this.setState({table});
    }

    onChangeRow(i, row) {
        const rows = ((this.state.table||{}).rows || []).concat([]);
        rows[i] = row;
        this.onChangeField("rows", rows);
    }

    onDeleteRow(i) {
        const rows = ((this.state.table||{}).rows || []).concat([]);
        rows.splice(i,1);
        this.onChangeField("rows", rows);
    }
}

const dieCounts=[
    {name:"d2", value:2},
    {name:"d3", value:3},
    {name:"d4", value:4},
    {name:"d6", value:6},
    {name:"d8", value:8},
    {name:"d10", value:10},
    {name:"d12", value:12},
    {name:"d20", value:20}
];

class EditRandomRow extends React.Component {
    constructor(props) {
        super(props);
        // prevent require loop
        if (!PickBookDialog) {
            PickBookDialog = require('./book.jsx').PickBookDialog;
        }

	    this.state= {};
    }

    render() {
        const row = this.props.row;
        const monsters=row.monsters||{};
        const monsterlist = [];

        for (let i in monsters) {
            const mon=monsters[i];
            const monInfo = campaign.getMonster(i);
            monsterlist.push(<div key={i} className="mb1">
                <NumberAdjustPlusMinus value={mon.dieCount||0} onChange={this.changeMonster.bind(this, i, "dieCount")}/>&nbsp;
                <SelectVal values={dieCounts} value={mon.dieType} onClick={this.changeMonster.bind(this, i, "dieType")}/>
                + <NumberAdjustPlusMinus value={mon.diePlus||0} onChange={this.changeMonster.bind(this, i, "diePlus")}/> {monInfo&&monInfo.displayName}
            </div>);
        }

        return <tr>
            <td><SelectVal helperText="weight" value={row.weight||1} values={oneTo20} onClick={this.onChangeField.bind(this, "weight")}/></td>
            <td className="w-100">
                <div className="pv1">
                    {monsterlist}
                    <TextVal fullWidth text={row.text} onChange={this.onChangeField.bind(this, "text")} placeholder="text"/>
                </div>
            </td>
            <td className="pv1">
                <div className="mb1">
                    <Button variant="outlined" size="small" onClick={this.showMonsterPicker.bind(this)} color="primary">
                        Monsters
                    </Button>
                </div>
                <div>
                    <Button variant={row.bookname?"contained":"outlined"} size="small" onClick={this.pickBookLocation.bind(this)} color="primary">
                        Book
                    </Button>
                </div>
            </td>
            <td>{this.props.onDeleteRow?<DeleteWithConfirm className="pa1" onClick={this.props.onDeleteRow}/>:null}</td>
            <MonsterPicker multiSelect onClose={this.pickMonsters.bind(this)} open={this.state.showMonsterPicker||false} selected={this.state.prevSelected} onChangeFilter={this.props.onChangeFilter} listFilter={this.props.listFilter}/>
            <PickBookDialog open={this.state.showPickBook} bookname={row.bookname} chapter={row.chapter} section={row.section} subsection={row.subsection} onClose={this.pickBook.bind(this)}/>
        </tr>
    }

    onChangeField(prop, val) {
        const row = Object.assign({}, this.props.row||{});
        row[prop] = val;
        this.props.onChange(row);
    }

    showMonsterPicker() {
        const row = this.props.row;
        const sel = row.monsters||{};
        const prevSelected = {};

        for (let i in sel) {
            prevSelected[i]=1;
        }
        this.setState({prevSelected, showMonsterPicker:true})
    }

    pickMonsters(selected) {
        if (selected) {
            const row = this.props.row;
            const sel = row.monsters||{};
            const newSelected = {};
            for (let i in selected) {
                if (sel[i]) {
                    newSelected[i] = sel[i];
                } else {
                    newSelected[i] = {dieCount:0, dieType:4, diePlus:1}
                }
            }
            this.onChangeField("monsters", newSelected);
        }
        this.setState({showMonsterPicker:false});
    }

    changeMonster(monster, field, val) {
        const row = this.props.row;
        const sel = Object.assign({}, row.monsters||{});
        sel[monster] = Object.assign({}, sel[monster]);
        sel[monster][field] = val;
        if (!sel[monster].dieCount && !sel[monster].diePlus) {
            delete sel[monster];
        }
        this.onChangeField("monsters", sel);
    }

    pickBookLocation() {
        this.setState({showPickBook:true});
    }

    pickBook(bookname, chapter, section, subsection) {
        if (bookname) {
            const row = Object.assign({}, this.props.row||{});

            row.bookname=bookname;
            row.chapter=chapter;
            row.section=section;
            row.subsection=subsection;

            this.props.onChange(row);
        }
        this.setState({showPickBook:false});
    }

}


class RandomTablePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render () {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            open
            maxWidth="sm"
            classes={{paper:"minvh-80"}}
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Pick Book</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={campaign.getRandomTables()}
                    onClick={this.pickTable.bind(this)}
                    filters={randomEncountersFilters}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClose() {
        this.props.onClose();
    }

    pickTable(table) {
        this.props.onClose(table);
    }
}

class NewRandomTable extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    createRandomTable() {
        const name = this.state.name;
        let newRandomTable = {};
        const baseItem = campaign.getRandomTableInfo(this.state.baseRandomTable);
        if (baseItem) {
            newRandomTable = Object.assign({},baseItem);
        }
        Object.assign(newRandomTable, {displayName:name, name:campaign.newUid()});

        this.setState({name:""});
        campaign.updateCampaignContent("randomtables", newRandomTable);
        this.props.onClose(newRandomTable.name);
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:"", baseRandomTable:null});
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const name=this.state.name||"";
        const baseItem = campaign.getRandomTableInfo(this.state.baseRandomTable);
    
        return <Dialog
            open={this.props.open||false}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create Random Encounter Table
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    helperText="Name"
                    fullWidth
                />
                {baseItem?<div className="mv1">based on {baseItem.displayName}</div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showPicker.bind(this)} color="primary">
                    Pick Table to Copy
                </Button>
                <Button disabled={!name} onClick={this.createRandomTable.bind(this)} color="primary">
                    New
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <RandomTablePicker open={this.state.pickBase} onClose={this.chooseTable.bind(this)}/>
        </Dialog>;
    }

    showPicker() {
        this.setState({pickBase:true});
    }
    
    chooseTable(baseRandomTable) {
        if (baseRandomTable) {
            this.setState({baseRandomTable});
        }
        this.setState({pickBase:false});
    }
}

class RandomTablesHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true, editName:campaign.newUid()});
    }

    render() {
        return <span>
            Random Encounter Tables
            <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>
            <NewRandomTable open={this.state.showNew} onClose={this.closeNew.bind(this)}/>
            <EditRandomTable open={this.state.showEdit} onClose={this.closeEdit.bind(this)} randomtable={this.state.editName}/>
        </span>;
    }

    closeNew(name) {
        this.setState({showNew:false, editName:name, showEdit:!!name});
    }

    closeEdit() {
        this.setState({showEdit:false});
    }
}

export {
    RenderRandomTables,
    RandomTable,
    printRandomTable,
    RandomTableDialog,
    NewRandomTable,
    RandomTablesHeader,
    EditRandomTable,
    PickRandomEncounterInfoDialog,
    PickRandomCount
}