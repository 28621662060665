const {campaign,globalDataListener,getExtensionEntryCheckFn} = require('../lib/campaign.js');

const React = require('react');
const {RenderFeature,FeatureListEdit,renderPrintFeatures} = require('./features.jsx');
const {Rendersource} = require("./rendersource.jsx");
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const {DeleteEntry, TextVal,SelectVal, CheckVal, defaultSourceFilter,defaultGamesystemFilter,customTypeBookFilter,customTypeContentMapBookFilter,MaxNumberAdjust} = require('./stdedit.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {ExtraArtList,ArtZoomList, FloatArt,FloatCardArt,printFloatArt} = require('./renderart.jsx');
const {getDiceFromString} = require('../src/diceroller.jsx');
const {AddChatEntry,getCustomHref} = require('./renderhref.jsx');
const {fTemplateName} = require('../lib/monobj.js');
import {htmlFromEntry} from "../lib/entryconversion.js";
const {gamesystemAnyOptions,spellcasterOptions,abilityNames} = require('../lib/stdvalues.js');


class CustomList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {list:campaign.getSortedCustomList(this.props.type)};
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.showNewCustomFn = this.showNewCustom.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSortedCustomList(this.props.type)})
    }

    componentDidMount() {
        if (this.props.pageSync){
           this.props.pageSync.on("action", this.showNewCustomFn);
        }
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "customTypes");
    }

    componentWillUnmount() {
        if (this.props.pageSync){
            this.props.pageSync.removeListener("action", this.showNewCustomFn);
        }
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "customTypes");
    }

    showNewCustom(action) {
        if (action != "newcustom") {
            return;
        }
        this.setState({showNewName:true});
    }


	render() {
        const l = campaign.getSortedCustomList(this.props.type);
        const list = [];

        for (let i in l) {
            const li = Object.assign({}, l[i]);
            li.name = li.id.toLowerCase();
            list.push(li);
        }

        return <div className="notecontent" key="items">
            <ListFilter 
                list={list}
                showCards
                render={customListRenderCard}
                filters={customListFilter}
                onClick={this.defaultClick.bind(this)}
                getListRef={this.saveRef.bind(this)}
                entryCheckFn={getExtensionEntryCheckFn()}
                noResort
                headerOutline={this.props.headerOutline}
            />
            <CustomDialog open={this.state.show||false} id={this.state.selectedId} editable={this.props.editable} type={this.props.type} onClose={this.hide.bind(this)} extraButtonsFn={this.getExtraButtons.bind(this)}/>
            <NewCustom open={this.state.showNewName} onClose={this.onNewItem.bind(this)} type={this.props.type}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    defaultClick(name,id) {
        this.setState({show:true, selectedId:id, openEdit:false});
    }

    hide() {
        this.setState({show:false});
    }

    showNew() {
        this.setState({showNewName:true});
    }

    onNewItem(name) {
        this.setState({showNewName:false, selectedId:null})
    }

    getExtraButtons(id) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(id))||{});

        return <span>
            <Button disabled={!prev} onClick={prev?this.clickCustom.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickCustom.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    clickCustom(selectedId){
        this.setState({selectedId});
    }
}

const emptyCustom = {
    features: []
}

class NewCustom extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:"", name:null, showDetails:false, newCustom:null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({text:"", name:null, showDetails:false, newCustom:null});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const newItem = {};
            const templateItem = campaign.getCustom(this.props.type, this.state.name);

            if (templateItem) {
                Object.assign(newItem, templateItem);
            } else {
                Object.assign(newItem, emptyCustom);
            }

            newItem.type = this.props.type;
            newItem.id = campaign.newUid();
            newItem.displayName = this.state.text;
            newItem.name=campaign.newUid();
            this.setState({showDetails:true, newItem});
        } else {
            this.props.onClose();
        }

        event.stopPropagation();
    };

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({text:event.target.value});
        }
    }

    closeItem(newItem) {
        this.props.onClose(newItem);
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.showDetails) {
            return <CustomDialog openEdit open item={this.state.newItem} onClose={this.closeItem.bind(this)}/>;
        }

        const name = this.state.text;
        const baseItem = campaign.getCustom(this.props.type, this.state.name);
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Create New {this.props.type}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    value={name||""}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    helperText="New Name"
                />
                {baseItem?<div className="mv1">based on {baseItem.displayName}</div>:null}
                <CustomPicker onClose={this.pickItem.bind(this)} open={this.state.showPicker||false} type={this.props.type} known={1}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showPicker.bind(this)} color="primary">
                    Pick Entry to Copy
                </Button>
                <Button disabled={!name || name==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    Create
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    showPicker() {
        this.setState({showPicker:true});
    }

    pickItem(items) {
        if (items) {
            const names = Object.keys(items);
            let name;
            if (!names || !names.length) {
                name=null;
            } else {
                name=names[0];
            }
            this.setState({showPicker:false, name});
        } else {
            this.setState({showPicker:false});
        }
    }
}


class CustomListDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
            className="nodrag"
            open
        >
            <DialogTitle onClose={this.props.onClose}>
                {this.props.type}
            </DialogTitle>
            <DialogContent>
                <CustomList noNav type={this.props.type} editable={this.props.editable} ref={this.rememberList.bind(this)}/>
            </DialogContent>
            <DialogActions>
                {this.props.editable&&!campaign.isSharedCampaign()?<Button onClick={this.showNew.bind(this)} color="primary">
                    New
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    rememberList(ref){
        this.customList = ref;
    }

    showNew() {
        this.customList.showNew();
    }
}

// needed since old version did not lowercase the id
function fixupSelected(selected,type) {
    const rs = {};
    for (let i in selected) {
        if (campaign.getCustom(type,i)) {
            rs[i.toLowerCase()]=1;
        }
    }
    return rs;
}

class CustomPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selected:fixupSelected(this.props.selected||{},this.props.type)});
        }
    }

    handleClose(save) {
        if (save) {
            this.props.onClose(this.state.selected);
        } else {
            this.props.onClose();
        }
        this.setState({selected:null});
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {character,gamesystemPref} = this.props;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character, !!gamesystemPref, gamesystemPref);
        const l = campaign.getSortedCustomList(this.props.type);
        const restrictOptions = this.props.restrictOptions;
        const list = [];
        const keywords = this.props.keywords?this.props.keywords.split(",").map(function (s) {return s.trim()}):null;

        for (let i in l) {
            const li = Object.assign({}, l[i]);
            if ((!this.props.tokenOnly || li.defaultToken) && ((!keywords && !restrictOptions) || (keywords && keywordsMatch(keywords, li.keywords)) || (restrictOptions&&restrictOptions.includes((li.id||li.name).toLowerCase())))) {
                li.name = (li.id||li.name).toLowerCase();
                list.push(li);
            }
        }

        return <Dialog
            scroll="paper"
            maxWidth="lg"
            fullWidth={true}
            open
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                {this.props.type}
            </DialogTitle>
            <DialogContent>
                <div className="stdcontent" key={this.props.id}>
                    <ListFilter 
                        list={list}
                        select="list"
                        showCards
                        single={this.props.known==1}
                        selected={this.state.selected}
                        render={this.props.known==1?customListRenderCard:customListRenderCardCheck}
                        onClick={this.clickCustom.bind(this)}
                        onSelectedChange={this.onSelectedChange.bind(this)}
                        filters={[keywordFilter,defaultSourceFilter,customTypeBookFilter,defaultGamesystemFilter]}
                        getListRef={this.saveRef.bind(this)}
                        entryCheckFn={extensionEntryCheckFn}
                    >
                        {this.getCounts()}
                    </ListFilter>
                </div>
            </DialogContent>
            <DialogActions>
                {this.props.known==1?null:<Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>}
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <CustomDialog open={this.state.selectedId} id={this.state.selectedId} type={this.props.type} onClose={this.clickCustom.bind(this,null)} extraButtonsFn={this.getExtraButtons.bind(this)}/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(id) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(id))||{});
        const selected = (this.state.selected||{})[this.state.selectedId] && (this.props.known!=1);

        return <span>
            <Button disabled={!prev} onClick={prev?this.clickCustom.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickCustom.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            <Button onClick={this.toggleSelected.bind(this,id)} color="primary">{selected?"Unselect":"Select"}</Button>
        </span>
    }

    toggleSelected(id) {
        const it = campaign.getCustom(this.props.type, id);
        if (it) {
            if (this.listfilter.selectItem((it.id||it.name).toLowerCase(), it.displayName, (it.id||it.name)) && (this.props.known==1)) {
                const t=this;
                setTimeout(function(){
                    t.handleClose(true);
                },30);
            }
        }
        this.clickCustom(null);
    }

    clickCustom(selectedId){
        this.setState({selectedId});
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    getCounts() {
        if (!this.props.known || (this.props.known ==1)) {
            return null;
        }
        const selected = this.state.selected;
        var num = 0;

        for (let i in selected) {
            if (campaign.getCustom(this.props.type, i)) {
                num++;
            } else {
                delete selected[i];
            }
        }

        return <div className="f3 mb2 tc">
            {this.props.type} {num}/{this.props.known}
        </div>;
    }
}

const keywordFilter = {
    filterName:"Keywords",
    fieldName:"keywords"
};


function customListRenderBasic(it) {
    return <div className="stdcontent f4">
        <div className="f3 titlecolor">
            {it.displayName}
        </div>
        {it.prerequisites?<div className="i mb1">Prerequisite: {it.prerequisites}</div>:null}
    </div>
}

function customListRenderCard(it, width,bc, sel, onClick) {
    const {description, keyFeatures} = getSummary(it);

    return <div key={it.name} style={{width:width-10}} onClick={onClick} className="defaultbackground shadow-3 ma1 stdcontent overflow-hidden">
        <div className={"h-100 hoverhighlight pa1 "+(bc||"")}>
            <div className="f1 titlecolor">{it.displayName}</div>
            {it.prerequisites?<div className="i f5 mb1"> (Prerequisite: {it.prerequisites})</div>:null}
            <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
            <FloatCardArt art={it.defaultArt} width={width}/>
            <Renderentry className="ll-8" entry={description}/>
            {keyFeatures.length?<div>
                <div className="b">Traits</div>
                {keyFeatures.join(", ")}
            </div>:null}
        </div>
    </div>
}

function customListRenderCardCheck(it, width,bc, sel, onClick, toggle) {
    const {description, keyFeatures} = getSummary(it);

    return <div key={it.name} style={{width:width-10}} onClick={onClick} className={"flex flex-column shadow-3 ma1 pa1 stdcontent hoverhighlight overflow-hidden "+(bc||"")}>
        <div className="f1 titlecolor">{it.displayName}</div>
        {it.prerequisites?<div className="i f5 mb1"> (Prerequisite: {it.prerequisites})</div>:null}
        <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
        <FloatCardArt art={it.defaultArt} width={width}/>
        <Renderentry className="ll-8" entry={description}/>
        {keyFeatures.length?<div>
            <div className="b">Traits</div>
            {keyFeatures.join(", ")}
        </div>:null}
        <div className="flex-auto"/>
        <div className="tc f2"><span onClick={toggle} className={sel?"pa1 hoverhighlight far fa-check-square":"pa1 hoverhighlight far fa-square"}/></div>
    </div>
}

function getSummary(it) {
    let keyFeatures = [];
    let first;
    for (let i in it.features) {
        const f = it.features[i];
        if (!first) {
            first = f;
        }
        if (f.name && !f.name.toLowerCase().endsWith("traits") && !["skills","tool proficiency","ability score increase","abilities", "languages", "alignment", "size", "speed", "age"].includes(f.name.toLowerCase())) {
            keyFeatures.push(f.name);
        }
    }

    return {keyFeatures, description:it.description || (!first?.name && (first?.entries||[])[0]?.html)};
}


class CustomItem extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        let it;

        if (this.props.customItem) {
            it = this.props.customItem;
        } else {
            const type = this.props.type;
            it = campaign.getCustom(type, this.props.id);
        }

        if (!it) {
            return <div>{this.props.id} not found</div>;
        }

        const features=[];

        for (let i in it.features) {
            const f = it.features[i];
            features.push(<div key={i} className={f?.noDiv?"mb1":null}><RenderFeature h3 feature={f} showInstantRoll noDiv={f?.noDiv} doRoll={this.props.doSubRoll?this.doTextRoll.bind(this,it.displayName):null} doSubRoll={this.props.doSubRoll} getDiceRoller={this.props.getDiceRoller} addSpellToken={this.props.addSpellToken} addToEncounter={this.props.addToEncounter}/></div>);
        }

        return <div className="stdcontent f4" key={this.props.id}>
            {this.props.noTitle?null:<div className="small-caps f3 b titlecolor ttc">
                {this.props.extraName||it.displayName}
            </div>}
            <FloatArt art={it.defaultArt||it.defaultToken} artList={it.artList} defaultArt={it.defaultArt} defaultToken={it.defaultToken} pageSync={this.props.pageSync}/>
            {it.prerequisites?<div className="i mb1">Prerequisite: {it.prerequisites}</div>:null}
            <Renderentry entry={it.description} showInstantRoll doRoll={this.props.doSubRoll?this.doTextRoll.bind(this,it.displayName):null} doSubRoll={this.props.doSubRoll} getDiceRoller={this.props.getDiceRoller} addSpellToken={this.props.addSpellToken} addToEncounter={this.props.addToEncounter}/>
            {features}
            {this.props.noSource?null:<Rendersource entry={it}/>}
            <div className="cb"/>
        </div>;
    }

    doTextRoll(name,text){
        return this.props.doSubRoll(name,text);
    }
}

function printCustom(type, id,noTitle,header) {
    const list=[];
    const it = campaign.getCustom(type, id);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(it.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        list.push(printFloatArt(it.defaultArt||it.defaultToken));
        if (it.prerequisites) {
            list.push(`<div><i>Prerequisite: ${it.prerequisites}</i></div>`);
        }
        if (it.description) {
            list.push(`<div>${htmlFromEntry(it.description)}</div>`);
        }
        list.push(renderPrintFeatures(it.features, noTitle?header:header+1));
        list.push('<div style="clear: both"></div>');
    }
    return list.join("\n");
}

function arrayFromKeywords(keywords) {
    if (!keywords) {
        return null;
    }
    if (Array.isArray(keywords)) {
        return keywords;
    }

    return keywords.split(",").map(function (a){return a.trim()});
}

class CustomDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= this.getState(props);
    }

    componentDidUpdate(prevProps) {
        if (((this.props.id != prevProps.id) || (this.props.open != prevProps.open)) && this.props.open) {
            this.setState(this.getState(this.props));
        }
    }

    getState(props) {
        const item = props.item || campaign.getCustom(props.type, props.id);
        return {editing:props.openEdit, item, keywords:((item&&arrayFromKeywords(item.keywords))||[]).join(", "), dirty:!!props.item}
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        let inside;
        const it = this.state.item;
        const extraButtonsFn = this.props.extraButtonsFn;
        const {PickRandomEncounterInfoDialog} = require('./renderrandomtables.jsx');

        if (!it) {
            inside = <div>{this.props.id} not found</div>;
        } else if (this.state.editing) { 
            inside= <div className="stdcontent f4" key={this.props.id}>
                <TextVal    
                    text={(it && it.displayName)||""}
                    fullWidth
                    helperText="Name"
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
                {it.type=="Conditions"?<div>
                    <div>
                        <CheckVal value={it.hideIndicator} onChange={this.onChangeField.bind(this, "hideIndicator")} label="No condition indicater on token"/>
                    </div>
                    <div>
                        <CheckVal value={it.conditionLevel} onChange={this.onChangeField.bind(this, "conditionLevel", it.conditionLevel?0:9)} label="Show level with condition"/>
                    </div>
                    {it.conditionLevel?<div>
                        Maximum Condition Level <MaxNumberAdjust max={1000} useNumbers value={it.conditionLevel} readonly={this.props.readonly} onAdjustValue={this.onChangeField.bind(this, "conditionLevel")}/>
                    </div>:null}
                </div>:null}
                {it.type==fTemplateName?<div>
                    <CustomDisplayPick value={it.hide} onChange={this.onChangeField.bind(this,"hide")}/>
                    <SelectVal value={it.spellcaster||"none"} values={spellcasterOptions} onClick={this.onChangeField.bind(this,"spellcaster")} helperText="Spellcasting"/>
                    <SelectVal value={it.abilityDC||"cha"} values={abilityNames} onClick={this.onChangeField.bind(this,"abilityDC")} helperText="Spellcasting Abiltiy" className="ml2"/>
                </div>:null}
                {!["Conditions",fTemplateName].includes(it.type) || it.prerequisites?<TextVal 
                    className="mb1"
                    helperText="Prerequisites"
                    text={it.prerequisites||""}
                    fullWidth
                    onChange={this.onChangeField.bind(this,"prerequisites")}
                />:null}
                <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={it.description} placeholder="Description"/>
                <FeatureListEdit 
                    editable 
                    noFeats 
                    compact
                    features={it.features || []} 
                    displayType={it.type} type={it.displayName} emptyName={it.displayName} name={it.name}
                    onChange={this.onChangeField.bind(this,"features")}
                />
                <TextVal fullWidth multiline rowsMax={3} className="mb2" text={this.state.keywords} onChange={this.onChangeKeywords.bind(this)} helperText="Keywords (comma separated)"/>
                <h2>Artwork</h2>
                <ExtraArtList artList={it.artList} onChange={this.onChangeArtwork.bind(this)} pickArt pickToken defaultArt={it.defaultArt} defaultToken={it.defaultToken}/>
                <ArtZoomList editable open={this.state.showExtraArtDialog} artList={it.artList} onClose={this.onSaveArtwork.bind(this)} pickArt pickToken defaultArt={it.defaultArt} defaultToken={it.defaultToken} defaultSearch={it.displayName}/>
                <div className="pt2">
                    <SelectVal value={it.gamesystem||"5e"} values={gamesystemAnyOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                </div>
            </div>;
        } else { 
            inside= <CustomItem noTitle noSource={this.props.noSource} customItem={it} key={this.props.id} pageSync={this.props.pageSync} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} addToEncounter={this.props.addToEncounter}/>;
        }
        const showEdit = (this.props.editable&&!campaign.isSharedCampaign());

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
            className="nodrag"
            open
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                {this.state.editing?("Edit "+((it && it.type)||"")):(it && it.displayName)}
            </DialogTitle>
            <DialogContent>
                {inside}
            </DialogContent>
            <DialogActions>
                {!this.state.editing && this.props.getDiceRoller?this.props.getDiceRoller():null}
                {!this.state.editing?<AddChatEntry type={this.props.type} displayName={it&&it.displayName} href={getCustomHref(this.props.type,this.props.id)}/>:null}
                {!this.state.editing && extraButtonsFn && it && extraButtonsFn(it.id||it.name)}
                {this.props.addToEncounter&&!this.state.editing?<Button onClick={this.clickAddCustom.bind(this)} color="primary">
                    ADD TO ENCOUNTER
                </Button>:null}
                {showEdit?<DeleteEntry type="customTypes" entry={it} onClose={this.props.onClose}/>:null}
                {!this.state.editing && showEdit?<Button onClick={this.doEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                {this.state.editing?<Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>:null}
                {this.state.editing?<Button disabled={!(it && it.displayName && it.displayName.length)} onClick={this.doSave.bind(this)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    {this.state.editing?"Cancel":"Close"}
                </Button>
            </DialogActions>
            <PickRandomEncounterInfoDialog open={this.state.showAddCustom} row={this.state.addCustomRow} onClose={this.closeAddCustom.bind(this)} addToEncounter={this.props.addToEncounter}/>
        </Dialog>;
    }

    showExtraArt() {
        this.setState({showExtraArtDialog:true});
    }

    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            this.onChangeArtwork(artList, defaultArt,defaultToken);
        }
        this.setState({showExtraArtDialog:false})
    }

    clickAddCustom() {
        const row = {custom:[]};
        const it = this.state.item;
        const {previousDice} = this.props;
        const dice= getDiceFromString(previousDice||"1",0,true);
        row.custom.push({type:this.props.type, id:it.id||it.name, dice});
        this.setState({showAddCustom:true, addCustomRow:row});
    }

    closeAddCustom(row) {
        if (row){
            this.props.addToEncounter(row);
            this.props.onClose();
        }
        this.setState({showAddCustom:false, addCustomRow:null});
    }
    
    onClose() {
        this.props.onClose();
    }

    doEdit() {
        this.setState({editing:true});
    }

    doSave() {
        campaign.updateCampaignContent("customTypes", this.state.item);
        this.props.onClose(this.state.item);
    }

    onChangeKeywords(value) {
        this.setState({keywords:value||""});
        this.onChangeField("keywords", arrayFromKeywords(value));
    }

    onChangeField(name, value) {
        const item = Object.assign({}, this.state.item)
        if (value=="none") {
            value=null;
        }
        item[name] = value; 
        this.setState({item, dirty:true})
    }

    onChangeArtwork(artList, defaultArt, defaultToken) {
        const item = Object.assign({}, this.state.item)
        item.artList = artList;
        if (defaultArt) {
            item.defaultArt = defaultArt;
        } else {
            delete item.defaultArt;
        }
        if (defaultToken) {
            item.defaultToken = defaultToken;
        } else {
            delete item.defaultToken;
        }
        this.setState({item, dirty:true})
    }
}

class CustomAddDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {customType:null, customId:null};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({customType:null, customId:null});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        //load here to prevent require loop
        const {PickFeat} = require('./renderfeats.jsx');
        const ctl = ["Feats"].concat(campaign.getCustomTablesList());
        const customType = this.state.customType;
        const customId = this.state.customId;
        const selected = {};
        let selectedName;

        if (customType && customId) {
            selected[customId]=1;
            if (customType=="Feats") {
                const feat = campaign.getFeatInfo(customId);
                selectedName = feat.displayName;
            } else {
                const it = campaign.getCustom(customType,customId);
                selectedName = it.displayName;
            }
        }

        return <Dialog
            scroll="paper"
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogTitle onClose={this.doClose.bind(this,false)}>Pick Hero Ability</DialogTitle>
            <DialogContent>
                <SelectVal fullWidth className="minw4" value={customType||""} values={ctl} label="Ability Type" onClick={this.onChangeType.bind(this)}/>
                {selectedName?<div className="f3 mv1">{selectedName}</div>:null}
                {customType?<div className="mv1">
                    <Button onClick={this.onPickVal.bind(this)} variant="outlined" color="primary">Pick</Button>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button disabled={!(customType && customId)} onClick={this.doClose.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.doClose.bind(this,false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <CustomPicker 
                character={this.props.character}
                open={this.state.showCustomPicker} 
                known={1}
                type={customType}
                selected={selected} 
                onClose={this.onCloseCustomPicker.bind(this)}
            />
            <PickFeat open={this.state.showPickFeat} feat={customId} onClose={this.pickFeat.bind(this)} character={this.props.character}/>
        </Dialog>;
    }    

    doClose(save) {
        if (save) {
            this.props.onClose(this.state.customType, this.state.customId);
        } else {
            this.props.onClose();
        }
    }

    onChangeType(customType){
        if (this.state.customType != customType) {
            this.setState({customType, customId:null});
        }
    }

    onPickVal() {
        const customType = this.state.customType;
        if (customType=="Feats") {
            this.setState({showPickFeat:true});
        } else {
            this.setState({showCustomPicker:true});
        }
    }

    pickFeat(feat) {
        if (feat) {
            this.setState({customId:feat, showPickFeat:false});
        } else {
            this.setState({showPickFeat:false});
        }
    }

    onCloseCustomPicker(selected) {
        if (selected) {
            let customId;
            for (let i in selected) {
                customId=i;
            }
            this.setState({customId, showCustomPicker:false});
        } else {
            this.setState({showCustomPicker:false});
        }
    }

}

class CustomDisplayPick extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(){
        this.setState({showmenu:true, value:this.props.value});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const {value} = this.state;
            this.props.onChange(value);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    toggleValue(v) {
        const value = Object.assign({},this.state.value||{});
        value[v] = !value[v];

        for (let x in value) {
            if (value[x]) {
                this.setState({value});
                return;
            }
        }
        this.setState({value:null});
    }

    render() {
        const value = this.state.value||{};

        return <div className="mb1">
            <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                Hide Attributes
            </Button> {this.props.value?"some attributes hidden":null}
            {this.state.showmenu?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Hide Attributes</DialogTitle>
                <DialogContent>
                    <div className="mb1">
                        <CheckVal value={value.size} onChange={this.toggleValue.bind(this, "size")} label="Size"/>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.alignment} onChange={this.toggleValue.bind(this, "alignment")} label="Alignment"/>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.ac} onChange={this.toggleValue.bind(this, "ac")} label="Armor Class"/>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.hp} onChange={this.toggleValue.bind(this, "hp")} label="Hit Points"/>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.speed} onChange={this.toggleValue.bind(this, "speed")} label="Speed"/>
                    </div>
                    <div className="mb1">
                        <b>Ability Scores</b>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.str} onChange={this.toggleValue.bind(this, "str")} label="STR"/>
                        <CheckVal className="ml3" value={value.dex} onChange={this.toggleValue.bind(this, "dex")} label="DEX"/>
                        <CheckVal className="ml3" value={value.con} onChange={this.toggleValue.bind(this, "con")} label="CON"/>
                        <CheckVal className="ml3" value={value.int} onChange={this.toggleValue.bind(this, "int")} label="INT"/>
                        <CheckVal className="ml3" value={value.wis} onChange={this.toggleValue.bind(this, "wis")} label="WIS"/>
                        <CheckVal className="ml3" value={value.cha} onChange={this.toggleValue.bind(this, "cha")} label="CHA"/>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.senses} onChange={this.toggleValue.bind(this, "senses")} label="Senses"/>
                    </div>
                    <div className="mb1">
                        <CheckVal value={value.cr} onChange={this.toggleValue.bind(this, "cr")} label="Challenge Rating"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </div>;
    }
}


function CustomListHeader(props) {
    return <span>
        {props.type}
        {!campaign.isSharedCampaign()?<Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={triggerEvent.bind(null, props.pageSync,"action","newcustom")}>New</Button>:null}
    </span>;
}

function CustomItemHeader(props) {
    return <span>
    </span>;
}

function keywordsMatch(lookup, keywords) {
    if (!keywords){
        return false;
    }
    for (let i in lookup) {
        const lc = (lookup[i]||"").toLowerCase();
        if (arrayFromKeywords(keywords).find(function (f) {return f.toLowerCase()==lc;})) {
            return true;
        }
    }
    return false;
}

function triggerEvent(eventSync, event, data) {
    eventSync.emit(event, data);
}
const customListFilter = [keywordFilter, defaultSourceFilter,customTypeBookFilter,defaultGamesystemFilter];
const customMapListFilter = [keywordFilter, defaultSourceFilter,customTypeContentMapBookFilter,defaultGamesystemFilter];

export {
    CustomDialog,
    CustomItem,
    CustomList,
    CustomPicker,
    CustomListDialog,
    CustomListHeader,
    CustomItemHeader,
    CustomAddDialog,
    NewCustom,
    customMapListFilter,
    printCustom
}