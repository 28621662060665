const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const Parser = require("../lib/dutils.js").Parser;
import sizeMe from 'react-sizeme';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

const {Character} = require('../lib/character.js');
const {MonObj} = require('../lib/monobj.js');
const {CharacterSheetView} = require('./charactersheet.jsx');
const {PickCharacter} = require("./renderplayers.jsx");
const {MapSharedView,openFullscreen} = require("./map.jsx");
const {GenCharacterName} = require('./randomname.jsx');
const {RenderChat} = require('./renderchat.jsx');
const {AddObject} = require('./objects.jsx');
const {getAnchorPos,TabBlock,VDivider,HDivider} = require('./stdedit.jsx');
const {FeedbackDialog} = require('./renderfeedback.jsx');
const {DiceTower} = require('./dicetower.jsx');
const {RenderJournal} = require('./renderjournal.jsx');
const {SoundTool} = require('./renderaudio.jsx');
const {ConditionList} = require('./conditions.jsx');
const {CompanionView} = require('./rendercompanions.jsx');

const checkSize = 900;
const splitHPercentDefault=40;
const splitVPercentDefault=50;

class PlayCampaign extends React.Component {
    constructor(props) {
        super(props);

        this.updateFn = this.update.bind(this);
        this.parentRef = React.createRef();
        this.updateUserPackageFn = this.updateUserPackage.bind(this);
        const noSideBySide = campaign.getPrefs().noSideBySide;
        const {playPercentSplitH,playPercentSplitV} = campaign.getPrefs();

	    this.state= {campaign:null, error:null, noSideBySide, splitHPercent:playPercentSplitH??splitHPercentDefault, splitVPercent:playPercentSplitV??splitVPercentDefault};
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.size != this.props.size ) || (prevProps.size.width != this.props.size.width)) {

            this.props.pageSync.emit("action", ((this.props.size.width < checkSize)&&(this.props.size.height<checkSize))?"showbuttons":"hidebuttons");
        }
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignSettings(this.updateFn);
        globalDataListener.onChangeCampaignContent(this.updateFn,"players");
        globalDataListener.onChangeCampaignContent(this.updateFn,"adventure");

        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"classes");
        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"races");
        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"backgrounds");
        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"feats");
        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"customTypes");
        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"spells");
        globalDataListener.onChangeCampaignContent(this.updateUserPackageFn,"mycharacters");


        if (this.props.size) {
            this.props.pageSync.emit("action", ((this.props.size.width < checkSize)&&(this.props.size.height<checkSize))?"showbuttons":"hidebuttons");
        }
        this.updateUserPackage();
    }

    update() {
        const noSideBySide = campaign.getPrefs().noSideBySide;
        this.updateUserPackage();
        this.setState({players:campaign.getPlayers(), settings:campaign.getGameState(), noSideBySide})
    }

    componentWillUnmount() {
        this.closed = true;
        globalDataListener.removeCampaignSettingsListener(this.updateFn);
        globalDataListener.removeCampaignContentListener(this.updateFn, "players");
        globalDataListener.removeCampaignContentListener(this.updateFn, "adventure");

        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "classes");
        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "races");
        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "backgrounds");
        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "feats");
        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "customTypes");
        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "spells");
        globalDataListener.removeCampaignContentListener(this.updateUserPackageFn, "mycharacters");
        if (this.updatePackageTimer) {
            clearTimeout(this.updatePackageTimer)
        }
    }

    updateUserPackage() {
        if (this.updatePackageTimer) {
            clearTimeout(this.updatePackageTimer)
        }
        this.updatePackageTimer = setTimeout(function (){
            const userInfo = campaign.getUserInfo(campaign.userId);
            if (userInfo) {
                const players = campaign.getPlayers();
                const pkg={};

                for (let i in players) {
                    const char = campaign.getMyCharacterInfo(players[i].name);
                    if (char) {
                        const character = new Character(char);
                        character.getCharacterPackage(pkg)
                    }
                }
                for (let i in pkg) {
                    pkg[i].sort(function (a,b){return a.name.localeCompare(b.name)});
                }
                const strjson = JSON.stringify(pkg);
                const same = userInfo.userPkg && (strjson==userInfo.userPkg);
                if (!same) {
                    //console.log("change user package", strjson, userInfo.userPkg);
                    const newUserInfo = Object.assign({}, userInfo);
                    newUserInfo.userPkg = strjson;
                    campaign.updateCampaignContent("users", newUserInfo);
                }
            }
            this.updatePackageTimer = null;
        },2000);
    }

	render() {
        let showSingle=false;
        let tab="character";
        let cTabName = "Character";
        const sharing = campaign.getGameState().sharing||"readonly";
        const shared = (sharing!="closed") || !!campaign.adminStatus.level;
        const readonly = (sharing=="readonly") && !campaign.adminStatus.level;
        const showVert = this.props.size.height > (this.props.size.width*1.2);

        if (this.state.noSideBySide || ((this.props.size.width < checkSize) && (this.props.size.height < checkSize))) {
            showSingle=true;
        }
        
        if (showSingle && (this.props.mode == "map")) {
            tab="map";
        } else if (this.props.mode=="list") {
            tab="list";
        } else if (this.props.mode=="journal") {
            tab="journal";
        } else if (this.props.mode=="chat") {
            tab="chat";
        }

        const fchar = this.props.characterName && campaign.getPlayerInfo(this.props.characterName);
        if (this.props.characterName && !fchar) {
            campaign.checkCharacter(this.props.characterName);
        }

        const mine = this.props.characterName && campaign.getMyCharacterInfo(this.props.characterName);
        const softSelected = (readonly&&!mine)?null:this.getSelected();

        let player = null, character=null;
        if (fchar && (shared || mine)) {
            character = new Character(fchar, "players",readonly && !mine);
            if (this.props.companion && character.companions[this.props.companion]) {
                cTabName="Companion";
                player = <CompanionView key="companion" readonly={readonly && !mine} name={this.props.characterName} companionId={this.props.companion} eventSync={this.props.pageSync} addSpellToken={this.addMapToken.bind(this)} character={character}/>
            } else {
                player = <CharacterSheetView key="player" disallowDiceSounds readonly={readonly && !mine} name={this.props.characterName} type="players" eventSync={this.props.pageSync} addSpellToken={this.addMapToken.bind(this)}/>;
            }
        }

        const map = <MapSharedView key="map" onClickCharacter={this.onClickToken.bind(this)} character={character}/>;
        const playerList = this.getPlayerList(shared, readonly);
        const always = <div>
            {mine?<DiceTower character={character}/>:null}
            {this.getContextMenu()}
        </div>;

        const tabs = [];
        tabs.push({id:"list", label:"List", body:<div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultsemi">{playerList}</div>});
        if (player) {
            tabs.push({id:"character", label:cTabName, body:<div className="w-100 h-100 flex flex-column">
                <div key={this.props.characterName} className="flex-auto w-100 h-100 overflow-y-auto overflow-x-hidden">
                    {player}
                </div>
            </div>});
        }

        if (showSingle) {
            tabs.push({id:"map", label:"Map", body:map});

            tabs.push({id:"chat", label:"Chat", body:<div key="chat" className="flex-auto w-100 h-100 overflow-y-auto overflow-x-hidden flex">
                <RenderChat allowDiceSounds softSelected={softSelected} width={this.props.width} addSpellToken={this.addMapToken.bind(this)}/>
            </div>});
            tabs.push({id:"journal", label:"Journal", body:<div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultbackground"><RenderJournal character={character}/></div>});

            return <div key="w" className="w-100 h-100 flex flex-column">
                <div key="twrap" className="w-100 h2 flex-auto">
                    <TabBlock key="tabs" tabs={tabs} currentTab={tab} onChangeTab={this.changeTab.bind(this)}/>
                </div>
                {always}
            </div>;
        } else if (showVert) {
            let [height, mapHeight] = this.getVSectionsTabs(this.state.splitVPercent*this.props.size.height/100);
            let spacer=null;
            if (mapHeight <=10) {
                tabs.push({id:"map", label:"Map", body:map});
                height += mapHeight-10;
                mapHeight=0
                spacer="bottom";
            } else if (height<=10) {
                mapHeight += height-10;
                height=0;
                spacer="top";
            }
            tabs.push({id:"chat", label:"Chat", body:<div key="chat" className="flex-auto w-100 h-100 overflow-y-auto overflow-x-hidden flex">
                <RenderChat allowDiceSounds softSelected={softSelected} width={this.props.width} addSpellToken={this.addMapToken.bind(this)}/>
            </div>})
            tabs.push({id:"journal", label:"Journal", body:<div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultbackground"><RenderJournal  character={character}/></div>});

            return <div key="w" className="w-100 h-100 flex flex-column" ref={this.parentRef}>
                <div key="twrap" className="w-100" style={{height}}>
                    <TabBlock key="tabs" tabs={tabs} currentTab={tab} onChangeTab={this.changeTab.bind(this)}/>
                </div>
                <HDivider 
                    onSlide={this.onVSlideTabs.bind(this)} 
                    getSections={this.getVSectionsTabs.bind(this)} 
                    parentRef={this.parentRef}
                    spacer={spacer}
                    spacerSize={10}
                    defaultPercentage={splitHPercentDefault}
                />
                <div className="w-100" style={{height:mapHeight}}>{map}</div>
                {always}
            </div>;
        } else {
            let [width, mapWidth] = this.getHSectionsTabs(this.state.splitHPercent*this.props.size.width/100);
            let spacer=null;
            if (mapWidth <=10) {
                tabs.push({id:"map", label:"Map", body:map});
                width += mapWidth-10;
                mapWidth=0;
                spacer="right"
            } else if (width <=10) {
                mapWidth += width-10;
                width=0;
                spacer="left"
            }

            tabs.push({id:"chat", label:"Chat", body:<div key="chat" className="flex-auto w-100 h-100 overflow-y-auto overflow-x-hidden flex">
                <RenderChat allowDiceSounds softSelected={softSelected} width={width} addSpellToken={this.addMapToken.bind(this)}/>
            </div>});
            tabs.push({id:"journal", label:"Journal", body:<div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultsemi"><RenderJournal character={character}/></div>});

            return <div key="w" className="w-100 h-100 flex" ref={this.parentRef}>
                <div key="twrap" className="h-100" style={{width}}>
                    <TabBlock key="tabs" tabs={tabs} currentTab={tab} onChangeTab={this.changeTab.bind(this)}/>
                </div>
                <VDivider 
                    onSlide={this.onHSlideTabs.bind(this)} 
                    getSections={this.getHSectionsTabs.bind(this)} 
                    parentRef={this.parentRef}
                    spacer={spacer}
                    spacerSize={10}
                    defaultPercentage={splitHPercentDefault}
                />
                <div className="h-100" style={{width:mapWidth}}>{map}</div>
                {always}
            </div>;
        }
    }

    getSelected() {
        const {combatants} = campaign.getAdventure();
        let {characterName, companion} = this.props;
        if (characterName) {
            characterName = characterName.toLowerCase();
            for (let i in combatants) {
                const c = combatants[i];

                if (((c.name||"").toLowerCase() == characterName)) {
                    if (companion) {
                        if (c.companionId==companion && c.ctype=="cmonster") {
                            return c.id
                        }
                    } else if (c.ctype == "pc") {
                        return c.id;
                    } 
                }
            }
        }
        return null;
    }

    getHSectionsTabs(pos) {
        const width = this.props.size.width;
        if ((width-pos) < 340) {
            return [width,0];
        } else if (pos < 300) {
            return [0,width];
        }

        return [pos,width-pos];
    }

    onHSlideTabs(pos) {
        const width = this.props.size.width;
        const splitHPercent = pos/width*100;
        campaign.setPrefs({playPercentSplitH:splitHPercent});

        this.setState({splitHPercent});
    }

    getVSectionsTabs(pos) {
        const height = this.props.size.height;
        if ((height-pos) < 300) {
            return [height,0];
        } else if (pos < 200) {
            return [0,height];
        }

        return [pos,height-pos];
    }

    onVSlideTabs(pos) {
        const height = this.props.size.height;
        const splitVPercent = pos/height*100;
        campaign.setPrefs({playPercentSplitV:splitVPercent});

        this.setState({splitVPercent});
    }

    changeTab(tab) {
        const {campaignName,characterName,companion} = this.props;
        const campaignBase = encodeURIComponent(campaignName);
        let characterExtra=encodeURIComponent(campaignName);
        if (characterName) {
            characterExtra += "&character="+encodeURIComponent(characterName);
            if (companion) {
                characterExtra += "&companion="+encodeURIComponent(companion);
            }
        }

        switch (tab) {
            case "list":
                window.location.href = "/#play?mode=list&campaign="+characterExtra;
                break;
            case "character":
                window.location.href = "/#play?mode=character&campaign="+characterExtra;
                break;
            case "map":
                window.location.href = "/#play?mode=map&campaign="+characterExtra;
                break;
            case "chat":
                window.location.href = "/#play?mode=chat&campaign="+characterExtra;
                break;
            case "journal":
                window.location.href = "/#play?mode=journal&campaign="+characterExtra;
                break;
        }

    }

    getContextMenu() {
        if (!this.state.showTokenMenu){
            return null;
        }
        return <Menu open disableAutoFocusItem anchorPosition={this.state.menuAnchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.closeMenu.bind(this)}>
            <MenuItem onClick={this.onDelete.bind(this, this.state.selectedToken)}>Delete</MenuItem>
        </Menu>;
    }

    closeMenu() {
        this.setState({showTokenMenu:false});
    }

    onClickToken(name, i, e) {
        const adventure = campaign.getAdventure();
        const combatants = adventure.combatants;
        const c = combatants[i];

        if (c?.ctype == "object") {
            this.setState({menuAnchorPos:getAnchorPos(e.evt, true), showTokenMenu:true, selectedToken:i});
        } else if (!this.state.noSideBySide) {
            if (c?.ctype == "cmonster") {
                this.onSelectPlayer(c.name, c.companionId);
            } else {
                this.onSelectPlayer(name);
            }
        }
    }

    getPlayerList(shared,readonly) {
        const adventure = campaign.getAdventure();
        const combatants = adventure.combatants;
        const players = campaign.getPlayers();
        const ret = [];
        const inactive=[];

        const myChars = campaign.getMyCharacters();
        let foundMine = false;
        let claimable=false;
        let num=1;
    
        for (let i in players) {
            const p=players[i];

            if (p.claimable) {
                claimable=true;
            }
        }

        for (let i in combatants) {
            const c = combatants[i];
            const active = (!c.state || c.state=="active");
            const list = active?ret:inactive;

            if (c.ctype == "pc") {
                const mine = campaign.getMyCharacterInfo(c.name);
                const p = campaign.getPlayerInfo(c.name);

                if (p) {
                    const character = new Character(p, "players", true);

                    list.push(<tr key={i} className={"cursor-context-menu pb1 overflow-hidden"+((shared || mine)?" hoverhighlight":"")} onClick={(shared || mine)?this.onSelectPlayer.bind(this, p.name,null):null}>
                        {shared?<td className="titleborder bb">
                            {!c.hidden&&active?<span className="f2">{num}</span>:null}
                            {c.currentTurn?<div className="f2 fas fa-arrow-right"/>:null}
                        </td>:null}
                        <td className="titleborder bb">
                            <img height="70" width="70" src={p.imageURL || "/blankplayer.png"}/>
                        </td>
                        <td className="w-100 titleborder bb">
                            <div className="f3 titletext titlecolor" >{mine?"*":null}{p.displayName}</div>
                            <div className="f6 near-black i">
                                {p.playerName?<div className="ml2">Played by {p.playerName}</div>:null}
                                {(shared || mine)?<div>{p.originDisplayName||(!p.gamesystem?p.raceDisplayName:null)} {p.backgroundDisplayName}</div>:null}
                                {(shared || mine)?<div>Level {p.level} {p.classDisplayNames}</div>:null}
                                {!mine?(readonly?"Read Only":(!shared?"Locked":null)):null}
                            </div>
                            {(character.state.conditions&&(shared||mine))?<div className="pt--2">
                                <ConditionList conditions={character.state.conditions} readonly/>
                            </div>:null}
                        </td>
                        {(shared||mine)?<td className="f2 titlecolor tc v-top ph1 titleborder bb">
                            <div className="titleborder ba br1 mv1 pa--2 nowrap">HP <span className="defaultcolor">{character.hp}/{character.maxhp}</span></div>
                            <div className="titleborder ba br1 pa--2 nowrap">AC <span className="defaultcolor">{character.ac}</span></div>
                        </td>:<td className="f2 titlecolor tc v-top ph1 titleborder bb"/>}
                    </tr>);
                }
                if (mine) {
                    foundMine=true;
                }
            } else if (c.ctype=="cmonster") {
                const mine = campaign.getMyCharacterInfo(c.name);
                const p = campaign.getPlayerInfo(c.name);

                if (p) {
                    const character = new Character(p, "players", true);
                    const monVal = character.companions[c.companionId];
        
                    if (monVal){
                        const monObj = new MonObj(monVal, null, true);

                        list.push(<tr key={i} className={"cursor-context-menu pb1 overflow-hidden"+((shared || mine)?" hoverhighlight":"")} onClick={(shared || mine)?this.onSelectPlayer.bind(this, p.name,c.companionId):null}>
                            {shared?<td className="titleborder bb">
                                {!c.hidden&&active?<span className="f2">{num}</span>:null}
                                {c.currentTurn?<div className="f2 fas fa-arrow-right"/>:null}
                            </td>:null}
                            <td className="titleborder bb">
                                <img height="70" width="70" src={monObj.imageURL || "/blankmonster.png"}/>
                            </td>
                            <td className="w-100 titleborder bb">
                                <div className="f3 titletext titlecolor" >{mine?"*":null}{monObj.displayName} ({character.displayName})</div>
                                <div className="f6 near-black i">
                                    {p.playerName?<div className="ml2">Played by {p.playerName}</div>:null}

                                    {(shared || mine)?<div>
                                        <span>{Parser.sizeAbvToFull(monVal.size)} </span>
                                        <span>{Parser.monTypeToFullObj(monVal.type).asText}</span>
                                    </div>:null}
                                    {!mine?(readonly?"Read Only":(!shared?"Locked":null)):null}
                                </div>
                                {(monObj.state.conditions&&(shared||mine))?<div className="pt--2">
                                    <ConditionList conditions={monObj.state.conditions} readonly/>
                                </div>:null}
                            </td>
                            {(shared||mine)?<td className="f2 titlecolor tc v-top ph1 titleborder bb">
                                <div className="titleborder ba br1 mv1 pa--2 nowrap">HP <span className="defaultcolor">{monObj.hp}/{monObj.maxhp}</span></div>
                                <div className="titleborder ba br1 pa--2 nowrap">AC <span className="defaultcolor">{monObj.ac}</span></div>
                            </td>:<td className="f2 titlecolor tc v-top ph1 titleborder bb"/>}
                        </tr>);
                    }
                }
            }
            if ((c.ctype != "object") && (!c.state || c.state=="active") && !c.hidden) {
                num++;
            }
        }

        return <div className="mw7 ma1">
            {!(ret.length+inactive.length)?<div className="tc ma1">No characters have been added to campaign yet.</div>:null}
            <div>
                <table>
                    <tbody>
                        {ret}
                    </tbody>
                </table>
            </div>
            {inactive.length?<div>
                <div className="f3 titletext bb mt1 pt1 titleborder titlecolor">Inactive</div>
                <table>
                    <tbody>
                        {inactive}
                    </tbody>
                </table>
            </div>:null}
            {myChars.length?<div className="tc mv1">
                <Button onClick={this.onPickCharacter.bind(this)} color="primary" variant="outlined">
                    Join My Character to Campaign
                </Button>
            </div>:null}
            <div className="tc mv1">
                <Button onClick={this.onNewCharacter.bind(this)} color="primary" variant="outlined">
                    Create a New Character
                </Button>
            </div>
            {claimable?<div className="tc mv2">
                <Button onClick={this.onPickPregenCharacter.bind(this)} color="primary" variant="outlined">
                    Pick a Pregenerated Character
                </Button>
            </div>:null}
            <GenCharacterName show={this.state.showNewPC} title="Create New Character" label="Character Name" onChange={this.onNewPC.bind(this)}/>
            <PickCharacter open={this.state.showPickPregenCharacter} onClose={this.doPickPregenCharacter.bind(this)} type="players" claimable single/>
            <PickCharacter open={this.state.showPickMyCharacter} onClose={this.pickCharacter.bind(this)} type="mycharacters" single/>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Adding Character...
                </DialogContent>
            </Dialog>:null}
        </div>
    }

    onPickPregenCharacter() {
        if (campaign.getMyCharacters().length >= campaign.maxCharacters) {
            displayMessage(<span>You have reached your limit of {campaign.maxCharacters} characters that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
            return;
        }

        this.setState({showPickPregenCharacter:true});
    }

    doPickPregenCharacter(name) {
        if (name) {
            const t=this;
            const newCharacter = Object.assign({}, campaign.getPlayerInfo(name));

            t.setState({showPickPregenCharacter:false, loading:true});
            newCharacter.name = campaign.newUid();
            delete newCharacter.pregen;
            delete newCharacter.diceRolls;
            delete newCharacter.lastRoll;
            delete newCharacter.claimable;
            campaign.updateCampaignContent("mycharacters", newCharacter).then(function () {
                campaign.joinCharacterToSharedCampaign(newCharacter.name, t.props.campaignName).then(function(){
                    t.setState({loading:false});
                    campaign.deleteCampaignContent("players",name)
                    window.location.href = "/#play?mode=character&campaign="+encodeURIComponent(t.props.campaignName)+"&character="+encodeURIComponent(newCharacter.name);
                    campaign.addUserMRUList("mruCharacters", {description:newCharacter.name});
                }, function (err){
                    t.setState({loading:false});
                    displayMessage("Error joining campaign. "+err);
                });
        
            }, function (err) {
                displayMessage("Error claiming character. "+err);
                t.setState({loading:false});
            });
            
        } else {
            this.setState({showPickPregenCharacter:false});
        }
    }

    onPickCharacter() {
        this.setState({showPickMyCharacter:true});
    }

    pickCharacter(name) {
        if (name) {
            const t=this;
            
            campaign.joinCharacterToSharedCampaign(name, this.props.campaignName).then(function (){
                t.onSelectPlayer(name);
                campaign.addUserMRUList("mruCharacters", {description:name});
            }, function (err){
                displayMessage("Error joining campaign. "+err);
            });
        }
        this.setState({showPickMyCharacter:false});
    }

    onNewCharacter(){
        if (campaign.getMyCharacters().length >= campaign.maxCharacters) {
            displayMessage(<span>You have reached your limit of {campaign.maxCharacters} characters that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
            return;
        }

        this.setState({showNewPC:true});
    }

    onDelete(i) {
        const adventure = Object.assign({}, campaign.getAdventure());
        const combatants = adventure.combatants = adventure.combatants.concat([]);

        combatants.splice(i,1);
        campaign.versionUpdateCampaignContent("adventure", adventure);
        this.setState({showTokenMenu:false});
    }

    async onNewPC(name) {
        this.setState({showNewPC:false});
        if (name) {
            try {
                const char = {name:campaign.newUid(), displayName:name, full:true, gamesystem:campaign.defaultGamesystem};
                const ret= campaign.updateCampaignContent("mycharacters", char);
                await ret;
                await campaign.joinCharacterToSharedCampaign(char.name, this.props.campaignName);
                this.onSelectPlayer(char.name);
                campaign.addUserMRUList("mruCharacters", {description:char.name});
            } catch (err){
                displayMessage("Error joining campaign. "+err);
            };
        }
    }

    onSelectPlayer(name,companion) {
        const player = campaign.getPlayerInfo(name);
        if (player) {
            window.location.href = "/#play?campaign="+encodeURIComponent(this.props.campaignName)+"&character="+encodeURIComponent(name)+(companion?"&companion="+encodeURIComponent(companion):"");
        }
    }

    addMapToken(ao) {
        addMapToken(ao, this.props.characterName, this.props.campaignName);
    }
}

class PlayCampaignHeader extends React.Component {
    constructor(props) {
        super(props);

        this.pageEventListenFn = this.pageEventListen.bind(this);
        this.updateFn = this.update.bind(this);
        const noSideBySide = campaign.getPrefs().noSideBySide;

	    this.state= {hideButtons:false,noSideBySide};
        this.anchorRef = React.createRef();
    }

    componentDidMount() {
        this.props.pageSync.on("action", this.pageEventListenFn);
        globalDataListener.onChangeCampaignContent(this.updateFn,"adventureview");
    }
  
    componentWillUnmount() {
        this.props.pageSync.removeListener("action", this.pageEventListenFn);
        globalDataListener.removeCampaignContentListener(this.updateFn, "adventureview");
    }

    update() {
        this.setState({av:campaign.getAdventureView()})
    }

    render() {
        if (!this.props.campaignName) {
            return null;
        }

        const campaignInfo = campaign.getPrefs();
        const noSideBySide = campaignInfo.noSideBySide;
        const av = campaign.getAdventureView();
        const {isShared} = campaign.getSharing()

        return <div className="flex items-center">
            <span className="truncate hoverhighlight" ref={this.anchorRef} onClick={this.showMenu.bind(this,true)}>{campaignInfo.displayName} <span className="fas fa-cog"/></span>
            <SoundTool/>
            {(av.imageName && av.imageSrc)?<AddObject onAddObject={this.addMapToken.bind(this)} useIcon defaultType="Spell Token" playerControlled/>:null}
            <div className="flex-auto">
            </div>
            {this.state.hideButtons?<div><span className={noSideBySide?"far fa-square pa1 hoverhighlight":"far fa-check-square pa1 hoverhighlight"} onClick={this.toggleSideBySide.bind(this,!noSideBySide)}/>split screen</div>:null}
            <Menu open={this.state.showMenu||false} 
                anchorEl={this.anchorRef.current} 
                onClose={this.showMenu.bind(this,false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
            >
                <MenuItem onClick={this.showFeedback.bind(this,true)}>Give Feedback</MenuItem>
                <MenuItem onClick={this.showSharing.bind(this,true)}>{isShared?"Stop Sharing Library":"Share Library"}</MenuItem>
            </Menu>
            <FeedbackDialog open={this.state.showFeedback} onClose={this.showFeedback.bind(this,false)}/>
            <ShareLibrary open={this.state.showSharing} onClose={this.showSharing.bind(this,false)}/>
        </div>;
    }

    showSharing(showSharing) {
        this.setState({showSharing, showMenu:false});
    }

    showFeedback(showFeedback) {
        this.setState({showFeedback, showMenu:false});
    }

    showMenu(show) {
        this.setState({showMenu:show});
    }

    toggleSideBySide(noSideBySide) {
        campaign.setPrefs({noSideBySide});
        this.setState({noSideBySide});
    }

    pageEventListen(action) {
        if (action=="hidebuttons") {
            this.setState({hideButtons:true});
        } else if (action=="showbuttons") {
            this.setState({hideButtons:false});
        }
        const noSideBySide = campaign.getPrefs().noSideBySide;
        this.setState({noSideBySide});
    }

    addMapToken(ao) {
        addMapToken(ao, this.props.characterName, this.props.campaignName);
    }
}

class ShareLibrary extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {isShared, isShareable,needSubscription,noAllowLibrarySharing} = campaign.getSharing();

        console.log("sharing",isShared, isShareable,needSubscription,noAllowLibrarySharing)
        return <Dialog 
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogContent>
                {isShared?<div className="f2 tc mb1">
                    Your purchased content library is available for everyone in the campaign to use.
                </div>:isShareable?<div className="f2 tc mb1">
                    Sharing your content library will allow everyone in the campaign to view and use all of your purchased content.
                </div>:noAllowLibrarySharing?<div className="f2 tc mb1">
                    Sharing your content library requires either you or the campaign gamemaster to have a Gamemaster or Gamemaster Pro subscription.<br/>See <a href="/marketplace#shardsubscriptions">subscription options</a>.
                </div>:needSubscription?<div className="f2 tc mb1">
                    You have reached your sharing limits. See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.
                </div>:<div className="f2 tc mb1">
                    You have reached your maximum sharing limits.  To share with another campaign you will first need to stop sharing with another campaign.
                </div>}
            </DialogContent>
            <DialogActions>
                {isShared||isShareable?<Button onClick={this.shareLibrary.bind(this,!isShared)} color="primary">
                    {isShared?"Stop Sharing":"Share"}
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>

            <Dialog open={this.state.loading}>
                <DialogContent>
                    Updating...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    shareLibrary(add) {
        const t = this;
        const campaignInfo = campaign.getPrefs();
        this.setState({loading:true});
        if (add) {
            campaign.addShareCampaign(campaignInfo.shareCampaignName, campaignInfo.shareUser).then(function () {
                t.setState({loading:false});
                t.props.onClose();
                displayMessage("Library has been shared with the campaign.");
            }, function (err) {
                t.setState({loading:false});
                t.props.onClose();
                displayMessage("Error sharing library with campaign: "+err.message);
            });
        } else {
            campaign.removeShareCampaign(campaignInfo.shareCampaignName).then(function () {
                t.setState({loading:false});
                t.props.onClose();
                displayMessage("Library will no longer be shared with the campaign.");
            }, function (err) {
                t.setState({loading:false});
                t.props.onClose();
                displayMessage("Error changing library sharing: "+err.message);
            });
        }
    }
}


function addMapToken(ao, characterName, campaignName) {
    const {getTokenInfo,fixupCombatants} =require('./encountermonsterlist.jsx');
    const adventure = Object.assign({}, campaign.getAdventure());
    const av = campaign.getAdventureView();

    const combatants = adventure.combatants = adventure.combatants.concat([]);
    const id = campaign.newUid();
    ao.id = id;
    ao.playerControlled = true;

    if (characterName) {
        for (let i in combatants) {
            const c = combatants[i];
            if ((c.ctype == "pc") && (c.name==characterName) && (c.tokenMap==av.imageName)) {
                ao.tokenX = c.tokenX;
                ao.tokenY = c.tokenY;
                ao.tokenMap = av.imageName;
            }
        }
    }
    if (!ao.tokenMap) {
        ao.tokenX = av.mapPos.x;
        ao.tokenY = av.mapPos.y;
        ao.tokenMap = av.imageName;
    }
    if (ao.otype=="image") {
        const cInfo=getTokenInfo(ao,true);
        ao.url = cInfo.tokenImageURL;
        if (cInfo.width) {
            ao.width = cInfo.width;
        }
        if (cInfo.height) {
            ao.height = cInfo.height;
        }
        ao.opacity = cInfo.opacity||0;
    }
    combatants.push(ao);
    fixupCombatants(combatants);
    
    campaign.versionUpdateCampaignContent("adventure", adventure);
    window.location.href = "/#play?mode=map&campaign="+encodeURIComponent(campaignName)+
        (characterName?"&character="+encodeURIComponent(characterName):"");
}


function triggerEvent(eventSync, event, data) {
    eventSync.emit(event, data);
}
const PlayCampaignSize = sizeMe({monitorHeight:true, monitorWidth:true})(PlayCampaign);

export {
    PlayCampaignSize as PlayCampaign,
    PlayCampaignHeader
}