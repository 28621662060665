import React, { Component } from 'react';
const {areSameDeep,areSameDeepInst}= require('../lib/campaign.js');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {TextVal} = require('./stdedit.jsx');
const {getLocationInfo, RenderHref} = require('./renderhref.jsx');
const {DicePopup,getDiceFromString,doRoll} = require('./diceroller.jsx');
import {htmlFromEntry, htmlFromString} from "../lib/entryconversion.js";
const {searchContent,searchLinks} = require('./search.jsx');
import ckeditor from '../ckeditor/node_modules/ckeditor.js';

const emptyEntry = {entries:[""]};
const linkExclude = {mycharacters:true, players:true, plannedencounter:true};

const fullEditorToolbar = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'blockQuote',
    'bulletedList',
    'numberedList',
    '|',
    'link',
    'alignment',
    'heading',
    '|',
    'indent',
    'outdent',
    '|',
    'insertTable',
    'undo',
    'redo'
];

const shortEditorToolbar = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'undo',
    'redo'
];


class EntityEditor extends Component {
    constructor(props) {
        super();
        this.editref = React.createRef(); 

        this.state = {
            value: null,
        };
        this.onChangeFn = this.onChange.bind(this);
        this.contentRef = React.createRef();
        this.key = Math.random();
    }

    getEditorStateFromEntry(props) {
        let entry = props.entry;
        let depth;
        let root = props.root;

        if (root || (entry && entry.root)) {
            root= true;
            depth=-1;
        } else {
            depth=(entry && entry.depth)||props.depth||0;
        }

        if (!entry)
            entry = emptyEntry;

        let html = htmlFromEntry(entry, depth);
        this.lastEntry = props.entry;
        return  html;
    }

    componentDidMount() {
        const html = this.getEditorStateFromEntry(this.props||{});
        this.contentRef.current.innerHTML = html;
        if (!html) {
            this.createEditor();
        } else {
            this.observer = new IntersectionObserver(this.inView.bind(this), {
                threshold:0.001
            })
            this.observer.observe(this.contentRef.current);
        }
    }

    inView(entries) {
        //console.log("inView",entries[0].intersectionRatio)
        if (entries[0].intersectionRatio > 0) {
            if (!this.editor) {
                this.createEditor();
            }
            if (this.observer) {
                this.observer.disconnect();
                this.observer=null;
            }
        }
    }

    onFocus() {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    onClick(evt) {
        //console.log("on click", this.editor);
        if (!this.editor) {
            this.createEditor(true);
        }
    }

    createEditor(setFocus) {
        const t=this;

        ckeditor.create(this.contentRef.current,{
            placeholder:t.props.placeholder||"Rich text",
            alignment: {
                options: [ 'left', 'center', 'right' ],
            },
            toolbar:t.props.shortOptions?shortEditorToolbar:fullEditorToolbar,
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                ]
            },
            table:{
                contentToolbar: [ 'tableRow', 'tableColumn', 'mergeTableCells' ]

            }
        }).then(function (editor) {
            t.editor = editor;
            if (!t.props.noSearch) {
                extendEditor(editor);
                scanEditor(editor);
            }
            editor.model.document.on('change:data', t.onChange.bind(t));
            editor.on('search', function (a,element){
                if (element) {
                    const selection = editor.model.document.selection;
                    const firstPosition = selection.getFirstPosition();
                    let href=null;
                    if (firstPosition.textNode){
                        editor.model.change(function (writer){
                            const range = writer.createRangeOn(firstPosition.textNode);
                            const selection = writer.createSelection(range);
                            writer.setSelection(selection);
                        });
                        href = firstPosition.textNode.getAttribute("linkHref");
                    }
                    let text = textFromElement(element).trim();
                    t.setState({anchorText:text, anchorHref:href, showPickURL:true});
                } else {
                    const selection = editor.model.document.selection;
                    const firstPosition = selection.getFirstPosition();
                    const lastPosition = selection.getLastPosition();
                    let ftn = firstPosition.textNode;
                    let ltn = lastPosition.textNode;
                    let text = null;

                    if (!ftn){
                        ftn=firstPosition.nodeAfter;
                    }
                    if (!ltn) {
                        ltn=lastPosition.nodeBefore;
                    }

                    if (ftn && (ftn == ltn)) {
                        const tn = ftn.data;
                        text = tn.substring(firstPosition.offset, lastPosition.offset);
                    }
                    text = (text||"").trim();
                    t.setState({anchorText:text, anchorHref:null, showPickURL:true});
                }
            });

            if (t.props.enterHandler) {
                editor.keystrokes.set("Enter", t.props.enterHandler,{priority:'highest'});
            }
            if (setFocus) {
                t.contentRef.current.focus();
            }
        }, function (err) {
            console.log("error creating editor",err);
        });
    }
  
    componentWillUnmount() {
        if (this.timeout){
            clearTimeout(this.timeout);
            this.timeout=null;
            this.sendChange();
        }
        if (this.editor) {
            this.editor.destroy();
            this.editor = null;
        }

        if (this.observer) {
            this.observer.disconnect();
            this.observer=null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!areSameDeep(this.props.entry,this.lastEntry) && !areSameDeep(this.props.entry,prevProps.entry)) {
            //console.log('different', this.props.entry, this.lastEntry, prevProps.entry);
            this.doEditorLoad = true;
            if (this.editor) {
                //console.log("updating editor")
                const state = this.getEditorStateFromEntry(this.props||{});
                this.editor.data.set(state);
            } else {
                //console.log("updating directly")
                const html = this.getEditorStateFromEntry(this.props||{});
                this.contentRef.current.innerHTML = html;
            }
            this.doEditorLoad = false;
        }
    }

    isDirty() {
        return !!this.timeout;
    }

    onChange() {
        if (this.doEditorLoad || !this.editor) {
            return;
        }
        const t=this;
        const saveDelay = this.props.saveDelay;

        if (this.timeout){
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(function () {
            t.timeout = null;
            t.sendChange();
        }, saveDelay||200);
    }

    sendChange() {
        const html = this.editor.getData();
        if (!this.props.noSearch) {
            scanEditor(this.editor);
        }

        if (html == ''){
            this.lastEntry = null;
        } else {
            this.lastEntry = {type:"html", html:html};
        }
        if (this.props.onChange) {
            this.props.onChange(this.lastEntry);
        }
    }

    render() {
        return <div key={this.key}>
            <div className="stdcontent minoneline" ref={this.contentRef} onFocus={this.onFocus.bind(this)} onClick={this.onClick.bind(this)} onKeyPress={this.props.onKeyPress}></div>
            <PickURL text={this.state.anchorText} href={this.state.anchorHref} open={this.state.showPickURL} onClose={this.onClosePickURL.bind(this)} noSearch={this.props.noSearch}/>
        </div>;
    }

    onClosePickURL(text,href) {
        if (text && text.length && href && href.length) {
            //console.log("pickurl", text, href);
            const editor=this.editor;
            //const linkCommand = editor.commands.get( 'link' );
            editor.execute( 'link', href, {linkIsExternal: false});
        }
        this.setState({showPickURL:false});
    }

}

function extendEditor(editor) {
    editor.model.schema.extend( '$text', { allowAttributes: 'contentlink' } );
    editor.model.schema.setAttributeProperties( 'contentlink', {
        isFormatting: true,
        copyOnEnter: false
    } );

    // Build converter from model to view for data and editing pipelines.
    editor.conversion.for( 'editingDowncast' ).attributeToElement( {
        model: 'contentlink',
        view: {
            name:'span',
            classes:["potentiallink"]
        }
    } );

}

function scanEditor(editor) {
    const matchList=[];
    modelElementToPlainText( editor.model.document.getRoot());
    if (matchList.length) {
        editor.model.change(function (writer){
            for (let i in matchList) {
                const m = matchList[i];

                if (m.value) {
                    writer.setAttribute("contentlink", m.value, m.range);
                } else {
                    writer.removeAttribute("contentlink", m.range);
                }
            }
        });
    }

    function modelElementToPlainText( element ) {
        if ( element.is( 'text' ) || element.is( 'textProxy' ) ) {
            let text = element.data;
            if (element.getAttribute('linkHref')) {
                if (element.getAttribute('contentlink')) {
                    matchList.push({
                        value:false, 
                        range:editor.model.createRangeOn(element)
                    });
                }
            } else if (element.getAttribute('contentlink')) {
                if (!(searchLinks.checkLink(text) >1)) {
                    //no longer a match
                    matchList.push({
                        value:false, 
                        range:editor.model.createRangeOn(element)
                    });
                }
            } else {
                text = text.replace(/\s+$/,"");
                const spaces = findSpaces(text);
                let startWord =0;
                while (startWord <= spaces.length) {
                    let endWord = startWord;
                    let continueMatch = searchLinks.checkLink(getText(text, spaces, startWord,endWord));
                    let endWordMatch=(continueMatch > 1)?startWord:-1;

                    while (continueMatch && (endWord<spaces.length)) {
                        continueMatch = searchLinks.checkLink(getText(text, spaces, startWord, endWord+1));
                        if (continueMatch) {
                            endWord++;
                        }
                        if (continueMatch > 1) {
                            endWordMatch = endWord;
                        }
                    }
                    if (endWordMatch>=0) {
                        const startPos = startWord?(spaces[startWord-1]+1):0;
                        const endPos = (endWordMatch<spaces.length)?spaces[endWordMatch]:text.length;
                        matchList.push({
                            value:true, 
                            range:editor.model.createRange(editor.model.createPositionAt(element.parent, startPos+element.startOffset), editor.model.createPositionAt(element.parent, endPos+element.startOffset))
                        });
                        startWord = endWordMatch+1;
                    } else {
                        startWord++;
                    }
                }  
            }
            return;
        }
    
        for ( const child of element.getChildren() ) {
            modelElementToPlainText( child );
        }
    }
}

function textFromElement( element ) {
    if ( element.is( 'text' ) || element.is( 'textProxy' ) ) {
        return  element.data;
    }

    let text = ""
    for ( const child of element.getChildren() ) {
        text = text + textFromElement( child );
    }
    return text;
}


class PickURL extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({text:this.props.text||"", href:this.props.href||null, searchResults:null});
            this.doSearch(50);
        }
    }

    handleClose(save) {
        if (save) {
            this.props.onClose(this.state.text, this.state.href);
        } else {
            this.props.onClose();
        }
    };

    render() {
        if (!this.props.open) {
            return null;
        }
        const sr = this.state.searchResults||[];
        const list = [];
        const href = this.state.href||""

        for (let i in sr) {
            const r=sr[i];
            list.push(<div key={i} className="hoverhighlight pv--2 flex">
                <div className="flex-auto" onClick={this.setHref.bind(this, r.href)}><span className="ttc">{r.type}</span>: {r.name}</div>
                <span className="fas fa-search pa1" onClick={this.clickPreview.bind(this, r.href)}/>
            </div>);
        }

        return  <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogContent>
                <TextVal label="Text" text={this.state.text} fullWidth onChange={this.setText.bind(this)}/>
                <div className="flex items-end">
                    <TextVal label="URL" text={href} fullWidth onChange={this.setHref.bind(this)}/>
                    {(href.charAt(0)=="#")?<span className="fas fa-search pa1 hoverhighlight" onClick={this.clickPreview.bind(this, href)}/>:null}
                </div>
                {list.length?<div className="stdcontent mv2">
                    <h2>Discovered Links</h2>
                    {list}
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Set Link
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <RenderHref open={this.state.showPreview} href={this.state.previewHref} onClose={this.closeHref.bind(this)}/>
        </Dialog>;
    }

    clickPreview(href) {
        this.setState({showPreview:true, previewHref:href});
    }
    
    closeHref() {
        this.setState({showPreview:false});
    }

    setText(text){
        this.doSearch();
        this.setState({text});
    }

    setHref(href){
        this.setState({href});
    }

    doSearch(time) {
        const t=this;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
        if (this.props.noSearch) {
            return;
        }
        this.timer = setTimeout(function () {
            t.setState({searchResults:searchContent(t.state.text, linkExclude)})
        }, time||500);
    }
}

function getText(text, spaces, startWord, endWord) {
    const startPos = (startWord>0)?(spaces[startWord-1]+1):0;
    const endPos = (endWord < spaces.length)?spaces[endWord]:text.length;
    return text.substring(startPos,endPos);
}

function findSpaces(text) {
    const list = [];
    let pos = 0;
    while (pos >= 0) {
        pos = text.indexOf(" ", pos);
        if (pos >=0) {
            list.push(pos);
            pos++;
        }
    }
    return list;
}

class Renderentry extends React.Component {
    constructor(props) {
        super(props);

        var state = props.expanddefault;
        this.ref = React.createRef(); 
		
        this.state= {expanded:state};
    }

    componentDidMount() {
        this.setState({nodes:this.getNodes(this.props)});
    }
  
    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.entry != this.props.entry) || (prevProps.root != this.props.root)) {
            this.setState({nodes:this.getNodes(this.props)});
        }
        if (prevState.nodes != this.state.nodes) {
            this.logBold();
        }
    }

    logBold() {
        const t=this;
        if (this.timeout) {
            return;
        }
        this.timeout = setTimeout(function (){
            t.timeout = null;
            if (t.ref.current) {
                if (!t.props.noDice) {
                    labelDieRolls(t.ref.current);
                }

                if (t.props.addToEncounter) {
                    const tlist=t.ref.current.getElementsByTagName("tr");
                    for (let i=0; i<tlist.length; i++){
                        let foundRollable = false;
                        let onlyequip = true;

                        const alist=tlist[i].getElementsByTagName("a");
                        for (let a=0; a<alist.length; a++){
                            const th = alist[a];
                            if ((th.host == window.location.host) && (th.pathname == "" || th.pathname=="/")) {
                                if ((th.hash||"").match(/^(#monster|#item|#coins|#customlist)/i)) {
                                    foundRollable = true;
                                    if (onlyequip && (th.hash||"").match(/^(#monster|#customlist)/i)) {
                                        onlyequip=false;
                                    }

                                    break;
                                }
                            }
                        }
                        if (foundRollable) {
                            tlist[i].classList.add(onlyequip?"addequiptocombat":"addtocombat");
                        }
                    }
                }
                {
                    const alist=t.ref.current.getElementsByTagName("a");
                    for (let a=0; a<alist.length; a++){
                        const th = alist[a];
                        if ((th.host == window.location.host) && (th.pathname == "" || th.pathname=="/")) {
                            if ((th.hash||"").match(/^#audio/i)) {
                                const span = document.createElement("span");
                                span.className="fas fa-volume-up titlecolor ph--1";
                                th.insertBefore(span,th.firstChild);
                            }
                        }
                    }
                }
                if (t.props.extraScan) {
                    t.props.extraScan(t.ref.current);
                }
            }
        },20);
    }

    getNodes(props) {
        var entry = props.entry||{};
        var depth;
        var root = props.root;

        if (root || entry.root){
            root= true;
            depth=-1;
        } else {
            depth=entry.depth||props.depth||0;
        }

        return htmlFromEntry(entry, depth, false, false, props.nodiv);
        //return getNodesFromEntry(entry, depth, props.nodiv);
    }

    render() {
        let nodes = this.state.nodes;
        const hrefDialog = this.state.showDialogHref?<RenderHref open href={this.state.href} getDiceRoller={this.props.getDiceRoller} doSubRoll={this.props.doSubRoll} onClose={this.closeHref.bind(this)} addSpellToken={this.props.addSpellToken?this.addSpellToken.bind(this):null} previousDice={this.state.previousDice} addToEncounter={this.props.addToEncounter} character={this.props.character} linkElement={this.state.linkElement}/>:null;
        const dicePopup = this.state.showDice?<DicePopup open roll={this.state.showRoll} anchorPos={this.state.anchorPos} onClose={this.hideRoll.bind(this)}/>:null;
        let addToCombat=null;
        if (this.state.showAddToCombat) {
            const {PickRandomEncounterInfoDialog} = require('./renderrandomtables.jsx');
            addToCombat = <PickRandomEncounterInfoDialog open row={this.state.row} onClose={this.closeAddToCombat.bind(this)} addToEncounter={this.props.addToEncounter}/>
        }

        if (this.props.nodiv) {
            return <span className={"pre-wrap "+(this.props.className||"")} ref={this.ref}>
                <span className="inlinefirstp" dangerouslySetInnerHTML={{__html:nodes}} onClick={this.onClick.bind(this)}/>
                {hrefDialog}
                {addToCombat}
                {dicePopup}
            </span>;
        }

        return <div className={"pre-wrap "+(this.props.className||"")} ref={this.ref}>
            <span dangerouslySetInnerHTML={{__html:nodes}} onClick={this.onClick.bind(this)}/>
            {hrefDialog}
            {addToCombat}
            {dicePopup}
        </div>;

    }

    hideRoll() {
        this.setState({showDice:false, showRoll:null, anchorPos:null});
    }

    addSpellToken(ao) {
        this.props.addSpellToken(ao);
        this.setState({showDialogHref:false});
    }

    onClick(event) {
        const target = event.target;
        let th = target;

        while (th && !th.href) {
            th=th.parentElement;
        }
        if (th && th.href) {
            if ((th.host == window.location.host) && (th.pathname == "" || th.pathname=="/" || th.pathname=="/marketplace")) {
                event.preventDefault();
                event.stopPropagation();

                let hash = th.hash;
                if (hash.endsWith("/")){
                    hash=hash.substr(0, hash.length-1);
                }
                if (this.props.handleHref) {
                    if (this.props.handleHref(hash, event)) {
                        return;
                    }
                }
                this.setState({showDialogHref:true, href:hash, previousDice:getPreviousDice(th), linkElement:th});
                return;
            } else {
                event.preventDefault();
                event.stopPropagation();
                window.open(th.href, "_blank");
                return;
            }
        } else if (target.classList.contains("dodieroll")) {
            event.preventDefault();
            event.stopPropagation();
            const {getAnchorPos} = require('./stdedit.jsx');
            const troll = (target.textContent||"").replace(/[\)\(]/g,"");
            if (this.props.doRoll) {
                //use textContent instead of innerText since innerText seems to behave weirdly if there is a line break
                const roll = this.props.doRoll(troll);
                if (roll && this.props.showInstantRoll) {
                    this.setState({showRoll:roll, showDice:true, anchorPos:getAnchorPos(event)});
                } 
            } else {
                const dice = getDiceFromString(troll);
                const {rolls} = doRoll(dice);
                const roll = {dice, rolls};
        
                this.setState({showRoll:roll, showDice:true, anchorPos:getAnchorPos(event)});
            }
            return;
        } else if (this.props.addToEncounter) {
            let test = target;
            while (test) {
                if (test.classList.contains("addtocombat") || test.classList.contains("addequiptocombat")) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.doCombatClick(test);
                    return;
                } else {
                    test = test.parentElement;
                }
            }
        }

        if (this.props.pageSync) {
            let find=target;
            while (find && (find.nodeName!="BLOCKQUOTE")) {
                find = find.parentElement;
            }
            if (find) {
                let text = find.textContent||"";
                const pos = text.indexOf("\n");
                if (pos > 5 && pos < 30) {
                    text = text.substr(0,pos);
                } else if (text.length > 20) {
                    text = text.substr(0,20)+"..."
                }
                this.props.pageSync.emit("clickcontent", {contentType:"BlockQuote", html:removeClasses(find.innerHTML), summary:text, event});
                return;
            }
        }
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    doCombatClick(target) {
        const alist=target.getElementsByTagName("a");
        const row = {monsters:{},items:{},coins:{}, custom:[]};
        let found = false;
        const {campaign} = require('../lib/campaign.js');


        for (let a=0; a<alist.length; a++){
            const th = alist[a];
            if ((th.host == window.location.host) && (th.pathname == "" || th.pathname=="/")) {
                const page = getLocationInfo(th.hash||"");
                if (page.page=="monster") {

                    row.monsters[page.id]={dice:getDiceFromString(getPreviousDice(th)||"1",0,true)};
                    found=true;
                }
                if (page.page=="item") {
                    const item = campaign.getItem(page.id)
                    if (item) {
                        row.items[campaign.newUid()]={item, dice:getDiceFromString(getPreviousDice(th)||"1",0,true)};
                        found=true;
                    }
                }
                if (page.page=="customlist") {
                    const it = campaign.getCustom(page.type, page.id)
                    if (it) {
                        row.custom.push({type:page.type, id:page.id, dice:getDiceFromString(getPreviousDice(th)||"1",0,true)})
                        found=true;
                    }
                }
                if (page.page=="coins") {
                    if (page.id) {
                        const coin = page.id.toLowerCase();
                        row.coins[coin]= getDiceFromString(getPreviousDice(th)||"1",0,true);
                        found=true;
                    }
                }
            }
        }
        if (found) {
            this.setState({showAddToCombat:true, row});
        }

    }

    closeAddToCombat(row) {
        if (row){
            this.props.addToEncounter(row);
        }
        this.setState({showAddToCombat:false, row:null});
    }

    closeHref(){
        this.setState({showDialogHref:false})
    }
    
}

function labelDieRolls(current) {
    const dieMatch =/^([\s\+\-dD\d\(\)]+)$/;
    for (let v of ["strong","b"]) {
        const list=current.getElementsByTagName(v);
        for (let i=0; i<list.length; i++){
            if (!list[i].children?.length) {// don't bold if nested elements that aren't just text.
                const inner = (list[i].textContent||"").trim();
                if (inner && inner.match(dieMatch)) {
                    list[i].classList.add("dodieroll");
                }
            }
        }
    }
}

function getPreviousDice(target) {
    let previous = target.previousSibling;
    const dieMatch = /(^|\s)\(?([\d]*d[\d]+\s*(|\+\s*\d+)|\+?\s*\d+)\)?\s*$/i;
    while (previous) {
        let inner = (previous.nodeType==3 /*TEXT_NODE*/)?previous.nodeValue:previous.textContent;
        inner = (inner||"").trim();
        if (inner.length) {
            const m = inner.match(dieMatch);
            return m && m[0].trim().replace(/[\(\)]/g,"");
        }
        previous = previous.previousSibling;
    }
    return null;
}

class Renderstring extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        if (!this.props.entry)
            return null;
        let html = htmlFromString(this.props.entry);
        return <Renderentry entry={{type:"html", html}} nodiv noDice/>;
    }
}

function entryToText(entry) {
    let html = htmlFromEntry(entry);
    let wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    return wrapper.textContent||"";
}

function entryToElement(entry) {
    let html = htmlFromEntry(entry);
    let wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    return wrapper;
}

function removeClasses(html) {
    let wrapper= document.createElement('div');
    wrapper.innerHTML= html;

    removeClass(wrapper,"dodieroll" );
    removeClass(wrapper,"addtocombat" );
    removeClass(wrapper,"addequiptocombat" );
    removeClass(wrapper,"fa-volume-up" );
    return wrapper.innerHTML;
}

function removeClass(wrapper, className) {
    const collection = wrapper.getElementsByClassName(className);
    if (collection) {
        for (let i=collection.length-1; i>=0; i--) {
            collection[i].classList.remove(className);
        }
    }
}

export {
    EntityEditor, 
    Renderentry,
    Renderstring,
    entryToText,
    entryToElement,
    labelDieRolls
};