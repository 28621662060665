const React = require('react');
const {campaign,globalDataListener,sortDisplayName, httpHeadRequest,addCampaignToPath} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
import Tooltip from '@material-ui/core/Tooltip';
const {ArtPicker, ArtDialog, SearchWebArtPicker, ArtImageList, cleanFilename, uploadArtworkWithThumbnail,getFileAsUrl, resizeImage} = require('./renderart.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const {DeleteWithConfirm, TextVal, SelectVal, CheckVal, getAnchorPos, defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
import { Stage, Layer, Rect, Image as KImage, Line } from 'react-konva';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
const {HoverMenu} = require( "./hovermenu.jsx");
const {ListFilter} = require('./listfilter.jsx');

const {environmentTypeList,nameEncode} = require('../lib/stdvalues.js');
const {displayMessage} = require('./notification.jsx');

let InlineMap;

const mapTypeList = [ "Battle", "Building", "City/Town", "Continent", "Dungeon", "Region", "World"];

class RenderMapList extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
    
	    this.state= {type:this.props.defaultType};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "maps");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "maps");
    }

    onDataChange() {
        this.setState({maps:campaign.getMaps()});
    }

	render() {
        return <div className="h-100 w-100 pa1">
            <ListFilter 
                list={campaign.getMaps()}
                showThumbnails
                defaultFilter={{type:this.props.defaultType||null}}
                filters={mapListFilters}
                onClick={this.clickMap.bind(this)}
                select="click"
            />
            <MapDialog open={this.state.showMap} name={this.state.pickMap} onClose={this.onCloseMap.bind(this)}/>
        </div>;
    }

    clickMap(name){
        if (this.props.doNav) {
            window.location.href = addCampaignToPath("#maps?id="+encodeURIComponent(name),true);
        } else if (this.props.onClick) {
            this.props.onClick(campaign.getMapInfo(name));
        } else {
            this.setState({showMap:true, pickMap:name});
        }
    }

    onCloseMap() {
        this.setState({showMap:false});
    }
}

const mapListFilters = [
    {
        filterName:"Environment",
        fieldName:"environments"
    },
    {
        filterName:"Type",
        fieldName:"type"
    },
    defaultSourceFilter,
    defaultBookFilter
];


function fixMapShift(map) {
    const art = (map.art?campaign.getArtInfo(map.art):null)||{imgWidth:map.imgWidth||1, imgHeight:map.imgHeight||1, url:map.url};
    const imgWidth = Number(art.originalWidth||art.imgWidth);
    const imgHeight = Number(art.originalHeight||art.imgHeight);
    const minDimension = Math.trunc(Math.min(imgHeight, imgWidth)/4);

    if (!minDimension){
        return;
    }
    if (Number(map.pixelsPerGrid) > minDimension) {
        map.pixelsPerGrid = minDimension;
    }
    if (isNaN(map.gridxShift) || (Number(map.gridxShift) > 0)) {
        map.gridxShift = 0;
    } else if ((Number(map.gridxShift) +imgWidth)<0) {
        map.gridxShift = -imgWidth;
    }

    if (isNaN(map.gridyShift) || (Number(map.gridyShift) > 0)) {
        map.gridyShift = 0;
    } else if ((Number(map.gridyShift) + imgHeight)<0) {
        map.gridyShift = -imgHeight;
    }
}

class MapDialog extends React.Component {
    constructor(props) {
        super(props);
        const t=this;

        //prevent require loop
        if (!InlineMap) {
            InlineMap = require('./map.jsx').InlineMap;
        }

        this.state={showPickMap:false};
        this.idKey = campaign.newUid();
    }

    componentDidMount() {
        if (this.props.open) {
            this.loadState();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.open != this.props.open) && this.props.open) {
            this.loadState();
        }
    }

    loadState() {
        let map;
        if (this.props.name) {
            map =campaign.getMapInfo(this.props.name);
        } else {
            map={};
            map.type = this.props.defaultType || "Battle";
            map.displayName=this.props.defaultName;
            map.name=campaign.newUid();
            map.pixelsPerGrid=70;
            map.gridxShift=-140;
            map.gridyShift=-140;
            map.art=this.props.mapArt||null;
        }
        fixMapShift(map);
        if (!map.units){
            map.units="feet";
        }
        if (!map.gridSize) {
            map.gridSize = 5;
        }
        this.setState({map, image:null, showPickMap:false, mapArt:this.props.mapArt, showQuickBuild:!!(this.props.webURL||this.props.file)});
    }

    render() {
        if (!this.props.open || !this.state.map) {
            return null;
        }
        const map=this.state.map;
        const art = (map.art?campaign.getArtInfo(map.art):null)||{imgWidth:map.imgWidth||1, imgHeight:map.imgHeight||1, url:map.url};
        const environments = map.environments||[];
        const envList=[];

        for (let i in environmentTypeList) {
            const e=environmentTypeList[i];
            envList.push(<div key={e} className="minw4"><CheckVal label={e} value={environments.includes(e)} onChange={this.toggleEnvironment.bind(this, e)}/></div>);
        }

        var pixelsPerGrid = Number(map.pixelsPerGrid);
        var scale = 1;
        var totalWidth = "";
        var totalHeight = "";
        let gridyShift = map.gridyShift;
        let gridxShift = map.gridxShift;
        const scaleSize = 320;
        const rescaled = (art.originalWidth && (art.originalWidth != art.imgWidth));

        if (pixelsPerGrid < 1) {
            pixelsPerGrid = 1;
        } 
        let calcPixelsPerGrid = pixelsPerGrid;

        if (rescaled) {
            calcPixelsPerGrid = calcPixelsPerGrid*art.imgWidth/art.originalWidth;
            gridyShift = gridyShift*art.imgWidth/art.originalWidth;
            gridxShift = gridxShift*art.imgWidth/art.originalWidth;
        }
        scale = scaleSize/calcPixelsPerGrid/4;
        this.scale = scale;

        var boxLow = scaleSize/2 - Math.trunc(calcPixelsPerGrid/2)*scale;
        var boxHigh = boxLow + calcPixelsPerGrid*scale;
        this.boxShift = boxLow/scale;
            
        if (art) {
            totalWidth = Math.trunc((art.originalWidth||art.imgWidth) / pixelsPerGrid*(scaleSize/4) * Number(map.gridSize)/(scaleSize/4));
            totalHeight =  Math.trunc((art.originalHeight|| art.imgHeight) / pixelsPerGrid*(scaleSize/4) * Number(map.gridSize)/(scaleSize/4));
        } 

        return <Dialog 
            open
            maxWidth="md"
            fullWidth
        >
            <DialogContent className="overflow-y-scroll">
                <div className="mb1">
                    <TextVal fullWidth inputProps={{className:"f2 titletext titlecolor"}} className="mb1" text={map.displayName} onChange={this.onChangeField.bind(this,"displayName")} label="Name"/>
                    <div className="flex flex-wrap w-100 items-start">
                        <div className="minw4">
                            <SelectVal fullWidth className="mb2 titletext titlecolor minw5" value={map.type||""} values={mapTypeList} onClick={this.onChangeField.bind(this, "type")} label="Type"/>
                        </div>
                        <div className="w-50 flex-auto ml2 hk-well">
                            <div className="flex flex-wrap tl">
                                {envList}
                            </div>
                        </div>
                    </div>
                </div>
                {art && art.url?<div className="hk-well flex-auto w-100 mb1">
                    <input
                        key={this.idKey}
                        accept="image/*"
                        className="dn"
                        id={"image-file-upload2"+this.idKey}
                        type="file"
                        onChange={this.selectFile.bind(this)}
                        multiple
                    />
                    <div className="mb1">
                        Alternate Map Artwork <Button onClick={this.onShowPickAltMap.bind(this)} variant="outlined" size="small">
                            Pick Image
                        </Button>
                        <label htmlFor={"image-file-upload2"+this.idKey}>
                            <Button  size="small" className="ml1" variant="outlined" component="span">
                                Upload Image
                            </Button>
                        </label>
                    </div>
                    {map.artList?<div>
                        {this.getArtList()}
                    </div>:<div>
                        As an option you may select alternate artwork to allow map image to be changed without altering fog, or token locations.
                    </div>}
                </div>:null}
                {art && art.url?<div className="flex w-100 flex-wrap">
                    <div className="flex-auto minw5 w-50 mw7 mb2">
                        <div className="mb1">
                            Map Size: {totalWidth} {map.units} wide x {totalHeight} {map.units} tall
                        </div>
                        <InlineMap className="w-100" noPlaceHolder mapInfo={map} onClick={null} onLoadMap={this.onLoadMap.bind(this)}/>
                    </div>
                    <div className="ph2 relative">
                        {this.state.image?<div>
                            <div className="flex items-end mb1">
                                <TextVal
                                    text={map.pixelsPerGrid}
                                    isNum
                                    onChange={this.onChangeField.bind(this, "pixelsPerGrid")}
                                    helperText="Pixels/Grid"
                                    className="flex-auto w3"
                                />
                                <TextVal
                                    text={map.gridSize}
                                    isNum
                                    onChange={this.onChangeField.bind(this,"gridSize")}
                                    helperText="Grid Size"
                                    className="flex-auto mh2 w3"
                                />

                                <SelectVal
                                    value={map.units}
                                    values={["feet", "miles"]}
                                    onClick={this.onChangeField.bind(this,"units")}
                                    helperText="units"
                                />
                            </div>
                            <div className="hk-well mb1" style={{maxWidth:scaleSize}}>
                                <div>Drag image to align grid.</div>
                                {rescaled?<div className="mt1">The artwork for this map has been rescaled from {art.originalWidth.toLocaleString()} X {art.originalHeight.toLocaleString()} to {art.imgWidth.toLocaleString()} X {art.imgHeight.toLocaleString()}.  Set pixels/grid using the original artwork size.</div>:null}
                            </div>
                            <Stage height={scaleSize} width={scaleSize} onWheel={this.onWheel.bind(this)}>
                                <Layer scaleX={scale} scaleY={scale} x={boxLow} y={boxLow}>
                                    <KImage image={this.state.image} draggable onDragMove={this.onDragMove.bind(this)} x={gridxShift} y={gridyShift}/>
                                </Layer>
                                <Layer>
                                    <Line points={[boxLow,0,boxLow,scaleSize]} stroke="yellow" shadowBlur={5}/>
                                    <Line points={[boxHigh,0,boxHigh,scaleSize]} stroke="yellow" shadowBlur={5}/>
                                    <Line points={[0,boxLow,scaleSize,boxLow]} stroke="yellow" shadowBlur={5}/>
                                    <Line points={[0,boxHigh,scaleSize,boxHigh]} stroke="yellow" shadowBlur={5}/>
                                </Layer>
                            </Stage>
                        </div>:null}
                    </div>
                </div>:<div className="ma3">
                    <Button onClick={this.onShowPickMap.bind(this)} color="primary">
                        Pick Map Image
                    </Button>
                </div>}
            </DialogContent>
            <DialogActions>
                {art&&art.url?<Button onClick={this.onShowPickMap.bind(this)} color="primary">
                    Change Image
                </Button>:null}
                <Button onClick={this.onSave.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog
                open
            >
                <DialogContent>
                    Loading artwork...
                </DialogContent>
            </Dialog>:null}
            <ArtPicker open={this.state.showPickMap} defaultType="Map" defaultName={map.displayName} onClose={this.pickMap.bind(this)}/>
            <ArtPicker open={this.state.showPickAltMap} defaultType="Map" defaultName={map.displayName} onClose={this.pickAltMap.bind(this)}/>
            <ArtDialog open={this.state.showQuickBuild} defaultType="Map" defaultName={this.props.defaultName} quickBuild onClose={this.closeQuickBuild.bind(this)} file={this.props.file} webURL={this.props.webURL}/>
            <ArtDialog open={this.state.showAltMap} name={this.state.showAltMap} defaultType="Map" onClose={this.hideAltMap.bind(this)}/>
            <ArtDialog open={this.state.showNewAltMap} defaultName={this.state.defaultName} file={this.state.file} defaultType="Map" onClose={this.createAltMap.bind(this)}/>
        </Dialog>
    }

    async selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            // upload file
            const file = e.target.files[0];
            this.setState({defaultName:cleanFilename(file.name), showNewAltMap:true, file});
        } else if (e.target.files.length > 1) {
            this.setState({loading:true});
            try {
                await this.uploadAltFiles(e.target.files);
                displayMessage("Alternate map images uploaded");
            } catch (err) {
                console.log("error uploading files", err);
                displayMessage("Error uploading artwork");
            }
            this.setState({loading:false});
        }
    }

    async uploadAltFiles(files) {
        const map=Object.assign({}, this.state.map);
        const artList = (map.artList||[]).concat([]);
        const art = campaign.getArtInfo(map.art);
        if (!art) {
            throw (new Error("No artwork found"));
        }

        const res = await httpHeadRequest(art.url);
        const switchToJpeg = (res.contentType == "image/jpeg");

        for (let file of files) {
            const url = await getFileAsUrl(file);
            const contentType = switchToJpeg?"image/jpeg":file.type;
            const blob = await resizeImage(url, art.imgWidth, contentType);
            const token = {
                name:campaign.newUid(),
                displayName:cleanFilename(file.name),
                type:"Map",
                imgWidth:art.imgWidth,
                imgHeight:art.imgHeight,
                artVersionId:campaign.newUid()
            };

            await uploadArtworkWithThumbnail(token, blob, contentType, file);
            artList.push(token.name);
        }
        map.artList = artList;
        this.setState({map});
    }

    getArtList() {
        const map=this.state.map;
        const artList = map.artList;
        const list = [];

        if (!artList || !artList.length) {
            return null;
        }
        for (let i of artList) {
            const art = campaign.getArtInfo(i)
            if (art) {
                list.push({name:i, displayName:getSimpleAltMapName(map, art), thumb:art.thumb, url:art.url, imgHeight:art.imgHeight, imgWidth:art.imgWidth});
            }
        }
        list.sort(sortDisplayName);
        return <ArtImageList widthCalc="120" onClick={this.selectFromArtList.bind(this)} onDelete={this.deleteFromArtList.bind(this)} imageList={list}/>
    }

    selectFromArtList(art) {
        this.setState({showAltMap:art.name});
    }

    hideAltMap() {
        this.setState({showAltMap:null})
    }

    closeQuickBuild(name) {
        if (!name) {
            this.onClose();
            return;
        }
        const map = Object.assign({},this.state.map);
        map.art = name;
        fixMapShift(map);
        this.setState({showQuickBuild:false, map});
    }

    onShowPickMap(){
        this.setState({showPickMap:true});
    }

    onShowPickAltMap(){
        this.setState({showPickAltMap:true});
    }

    createAltMap(name) {
        if (name) {
            const art = campaign.getArtInfo(name);
            this.pickAltMap(art);
        }
        this.setState({showNewAltMap:false});
    }

    pickAltMap(art) {
        if (art) {
            const map=Object.assign({}, this.state.map);
            const artList = (map.artList||[]).concat([]);
            if (!artList.includes(art.name)) {
                artList.push(art.name);
            }
            map.artList = artList;
            this.setState({showPickAltMap:false, map});
        } else {
            this.setState({showPickAltMap:false});
        }
    }

    deleteFromArtList(art) {
        const map=Object.assign({}, this.state.map);
        const artList = (map.artList||[]).concat([]);
        const pos = artList.indexOf(art.name);
        if (pos>=0) {
            artList.splice(pos,1);
        }
        map.artList = artList;
        this.setState({showPickAltMap:false, map});
    }

    pickMap(art){
        if (art) {
            const map=Object.assign({}, this.state.map);
            const oldart = map.art?campaign.getArtInfo(map.art):null;

            if (!map.pixelsPerGrid) {
                map.pixelsPerGrid=70;
            } else if (oldart && oldart.imgWidth && art.imgWidth) {
                const ratio = (art.originalWidth||art.imgWidth)/(oldart.originalWidth || oldart.imgWidth);
                map.pixelsPerGrid= Math.trunc(ratio*map.pixelsPerGrid)||1;
                map.gridxShift= map.gridxShift*ratio;
                map.gridyShift= map.gridyShift*ratio;
            }

            fixMapShift(map);
            map.art = art.name;
            if (!map.displayName) {
                map.displayName=art.displayName;
            }
            delete map.url;
            delete map.thumb;
            delete map.imgWidth;
            delete map.imgHeight;
            this.setState({showPickMap:false, map});
        } else {
            this.setState({showPickMap:false});
        }
    }

    onLoadMap(image) {
        this.setState({image:image})
    }

    toggleEnvironment(env) {
        const map=Object.assign({}, this.state.map);
        const environments = (map.environments||[]).concat([]);
        const pos = environments.indexOf(env);
        if (pos<0) {
            environments.push(env);
        } else {
            environments.splice(pos,1);
        }
        map.environments = environments;
        this.setState({map});

    }

    onChangeField(prop, val, p2,v2) {
        const map=Object.assign({}, this.state.map);

        map[prop] = val;
        if (p2) {
            map[p2]=v2;
        }
        fixMapShift(map);
        this.setState({map});
    }

    onClose() {
        this.props.onClose();
    }

    onSave() {
        const mapArt = this.state.mapArt;
        const map = this.state.map;

        if (mapArt && (map.art == mapArt) && map.displayName && map.displayName.length) {
            const art = campaign.getArtInfo(mapArt);
            if (art) {
                const newArt = Object.assign({}, art);
                newArt.displayName = map.displayName;
                campaign.updateCampaignContent("art", newArt);
            }
        }
        campaign.updateCampaignContent("maps", map);
        this.props.onClose(map.name);
    }


    onWheel(e) {
        e.evt.preventDefault();
        e.evt.stopPropagation();
        var oldP = Number(this.state.map.pixelsPerGrid)||70;
        this.onChangeField("pixelsPerGrid",oldP+((e.evt.deltaY<0)?-1:1));        
    }

    onDragMove(e){
        const x = e.target.x();
        const y = e.target.y();
        const map = this.state.map;
        const art = map.art?campaign.getArtInfo(map.art):{imgWidth:map.imgWidth||1, imgHeight:map.imgHeight||1, url:map.url};
        const gridScale = art.originalWidth?art.originalWidth/art.imgWidth:1;

        this.onChangeField("gridxShift", x*gridScale, "gridyShift",y*gridScale);
    }
}

class MapDialogReadOnly extends React.Component {
    constructor(props) {
        super(props);
        const t=this;

        //prevent require loop
        if (!InlineMap) {
            InlineMap = require('./map.jsx').InlineMap;
        }

        this.state={};
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.open != this.props.open) && this.props.open) {
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const map =campaign.getMapInfo(this.props.name);;

        return <Dialog 
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>{map?map.displayName:"Unknown Map"}</DialogTitle>
            <DialogContent>
                {map?<InlineMap className="w-100" noPlaceHolder mapInfo={map}/>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    }

    onClose() {
        this.props.onClose();
    }
}

function getSimpleAltMapName(map, art) {
    const mapDisplayName = map.displayName;
    const mainArt = campaign.getArtInfo(map.art);
    const mainArtName = (mainArt && mainArt.displayName);

    let displayName = art.displayName||"";
    if (mapDisplayName && displayName.startsWith(mapDisplayName)) {
        displayName=displayName.substr(mapDisplayName.length).trim();
    } else if (mainArtName && displayName.startsWith(mainArtName)) {
        displayName=displayName.substr(mainArtName.length).trim();
    }
    if (displayName.startsWith("-") || displayName.startsWith(",")){
        displayName=displayName.substr(1).trim();
    }
    return displayName;
}

class MapPicker extends React.Component {
    constructor(props) {
        super(props);

   
        this.state={showNew:false,selection:null,bookMaps:props.open?getCampaignBookMaps():null};
        this.idKey = campaign.newUid();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({showNew:false, selection:null, bookMaps:getCampaignBookMaps()});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        let filter;
        let title="Maps";

        if (this.props.campaignVersion) {
            const showFavorites = !campaign.isDefaultCampaign();
            const mruList = campaign.getMRUList("mruMaps", this.props.currentMap)||[];
            const favorites = campaign.getPrefs().mapFavorites||{};
            const areFavorites = Object.keys(favorites).length;
            const altImages = this.getAltMapImages();
            const {bookMaps}=this.state;
            let selection = this.state.selection || (mruList.length?"recent":"all");

            switch (selection) {
                case "recent": {
                    const list =[];
                    const shown={};
                    for (let i in mruList) {
                        const m=mruList[i];
                        if (m.mapName) {
                            const map = campaign.getMapInfo(m.mapName);
                            if (map && !shown[m.mapName]) {
                                list.push(map);
                                shown[m.mapName] = true
                            }
                        }
                    }
                    filter = <ListFilter 
                        list={list}
                        showThumbnails
                        filters={mapListFilters}
                        onClick={this.onClickMap.bind(this)}
                        select={showFavorites?"list":"click"}
                        selected={favorites}
                        hideSelected
                        useStar
                        onSelectedChange={this.updateFavorites.bind(this)}
                    />
                    break;
                }
                case "favorites": {
                    const list =[];
                    for (let i in favorites) {
                        const map = campaign.getMapInfo(i);
                        if (map) {
                            list.push(map);
                        }
                    }
                    list.sort(sortDisplayName);
                    filter = <ListFilter 
                        list={list}
                        showThumbnails
                        filters={mapListFilters}
                        onClick={this.onClickMap.bind(this)}
                        select={showFavorites?"list":"click"}
                        selected={favorites}
                        hideSelected
                        useStar
                        onSelectedChange={this.updateFavorites.bind(this)}
                    />
                    break;
                }
                case "alternates": {
                    filter = <ArtImageList onClick={this.onClickAltMap.bind(this)} imageList={altImages}/>;
                    break;
                }
                case "book":{
                    filter = <ListFilter 
                        list={bookMaps}
                        showThumbnails
                        filters={mapListFilters}
                        onClick={this.onClickMap.bind(this)}
                        select={showFavorites?"list":"click"}
                        selected={favorites}
                        hideSelected
                        useStar
                        onSelectedChange={this.updateFavorites.bind(this)}
                    />
                    break;
                }
                default:
                case "all":{
                    filter = <ListFilter 
                        list={campaign.getMaps()}
                        showThumbnails
                        defaultFilter={{type:this.props.defaultType||null}}
                        filters={mapListFilters}
                        onClick={this.onClickMap.bind(this)}
                        select={showFavorites?"list":"click"}
                        selected={favorites}
                        hideSelected
                        useStar
                        onSelectedChange={this.updateFavorites.bind(this)}
                    />
                    break;
                }
            }

            title = <div className="notecontent ignoreDrag">
                <div className="tc mb2">
                    <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                        {altImages?<Button variant={(selection=="alternates")?"contained":null} onClick={this.clickSelection.bind(this, "alternates")}>Alternate Images</Button>:null}
                        {showFavorites&&areFavorites?<Button variant={(selection=="favorites")?"contained":null} onClick={this.clickSelection.bind(this, "favorites")}>Campaign Favorites</Button>:null}
                        {mruList.length?<Button variant={(selection=="recent")?"contained":null} onClick={this.clickSelection.bind(this, "recent")}>Recently Used</Button>:null}
                        {bookMaps?<Button variant={(selection=="book")?"contained":null} onClick={this.clickSelection.bind(this, "book")}>Book</Button>:null}
                        <Button variant={(selection=="all")?"contained":null} onClick={this.clickSelection.bind(this, "all")}>All Maps</Button>
                    </ButtonGroup>
                </div>
            </div>;

        } else {
            filter = <ListFilter 
                list={campaign.getMaps()}
                showThumbnails
                defaultFilter={{type:this.props.defaultType||null}}
                filters={mapListFilters}
                onClick={this.onClickMap.bind(this)}
                select="click"
            />
        }

        return <Dialog
            open
            scroll="paper"
            maxWidth="md"
            fullWidth
            onClose={this.onDone.bind(this)}
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onDone.bind(this)}>{title}</DialogTitle>    
            <DialogContent className="overflow-y-scroll">
                {filter}
            </DialogContent>
            <DialogActions>
                <input
                    key={this.idKey}
                    accept="image/*"
                    className="dn"
                    id={"image-file-uploadx"+this.idKey}
                    type="file"
                    onChange={this.selectFile.bind(this)}
                />
                <label htmlFor={"image-file-uploadx"+this.idKey}>
                    <Button color="primary" component="span">
                        Upload Map
                    </Button>
                </label>
                {!this.props.noSearchMap?<Button color="primary" onClick={this.showSearchWeb.bind(this)}>
                    Search Web for Map
                </Button>:null}

                <Button onClick={this.onDone.bind(this)} color="primary">
                   Close
                </Button>
            </DialogActions>
            <SearchWebArtPicker open={this.state.showSearchWeb} onClose={this.selectFromWeb.bind(this)} type="Map"/>
            <MapDialog open={this.state.showNew} onClose={this.onCloseNew.bind(this)} defaultName={this.state.defaultName} file={this.state.file} webURL={this.state.webURL}  defaultType={this.props.defaultType}/>
        </Dialog>;
    }

    clickSelection(selection) {
        this.setState({selection});
    }

    updateFavorites(mapFavorites) {
        campaign.setPrefs({mapFavorites})
        this.setState({mapFavorites});
    }

    onClickAltMap(sel) {
        this.props.onClickAltMap(sel.name);
    }

    getAltMapImages() {
        const map=campaign.getMapInfo(this.props.mapName);
        if (!map || !this.props.onClickAltMap){
            return null;
        }
        const artList = map.artList;
        if (!artList) {
            return null;
        }
        const list = [];

        for (let i of artList) {
            const art = campaign.getArtInfo(i)
            if (art) {
                list.push({name:i, displayName:getSimpleAltMapName(map, art), thumb:art.thumb, url:art.url, imgHeight:art.imgHeight, imgWidth:art.imgWidth});
            }
        }
        list.sort(sortDisplayName);
        {
            const art = campaign.getArtInfo(map.art);

            if (art) {
                list.unshift({name:"default", displayName:"Default", thumb:art.thumb, url:art.url, imgHeight:art.imgHeight, imgWidth:art.imgWidth});
            }
        }
        return list;
    }

    onClickMap(name) {
        this.props.onClose(campaign.getMapInfo(name));
    }

    showNew(){
        this.setState({showNew:true, file:null, webURL:null, defaultName:this.props.defaultName||null});
    }

    onCloseNew(name) {
        if (name) {
            const map = campaign.getMapInfo(name);
            this.props.onClose(map);
        }
        this.setState({showNew:false})
    }

    onDone() {
        this.props.onClose();
    }

    selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            // upload file
            const file = e.target.files[0];
            return this.setState({defaultName:cleanFilename(file.name), showNew:true, webURL:null, file});
        }
    }

    showSearchWeb() {
        this.setState({showSearchWeb:true});
    }

    selectFromWeb(url, search) { 
        if (url) {
            return this.setState({defaultName:search||this.props.defaultName||"map", showNew:true, webURL:url, file:null, showSearchWeb:false});
        } else {
            this.setState({showSearchWeb:false});
        }
    }
}

function getCampaignBookMaps() {
    const {selectedReference, bookTabs} = campaign.getPrefs();
    const maps = {};
    const list = [];

    addBooksToMaps(maps, selectedReference);
    for (let i in bookTabs) {
        const bt = bookTabs[i];
        if (bt) {
            addBooksToMaps(maps, bt.book);
        }
    }

    for (let m in maps) {
        const map = campaign.getMapInfo(m);
        if (map) {
            list.push(map);
        }
    }
    if (!list.length) {
        return null;
    }

    list.sort(function (a,b) { return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase())});
    return list;
}

function addBooksToMaps(maps, bookName) {
    const b = campaign.getBookInfo(bookName);

    if (b) {
        for (let c of (b.chapters||[])) {
            addMaps(c);
    
            for (let s of (c.sections||[])){
                addMaps(s);
                for (let ss of (s.subsections||[])){
                    addMaps(ss);
                }
            }
        }
    }

    function addMaps(s) {
        if (s.contentType=="Maps") {
            maps[s.contentId]=1;
        }

        for (let c of s.contentList||[]) {
            if (c.contentType=="Maps") {
                maps[c.contentId]=1;
            }
        }
    }
}

class AltMapPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const map=campaign.getMapInfo(this.props.mapName);
        const artList = map.artList;
        const list = [];

        for (let i of artList) {
            const art = campaign.getArtInfo(i)
            if (art) {
                list.push({name:i, displayName:getSimpleAltMapName(map, art), thumb:art.thumb, url:art.url, imgHeight:art.imgHeight, imgWidth:art.imgWidth});
            }
        }
        list.sort(sortDisplayName);
        {
            const art = campaign.getArtInfo(map.art);

            if (art) {
                list.unshift({name:"default", displayName:"Default", thumb:art.thumb, url:art.url, imgHeight:art.imgHeight, imgWidth:art.imgWidth});
            }
        }


        return <Dialog
            open
            maxWidth="sm"
            fullWidth
            onClose={this.onDone.bind(this)}
        >
            <DialogTitle onClose={this.onDone.bind(this)}>Pick Image</DialogTitle>    
            <DialogContent>
                <ArtImageList onClick={this.onClickMap.bind(this)} imageList={list}/>                
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onDone.bind(this)} color="primary">
                   Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClickMap(sel) {
        this.props.onClose(sel.name);
    }

    onDone() {
        this.props.onClose();
    }
}

class MapPickList extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
        this.idKey = campaign.newUid();
    }

    render() {
        let inside;
        if (this.props.showButtonMenu) {
            inside= <Tooltip title="Load Map"><span onClick={this.showPicker.bind(this)}>{this.props.children}</span></Tooltip>;
        } else {
            inside= <span>
                <label htmlFor={"image-file-upload2"+this.idKey}>
                    <Button  size="small" className="minw2 ml1" variant="outlined" component="span">
                        Upload Map
                    </Button>
                </label>
                {!this.props.noSearchMap?<Button size="small" className="minw2 ml1" variant="outlined" onClick={this.showSearchWeb.bind(this)}>
                    Search Web for Map
                </Button>:null}
                {!this.props.noPickMap?<Button size="small" className="minw2 ml1" variant="outlined" onClick={this.showPicker.bind(this)}>
                    Load Map
                </Button>:null}
            </span>;
        }
        return <span>
            <input
                key={this.idKey}
                accept="image/*"
                className="dn"
                id={"image-file-upload2"+this.idKey}
                type="file"
                onChange={this.selectFile.bind(this)}
            />
            {inside}
            {this.state.showPicker?<MapPicker onClose={this.pickMap.bind(this)} campaignVersion mapName={this.props.currentMap} onClickAltMap={this.showAltArtwork.bind(this)} open/>:null}
            {this.state.showNewMap?<MapDialog open onClose={this.onCloseNew.bind(this)} defaultName={this.state.defaultName} file={this.state.file} webURL={this.state.webURL}/>:null}
            {this.state.showSearchWeb?<SearchWebArtPicker open onClose={this.selectFromWeb.bind(this)} type="Map"/>:null}
            {this.state.showAltPicker?<AltMapPicker mapName={this.props.currentMap} onClose={this.showAltArtwork.bind(this)} open/>:null}
        </span>
    }

    onCloseNew(name) {
        if (name) {
            const map = campaign.getMapInfo(name);
            this.props.onPickMap(map);
        }
        this.setState({showNewMap:false})
    }

    clickChildren(e) {
        this.setState({anchorPos:getAnchorPos(e, true), showMenu:true, showMRU:true});
    }

    getMapMenu() {
        const ml = [];
        const mruList = campaign.getMRUList("mruMaps", this.props.currentMap);
        const shown={};
        const map =campaign.getMapInfo(this.props.currentMap);

        if (map && map.artList && map.artList.length) {
            ml.push(<MenuItem key="ap" onClick={this.showAltArtworkPicker.bind(this)}>Pick Alternate Image</MenuItem>);
            ml.push(<Divider key="da"/>);
        }
        ml.push(<MenuItem key="p" onClick={this.showPicker.bind(this)}>Pick Map</MenuItem>);
        ml.push(<span key="u">
            <label htmlFor={"image-file-upload2"+this.idKey}>
                <MenuItem>Upload map</MenuItem>
            </label>
        </span>);
        ml.push(<MenuItem key="ws" onClick={this.showSearchWeb.bind(this)}>Search Web for Map</MenuItem>);

        if (mruList.length > 0) {
            ml.push(<Divider key="d"/>);
        }

        for (let i in mruList) {
            const m=mruList[i];

            if (m.pin && this.props.onGoToPin) {
                ml.push(<MenuItem key={i} onClick={this.onGoToPin.bind(this, m.pin)}>{m.description}</MenuItem>);
            } else if (m.mapName) {
                const mapInfo = campaign.getMapInfo(m.mapName);
                if (mapInfo) {
                    ml.push(<MenuItem key={i} onClick={this.pickMap.bind(this, mapInfo)}>{mapInfo.displayName}</MenuItem>);
                    shown[m.mapName] = true;
                }
            }
        }

        return <Paper>
            {ml}
        </Paper>
    }

    getAltMapMenu(map) {
        const artList = map.artList||[];
        const ml = [];
        const width=75;

        const defaultArt = campaign.getArtInfo(map.art);
        if (defaultArt) {
            ml.push(<MenuItem key="d" onClick={this.showAltArtwork.bind(this, null)}>
                <div className="tc" style={{width}}>
                    {defaultArt.imgHeight>defaultArt.imgWidth?<img src={defaultArt.thumb || defaultArt.url} height={width} alt="Loading..."/>:<img src={defaultArt.thumb || defaultArt.url} width={width} alt="Loading..."/>}
                </div>
                <div>
                    Default
                </div>
            </MenuItem>);
        }

        for (let i in artList) {
            const art = campaign.getArtInfo(artList[i]);
            if (art) {
                ml.push(<MenuItem key={i} onClick={this.showAltArtwork.bind(this, art.name)}>
                    <div className="tc" style={{width}}>
                        {art.imgHeight>art.imgWidth?<img src={art.thumb || art.url} height={width} alt="Loading..."/>:<img src={art.thumb || art.url} width={width} alt="Loading..."/>}
                    </div>
                    <div>
                        {art.displayName}
                    </div>
                </MenuItem>);
            }
        }

        return <Paper>
            {ml}
        </Paper>
    }

    showAltArtworkPicker(){
        this.closeMenu();
        this.setState({showAltPicker:true, showMenu:false});
    }

    showAltArtwork(art) {
        console.log("showAltArtwork", art);
        if (art) {
            if (art == "default") {
                art=null;
            }
            this.props.onClickAltArtwork(art)
        }
        this.setState({showAltPicker:false,showPicker:false});
    }

    showPicker(){
        this.closeMenu();
        this.setState({showPicker:true, showMenu:false});
    }

    onGoToPin(pin){
        this.closeMenu();
        this.props.onGoToPin(pin);
    }

    closeMenu() {
        if (this.hoverRef && this.hoverRef.current) {
            this.hoverRef.current.close();
        }
    }

    pickMap(mapInfo) {
        this.closeMenu();
        if (mapInfo) {
            this.props.onPickMap(mapInfo);
        }
        this.setState({showPicker:false});
    }

    showSearchWeb() {
        this.closeMenu();
        this.setState({showSearchWeb:true});
    }

    selectFromWeb(url, search) { 
        if (url) {
            return this.setState({defaultName:search||"map", showNewMap:true, webURL:url, file:null, showSearchWeb:false});
        } else {
            this.setState({showSearchWeb:false});
        }
    }

    selectFile(e) {
        this.closeMenu();
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            // upload file
            const file = e.target.files[0];
            return this.setState({defaultName:cleanFilename(file.name), showNewMap:true, webURL:null, file});
        }
    }
}

class MapsHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        return <span>
            Maps
            <MapPickList noPickMap onPickMap={function(){}}/>
        </span>;
    }
}

class MapHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        const map =campaign.getMapInfo(this.props.id);

        return <span>
            {map && map.displayName}
        </span>;
    }
}

export {
    RenderMapList,
    MapDialog,
    MapsHeader,
    MapHeader,
    MapPicker,
    MapPickList,
    AltMapPicker,
    MapDialogReadOnly
}