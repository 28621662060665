const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {NewBook} = require('./book.jsx');
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
const {Dialog} = require('./responsivedialog.jsx');
const {getMyCreationsContentTypes,contentTypeMap,getMyCreationsNewContentTypes} = require('./contentmap.jsx');
const {DeleteWithConfirm, TextBasicEdit,defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
const {NewSubclass, EditClass} = require('./renderclass.jsx');
const {ListFilter} = require('./listfilter.jsx');

class MyCreations extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({update:campaign.newUid()});  // just force a redraw
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "");
    }

	render() {
        const selectCount = Object.keys(this.state.selected||{}).length;
        const list =this.getFullList();

        if (!list.length) {
            return <div className="w-100 pa2">You have no creations</div>
        }
        
        return <div className="w-100 pa2">
            <ListFilter 
                list={list}
                filters={myCreationsFilter}
                groupBy="type"
                defaultCollapsed
                onClick={this.onClick.bind(this)}
                select="list"
                selected={this.state.selected}
                onSelectedChange={this.onSelectedChange.bind(this)}
                selectAll
                extraSelectInfo
                extraButtons={<DeleteWithConfirm 
                    className="ml2 minw2" 
                    disabled={!selectCount} 
                    color="primary" 
                    variant="outlined" 
                    size="small" 
                    onClick={this.deleteCreations.bind(this)}
                    useButton
                    name={(selectCount>1)?(selectCount+" creations"):"1 creation"}
                    confirm={(selectCount>1)?"delete":null}
                    extraDescription="Your creations will be permanently deleted."
                />
                }
            />
            {this.state.showEdit}
        </div>;
    }

    deleteCreations() {
        const {selected} = this.state;
        for (let i in selected) {
            const info = selected[i].id;
            const cm = contentTypeMap[info.type];
            if (cm && cm.delete) {
                cm.delete(info.name);
            }
        }
        this.setState({selected:null});
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    onClick(name, id) {
        const cm = contentTypeMap[id.type];
        if (!cm || !cm.edit) return;
        this.setState({showEdit:cm.edit(id.id||id.name, this.doneEdit.bind(this))});
    }

    doneEdit() {
        this.setState({showEdit:null});
    }

    getFullList() {
        const list = [];

        const myList = getMyCreationsContentTypes();
        for (let t of myList) {
            const el = getEditedContent(t);
            for (let it of el) {
                list.push({
                    type:t,
                    id:{
                        id:it.id,
                        name:it.name,
                        type:t
                    },
                    name:it.name,
                    displayName:(it.extraPrefix||"")+(it.displayName||""),
                    source:it.source
                })
            }
        }
        return list;
    }
}

const myCreationsFilter = [
    {
        filterName:"Type",
        fieldName:"type",
    },
    defaultSourceFilter,
    {
        filterName:"Books",
        fieldName:"bookref",
        convertField: function(bookref, it) {
            return defaultBookFilter.convertField(bookref,it.id);
        }
    }
];


function  getEditedContent(contentType) {
    const cm = contentTypeMap[contentType];
    if (!cm) {
        return [];
    }
    const list = cm.loader({});
    const editedList = [];

    for (let i in list) {
        if (list[i].edited) {
            editedList.push(list[i]);
        }
    }
    return editedList;
}

function eatClicks(e) {
    e.stopPropagation();
}

const subclassMap = {
    edit:function(id, onClose) {
        return <EditClass cclass={id.className} subclass={id.subclassName} open onClose={onClose}/>
    },
    delete:function(name) {
        campaign.deleteCampaignContent("classes", name);
    },
    createNew:function(onClose) {
        return <NewSubclass show onClose={onCloseFn}/>;

        function onCloseFn(className, subclassName) {
            if (className && subclassName) {
                const cls = campaign.getSubclassInfo(subclassName);
                onClose({name:cls.name, id:{className, subclassName}, extraPrefix:className+" - ", displayName:cls.displayName});
            } else {
                onClose();
            }
        }
    },
};

class MyCreationsHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        const menu = this.getPickMenu();
        const {selectedType} = this.state;
        const selected = (selectedType=="Subclasses")?subclassMap:contentTypeMap[selectedType];

        return <span>
            My Creations
            <Button className="ml1 minw2" color="secondary" variant="outlined" size="small" onClick={this.onShowNewMenu.bind(this)}>New</Button>
            <NewBook open={this.state.showNewBook} onClose={this.closeNewBook.bind(this)}/>
            <TextBasicEdit show={this.state.showNew} label="Name of Custom Type" onChange={this.onNewCustom.bind(this)} info="This will create a new type of entry.  Only use this when you need a new data type." />
            {menu}
            {this.state.showNewContent && selected.createNew(this.onCreateNew.bind(this))}
            {this.state.showEditContent&& selected.edit(this.state.showEditInfo.id||this.state.showEditInfo.name, this.doneEditContent.bind(this))}
        </span>;
    }

    onNewBook() {
        this.setState({showNewBook:true, onShowNewMenu:false});
    }

    closeNewBook(name){
        if (name) {
            window.location.href = "/#book?mode=edit&id="+encodeURIComponent(name);
        }
        this.setState({showNewBook:false});
    }

    onNewCustom(name) {
        this.setState({showNew:false});
        if (name) {
            window.location.href="/#customtable?type="+encodeURIComponent(name);
        }
    }

    closeNewItem() {
        this.setState({showNewItem:false, onShowNewMenu:false});
    }

    onCreateNew(info, noEdit) {
        if (info) {
            this.setState({showEditContent:!noEdit, showEditInfo:info});
        }
        this.setState({showNewContent:false});
    }

    doneEditContent() {
        this.setState({showEditContent:false});
    }

    onShowNewMenu(e) {
        this.setState({onShowNewMenu:true, anchorEl:e.target});
    }

    getPickMenu() {
        if (!this.state.onShowNewMenu) {
            return null;
        }
        const list = getMyCreationsNewContentTypes();

        const ml = [];
        ml.push(<MenuItem key="_b" onClick={this.onNewBook.bind(this)}>Book</MenuItem>)
        ml.push(<MenuItem key="_nc" onClick={this.onNew.bind(this)}>Custom Type</MenuItem>)
        ml.push(<Divider key="_d"/>);
        for (let i in list) {
            const it = list[i];
            ml.push(<MenuItem key={i} onClick={this.onPickNew.bind(this, it)}>{it}</MenuItem>);
            if (it=="Classes") {
                ml.push(<MenuItem key="sc" onClick={this.onPickNew.bind(this, "Subclasses")}>Subclasses</MenuItem>);
            }
        }

        return <Menu 
            open 
            anchorEl={this.state.anchorEl} 
            onClose={this.hideMenu.bind(this)}
        >
            {ml}
        </Menu>;
    }

    hideMenu(){
        this.setState({onShowNewMenu:false});
    }

    onPickNew(selectedType) {
        if (selectedType) {
            this.setState({showNewContent:true, selectedType});
        }
        this.setState({onShowNewMenu:false});
    }
}


export {
    MyCreations,
    MyCreationsHeader
}